import React from 'react';
import { Route, Routes } from "react-router-dom";
import Home from './Home';
import Draft from './Draft';
import Game from './Game';
import Result from './Result';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  render() {
    return <div className="App">
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/draft" element={<Draft />} />
        <Route path="/game" element={<Game />} />
        <Route path="/result" element={<Result />} />
      </Routes>
    </div>
  }
}

export default App;
