import { useEffect, useState } from "react";
import { playerNames } from "./Common";
import { useNavigate } from 'react-router-dom';

const Result = (props) => {
    const [scoreList] = useState(localStorage.getItem('scoreList')?.split(',') || []);
    const [rankList, setRankList] = useState([]);   // [userIndex, score]

    const [startDate] = useState(new Date(localStorage.getItem('startDate')));
    const [endDate, setEndDate] = useState(new Date());

    const navigate = useNavigate();

    useEffect(() => {
        const rankList = [];
        scoreList.forEach((score, i) => {
            rankList.push([i, score]);
        });
        rankList.sort((a, b) => b[1] - a[1]);
        setRankList(rankList);
    }, [scoreList]);
    
    useEffect(() => {
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6);
        setEndDate(endDate);
    }, [startDate]);

    const handleBackClick = () => {
        navigate('/');
    };
    
    return (
    <div className="min-h-screen bg-gray-100 p-6">
        <div className="max-w-2xl mx-auto space-y-6">
        {/* Main Rank Display */}
        <div className="text-center">
            <div className="text-6xl font-bold text-blue-600">#{rankList.findIndex(r => r[0] === 0) + 1}</div>
            <div className="text-xl text-gray-600 mt-2">Your Ranking</div>
        </div>

        {/* Players List */}
        <div className="bg-white rounded-lg shadow-lg p-6">
            <h2 className="text-xl font-semibold mb-4">Leaderboard</h2>
            <div className="space-y-3">
                {rankList.map((rank) => (
                <div
                    key={rank[0]}
                    className={`flex items-center justify-between p-3 rounded-lg ${
                    rank[0] === 0
                        ? 'bg-blue-100'
                        : 'bg-gray-50'
                    }`}
                >
                    <div className="flex items-center space-x-4">
                    <span className="text-lg font-semibold w-8">#{rank[0] + 1}</span>
                    <span className="text-lg">{playerNames[rank[0]]}</span>
                    </div>
                    <span className="font-mono text-lg">{rank[1]}</span>
                </div>
                ))}
            </div>
        </div>

        {/* Play Time Info */}
        <div className="bg-white rounded-lg shadow-lg p-4">
            <div className="text-center">
                <p className="text-gray-600">Actual Match Period</p>
                <p className="text-xl font-semibold">{startDate.toDateString()} ~ {endDate.toDateString()}</p>
            </div>
        </div>

        {/* Back Button */}
        <div className="text-center">
            <button
                onClick={handleBackClick}
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
                <svg
                className="w-4 h-4 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10 19l-7-7m0 0l7-7m-7 7h18"
                />
                </svg>
                <span>Back to Main Menu</span>
            </button>
        </div>
        </div>
    </div>
    );
};

export default Result;