import { useNavigate } from 'react-router-dom';

const Home = (props) => {
    const navigate = useNavigate();

    const handleGetStarted = () => {
        localStorage.clear();
        navigate('/draft');
    };

    return (
        <div className="relative h-screen w-screen overflow-hidden">
          {/* Background Image with Blur */}
          <div 
            className="absolute inset-0 bg-cover bg-center bg-no-repeat filter blur-sm"
            style={{
              backgroundImage: `url("${process.env.PUBLIC_URL}/images/background.jpg")`,
              transform: 'scale(1.1)' // Prevents blur from showing edges
            }}
          />
          
          {/* Overlay to darken the background slightly */}
          <div className="absolute inset-0 bg-black/30" />
    
          {/* Content Container */}
          <div className="relative h-full flex flex-col items-center justify-between py-12">
            {/* Spacer for top alignment */}
            <div className="flex-1" />
            
            {/* Logo Container */}
            <div className="flex-1 flex items-center justify-center">
              {/* Replace with your actual logo */}
              <div>
                <h1 className="text-5xl font-bold text-gray-800">
                    <img
                        src={`${process.env.PUBLIC_URL}/images/logo.png`}
                        alt="Logo"
                    />
                </h1>
              </div>
            </div>
    
            {/* Button Container */}
            <div className="flex-1 flex items-end">
              <button
                onClick={handleGetStarted}
                className="px-10 py-5 text-4xl font-semibold text-white bg-blue-600 rounded-lg shadow-lg 
                         hover:bg-blue-700 transform hover:scale-105 transition-all duration-200
                         focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Start Game
              </button>
            </div>
          </div>
        </div>
      );

    return <div
        style={{
            // align center
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/background.jpg)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
        }}>
            <div
                style={{
                    display: "flex",
                    maxWidth: "500px",
                    margin: "0 auto",
                    padding: "16px",
                    flexDirection: "column",
                    alignItems: "center",
                    position: "relative",
                }}
            >
                <img
                    src={`${process.env.PUBLIC_URL}/images/logo.png`}
                    alt="Logo"
                    style={{
                        width: "100%",
                        height: "auto",
                        maxWidth: "100%",
                        marginBottom: "100px",
                    }}
                />

                <button
                style={{
                    width: "100%",
                    minHeight: "72px",
                    backgroundColor: "rgb(240, 66, 44)",
                    color: "white",
                    border: "1px solid black",
                    boxShadow: "4px 4px 0px 0px rgba(0,0,0,0.25)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "8px 0",
                    cursor: "pointer",
                }}
                onClick={() => {
                    localStorage.clear();
                    navigate('/draft');
                }}>
                <p
                    style={{
                    margin: 0,
                    fontSize: "28px",
                    fontWeight: 600,
                    }}
                >
                    시작하기
                </p>
                </button>
            </div>
        </div>
    ;
};

export default Home;