export const hitterCategories = [
    {
        name: 'R',
        title: '득점 (Runs)',
        point: n => n * 1,
    },
    {
        name: 'H',
        title: '안타 (Hits)',
        point: n => n * 1,
        sum: n => n * 1,
    },
    {
        name: '2B',
        title: '2루타 (Doubles)',
        point: n => n * 2,
        sum: n => n * 2,
    },
    {
        name: '3B',
        title: '3루타 (Triples)',
        point: n => n * 3,
        sum: n => n * 3,
    },
    {
        name: 'HR',
        title: '홈런 (Home Runs)',
        point: n => n * 4,
        sum: n => n * 4,
    },
    {
        name: 'RBI',
        title: '타점 (Runs Batted In)',
        point: n => n * 1,
    },
    {
        name: 'BB',
        title: '볼넷 (Walks)',
        point: n => n * 1,
    },
    {
        name: 'SO',
        title: '삼진 (Strikeouts)',
        point: n => n * -1,
        sum: n => n * -1,
    },
    {
        name: 'HP',
        title: '몸에 맞는 공 (Hit By Pitch)',
        point: n => n * 1,
    },
    {
        name: 'SB',
        title: '도루 (Stolen Bases)',
        point: n => n * 1,
    },
    {
        name: 'CS',
        title: '도루 실패 (Caught Stealing)',
        point: n => n * -1,
    },
    {
        name: 'GDP',
        title: '병살타 (Ground Into Double Play)',
        point: n => n * -1,
        sum: n => n * -1,
    },
    {
        name: 'SH',
        title: '희생번트 (Sacrifice Hit)',
        point: n => n * 1,
    },
    {
        name: 'SF',
        title: '희생플라이 (Sacrifice Fly)',
        point: n => n * 1,
    },
];

export const pitcherCategories = [
    {
        name: 'IP',
        title: '투구 이닝 (Innings Pitched)',
        point: n => (n * 10 % 10) + Math.floor(n) * 3,
    },
    {
        name: 'ER',
        title: '자책점 (Earned Runs)',
        point: n => n * -1,
    },
    {
        name: 'H',
        title: '피안타 (Hits Allowed)',
        point: n => n * -1,
    },
    {
        name: 'HR',
        title: '피홈런 (Home Runs Allowed)',
        point: n => n * -1,
    },
    {
        name: 'BB',
        title: '볼넷 (Base on Balls)',
        point: n => n * -1,
    },
    {
        name: 'HP',
        title: '몸에 맞는 공 (Hit By Pitch)',
        point: n => n * -1,
    },
    {
        name: 'SO',
        title: '삼진 (Strikeouts)',
        point: n => n * 1,
    },
    {
        name: 'DEC',
        title: '경기결과',
        point: c => {
            if (c == 'W') {
                return 3;
            } else if (c == 'L') {
                return -3;
            } else {
                return 0;
            }
        },
    },
];

export const gamePositions = [
    {
        type: '야수',
        position: 'C',
        name: '포수',
        number: [2],
        selectable: p => p == 'C',
    },
    {
        type: '야수',
        position: '1B',
        name: '1루수',
        number: [3],
        selectable: p => p == '1B',
    },
    {
        type: '야수',
        position: '2B',
        name: '2루수',
        number: [4],
        selectable: p => p == '2B',
    },
    {
        type: '야수',
        position: '3B',
        name: '3루수',
        number: [5],
        selectable: p => p == '3B',
    },
    {
        type: '야수',
        position: 'SS',
        name: '유격수',
        number: [6],
        selectable: p => p == 'SS',
    },
    {
        type: '야수',
        position: 'OF',
        name: '외야수1',
        filter: ['LF', 'CF', 'RF'],
        number: [7, 8, 9],
        selectable: p => ['LF', 'CF', 'RF'].includes(p),
    },
    {
        type: '야수',
        position: 'OF',
        name: '외야수2',
        filter: ['LF', 'CF', 'RF'],
        number: [7, 8, 9],
        selectable: p => ['LF', 'CF', 'RF'].includes(p),
    },
    {
        type: '야수',
        position: 'OF',
        name: '외야수3',
        filter: ['LF', 'CF', 'RF'],
        number: [7, 8, 9],
        selectable: p => ['LF', 'CF', 'RF'].includes(p),
    },
    {
        type: '야수',
        position: 'UTILS',
        name: '유틸리티',
        // filter: ['P'] 제외
        selectable: p => p != 'P',
    },

    {
        type: '선발투수',
        position: 'SP',
        name: '선발투수1',
        filter: ['P'],
        number: [1],
        selectable: p => p == 'P',
    },
    {
        type: '선발투수',
        position: 'SP',
        name: '선발투수2',
        filter: ['P'],
        number: [1],
        selectable: p => p == 'P',
    },
    {
        type: '선발투수',
        position: 'SP',
        name: '선발투수3',
        filter: ['P'],
        number: [1],
        selectable: p => p == 'P',
    },
    {
        type: '선발투수',
        position: 'SP',
        name: '선발투수4',
        filter: ['P'],
        number: [1],
        selectable: p => p == 'P',
    },
    {
        type: '선발투수',
        position: 'SP',
        name: '선발투수5',
        filter: ['P'],
        number: [1],
        selectable: p => p == 'P',
    },

    {
        type: '중간계투',
        position: 'RP',
        name: '중간계투1',
        filter: ['P'],
        number: [1],
        selectable: p => p == 'P',
    },
    {
        type: '중간계투',
        position: 'RP',
        name: '중간계투2',
        filter: ['P'],
        number: [1],
        selectable: p => p == 'P',
    },
    {
        type: '중간계투',
        position: 'RP',
        name: '중간계투3',
        filter: ['P'],
        number: [1],
        selectable: p => p == 'P',
    },
]

export const positions = [
    ...gamePositions,
    {
        type: '후보선수',
        position: 'SUB',
        name: '후보선수1',
        // 필터 없음
        selectable: p => true,
    },
    {
        type: '후보선수',
        position: 'SUB',
        name: '후보선수2',
        // 필터 없음
        selectable: p => true,
    },
    {
        type: '후보선수',
        position: 'SUB',
        name: '후보선수3',
        // 필터 없음
        selectable: p => true,
    },
];

export const playerNames = ['Me', 'Smith', 'Johnson', 'Williams', 'Jones', 'Miller', 'Moore', 'Thomas'];

export const isPitcher = player => player.position !== 'P';

export const config = {
    PLAYER_COUNT: 5,
    TIMER_SEC: 1,
};