import { useCallback, useEffect, useState } from "react";
import { gamePositions, hitterCategories, pitcherCategories, positions } from "./Common";
import playerInfoMap from "./data/PlayerInfo";
import axios from 'axios';
import { useNavigate } from "react-router-dom";

const HITTER_COUNT = 9;

const Game = (props) => {
    const navigate = useNavigate();

    const [draftIdList, setDraftList] = useState([]);
    const [playerCount, setPlayerCount] = useState(0);
    const [dateList, setDateList] = useState([]);
    const [statMap, setStatMap] = useState(new Map());
    const [scoreList, setScoreList] = useState([]);

    useEffect(() => {
        if (localStorage.getItem('scoreList')) {
            navigate('/result', { replace: true });
            return;
        }

        const draftIdList = localStorage.getItem('selectedIdList')?.split(',') || [];
        setDraftList(draftIdList);

        const playerCount = draftIdList.length / positions.length;
        setPlayerCount(playerCount);

        const scoreList = Array(playerCount).fill(0);
        setScoreList(scoreList);

        let startDate = localStorage.getItem('startDate') ? new Date(localStorage.getItem('startDate')) : null;
        if (!startDate) {
            startDate = new Date(2024, 2, 26 + (7 * Math.round(21 * Math.random())));
            localStorage.setItem('startDate', startDate.toISOString());
        }

        const dateList = [...Array(7)].map((_, i) => {
            startDate.setDate(startDate.getDate() + i);
    
            const monthStr = `${startDate.getMonth() + 1}`.padStart(2, '0');
            const dateStr = `${startDate.getDate()}`.padStart(2, '0');
            return `${monthStr}-${dateStr}`;
        });
        setDateList(dateList);

        const statMap = new Map();
        draftIdList.forEach(async (id, i) => {
            // load json resource
            const res = await axios.get(`${process.env.PUBLIC_URL}/stat/${id}_stat.json`);
            const stat = res.data;
            for (const date of dateList) {
                if (!stat[date]) continue;
    
                statMap.set(`${id}_${date}`, stat[date]);
            }
        });
        setStatMap(statMap);
    }, []);  

    const [todayIndex, setTodayIndex] = useState(0); // 현재 날짜
    const [dateIndex, setDateIndex] = useState(0); // 선택한 날짜

    const [lineupList, setLineupList] = useState([]); // [(gamePositions.length + positions.length) * DAY_COUNT]
    const [sumList, setSumList] = useState([]); // [(hitterCategories.length + pitcherCategories.length) * DAY_COUNT]

    const [selectedPlayer, setSelectedPlayer] = useState(null);

    const getPlayerList = (playerIdList) => {
        // 오늘 경기 참여하는 선수 우선 선택
        return playerIdList
        .map((id, i) => {
            if (!id) return null;

            const { name, position, record } = playerInfoMap.get(id);
            const stat = statMap.get(`${id}_${dateList[dateIndex]}`);
            let point = null;
            const isPitcher = position === 'P';
            const gameCount = parseInt(record.R) || 1;
            let recordPoint = 0;
            if (isPitcher) {
                pitcherCategories.forEach(c => {
                    if (!c.point) return 0;
                    if (!record[c.name]) return 0;
                    recordPoint += c.point(record[c.name]);
                });
            } else {
                hitterCategories.forEach(c => {
                    if (!c.point) return 0;
                    if (!record[c.name]) return 0;
                    recordPoint += c.point(record[c.name]);
                });
            }
            record.point = recordPoint;
            record.avg = recordPoint !== 0 ? (recordPoint / gameCount).toFixed(2) : 0;

            if (stat) {
                point = 0;

                if (isPitcher) {
                    pitcherCategories.forEach(c => {
                        if (!c.point) return 0;
                        if (!stat[c.name]) return 0;
                        point += c.point(stat[c.name]);
                    });
                } else {
                    hitterCategories.forEach(c => {
                        if (!c.point) return 0;
                        if (!stat[c.name]) return 0;
                        point += c.point(stat[c.name]);
                    });
                }
            }

            return {
                id,
                name,
                position,
                record,
                stat,
                point,
            };
        })
        .sort((a, b) => {
            // 경기 결과 있는 선수 우선
            const hasA = a.stat ? true : false;
            const hasB = b.stat ? true : false;

            if (hasA !== hasB) {
                return hasA ? -1 : 1;
            }

            return 0;
        });
    };

    useEffect(() => {
        if (draftIdList.length === 0) {
            return;
        }

        if (lineupList.length >= (gamePositions.length + positions.length) * (todayIndex + 1)) {
            return;
        }

        let playerIdList;
        if (todayIndex === 0) {
            // 드래프트 우선 순위
            playerIdList = draftIdList.slice(0, positions.length);
        } else {
            // 전날 라인업 우선 순위
            playerIdList = lineupList
                        .slice((gamePositions.length + positions.length) * (todayIndex - 1), (gamePositions.length + positions.length) * todayIndex)
                        .filter(player => player)
                        .map(player => player?.id)
                        ;
        }

        const playerList = getPlayerList(playerIdList);

        const todayLineupList = Array(gamePositions.length + positions.length).fill(null);
        
        for (const player of playerList) {
            if (!player) continue;

            let success = false;
            for (let i = 0; i < gamePositions.length; i++) {
                if (!todayLineupList[i] && gamePositions[i].selectable(player.position)) {
                    todayLineupList[i] = player;
                    success = true;
                    break;
                }
            }

            if (success) continue;

            const draftIndex = draftIdList.slice(0, positions.length).findIndex(draftId => draftId === player.id);
            todayLineupList[draftIndex + gamePositions.length] = player;
        }

        setLineupList([...lineupList, ...todayLineupList]);
    }, [todayIndex, dateIndex, draftIdList, lineupList]);

    const confirmLineup = useCallback(() => {
        if (todayIndex !== dateIndex) return;

        // 오늘 라인업 점수 합계
        const sumList = Array(hitterCategories.length + pitcherCategories.length).fill(0);

        const startIndex = (gamePositions.length + positions.length) * todayIndex;
        const endIndex = (gamePositions.length + positions.length) * (todayIndex + 1);
        const todayLineupList = lineupList.slice(startIndex, endIndex);
        for (let i = 0; i < gamePositions.length; i++) {
            const player = todayLineupList[i];
            if (!player) continue;

            if (player.stat) {
                scoreList[0] += player.point;

                if (player.position !== 'P') {
                    hitterCategories.forEach((c, categoryIndex) => {
                        if (!c.point) return;
                        sumList[categoryIndex] += c.point(player.stat[c.name]);
                    });
                } else {
                    pitcherCategories.forEach((c, categoryIndex) => {
                        if (!c.point) return;
                        sumList[hitterCategories.length + categoryIndex] += c.point(player.stat[c.name]);
                    });
                }
            }
        }

        setSumList(prevSumList => [...prevSumList, ...sumList]);

        // 다른 유저 라인업 생성 및 점수 합계
        for (let i = 1; i < playerCount; i++) {
            const playerIdList = draftIdList.slice(i * positions.length, (i + 1) * positions.length);
            const playerList = getPlayerList(playerIdList);

            const otherLineupList = Array(gamePositions.length).fill(null);
            for (const player of playerList) {
                if (!player) continue;

                for (let p = 0; p < gamePositions.length; p++) {
                    if (!otherLineupList[p] && gamePositions[p].selectable(player.position)) {
                        otherLineupList[p] = player;
                        if (player.stat) {
                            scoreList[i] += player.point;
                        }
                        break;
                    }
                }
            }
        }

        setTodayIndex(todayIndex + 1);
        setScoreList([...scoreList]);
    }, [todayIndex, dateIndex, draftIdList, lineupList, scoreList, playerCount]);

    const onClickFinish = useCallback(() => {
        if (todayIndex !== 7) return;

        // sumList 저장
        localStorage.setItem('scoreList', scoreList.join(','));

        navigate('/result', { replace: true });
    }, [todayIndex, scoreList, navigate]);

    const movePlayer = (index) => {
        if (!selectedPlayer) return;

        if (index >= 0 && !gamePositions[index].selectable(selectedPlayer.position)) return;

        const startIndex = (gamePositions.length + positions.length) * dateIndex;
        const endIndex = (gamePositions.length + positions.length) * (dateIndex + 1);

        const fromIndex = startIndex + lineupList.slice(startIndex, endIndex).findIndex(player => player?.id === selectedPlayer.id);
        const toIndex = startIndex + (index < 0 ? draftIdList.findIndex(playerId => playerId === selectedPlayer.id) + gamePositions.length : index);
        if (!lineupList[toIndex]) {
            lineupList[fromIndex] = null;
        } else {
            const prevPlayer = lineupList[toIndex];
            if (fromIndex >= gamePositions.length + startIndex || gamePositions[fromIndex - startIndex].selectable(prevPlayer.position)) {
                // 자리 바꾸기
                lineupList[fromIndex] = prevPlayer;
            } else {
                // 해당 위치 선수를 벤치로 이동
                const benchIndex = draftIdList.findIndex(playerId => playerId === prevPlayer.id) + gamePositions.length;
                lineupList[startIndex + benchIndex] = prevPlayer;
                lineupList[fromIndex] = null;
            }
        }
        
        lineupList[toIndex] = selectedPlayer;
        setLineupList([...lineupList]);
        setSelectedPlayer(null);
    };

    const printActionButton = (index) => {
        // 지난 날짜면 출력하지 않음
        if (dateIndex < todayIndex) return <></>;

        const player = lineupList[index];
        if (selectedPlayer) {
            if (selectedPlayer.id === player?.id) {
                // 현재 선택된 플레이어와 동일하면 Cancel 출력
                return <button className="px-2 py-1 text-red-600 border border-red-300 rounded hover:bg-red-100 hover:border-red-400 transition-colors" onClick={() => setSelectedPlayer(null)}>Cancel</button>;
            }

            if (index >= gamePositions.length) {
                return <button className="px-2 py-1 text-green-600 border border-green-300 rounded hover:bg-green-100 hover:border-green-400 transition-colors" onClick={() => movePlayer(-1)}>Here</button>;
            }
            
            if (gamePositions[index].selectable(selectedPlayer.position)) {
                // 이동 가능하면 Here
                return <button className="px-2 py-1 text-green-600 border border-green-300 rounded hover:bg-green-100 hover:border-green-400 transition-colors" onClick={() => movePlayer(index)}>Here</button>;
            }
        } else if (player) {
            // 선택한 선수 없을 때 해당 위치에 선수가 있으면 Move
            return <button className="px-2 py-1 text-blue-600 border border-blue-300 rounded hover:bg-blue-100 hover:border-blue-400 transition-colors" onClick={() => setSelectedPlayer(player)}>Move</button>;
        }        

        return <></>;
    };
    
    const isCompletedDate = dateIndex < todayIndex;
    return <>
        <div className="min-h-screen bg-gray-50 p-4">
        {/* Header Section */}
            <nav className="max-w-4xl mx-auto">
                <div className="flex items-center justify-between mb-4">
                <button
                    disabled={dateIndex <= 0}
                    onClick={() => setDateIndex(i => i - 1)}
                    className="px-4 py-2 bg-white border border-gray-300 rounded-lg shadow-sm hover:bg-gray-50
                    disabled:bg-slate-50 disabled:text-slate-300 disabled:border-slate-200 disabled:shadow-none"
                >
                    ←
                </button>
                
                {
                    ['Thu', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'Mon'].map((day, i) => {
                        if (i === dateIndex) {
                            return <p key={i} className="text-2xl font-bold text-gray-800">{day}</p>;
                        }

                        return <p key={i} className="text-2xl font-normal text-gray-300">{day}</p>;
                    })
                }
                
                <button
                    disabled={!isCompletedDate || dateIndex >= 6}
                    onClick={() => setDateIndex(i => i + 1)}
                    className="px-4 py-2 bg-white border border-gray-300 rounded-lg shadow-sm hover:bg-gray-50
                    disabled:bg-slate-50 disabled:text-slate-300 disabled:border-slate-200 disabled:shadow-none"
                >
                    →
                </button>
                </div>

                {
                    todayIndex === 7
                    ? <button
                        onClick={onClickFinish}
                        className="w-full py-3 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 mb-6"
                        >
                        Show Result
                    </button>
                    : dateIndex < todayIndex
                    ? <button
                        onClick={() => setDateIndex(i => i + 1)}
                        className="w-full py-3 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 mb-6"
                        >
                        Go Next
                    </button>
                    : <button
                        onClick={confirmLineup}
                        className="w-full py-3 bg-green-600 text-white font-semibold rounded-lg shadow-md hover:bg-green-700 mb-6"
                        >
                        Finalize Today Lineup
                    </button>
                }
            </nav>

            <main className="max-w-7xl mx-auto mb-6">
                <div style={{
                    padding: '12px',
                }}>
                    <table className="divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase" colSpan={3}>Batter</th>
                            </tr>
                            <tr>
                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Slot</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Name</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">-</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Opposite</th>
                                {
                                    hitterCategories.map((c, i) => {
                                        return <th key={i} className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">{c.name}</th>;
                                    })
                                }
                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Points</th>
                                {
                                    isCompletedDate
                                    ? <></>
                                    : <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Average</th>
                                }
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            {
                                lineupList
                                .slice((gamePositions.length + positions.length) * dateIndex, (gamePositions.length + positions.length) * (dateIndex + 1))
                                .map((player, i) => {
                                    if (i >= HITTER_COUNT && (!player || player.position === 'P')) return <></>;

                                    const hasPosition = i < HITTER_COUNT;
                                    return <tr key={i} className={`${selectedPlayer === player ? 'bg-blue-50' : 'hover:bg-gray-50'}`}>
                                        <td style={{ padding: "8px 12px", textAlign: 'center', }}>{hasPosition ? gamePositions[i].position : 'Bench'}</td>
                                        <td style={{ padding: "8px 12px", textAlign: 'center', }}>{player?.name || 'Empty'}</td>
                                        <td style={{ width: '90px', height: '52px', padding: "8px 12px", textAlign: 'center', }}>{printActionButton(i)}</td>
                                        <td style={{ padding: "8px 12px", textAlign: 'center', }}>{player?.stat?.['상대'] || '-'}</td>
                                        {
                                            hitterCategories.map((c, i) => {
                                                return <td key={i} style={{ padding: "8px 12px", textAlign: 'right' }}>{(isCompletedDate  ? player?.stat : player?.record)?.[c.name] || '-'}</td>;
                                            })
                                        }
                                        {
                                            isCompletedDate
                                            ? <td style={{ padding: "8px 12px", textAlign: 'right', }}>{player && player?.point !== null ? player?.point : '-'}</td>
                                            : <>
                                                <td style={{ padding: "8px 12px", textAlign: 'right', }}>{player ? player?.record?.point : '-'}</td>
                                                <td style={{ padding: "8px 12px", textAlign: 'right', }}>{player ? player?.record?.avg : '-'}</td>
                                            </>
                                        }
                                    </tr>;
                                })
                            }
                            {
                                isCompletedDate
                                ? <tr style={{ padding: "8px 12px", borderBottom: '1px solid rgb(237, 238, 240)', textAlign: 'right', }}>
                                    <td style={{ padding: "8px 12px", textAlign: 'center', }}></td>
                                    <td style={{ padding: "8px 12px", textAlign: 'center', }}></td>
                                    <td style={{ padding: "8px 12px", textAlign: 'center', }}></td>
                                    <td style={{ padding: "8px 12px", textAlign: 'center', }}>Total</td>
                                    {
                                        sumList.slice(dateIndex * (hitterCategories.length + pitcherCategories.length), (dateIndex + 1) * (hitterCategories.length + pitcherCategories.length) - pitcherCategories.length)
                                        .map((sum, i) => {
                                            return <td key={i} style={{ padding: "8px 12px", textAlign: 'right' }}>{sum}</td>;
                                        })
                                    }
                                    <td style={{ padding: "8px 12px", textAlign: 'right', }}>
                                        {
                                            sumList.slice(dateIndex * (hitterCategories.length + pitcherCategories.length), (dateIndex + 1) * (hitterCategories.length + pitcherCategories.length) - pitcherCategories.length)
                                            .reduce((a, b) => {
                                                return a + b;
                                            })
                                        }
                                    </td>
                                </tr>
                                : <></>
                            }
                            {
                                selectedPlayer && selectedPlayer.position !== 'P'
                                ? <tr key={'bench'} style={{ padding: "8px 12px", borderBottom: '1px solid rgb(237, 238, 240)', textAlign: 'right', }}>
                                    <td style={{ padding: "8px 12px", textAlign: 'center', }}>Bench</td>
                                    <td style={{ padding: "8px 12px", textAlign: 'center', }}>Empty</td>
                                    <td style={{ width: '90px', height: '52px', padding: "8px 12px", textAlign: 'center', }}><button className="px-2 py-1 text-green-600 border border-green-300 rounded hover:bg-green-100 hover:border-green-400 transition-colors" onClick={() => movePlayer(-1)}>Here</button></td>
                                    <td style={{ padding: "8px 12px", textAlign: 'center', }}>-</td>
                                    {
                                        hitterCategories.map((c, i) => {
                                            return <td key={i} style={{ padding: "8px 12px", textAlign: 'right' }}>-</td>;
                                        })
                                    }
                                    {
                                        isCompletedDate
                                        ? <td style={{ padding: "8px 12px", }}>-</td>
                                        : <>
                                            <td style={{ padding: "8px 12px", }}>-</td>
                                            <td style={{ padding: "8px 12px", }}>-</td>
                                        </>
                                    }
                                </tr>
                                : <></>
                            }
                        </tbody>
                    </table>
                </div>

                <div style={{
                    padding: '12px',
                }}>
                    <table className="divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase" colSpan={3}>Pitcher</th>
                            </tr>
                            <tr>
                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Slot</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Name</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">-</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Opposite</th>
                                {
                                    pitcherCategories.map((c, i) => {
                                        return <th key={i} className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">{c.name}</th>;
                                    })
                                }
                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Points</th>
                                {
                                    isCompletedDate
                                    ? <></>
                                    : <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Average</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                        {
                                lineupList
                                .slice((gamePositions.length + positions.length) * dateIndex, (gamePositions.length + positions.length) * (dateIndex + 1))
                                .map((player, i) => {
                                    if (i < HITTER_COUNT || (i >= gamePositions.length && player?.position !== 'P')) return <></>;

                                    const hasPosition = i < gamePositions.length;
                                    return <tr key={i} style={{ padding: "8px 12px", borderBottom: '1px solid rgb(237, 238, 240)', textAlign: 'right', }}>
                                        <td style={{ padding: "8px 12px", textAlign: 'center', }}>{hasPosition ? gamePositions[i].position : 'Bench'}</td>
                                        <td style={{ padding: "8px 12px", textAlign: 'center', }}>{player?.name || 'Empty'}</td>
                                        <td style={{ width: '90px', height: '52px', padding: "8px 12px", textAlign: 'center', }}>{printActionButton(i)}</td>
                                        <td style={{ padding: "8px 12px", textAlign: 'center', }}>{player?.stat?.['상대'] || '-'}</td>
                                        {
                                            pitcherCategories.map((c, i) => {
                                                return <td key={i} style={{ padding: "8px 12px", textAlign: 'right' }}>{(isCompletedDate ? player?.stat : player?.record)?.[c.name] || '-'}</td>;
                                            })
                                        }
                                        {
                                            isCompletedDate
                                            ? <td style={{ padding: "8px 12px", }}>{player && player?.point !== null ? player?.point : '-'}</td>
                                            : <>
                                                <td style={{ padding: "8px 12px", }}>{player ? player?.record?.point : '-'}</td>
                                                <td style={{ padding: "8px 12px", }}>{player ? player?.record?.avg : '-'}</td>
                                            </>
                                        }
                                    </tr>;
                                })
                            }
                            {
                                isCompletedDate
                                ? <tr style={{ padding: "8px 12px", borderBottom: '1px solid rgb(237, 238, 240)', textAlign: 'right', }}>
                                    <td style={{ padding: "8px 12px", textAlign: 'center', }}></td>
                                    <td style={{ padding: "8px 12px", textAlign: 'center', }}></td>
                                    <td style={{ padding: "8px 12px", textAlign: 'center', }}></td>
                                    <td style={{ padding: "8px 12px", textAlign: 'center', }}>Total</td>
                                    {
                                        sumList.slice(dateIndex * (hitterCategories.length + pitcherCategories.length) + hitterCategories.length, (dateIndex + 1) * (hitterCategories.length + pitcherCategories.length))
                                        .map((sum, i) => {
                                            return <td key={i} style={{ padding: "8px 12px", textAlign: 'right' }}>{sum}</td>;
                                        })
                                    }
                                    <td style={{ padding: "8px 12px", textAlign: 'right', }}>
                                        {
                                            sumList.slice(dateIndex * (hitterCategories.length + pitcherCategories.length) + hitterCategories.length, (dateIndex + 1) * (hitterCategories.length + pitcherCategories.length))
                                            .reduce((a, b) => {
                                                return a + b;
                                            })
                                        }
                                    </td>
                                </tr>
                                : <></>
                            }
                            {
                                selectedPlayer && selectedPlayer.position === 'P'
                                ? <tr key={'bench'} style={{ padding: "8px 12px", borderBottom: '1px solid rgb(237, 238, 240)', textAlign: 'right', }}>
                                    <td style={{ padding: "8px 12px", textAlign: 'center', }}>Bench</td>
                                    <td style={{ padding: "8px 12px", textAlign: 'center', }}>Empty</td>
                                    <td style={{ width: '90px', height: '52px', padding: "8px 12px", textAlign: 'center', }}><button className="px-2 py-1 text-green-600 border border-green-300 rounded hover:bg-green-100 hover:border-green-400 transition-colors" onClick={() => movePlayer(-1)}>Here</button></td>
                                    <td style={{ padding: "8px 12px", textAlign: 'center', }}>-</td>
                                    {
                                        pitcherCategories.map((c, i) => {
                                            return <td key={i} style={{ padding: "8px 12px", textAlign: 'right' }}>-</td>;
                                        })
                                    }
                                    {
                                        isCompletedDate
                                        ? <td style={{ padding: "8px 12px", }}>-</td>
                                        : <>
                                            <td style={{ padding: "8px 12px", }}>-</td>
                                            <td style={{ padding: "8px 12px", }}>-</td>
                                        </>
                                    }
                                </tr>
                                : <></>
                            }
                        </tbody>
                    </table>
                </div>
            </main>
        </div>
    </>;
};

export default Game;