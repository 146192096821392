import { useCallback, useEffect, useState } from "react";
import { playerNames, positions, config } from "./Common";
import playerInfoMap from "./data/PlayerInfo";
import { useNavigate } from 'react-router-dom';

const { PLAYER_COUNT, TIMER_SEC } = config;
const MY_TURN_TIMER = 10 * TIMER_SEC;
const OTHER_TURN_TIMER = 5 * TIMER_SEC;

const CATEGORIES = [
    {
        name: 'H',
        title: '안타',
        point: (position, n) => position === 'P' ? -n : n,
    },
    {
        name: '2B',
        title: '2루타',
        point: (position, n) => position === 'P' ? 0 : n,
    },
    {
        name: '3B',
        title: '3루타',
        point: (position, n) => position === 'P' ? 0 : n,
    },
    {
        name: 'HR',
        title: '홈런',
        point: (position, n) => position === 'P' ? -n : n,
    },
    {
        name: 'SO',
        title: '삼진',
        point: (position, n) => position === 'P' ? n : -n,
    },
    {
        name: 'GDP',
        title: '병살타',
        point: (position, n) => position === 'P' ? 0 : -n,
    },
    {
        name: 'IP',
        title: '투구 이닝',
        point: (position, n) => position === 'P' ? ((n % 1) * 1 + Math.floor(n) * 3) : 0,
    },
    {
        name: 'W',
        title: '승리',
        point: (position, n) => position === 'P' ? n : 0,
    },
];

const calcSum = player => {
    if (!player) return 0;
    return parseInt(CATEGORIES
    .map(c => {
        if (!c.point) return 0;
        if (!player.record[c.name]) return 0;
        return c.point(player.position, parseFloat(player.record[c.name]));
    })
    .reduce((sum, num) => sum + num));
};

const getEmptyIndexFromSelectedIdList = (playerIndex, selectedIdList, player) => {
    const startIndex = playerIndex * positions.length;
    for (let i = 0; i < positions.length; i++) {
        if (selectedIdList[startIndex + i]) {
            continue;
        }

        if (positions[i].selectable(player.position)) {
            return startIndex + i;
        }
    }

    return -1;
};

const Draft = (props) => {
    const navigate = useNavigate();
    if (localStorage.getItem('selectedIdList')) {
        navigate('/game', { replace: true });
    }

    const [playerList, setPlayerList] = useState([]);
    const [pickList, setPickList] = useState([]);

    const [currentTurn, setCurrentTurn] = useState(-1); // Player Index
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [draftIdList, setDraftIdList] = useState(Array(positions.length * PLAYER_COUNT).fill(null));

    const [timerCount, setTimerCount] = useState(OTHER_TURN_TIMER);
    const [isTimerRunning, setIsTimerRunning] = useState(true);
    const [isTurnEnd, setIsTurnEnd] = useState(false);
    const [isRandomPick, setIsRandomPick] = useState(false);

    const isMyTurn = () => currentTurn === 0 && timerCount > 0;

    useEffect(() => {
        const draftList = [];

        playerInfoMap.forEach((player, id) => {
            if (player.record.Year !== '2024') return;
            const sum = calcSum(player);
            const gameCount = parseInt(player.record.R) || 1;
            player.sum = sum;
            player.avg = sum !== 0 ? (sum / gameCount).toFixed(2) : 0;
            draftList.push(player);
        });

        // 높은 선수부터 출력
        draftList.sort((a, b) => b.avg - a.avg);

        setPlayerList(draftList);
    }, []);

    const onClickSelectButton = player => {
        if (currentTurn !== 0) return;

        console.log('Click: select', player.name);
        setSelectedPlayer(player);
    };

    useEffect(() => {
        if (isRandomPick) {
            setIsRandomPick(false);

            for (const player of playerList) {
                const index = getEmptyIndexFromSelectedIdList(currentTurn, draftIdList, player);
                if (index >= 0) {
                    setSelectedPlayer(player);
                    return;
                }
            }
        }
    }, [isRandomPick, playerList, currentTurn, draftIdList]);

    useEffect(() => {
        if (!isTurnEnd) {
            return;
        }

        setIsTurnEnd(false);

        if (selectedPlayer) {
            const index = getEmptyIndexFromSelectedIdList(currentTurn, draftIdList, selectedPlayer);
            draftIdList[index] = selectedPlayer.id;
            setDraftIdList([...draftIdList]);
            setPickList([selectedPlayer, ...pickList]);
            setPlayerList(draftList => draftList.filter(p => p.id !== selectedPlayer.id));
        }
        setSelectedPlayer(null);

        if (draftIdList.every(item => item)) {
            setCurrentTurn(PLAYER_COUNT);
            return;
        }
        
        let nextTurn = currentTurn + 1;
        if (nextTurn === PLAYER_COUNT) {
            nextTurn = 0;
        }
        while (draftIdList.slice(nextTurn * positions.length, nextTurn * positions.length + positions.length).every(item => item)) {
            if (++nextTurn === PLAYER_COUNT) {
                nextTurn = 0;
            }
        }

        if (nextTurn === 0) {
            setCurrentTurn(0);
            setTimerCount(MY_TURN_TIMER);
        } else {
            setCurrentTurn(nextTurn);
            setTimerCount(OTHER_TURN_TIMER);

            setTimeout(() => setIsRandomPick(true), OTHER_TURN_TIMER * Math.random());
        }
        setIsTimerRunning(true);
    }, [isTurnEnd, currentTurn, draftIdList, selectedPlayer]);

    const onClickConfirmButton = useCallback(() => {
        if (currentTurn !== 0 || !selectedPlayer) return;
        
        console.log('Click: confirm', selectedPlayer.name);
        setTimerCount(0);
    }, [currentTurn, selectedPlayer]);

    useEffect(() => {
        if (!isTimerRunning) {
            return;
        }

        if (timerCount <= 0) {
            let isRandomPeek = !selectedPlayer;
            if (currentTurn >= 0) {
                if (selectedPlayer) {
                    const index = getEmptyIndexFromSelectedIdList(currentTurn, draftIdList, selectedPlayer);
                    if (index < 0) {
                        isRandomPeek = true;
                    }
                } else {
                    isRandomPeek = true;
                }
            }
    
            setIsTimerRunning(false);
            setIsRandomPick(isRandomPeek);
            setTimeout(() => setIsTurnEnd(true), 1000);
            return;
        }

        const timer = setTimeout(() => setTimerCount(timerCount - 1), 1000);
        return () => clearTimeout(timer);
    }, [isTimerRunning, timerCount, playerList, currentTurn, draftIdList, selectedPlayer]);

    if (currentTurn === PLAYER_COUNT) {
        // replace page with state
        localStorage.setItem('selectedIdList', draftIdList.join(','));
        navigate('/game', { replace: true });
        return <></>;
    }

    return <>
        <header style={{ 
            height: '80px',
            width: "100%",
            maxWidth: "100%",
            backgroundColor: "rgb(10, 39, 47)",
            color: "rgb(0, 0, 0)",
            fontSize: "16px",
            lineHeight: "18.4px",
        }}>
            <div
                style={{
                    display: "inline-block",
                    verticalAlign: 'middle',
                    color: "rgb(255, 255, 255)",
                    height: "72px",
                    width: "105px",
                }}
            >
                <div
                    style={{
                        width: '100%',
                        fontSize: "11px",
                        textAlign: "center",
                        textTransform: "uppercase",
                        textOverflow: "ellipsis",
                        whiteSpaceCollapse: "collapse",
                        textWrapMode: "nowrap",
                    }}
                >
                    TIMER
                </div>
                <div
                    style={{
                        color: "rgb(12, 123, 179)",
                        width: '100%',
                        textAlign: "center",
                        marginTop: '10px',
                    }}
                >
                    <div
                        style={{
                            fontSize: "24px",
                            marginBottom: "15px",
                        }}
                    >
                        <span>
                            {timerCount.toString().padStart(2, '0')}
                        </span>
                    </div>
                    
                <div
                    style={{
                        backgroundColor: "rgb(107, 126, 130)",
                        height: "2px",
                        width: "80px",
                        borderRadius: "10px",
                        margin: "0 auto",
                    }}
                >
                </div>
                </div>
            </div>

            <div
                style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    height: "80px",
                    width: "calc(100vw - 105px)",
                    padding: 0,
                    margin: 0,
                }}
            >
                <ul style={{
                    display: 'flex',
                    width: '100%',
                    height: "80px",
                    padding: "0px",
                    margin: "0px",
                    listStyle: "none",
                    }}
                >
                    {
                        [...Array(PLAYER_COUNT)].map((_, i) => {
                            return <li key={i} style={{
                                flex: 1,
                                height: "80px",
                                textAlign: "center",
                            }}>
                                <div
                                    style={{
                                    backgroundColor: currentTurn === i
                                        ? "rgb(23, 121, 150)"
                                        : "rgb(20, 78, 95)",
                                    }}
                                >
                                    <div
                                        style={{
                                            opacity: currentTurn === i ? 1 : 0.7,
                                            color: "rgb(241, 242, 243)",
                                            lineHeight: "80px",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        {playerNames[i]}
                                    </div>
                                </div>
                            </li>;
                        })
                    }
                </ul>
            </div>
            
        </header>

        <div id="container" style={{
            width: '100vw',
            height: 'calc(100vh - 85px)',
            display: 'flex',
            flexDirection: 'row',
            overflow: 'hidden',
        }}>
            <aside style={{ 
                width: '268px',
                borderRight: '1px solid rgb(237, 238, 240)',
                overflowY: 'auto',
            }}>
                <div
                    style={{
                    display: "flex",
                    alignItems: "center",
                    borderBottom: "1px solid rgb(237, 238, 240)",
                    padding: "8px 12px",
                    }}
                >
                    <div
                    style={{
                        fontSize: "12px",
                        lineHeight: "16px",
                        color: "rgb(21, 22, 23)",
                        width: "122px",
                        textAlign: "left",
                    }}
                    >
                    Roster
                    </div>
                </div>
                <table style={{
                    width: "100%",
                    borderCollapse: "collapse",
                }}>
                    <thead>
                        <tr style={{
                        color: "rgb(21, 22, 23)",
                        textAlign: "left",
                        padding: "8px",
                        borderBottom: "1px solid rgb(220, 221, 223)",
                        }}>
                            <th style={{ padding: '8px' }}>Position</th>
                            <th style={{ padding: '8px' }}>Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            draftIdList.slice(0, positions.length).map((id, i) => {
                                const player = playerInfoMap.get(id);
                                return <tr key={i} style={{
                                    color: "rgb(108, 109, 111)",
                                    borderBottom: "1px solid rgb(241, 242, 243)",
                                }}>
                                    <td title={positions[i].name} style={{ padding: '8px' }}>{positions[i].position}</td>
                                    <td style={{ padding: '8px' }}>{player ? player.name : ''}</td>
                                </tr>;
                            })
                        }
                    </tbody>
                </table>
                
                <div
                    style={{
                    display: "flex",
                    alignItems: "center",
                    borderTop: "1px solid rgb(237, 238, 240)",
                    borderBottom: "1px solid rgb(237, 238, 240)",
                    padding: "8px 12px",
                    }}
                >
                    <div
                    style={{
                        fontSize: "12px",
                        lineHeight: "16px",
                        color: "rgb(21, 22, 23)",
                        width: "122px",
                        textAlign: "left",
                    }}
                    >
                        Position Tracker
                    </div>
                    <div
                    style={{
                        fontSize: "11px",
                        lineHeight: "13px",
                        color: "rgb(165, 166, 167)",
                        width: "122px",
                        textAlign: "right",
                    }}
                    >
                        {draftIdList.slice(0, positions.length).filter(item => item).length}
                        /19 Players
                    </div>
                </div>
                <div
                    style={{
                    padding: "12px",
                    }}
                >
                    {
                    ["C", "1B", "2B", "3B", "SS", "LF", "CF", "RF", "DH", "P"].map(
                        (pos) => (
                            <div
                                key={pos}
                                style={{
                                    fontSize: "11px",
                                    lineHeight: "13px",
                                    color: "rgb(21, 22, 23)",
                                    display: "inline-block",
                                    width: "61px",
                                    paddingTop: "12px",
                                }}
                            >
                                {pos}
                                <span
                                    style={{
                                    fontSize: "11px",
                                    lineHeight: "13px",
                                    color: "rgb(108, 109, 111)",
                                    marginLeft: "5px",
                                    }}
                                >
                                    {
                                        draftIdList.slice(0, positions.length)
                                        .filter(id => playerInfoMap.get(id)?.position === pos)
                                        .length
                                    }
                                </span>
                            </div>
                        )
                    )
                    }
                </div>
            </aside>            
            
            <main style={{ 
                flex: 1,
            }}>
                <div id="selectedPlayer" className="flex items-center justify-between px-10">
                    {/* Player Info Section */}
                    <div className="flex items-center space-x-6">
                        {/* Player Image */}
                        <div className="w-20 h-24 rounded-lg overflow-hidden flex-shrink-0">
                            <img
                            src={selectedPlayer ? `${process.env.PUBLIC_URL}/images/${selectedPlayer.id}.png` : `${process.env.PUBLIC_URL}/images/not_selected.png`}
                            onError={event => event.currentTarget.src = `${process.env.PUBLIC_URL}/images/not_selected.png`}
                            alt={selectedPlayer?.name || 'Empty'}
                            />
                        </div>

                        {/* Player Details */}
                        <div className="flex flex-col">
                            {
                                selectedPlayer
                                ? <>
                                    <h2 className="text-2xl font-bold text-gray-900">
                                        {selectedPlayer.name}
                                    </h2>
                                    <div className="flex items-center space-x-2 mt-1">
                                        <span className="px-2 py-1 bg-blue-100 text-blue-800 text-sm font-medium rounded">
                                            {selectedPlayer.position}
                                        </span>
                                        <span className="text-gray-600">
                                            {selectedPlayer.team}
                                        </span>
                                    </div>
                                </>
                                : <></>
                            }
                        </div>
                    </div>

                    {/* Confirm Button */}
                    <button
                        disabled={!isMyTurn() || !selectedPlayer} 
                        onClick={onClickConfirmButton}
                        className="px-6 py-3 bg-green-600 text-white font-semibold rounded-lg 
                                shadow-md hover:bg-green-700 transform hover:scale-105 
                                transition-all duration-200 focus:outline-none 
                                focus:ring-2 focus:ring-green-500 focus:ring-offset-2
                                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                    >
                    Confirm
                    </button>
                </div>
                
                <div id="playerList" style={{
                    height: 'calc(100% - 96px)',
                    overflow: 'auto',
                }}>
                    <table style={{
                        width: "100%",
                        minWidth: '870px',
                        borderCollapse: "collapse",
                    }}>
                        <colgroup>
                            <col style={{ width: '130px' }} />
                            <col style={{ width: '80px' }} />
                            <col style={{ width: '80px' }} />
                            <col style={{ width: '110px' }} />
                            <col style={{ width: '80px' }} />
                            <col style={{ width: '80px' }} />
                            <col style={{ width: '60px' }} />
                            <col style={{ width: '60px' }} />
                            <col style={{ width: '60px' }} />
                            <col style={{ width: '60px' }} />
                            <col style={{ width: '60px' }} />
                            <col style={{ width: '60px' }} />
                            <col style={{ width: '60px' }} />
                            <col style={{ width: '60px' }} />
                        </colgroup>
                        <thead style={{
                            color: "rgb(21, 22, 23)",
                            textAlign: "left",
                            borderBottom: "1px solid rgb(220, 221, 223)",
                            position: 'sticky',
                            top: 0,
                            backgroundColor: 'white',
                        }}>
                            <tr id="categoryContainer" style={{ textAlign: 'center', }}>
                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Name</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Position</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Team</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">-</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Points</th>
                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Average</th>
                                {
                                    CATEGORIES.map(c => <th key={c.name} title={c.title} className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">{c.name}</th>)
                                }
                                <td></td>
                            </tr>
                        </thead>
                        <tbody id="hitterContainer">
                            {
                                playerList.map(player => {
                                    if (getEmptyIndexFromSelectedIdList(0, draftIdList, player) < 0) {
                                        return <></>;
                                    }

                                    return <tr key={player.id} style={{ padding: "8px 12px", borderBottom: '1px solid rgb(237, 238, 240)', }}>
                                        <td style={{ padding: "8px 12px", }}>{player.name}</td>
                                        <td style={{ padding: "8px 12px", }}>{player.position}</td>
                                        <td style={{ padding: "8px 12px", }}>{player.team}</td>
                                        <td style={{ padding: "8px 12px", }}>
                                            <button 
                                                disabled={!isMyTurn() || selectedPlayer?.id === player.id} 
                                                onClick={() => onClickSelectButton(player)}
                                                className="px-2 py-1 text-blue-600 border border-blue-300 rounded hover:bg-blue-100 hover:border-blue-400 transition-colors bg-slate-50 disabled:text-slate-300 disabled:border-slate-200 disabled:shadow-none"
                                            >Select</button>
                                        </td>
                                        <td style={{ padding: "8px 12px", textAlign: 'right', }}>{player.sum}</td>
                                        <td style={{ padding: "8px 12px", textAlign: 'right', }}>{player.avg}</td>
                                        {
                                            CATEGORIES.map((c, i) => <td key={c.name} style={{ padding: "8px 12px", textAlign: 'right', }}>{player.record[c.name] ? player.record[c.name] : ''}</td>)
                                        }
                                    </tr>;
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </main>

            <div id="pickedList" style={{
                width: '268px',
                borderLeft: '1px solid rgb(237, 238, 240)',
            }}>
                <div
                    style={{
                    display: "flex",
                    alignItems: "center",
                    borderTop: "1px solid rgb(237, 238, 240)",
                    borderBottom: "1px solid rgb(237, 238, 240)",
                    padding: "8px 12px",
                    }}
                >
                    <div
                    style={{
                        fontSize: "12px",
                        lineHeight: "16px",
                        color: "rgb(21, 22, 23)",
                        width: "122px",
                        textAlign: "left",
                    }}
                    >
                        Picks
                    </div>
                </div>
                
                <div style={{
                    overflowY: 'auto',
                    height: 'calc(100% - 60px)'
                }}>
                    <table style={{
                        width: "100%",
                        borderCollapse: "collapse",
                    }}>
                        <thead>
                            <tr style={{
                            color: "rgb(21, 22, 23)",
                            textAlign: "left",
                            padding: "8px",
                            borderBottom: "1px solid rgb(220, 221, 223)",
                            }}>
                                <th style={{ padding: '8px' }}>Name</th>
                                <th style={{ padding: '8px' }}>Position</th>
                                <th style={{ padding: '8px' }}>Team</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                pickList.map((player, i) => {
                                    return <tr key={i} style={{
                                        color: "rgb(108, 109, 111)",
                                        borderBottom: "1px solid rgb(241, 242, 243)",
                                    }}>
                                        <td style={{ padding: '8px' }}>{player.name}</td>
                                        <td style={{ padding: '8px' }}>{player.position}</td>
                                        <td style={{ padding: '8px' }}>{player.team}</td>
                                    </tr>;
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        
    </>;
};

export default Draft;