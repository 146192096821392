const playerInfoData = [
  [
    "2e0591d8a2fd38d9f03d8ca154cb206b501824b6",
    {
      "id": "2e0591d8a2fd38d9f03d8ca154cb206b501824b6",
      "name": "김재상",
      "team": "삼성",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "19",
        "Pos.": "2B",
        "oWAR": "-0.08",
        "dWAR": "0.05",
        "G": "35",
        "PA": "91",
        "ePA": "90",
        "AB": "84",
        "R": "13",
        "H": "20",
        "2B": "4",
        "3B": "0",
        "HR": "2",
        "TB": "30",
        "RBI": "12",
        "SB": "4",
        "CS": "0",
        "BB": "5",
        "HP": "0",
        "IB": "0",
        "SO": "23",
        "GDP": "0",
        "SH": "1",
        "SF": "1",
        "AVG": "0.238",
        "OBP": "0.278",
        "SLG": "0.357",
        "OPS": "0.635",
        "R/ePA": "0.073",
        "wRC+": "51.1",
        "WAR": "-0.03"
      }
    }
  ],
  [
    "b6e0f36ba14412d8693b96d356149f12fba97501",
    {
      "id": "b6e0f36ba14412d8693b96d356149f12fba97501",
      "name": "이재익",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "29",
        "Pos.": "P",
        "G": "9",
        "GS": "0",
        "GR": "9",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "9.2",
        "ER": "19",
        "R": "19",
        "rRA": "17.75",
        "TBF": "58",
        "H": "29",
        "2B": "3",
        "3B": "0",
        "HR": "2",
        "BB": "4",
        "HP": "0",
        "IB": "0",
        "SO": "6",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "17.69",
        "RA9": "17.69",
        "rRA9": "16.53",
        "rRA9pf": "17.15",
        "FIP": "6.39",
        "WHIP": "3.41",
        "WAR": "-0.76"
      }
    }
  ],
  [
    "2f137ff924fb1f1c824b5659eaa49217a0e3cba0",
    {
      "id": "2f137ff924fb1f1c824b5659eaa49217a0e3cba0",
      "name": "김재성",
      "team": "삼성",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "27",
        "Pos.": "C",
        "oWAR": "-0.08",
        "dWAR": "-0.16",
        "G": "10",
        "PA": "23",
        "ePA": "23",
        "AB": "20",
        "R": "3",
        "H": "4",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "TB": "5",
        "RBI": "1",
        "SB": "0",
        "CS": "0",
        "BB": "3",
        "HP": "0",
        "IB": "0",
        "SO": "5",
        "GDP": "1",
        "SH": "0",
        "SF": "0",
        "AVG": "0.200",
        "OBP": "0.304",
        "SLG": "0.250",
        "OPS": "0.554",
        "R/ePA": "0.066",
        "wRC+": "47.7",
        "WAR": "-0.24"
      }
    }
  ],
  [
    "e7e5078d89df11c4d312f56996d619e6683adeab",
    {
      "id": "e7e5078d89df11c4d312f56996d619e6683adeab",
      "name": "김동진",
      "team": "삼성",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "27",
        "Pos.": "2B",
        "oWAR": "-0.47",
        "dWAR": "0.17",
        "G": "30",
        "PA": "42",
        "ePA": "40",
        "AB": "37",
        "R": "5",
        "H": "4",
        "2B": "0",
        "3B": "0",
        "HR": "1",
        "TB": "7",
        "RBI": "2",
        "SB": "0",
        "CS": "0",
        "BB": "3",
        "HP": "0",
        "IB": "0",
        "SO": "10",
        "GDP": "1",
        "SH": "2",
        "SF": "0",
        "AVG": "0.108",
        "OBP": "0.175",
        "SLG": "0.189",
        "OPS": "0.364",
        "R/ePA": "-0.028",
        "wRC+": "-20.5",
        "WAR": "-0.31"
      }
    }
  ],
  [
    "caa16e4d0563e8e1f245e8fed61fe62376c2e7df",
    {
      "id": "caa16e4d0563e8e1f245e8fed61fe62376c2e7df",
      "name": "뷰캐넌",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2023",
        "Team": "삼성",
        "Age": "33",
        "Pos.": "P",
        "G": "30",
        "GS": "30",
        "GR": "0",
        "GF": "1",
        "CG": "1",
        "SHO": "0",
        "W": "12",
        "L": "8",
        "S": "0",
        "HD": "0",
        "IP": "188.0",
        "ER": "53",
        "R": "71",
        "rRA": "69.95",
        "TBF": "784",
        "H": "174",
        "2B": "25",
        "3B": "2",
        "HR": "4",
        "BB": "43",
        "HP": "6",
        "IB": "1",
        "SO": "139",
        "ROE": "13",
        "BK": "1",
        "WP": "7",
        "ERA": "2.54",
        "RA9": "3.40",
        "rRA9": "3.35",
        "rRA9pf": "3.25",
        "FIP": "3.05",
        "WHIP": "1.15",
        "WAR": "6.29"
      }
    }
  ],
  [
    "f51b83b7536cec6500c63ce2c0b676f3a4cd8ec4",
    {
      "id": "f51b83b7536cec6500c63ce2c0b676f3a4cd8ec4",
      "name": "육선엽",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "18",
        "Pos.": "P",
        "G": "11",
        "GS": "1",
        "GR": "10",
        "GF": "5",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "17.0",
        "ER": "10",
        "R": "11",
        "rRA": "12.55",
        "TBF": "87",
        "H": "19",
        "2B": "2",
        "3B": "0",
        "HR": "3",
        "BB": "17",
        "HP": "2",
        "IB": "0",
        "SO": "11",
        "ROE": "1",
        "BK": "0",
        "WP": "1",
        "ERA": "5.29",
        "RA9": "5.82",
        "rRA9": "6.64",
        "rRA9pf": "6.46",
        "FIP": "8.15",
        "WHIP": "2.12",
        "WAR": "0.14"
      }
    }
  ],
  [
    "dfd16b90c0e9f4621d45809fc50e3b8fd64ce24f",
    {
      "id": "dfd16b90c0e9f4621d45809fc50e3b8fd64ce24f",
      "name": "구자욱",
      "team": "삼성",
      "position": "RF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "30",
        "Pos.": "LF",
        "oWAR": "5.61",
        "dWAR": "0.08",
        "G": "129",
        "PA": "568",
        "ePA": "563",
        "AB": "493",
        "R": "92",
        "H": "169",
        "2B": "39",
        "3B": "1",
        "HR": "33",
        "TB": "309",
        "RBI": "115",
        "SB": "13",
        "CS": "4",
        "BB": "55",
        "HP": "12",
        "IB": "4",
        "SO": "73",
        "GDP": "6",
        "SH": "2",
        "SF": "6",
        "AVG": "0.343",
        "OBP": "0.417",
        "SLG": "0.627",
        "OPS": "1.044",
        "R/ePA": "0.228",
        "wRC+": "162.3",
        "WAR": "5.69"
      }
    }
  ],
  [
    "719fc7a51dbdc24ede92141f8bac51fe71933179",
    {
      "id": "719fc7a51dbdc24ede92141f8bac51fe71933179",
      "name": "강한울",
      "team": "삼성",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "32",
        "Pos.": "3B",
        "oWAR": "-0.47",
        "dWAR": "0.01",
        "G": "18",
        "PA": "25",
        "ePA": "22",
        "AB": "21",
        "R": "1",
        "H": "2",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "2",
        "RBI": "1",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "6",
        "GDP": "1",
        "SH": "3",
        "SF": "1",
        "AVG": "0.095",
        "OBP": "0.091",
        "SLG": "0.095",
        "OPS": "0.186",
        "R/ePA": "-0.114",
        "wRC+": "-81.5",
        "WAR": "-0.47"
      }
    }
  ],
  [
    "9976be351d3d8203e17c73541120867ac731d5f4",
    {
      "id": "9976be351d3d8203e17c73541120867ac731d5f4",
      "name": "이재현",
      "team": "삼성",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "20",
        "Pos.": "SS",
        "oWAR": "2.43",
        "dWAR": "0.60",
        "G": "109",
        "PA": "458",
        "ePA": "457",
        "AB": "389",
        "R": "71",
        "H": "101",
        "2B": "18",
        "3B": "1",
        "HR": "14",
        "TB": "163",
        "RBI": "66",
        "SB": "2",
        "CS": "1",
        "BB": "58",
        "HP": "8",
        "IB": "1",
        "SO": "83",
        "GDP": "8",
        "SH": "0",
        "SF": "3",
        "AVG": "0.260",
        "OBP": "0.365",
        "SLG": "0.419",
        "OPS": "0.784",
        "R/ePA": "0.146",
        "wRC+": "104.8",
        "WAR": "3.03"
      }
    }
  ],
  [
    "c4cdc33472b2076192bea201d306d76e0a3b4f7a",
    {
      "id": "c4cdc33472b2076192bea201d306d76e0a3b4f7a",
      "name": "김재혁",
      "team": "삼성",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "24",
        "Pos.": "1B",
        "oWAR": "-0.53",
        "dWAR": "0.04",
        "G": "35",
        "PA": "54",
        "ePA": "54",
        "AB": "49",
        "R": "6",
        "H": "9",
        "2B": "2",
        "3B": "1",
        "HR": "0",
        "TB": "13",
        "RBI": "6",
        "SB": "2",
        "CS": "2",
        "BB": "3",
        "HP": "0",
        "IB": "0",
        "SO": "19",
        "GDP": "2",
        "SH": "0",
        "SF": "2",
        "AVG": "0.184",
        "OBP": "0.222",
        "SLG": "0.265",
        "OPS": "0.487",
        "R/ePA": "0.017",
        "wRC+": "11.6",
        "WAR": "-0.49"
      }
    }
  ],
  [
    "3b8d830c14c83b7fbb322b477bc416375a428d56",
    {
      "id": "3b8d830c14c83b7fbb322b477bc416375a428d56",
      "name": "공민규",
      "team": "삼성",
      "position": "1B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "24",
        "Pos.": "3B",
        "oWAR": "-0.08",
        "dWAR": "-0.08",
        "G": "12",
        "PA": "17",
        "ePA": "17",
        "AB": "14",
        "R": "1",
        "H": "1",
        "2B": "0",
        "3B": "0",
        "HR": "1",
        "TB": "4",
        "RBI": "2",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "2",
        "IB": "0",
        "SO": "9",
        "GDP": "1",
        "SH": "0",
        "SF": "0",
        "AVG": "0.071",
        "OBP": "0.235",
        "SLG": "0.286",
        "OPS": "0.521",
        "R/ePA": "0.056",
        "wRC+": "39.5",
        "WAR": "-0.16"
      }
    }
  ],
  [
    "6e2c9d827ce6f5b5a6be99a21c31a277cc7c4b9e",
    {
      "id": "6e2c9d827ce6f5b5a6be99a21c31a277cc7c4b9e",
      "name": "홍정우",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "27",
        "Pos.": "P",
        "G": "1",
        "GS": "0",
        "GR": "1",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "1.0",
        "ER": "5",
        "R": "5",
        "rRA": "5.00",
        "TBF": "9",
        "H": "5",
        "2B": "1",
        "3B": "0",
        "HR": "1",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "45.00",
        "RA9": "45.00",
        "rRA9": "45.00",
        "rRA9pf": "40.80",
        "FIP": "16.70",
        "WHIP": "6.00",
        "WAR": "-0.18"
      }
    }
  ],
  [
    "89dfc7a99451ae2791607c8050d83cb0e695b238",
    {
      "id": "89dfc7a99451ae2791607c8050d83cb0e695b238",
      "name": "김민수",
      "team": "삼성",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "32",
        "Pos.": "C",
        "oWAR": "0.09",
        "dWAR": "-0.02",
        "G": "9",
        "PA": "8",
        "ePA": "8",
        "AB": "8",
        "R": "1",
        "H": "2",
        "2B": "0",
        "3B": "0",
        "HR": "1",
        "TB": "5",
        "RBI": "3",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.250",
        "OBP": "0.250",
        "SLG": "0.625",
        "OPS": "0.875",
        "R/ePA": "0.136",
        "wRC+": "99.8",
        "WAR": "0.07"
      }
    }
  ],
  [
    "169927563d783c26e01091cc2053935f874c1c0b",
    {
      "id": "169927563d783c26e01091cc2053935f874c1c0b",
      "name": "이성규",
      "team": "삼성",
      "position": "1B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "30",
        "Pos.": "RF",
        "oWAR": "1.02",
        "dWAR": "0.08",
        "G": "122",
        "PA": "355",
        "ePA": "351",
        "AB": "302",
        "R": "56",
        "H": "73",
        "2B": "10",
        "3B": "1",
        "HR": "22",
        "TB": "151",
        "RBI": "57",
        "SB": "9",
        "CS": "2",
        "BB": "31",
        "HP": "15",
        "IB": "0",
        "SO": "109",
        "GDP": "5",
        "SH": "4",
        "SF": "3",
        "AVG": "0.242",
        "OBP": "0.339",
        "SLG": "0.500",
        "OPS": "0.839",
        "R/ePA": "0.149",
        "wRC+": "106.1",
        "WAR": "1.11"
      }
    }
  ],
  [
    "754d33a30bee0dbc4d2e6951015cffc10aac3ac2",
    {
      "id": "754d33a30bee0dbc4d2e6951015cffc10aac3ac2",
      "name": "안주형",
      "team": "삼성",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "30",
        "Pos.": "2B",
        "oWAR": "-0.57",
        "dWAR": "-0.35",
        "G": "82",
        "PA": "166",
        "ePA": "154",
        "AB": "142",
        "R": "24",
        "H": "31",
        "2B": "2",
        "3B": "0",
        "HR": "0",
        "TB": "33",
        "RBI": "8",
        "SB": "4",
        "CS": "2",
        "BB": "11",
        "HP": "1",
        "IB": "0",
        "SO": "39",
        "GDP": "3",
        "SH": "12",
        "SF": "0",
        "AVG": "0.218",
        "OBP": "0.279",
        "SLG": "0.232",
        "OPS": "0.511",
        "R/ePA": "0.041",
        "wRC+": "29.2",
        "WAR": "-0.92"
      }
    }
  ],
  [
    "d9b564756b8ed8437812690a060c12a621c12064",
    {
      "id": "d9b564756b8ed8437812690a060c12a621c12064",
      "name": "이상민",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "33",
        "Pos.": "P",
        "G": "37",
        "GS": "0",
        "GR": "37",
        "GF": "6",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "1",
        "S": "1",
        "HD": "8",
        "IP": "30.0",
        "ER": "13",
        "R": "13",
        "rRA": "11.60",
        "TBF": "121",
        "H": "31",
        "2B": "5",
        "3B": "0",
        "HR": "2",
        "BB": "6",
        "HP": "4",
        "IB": "0",
        "SO": "12",
        "ROE": "0",
        "BK": "0",
        "WP": "2",
        "ERA": "3.90",
        "RA9": "3.90",
        "rRA9": "3.48",
        "rRA9pf": "3.52",
        "FIP": "5.03",
        "WHIP": "1.23",
        "WAR": "0.94"
      }
    }
  ],
  [
    "d260b7e1e0243d2d4a7a4aa58a80733983310207",
    {
      "id": "d260b7e1e0243d2d4a7a4aa58a80733983310207",
      "name": "류지혁",
      "team": "삼성",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "29",
        "Pos.": "2B",
        "oWAR": "0.42",
        "dWAR": "-0.34",
        "G": "100",
        "PA": "348",
        "ePA": "343",
        "AB": "302",
        "R": "43",
        "H": "78",
        "2B": "9",
        "3B": "1",
        "HR": "3",
        "TB": "98",
        "RBI": "36",
        "SB": "11",
        "CS": "4",
        "BB": "33",
        "HP": "6",
        "IB": "0",
        "SO": "70",
        "GDP": "7",
        "SH": "5",
        "SF": "2",
        "AVG": "0.258",
        "OBP": "0.341",
        "SLG": "0.325",
        "OPS": "0.666",
        "R/ePA": "0.106",
        "wRC+": "76.0",
        "WAR": "0.09"
      }
    }
  ],
  [
    "efa37da06dd04cfb25a479ea4f2e3a300f8e15a9",
    {
      "id": "efa37da06dd04cfb25a479ea4f2e3a300f8e15a9",
      "name": "김대우",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "35",
        "Pos.": "P",
        "G": "32",
        "GS": "1",
        "GR": "31",
        "GF": "13",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "5",
        "IP": "34.1",
        "ER": "21",
        "R": "24",
        "rRA": "23.20",
        "TBF": "157",
        "H": "42",
        "2B": "9",
        "3B": "0",
        "HR": "4",
        "BB": "9",
        "HP": "3",
        "IB": "2",
        "SO": "23",
        "ROE": "3",
        "BK": "0",
        "WP": "1",
        "ERA": "5.50",
        "RA9": "6.29",
        "rRA9": "6.08",
        "rRA9pf": "5.90",
        "FIP": "4.81",
        "WHIP": "1.49",
        "WAR": "0.31"
      }
    }
  ],
  [
    "41119d50bd6c10415fce8a9b95d8d09f09cbcc3c",
    {
      "id": "41119d50bd6c10415fce8a9b95d8d09f09cbcc3c",
      "name": "원태인",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "23",
        "Pos.": "P",
        "G": "28",
        "GS": "28",
        "GR": "0",
        "GF": "1",
        "CG": "1",
        "SHO": "0",
        "W": "15",
        "L": "6",
        "S": "0",
        "HD": "0",
        "IP": "159.2",
        "ER": "65",
        "R": "68",
        "rRA": "67.30",
        "TBF": "670",
        "H": "150",
        "2B": "29",
        "3B": "3",
        "HR": "17",
        "BB": "42",
        "HP": "7",
        "IB": "1",
        "SO": "119",
        "ROE": "5",
        "BK": "0",
        "WP": "3",
        "ERA": "3.66",
        "RA9": "3.83",
        "rRA9": "3.79",
        "rRA9pf": "3.58",
        "FIP": "4.55",
        "WHIP": "1.20",
        "WAR": "5.87"
      }
    }
  ],
  [
    "e7c11013f122431ca68bcc802dea9e99c1b8249f",
    {
      "id": "e7c11013f122431ca68bcc802dea9e99c1b8249f",
      "name": "양현",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "31",
        "Pos.": "P",
        "G": "18",
        "GS": "0",
        "GR": "18",
        "GF": "4",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "2",
        "IP": "13.0",
        "ER": "11",
        "R": "11",
        "rRA": "10.35",
        "TBF": "64",
        "H": "21",
        "2B": "2",
        "3B": "0",
        "HR": "4",
        "BB": "5",
        "HP": "1",
        "IB": "0",
        "SO": "10",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "7.62",
        "RA9": "7.62",
        "rRA9": "7.17",
        "rRA9pf": "6.71",
        "FIP": "7.37",
        "WHIP": "2.00",
        "WAR": "0.02"
      }
    }
  ],
  [
    "d932b6f542902f1497122db2a650d01169cd8c67",
    {
      "id": "d932b6f542902f1497122db2a650d01169cd8c67",
      "name": "이승현",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "32",
        "Pos.": "P",
        "G": "60",
        "GS": "0",
        "GR": "60",
        "GF": "9",
        "CG": "0",
        "SHO": "0",
        "W": "6",
        "L": "2",
        "S": "1",
        "HD": "9",
        "IP": "60.1",
        "ER": "30",
        "R": "31",
        "rRA": "34.15",
        "TBF": "265",
        "H": "68",
        "2B": "14",
        "3B": "2",
        "HR": "6",
        "BB": "23",
        "HP": "3",
        "IB": "0",
        "SO": "51",
        "ROE": "1",
        "BK": "0",
        "WP": "2",
        "ERA": "4.48",
        "RA9": "4.62",
        "rRA9": "5.09",
        "rRA9pf": "4.82",
        "FIP": "4.61",
        "WHIP": "1.51",
        "WAR": "1.17"
      }
    }
  ],
  [
    "c085f93b6fdc22d0f9d40df466f2cf7cbb606b2f",
    {
      "id": "c085f93b6fdc22d0f9d40df466f2cf7cbb606b2f",
      "name": "오승환",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "41",
        "Pos.": "P",
        "G": "58",
        "GS": "0",
        "GR": "58",
        "GF": "42",
        "CG": "0",
        "SHO": "0",
        "W": "3",
        "L": "9",
        "S": "27",
        "HD": "2",
        "IP": "55.0",
        "ER": "30",
        "R": "36",
        "rRA": "40.35",
        "TBF": "262",
        "H": "75",
        "2B": "17",
        "3B": "2",
        "HR": "9",
        "BB": "18",
        "HP": "3",
        "IB": "3",
        "SO": "42",
        "ROE": "3",
        "BK": "0",
        "WP": "5",
        "ERA": "4.91",
        "RA9": "5.89",
        "rRA9": "6.60",
        "rRA9pf": "6.17",
        "FIP": "5.31",
        "WHIP": "1.69",
        "WAR": "0.09"
      }
    }
  ],
  [
    "b19c1f87f0c0ac7757ac68f10bfbb157a18c8753",
    {
      "id": "b19c1f87f0c0ac7757ac68f10bfbb157a18c8753",
      "name": "이병헌",
      "team": "삼성",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "24",
        "Pos.": "C",
        "oWAR": "0.23",
        "dWAR": "-0.13",
        "G": "95",
        "PA": "166",
        "ePA": "164",
        "AB": "145",
        "R": "9",
        "H": "36",
        "2B": "4",
        "3B": "0",
        "HR": "1",
        "TB": "43",
        "RBI": "9",
        "SB": "0",
        "CS": "0",
        "BB": "17",
        "HP": "2",
        "IB": "0",
        "SO": "48",
        "GDP": "0",
        "SH": "2",
        "SF": "0",
        "AVG": "0.248",
        "OBP": "0.335",
        "SLG": "0.297",
        "OPS": "0.632",
        "R/ePA": "0.094",
        "wRC+": "66.8",
        "WAR": "0.10"
      }
    }
  ],
  [
    "41b5b2f9a96e11e437e19d99eacce009efd488c1",
    {
      "id": "41b5b2f9a96e11e437e19d99eacce009efd488c1",
      "name": "맥키넌",
      "team": "삼성",
      "position": "1B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "29",
        "Pos.": "1B",
        "oWAR": "0.35",
        "dWAR": "0.17",
        "G": "72",
        "PA": "315",
        "ePA": "313",
        "AB": "272",
        "R": "28",
        "H": "80",
        "2B": "13",
        "3B": "0",
        "HR": "4",
        "TB": "105",
        "RBI": "36",
        "SB": "1",
        "CS": "0",
        "BB": "39",
        "HP": "1",
        "IB": "3",
        "SO": "33",
        "GDP": "6",
        "SH": "0",
        "SF": "3",
        "AVG": "0.294",
        "OBP": "0.381",
        "SLG": "0.386",
        "OPS": "0.767",
        "R/ePA": "0.135",
        "wRC+": "96.5",
        "WAR": "0.51"
      }
    }
  ],
  [
    "ee4c1e80dde7eccaa91fd86ac782d8b4d790a015",
    {
      "id": "ee4c1e80dde7eccaa91fd86ac782d8b4d790a015",
      "name": "김태훈",
      "team": "삼성",
      "position": "RF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "27",
        "Pos.": "RF",
        "oWAR": "-0.14",
        "dWAR": "0.09",
        "G": "12",
        "PA": "21",
        "ePA": "21",
        "AB": "20",
        "R": "2",
        "H": "4",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "TB": "5",
        "RBI": "1",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "1",
        "IB": "0",
        "SO": "3",
        "GDP": "1",
        "SH": "0",
        "SF": "0",
        "AVG": "0.200",
        "OBP": "0.238",
        "SLG": "0.250",
        "OPS": "0.488",
        "R/ePA": "0.027",
        "wRC+": "18.5",
        "WAR": "-0.05"
      }
    }
  ],
  [
    "8c429a75225e9fb7b4128ddd380cd0409e776e15",
    {
      "id": "8c429a75225e9fb7b4128ddd380cd0409e776e15",
      "name": "장필준",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "35",
        "Pos.": "P",
        "G": "1",
        "GS": "0",
        "GR": "1",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "0.1",
        "ER": "5",
        "R": "5",
        "rRA": "3.95",
        "TBF": "6",
        "H": "5",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "0",
        "ROE": "0",
        "BK": "0",
        "WP": "1",
        "ERA": "99.99",
        "RA9": "99.99",
        "rRA9": "106.65",
        "rRA9pf": "102.27",
        "FIP": "4.20",
        "WHIP": "15.00",
        "WAR": "-0.16"
      }
    }
  ],
  [
    "281d1167811310b4f5419af83cc672f74058d1c5",
    {
      "id": "281d1167811310b4f5419af83cc672f74058d1c5",
      "name": "김태훈",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "31",
        "Pos.": "P",
        "G": "56",
        "GS": "0",
        "GR": "56",
        "GF": "8",
        "CG": "0",
        "SHO": "0",
        "W": "3",
        "L": "2",
        "S": "0",
        "HD": "23",
        "IP": "52.1",
        "ER": "23",
        "R": "24",
        "rRA": "25.55",
        "TBF": "225",
        "H": "49",
        "2B": "8",
        "3B": "1",
        "HR": "6",
        "BB": "19",
        "HP": "4",
        "IB": "1",
        "SO": "37",
        "ROE": "2",
        "BK": "1",
        "WP": "2",
        "ERA": "3.96",
        "RA9": "4.13",
        "rRA9": "4.39",
        "rRA9pf": "4.34",
        "FIP": "5.16",
        "WHIP": "1.30",
        "WAR": "1.32"
      }
    }
  ],
  [
    "2e622f5b82ee41229c151f4d2ac0e069473960ee",
    {
      "id": "2e622f5b82ee41229c151f4d2ac0e069473960ee",
      "name": "서현원",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "23269f702e5be5ebdacdb2e09a5f901f5d24b0c2",
    {
      "id": "23269f702e5be5ebdacdb2e09a5f901f5d24b0c2",
      "name": "김윤수",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "24",
        "Pos.": "P",
        "G": "4",
        "GS": "0",
        "GR": "4",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "5.1",
        "ER": "6",
        "R": "6",
        "rRA": "6.70",
        "TBF": "26",
        "H": "6",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "BB": "7",
        "HP": "0",
        "IB": "0",
        "SO": "2",
        "ROE": "0",
        "BK": "0",
        "WP": "1",
        "ERA": "10.13",
        "RA9": "10.13",
        "rRA9": "11.31",
        "rRA9pf": "10.93",
        "FIP": "7.33",
        "WHIP": "2.44",
        "WAR": "-0.24"
      }
    }
  ],
  [
    "7547824c9aba092c679bdc16f985f63e8851246c",
    {
      "id": "7547824c9aba092c679bdc16f985f63e8851246c",
      "name": "백정현",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "36",
        "Pos.": "P",
        "G": "17",
        "GS": "15",
        "GR": "2",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "6",
        "L": "5",
        "S": "0",
        "HD": "0",
        "IP": "78.2",
        "ER": "52",
        "R": "56",
        "rRA": "54.80",
        "TBF": "360",
        "H": "104",
        "2B": "15",
        "3B": "2",
        "HR": "13",
        "BB": "27",
        "HP": "1",
        "IB": "1",
        "SO": "56",
        "ROE": "4",
        "BK": "0",
        "WP": "0",
        "ERA": "5.95",
        "RA9": "6.41",
        "rRA9": "6.27",
        "rRA9pf": "5.83",
        "FIP": "5.45",
        "WHIP": "1.67",
        "WAR": "1.05"
      }
    }
  ],
  [
    "f327780c28a9a0121b9d9c8fbdc6eedbb7e0cd69",
    {
      "id": "f327780c28a9a0121b9d9c8fbdc6eedbb7e0cd69",
      "name": "김영웅",
      "team": "삼성",
      "position": "3B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "20",
        "Pos.": "3B",
        "oWAR": "1.59",
        "dWAR": "0.71",
        "G": "126",
        "PA": "509",
        "ePA": "508",
        "AB": "456",
        "R": "65",
        "H": "115",
        "2B": "16",
        "3B": "3",
        "HR": "28",
        "TB": "221",
        "RBI": "79",
        "SB": "9",
        "CS": "2",
        "BB": "45",
        "HP": "3",
        "IB": "0",
        "SO": "155",
        "GDP": "2",
        "SH": "1",
        "SF": "4",
        "AVG": "0.252",
        "OBP": "0.321",
        "SLG": "0.485",
        "OPS": "0.806",
        "R/ePA": "0.137",
        "wRC+": "97.8",
        "WAR": "2.29"
      }
    }
  ],
  [
    "04f9734775c7eb32a4c64e0e1205d817c99da6e3",
    {
      "id": "04f9734775c7eb32a4c64e0e1205d817c99da6e3",
      "name": "윤정빈",
      "team": "삼성",
      "position": "RF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "24",
        "Pos.": "RF",
        "oWAR": "0.67",
        "dWAR": "0.38",
        "G": "69",
        "PA": "188",
        "ePA": "188",
        "AB": "161",
        "R": "26",
        "H": "46",
        "2B": "4",
        "3B": "1",
        "HR": "7",
        "TB": "73",
        "RBI": "20",
        "SB": "1",
        "CS": "3",
        "BB": "22",
        "HP": "3",
        "IB": "0",
        "SO": "47",
        "GDP": "1",
        "SH": "0",
        "SF": "2",
        "AVG": "0.286",
        "OBP": "0.378",
        "SLG": "0.453",
        "OPS": "0.831",
        "R/ePA": "0.159",
        "wRC+": "113.7",
        "WAR": "1.05"
      }
    }
  ],
  [
    "6465b2480d421f82716d562bbac5c617b30e8250",
    {
      "id": "6465b2480d421f82716d562bbac5c617b30e8250",
      "name": "조민성",
      "team": "삼성",
      "position": "1B",
      "record": {
        "Div.": "베스트",
        "Year": "2022",
        "Team": "삼성",
        "Age": "18",
        "Pos.": "1B",
        "oWAR": "0.11",
        "dWAR": "-0.03",
        "G": "12",
        "PA": "16",
        "ePA": "16",
        "AB": "13",
        "R": "3",
        "H": "4",
        "2B": "0",
        "3B": "0",
        "HR": "1",
        "TB": "7",
        "RBI": "3",
        "SB": "1",
        "CS": "0",
        "BB": "2",
        "HP": "1",
        "IB": "0",
        "SO": "7",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.308",
        "OBP": "0.438",
        "SLG": "0.538",
        "OPS": "0.976",
        "R/ePA": "0.216",
        "wRC+": "183.8",
        "WAR": "0.08"
      }
    }
  ],
  [
    "012b9f2031e4531cfcca8d45876e701634c127a3",
    {
      "id": "012b9f2031e4531cfcca8d45876e701634c127a3",
      "name": "김헌곤",
      "team": "삼성",
      "position": "RF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "35",
        "Pos.": "LF",
        "oWAR": "0.87",
        "dWAR": "0.18",
        "G": "117",
        "PA": "311",
        "ePA": "307",
        "AB": "281",
        "R": "43",
        "H": "85",
        "2B": "8",
        "3B": "1",
        "HR": "9",
        "TB": "122",
        "RBI": "34",
        "SB": "4",
        "CS": "2",
        "BB": "22",
        "HP": "3",
        "IB": "0",
        "SO": "50",
        "GDP": "5",
        "SH": "4",
        "SF": "1",
        "AVG": "0.302",
        "OBP": "0.358",
        "SLG": "0.434",
        "OPS": "0.792",
        "R/ePA": "0.142",
        "wRC+": "101.0",
        "WAR": "1.05"
      }
    }
  ],
  [
    "5275576298d8d196f69f79797e94e1162ff8ccf2",
    {
      "id": "5275576298d8d196f69f79797e94e1162ff8ccf2",
      "name": "전병우",
      "team": "삼성",
      "position": "3B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "31",
        "Pos.": "3B",
        "oWAR": "-0.07",
        "dWAR": "0.36",
        "G": "58",
        "PA": "128",
        "ePA": "128",
        "AB": "111",
        "R": "17",
        "H": "25",
        "2B": "5",
        "3B": "0",
        "HR": "5",
        "TB": "45",
        "RBI": "14",
        "SB": "0",
        "CS": "1",
        "BB": "14",
        "HP": "1",
        "IB": "0",
        "SO": "42",
        "GDP": "3",
        "SH": "0",
        "SF": "2",
        "AVG": "0.225",
        "OBP": "0.313",
        "SLG": "0.405",
        "OPS": "0.718",
        "R/ePA": "0.110",
        "wRC+": "77.9",
        "WAR": "0.30"
      }
    }
  ],
  [
    "482f9dab292e73d76a27dd919be0335221920010",
    {
      "id": "482f9dab292e73d76a27dd919be0335221920010",
      "name": "이승민",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "23",
        "Pos.": "P",
        "G": "25",
        "GS": "8",
        "GR": "17",
        "GF": "6",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "4",
        "S": "0",
        "HD": "0",
        "IP": "47.1",
        "ER": "45",
        "R": "48",
        "rRA": "45.25",
        "TBF": "237",
        "H": "71",
        "2B": "8",
        "3B": "1",
        "HR": "14",
        "BB": "26",
        "HP": "3",
        "IB": "0",
        "SO": "23",
        "ROE": "5",
        "BK": "0",
        "WP": "1",
        "ERA": "8.56",
        "RA9": "9.13",
        "rRA9": "8.60",
        "rRA9pf": "8.27",
        "FIP": "8.40",
        "WHIP": "2.05",
        "WAR": "-0.33"
      }
    }
  ],
  [
    "1149f86805b42a849a00682161b58f3e327a6e83",
    {
      "id": "1149f86805b42a849a00682161b58f3e327a6e83",
      "name": "최하늘",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "24",
        "Pos.": "P",
        "G": "27",
        "GS": "0",
        "GR": "27",
        "GF": "4",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "1",
        "S": "0",
        "HD": "1",
        "IP": "32.0",
        "ER": "19",
        "R": "20",
        "rRA": "20.85",
        "TBF": "139",
        "H": "26",
        "2B": "4",
        "3B": "0",
        "HR": "2",
        "BB": "16",
        "HP": "4",
        "IB": "0",
        "SO": "18",
        "ROE": "3",
        "BK": "0",
        "WP": "2",
        "ERA": "5.34",
        "RA9": "5.63",
        "rRA9": "5.86",
        "rRA9pf": "5.72",
        "FIP": "5.47",
        "WHIP": "1.31",
        "WAR": "0.32"
      }
    }
  ],
  [
    "485ee3075e5e3300da7e3fa67c0cd0e81a82f1e8",
    {
      "id": "485ee3075e5e3300da7e3fa67c0cd0e81a82f1e8",
      "name": "김동엽",
      "team": "삼성",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "33",
        "Pos.": "DH",
        "oWAR": "-0.29",
        "dWAR": "0.00",
        "G": "8",
        "PA": "21",
        "ePA": "20",
        "AB": "18",
        "R": "1",
        "H": "2",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "TB": "3",
        "RBI": "2",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "1",
        "IB": "1",
        "SO": "5",
        "GDP": "0",
        "SH": "0",
        "SF": "1",
        "AVG": "0.111",
        "OBP": "0.190",
        "SLG": "0.167",
        "OPS": "0.357",
        "R/ePA": "-0.031",
        "wRC+": "-21.5",
        "WAR": "-0.29"
      }
    }
  ],
  [
    "3e956f7a450914d4c3084144b3c9e35220975051",
    {
      "id": "3e956f7a450914d4c3084144b3c9e35220975051",
      "name": "김성윤",
      "team": "삼성",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "24",
        "Pos.": "CF",
        "oWAR": "-0.07",
        "dWAR": "0.04",
        "G": "32",
        "PA": "89",
        "ePA": "86",
        "AB": "74",
        "R": "15",
        "H": "18",
        "2B": "4",
        "3B": "0",
        "HR": "0",
        "TB": "22",
        "RBI": "6",
        "SB": "3",
        "CS": "1",
        "BB": "9",
        "HP": "1",
        "IB": "0",
        "SO": "16",
        "GDP": "1",
        "SH": "3",
        "SF": "2",
        "AVG": "0.243",
        "OBP": "0.326",
        "SLG": "0.297",
        "OPS": "0.623",
        "R/ePA": "0.088",
        "wRC+": "65.0",
        "WAR": "-0.03"
      }
    }
  ],
  [
    "ba8c8a9ea379c54b145d48e2ac0bd69beed404e8",
    {
      "id": "ba8c8a9ea379c54b145d48e2ac0bd69beed404e8",
      "name": "최지광",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "25",
        "Pos.": "P",
        "G": "35",
        "GS": "0",
        "GR": "35",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "3",
        "L": "2",
        "S": "0",
        "HD": "7",
        "IP": "36.1",
        "ER": "9",
        "R": "9",
        "rRA": "12.25",
        "TBF": "154",
        "H": "22",
        "2B": "6",
        "3B": "1",
        "HR": "1",
        "BB": "21",
        "HP": "4",
        "IB": "2",
        "SO": "38",
        "ROE": "0",
        "BK": "1",
        "WP": "2",
        "ERA": "2.23",
        "RA9": "2.23",
        "rRA9": "3.03",
        "rRA9pf": "2.85",
        "FIP": "3.96",
        "WHIP": "1.18",
        "WAR": "1.63"
      }
    }
  ],
  [
    "9a9b42136a582896623b9f13d22f3c87dc08d7fa",
    {
      "id": "9a9b42136a582896623b9f13d22f3c87dc08d7fa",
      "name": "김현준",
      "team": "삼성",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "21",
        "Pos.": "RF",
        "oWAR": "-0.83",
        "dWAR": "0.25",
        "G": "79",
        "PA": "205",
        "ePA": "201",
        "AB": "183",
        "R": "23",
        "H": "41",
        "2B": "10",
        "3B": "1",
        "HR": "0",
        "TB": "53",
        "RBI": "11",
        "SB": "2",
        "CS": "0",
        "BB": "11",
        "HP": "4",
        "IB": "0",
        "SO": "49",
        "GDP": "4",
        "SH": "4",
        "SF": "3",
        "AVG": "0.224",
        "OBP": "0.279",
        "SLG": "0.290",
        "OPS": "0.569",
        "R/ePA": "0.059",
        "wRC+": "41.4",
        "WAR": "-0.58"
      }
    }
  ],
  [
    "8e09cc0944dfd538078ce9d7fd5e7815935c51c9",
    {
      "id": "8e09cc0944dfd538078ce9d7fd5e7815935c51c9",
      "name": "김도환",
      "team": "삼성",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "23",
        "Pos.": "C",
        "oWAR": "-0.07",
        "dWAR": "-0.04",
        "G": "1",
        "PA": "3",
        "ePA": "3",
        "AB": "3",
        "R": "0",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.000",
        "SLG": "0.000",
        "OPS": "0.000",
        "R/ePA": "-0.190",
        "wRC+": "-151.2",
        "WAR": "-0.11"
      }
    }
  ],
  [
    "33e4407d2f95a05789fed7fcf83390df56943cff",
    {
      "id": "33e4407d2f95a05789fed7fcf83390df56943cff",
      "name": "레예스",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "27",
        "Pos.": "P",
        "G": "26",
        "GS": "26",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "11",
        "L": "4",
        "S": "0",
        "HD": "0",
        "IP": "144.0",
        "ER": "61",
        "R": "65",
        "rRA": "62.55",
        "TBF": "617",
        "H": "159",
        "2B": "21",
        "3B": "3",
        "HR": "15",
        "BB": "30",
        "HP": "7",
        "IB": "0",
        "SO": "114",
        "ROE": "5",
        "BK": "0",
        "WP": "4",
        "ERA": "3.81",
        "RA9": "4.06",
        "rRA9": "3.91",
        "rRA9pf": "3.72",
        "FIP": "4.26",
        "WHIP": "1.31",
        "WAR": "5.06"
      }
    }
  ],
  [
    "622b53af1734e64361d692a5ec99d89e544d255a",
    {
      "id": "622b53af1734e64361d692a5ec99d89e544d255a",
      "name": "카데나스",
      "team": "삼성",
      "position": "RF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "26",
        "Pos.": "RF",
        "oWAR": "0.11",
        "dWAR": "-0.18",
        "G": "7",
        "PA": "25",
        "ePA": "25",
        "AB": "24",
        "R": "2",
        "H": "8",
        "2B": "2",
        "3B": "0",
        "HR": "2",
        "TB": "16",
        "RBI": "5",
        "SB": "0",
        "CS": "1",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "6",
        "GDP": "1",
        "SH": "0",
        "SF": "0",
        "AVG": "0.333",
        "OBP": "0.360",
        "SLG": "0.667",
        "OPS": "1.027",
        "R/ePA": "0.210",
        "wRC+": "145.5",
        "WAR": "-0.06"
      }
    }
  ],
  [
    "e36cef0855fafb4b9e6736782bd665faeddf552f",
    {
      "id": "e36cef0855fafb4b9e6736782bd665faeddf552f",
      "name": "임창민",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "38",
        "Pos.": "P",
        "G": "60",
        "GS": "0",
        "GR": "60",
        "GF": "6",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "1",
        "S": "1",
        "HD": "28",
        "IP": "54.1",
        "ER": "24",
        "R": "26",
        "rRA": "23.30",
        "TBF": "253",
        "H": "60",
        "2B": "10",
        "3B": "0",
        "HR": "4",
        "BB": "30",
        "HP": "2",
        "IB": "2",
        "SO": "50",
        "ROE": "3",
        "BK": "0",
        "WP": "5",
        "ERA": "3.98",
        "RA9": "4.31",
        "rRA9": "3.86",
        "rRA9pf": "3.61",
        "FIP": "4.49",
        "WHIP": "1.66",
        "WAR": "1.97"
      }
    }
  ],
  [
    "b6611f1a15776b52bc0a988f3ccabb6624ad9047",
    {
      "id": "b6611f1a15776b52bc0a988f3ccabb6624ad9047",
      "name": "홍무원",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "8171709d73baa4bde7b1e396d17afa0892ba4e33",
    {
      "id": "8171709d73baa4bde7b1e396d17afa0892ba4e33",
      "name": "강민호",
      "team": "삼성",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "38",
        "Pos.": "C",
        "oWAR": "3.02",
        "dWAR": "0.30",
        "G": "136",
        "PA": "452",
        "ePA": "450",
        "AB": "403",
        "R": "48",
        "H": "122",
        "2B": "19",
        "3B": "1",
        "HR": "19",
        "TB": "200",
        "RBI": "77",
        "SB": "3",
        "CS": "0",
        "BB": "35",
        "HP": "8",
        "IB": "2",
        "SO": "52",
        "GDP": "17",
        "SH": "0",
        "SF": "6",
        "AVG": "0.303",
        "OBP": "0.365",
        "SLG": "0.496",
        "OPS": "0.861",
        "R/ePA": "0.161",
        "wRC+": "115.6",
        "WAR": "3.32"
      }
    }
  ],
  [
    "82d5e4e6e65f1978b2fb9025e3c398a65bfc3816",
    {
      "id": "82d5e4e6e65f1978b2fb9025e3c398a65bfc3816",
      "name": "김시현",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2019",
        "Team": "삼성",
        "Age": "20",
        "Pos.": "P",
        "G": "14",
        "GS": "0",
        "GR": "14",
        "GF": "6",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "16.1",
        "ER": "8",
        "R": "9",
        "rRA": "8.30",
        "TBF": "71",
        "H": "16",
        "2B": "2",
        "3B": "0",
        "HR": "3",
        "BB": "8",
        "HP": "1",
        "IB": "0",
        "SO": "10",
        "ROE": "1",
        "BK": "0",
        "WP": "1",
        "ERA": "4.41",
        "RA9": "4.96",
        "rRA9": "4.57",
        "rRA9pf": "4.00",
        "FIP": "6.17",
        "WHIP": "1.47",
        "WAR": "0.27"
      }
    }
  ],
  [
    "8d257fe2bdd9680bc7c4dea59dd6e0f95fcc08fa",
    {
      "id": "8d257fe2bdd9680bc7c4dea59dd6e0f95fcc08fa",
      "name": "박권후",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2023",
        "Team": "삼성",
        "Age": "18",
        "Pos.": "P",
        "G": "4",
        "GS": "0",
        "GR": "4",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "2.2",
        "ER": "1",
        "R": "2",
        "rRA": "2.00",
        "TBF": "13",
        "H": "4",
        "2B": "0",
        "3B": "0",
        "HR": "1",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "0",
        "ROE": "0",
        "BK": "1",
        "WP": "0",
        "ERA": "3.38",
        "RA9": "6.75",
        "rRA9": "6.75",
        "rRA9pf": "6.31",
        "FIP": "9.47",
        "WHIP": "1.88",
        "WAR": "0.01"
      }
    }
  ],
  [
    "67ff73546b0ea3afcb79c9f3a8a6f67d043a9ef3",
    {
      "id": "67ff73546b0ea3afcb79c9f3a8a6f67d043a9ef3",
      "name": "허윤동",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2020",
        "Team": "삼성",
        "Age": "18",
        "Pos.": "P",
        "G": "11",
        "GS": "11",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "45.0",
        "ER": "24",
        "R": "26",
        "rRA": "23.95",
        "TBF": "204",
        "H": "43",
        "2B": "10",
        "3B": "0",
        "HR": "4",
        "BB": "31",
        "HP": "2",
        "IB": "0",
        "SO": "18",
        "ROE": "2",
        "BK": "1",
        "WP": "0",
        "ERA": "4.80",
        "RA9": "5.20",
        "rRA9": "4.79",
        "rRA9pf": "4.67",
        "FIP": "6.33",
        "WHIP": "1.64",
        "WAR": "1.05"
      }
    }
  ],
  [
    "936512ed6431098a92b84d06af1d3f104258d7f2",
    {
      "id": "936512ed6431098a92b84d06af1d3f104258d7f2",
      "name": "류승민",
      "team": "삼성",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "19",
        "Pos.": "RF",
        "oWAR": "0.04",
        "dWAR": "0.09",
        "G": "5",
        "PA": "3",
        "ePA": "3",
        "AB": "1",
        "R": "1",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "2",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.667",
        "SLG": "0.000",
        "OPS": "0.667",
        "R/ePA": "0.294",
        "wRC+": "226.9",
        "WAR": "0.13"
      }
    }
  ],
  [
    "38bdea9ba87d19d3f8e91ab30ac6c12e6e0337ba",
    {
      "id": "38bdea9ba87d19d3f8e91ab30ac6c12e6e0337ba",
      "name": "이창용",
      "team": "삼성",
      "position": "1B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "24",
        "Pos.": "1B",
        "oWAR": "-0.23",
        "dWAR": "-0.01",
        "G": "9",
        "PA": "21",
        "ePA": "21",
        "AB": "21",
        "R": "1",
        "H": "4",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "TB": "5",
        "RBI": "2",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "4",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.190",
        "OBP": "0.190",
        "SLG": "0.238",
        "OPS": "0.428",
        "R/ePA": "-0.013",
        "wRC+": "-9.2",
        "WAR": "-0.24"
      }
    }
  ],
  [
    "c609009f8630f8d273a5ede4522680076aaddfdf",
    {
      "id": "c609009f8630f8d273a5ede4522680076aaddfdf",
      "name": "최충연",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2018",
        "Team": "삼성",
        "Age": "20",
        "Pos.": "P",
        "G": "70",
        "GS": "0",
        "GR": "70",
        "GF": "14",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "6",
        "S": "8",
        "HD": "16",
        "IP": "85.0",
        "ER": "34",
        "R": "34",
        "rRA": "34.70",
        "TBF": "344",
        "H": "73",
        "2B": "16",
        "3B": "2",
        "HR": "6",
        "BB": "26",
        "HP": "2",
        "IB": "1",
        "SO": "101",
        "ROE": "1",
        "BK": "0",
        "WP": "8",
        "ERA": "3.60",
        "RA9": "3.60",
        "rRA9": "3.67",
        "rRA9pf": "3.45",
        "FIP": "3.20",
        "WHIP": "1.16",
        "WAR": "3.45"
      }
    }
  ],
  [
    "ccbeeeef64cd074feccb473ea43ef57565236b5d",
    {
      "id": "ccbeeeef64cd074feccb473ea43ef57565236b5d",
      "name": "코너",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "27",
        "Pos.": "P",
        "G": "28",
        "GS": "28",
        "GR": "0",
        "GF": "1",
        "CG": "1",
        "SHO": "1",
        "W": "11",
        "L": "6",
        "S": "0",
        "HD": "0",
        "IP": "160.0",
        "ER": "61",
        "R": "67",
        "rRA": "65.45",
        "TBF": "663",
        "H": "135",
        "2B": "17",
        "3B": "3",
        "HR": "21",
        "BB": "40",
        "HP": "19",
        "IB": "0",
        "SO": "158",
        "ROE": "4",
        "BK": "0",
        "WP": "9",
        "ERA": "3.43",
        "RA9": "3.77",
        "rRA9": "3.68",
        "rRA9pf": "3.54",
        "FIP": "4.44",
        "WHIP": "1.09",
        "WAR": "5.95"
      }
    }
  ],
  [
    "a0e95c335a5da12ea80248437c211e2eb8282190",
    {
      "id": "a0e95c335a5da12ea80248437c211e2eb8282190",
      "name": "김서준",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2022",
        "Team": "삼성",
        "Age": "18",
        "Pos.": "P",
        "G": "3",
        "GS": "0",
        "GR": "3",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "2.2",
        "ER": "3",
        "R": "4",
        "rRA": "4.50",
        "TBF": "19",
        "H": "6",
        "2B": "2",
        "3B": "0",
        "HR": "1",
        "BB": "4",
        "HP": "1",
        "IB": "0",
        "SO": "2",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "10.13",
        "RA9": "13.50",
        "rRA9": "15.19",
        "rRA9pf": "14.78",
        "FIP": "12.18",
        "WHIP": "3.75",
        "WAR": "-0.13"
      }
    }
  ],
  [
    "f3b722e0f0027d1509240c1f2d2ab51e923a6eb0",
    {
      "id": "f3b722e0f0027d1509240c1f2d2ab51e923a6eb0",
      "name": "최성훈",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "34",
        "Pos.": "P",
        "G": "28",
        "GS": "0",
        "GR": "28",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "3",
        "IP": "13.0",
        "ER": "10",
        "R": "10",
        "rRA": "9.30",
        "TBF": "61",
        "H": "14",
        "2B": "1",
        "3B": "0",
        "HR": "1",
        "BB": "9",
        "HP": "1",
        "IB": "1",
        "SO": "10",
        "ROE": "0",
        "BK": "0",
        "WP": "2",
        "ERA": "6.92",
        "RA9": "6.92",
        "rRA9": "6.44",
        "rRA9pf": "6.07",
        "FIP": "5.34",
        "WHIP": "1.77",
        "WAR": "0.08"
      }
    }
  ],
  [
    "5cb5940cc3d99866a44dcb76806c74d4a28c246d",
    {
      "id": "5cb5940cc3d99866a44dcb76806c74d4a28c246d",
      "name": "이호성",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "19",
        "Pos.": "P",
        "G": "16",
        "GS": "12",
        "GR": "4",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "4",
        "S": "0",
        "HD": "0",
        "IP": "45.0",
        "ER": "37",
        "R": "39",
        "rRA": "39.20",
        "TBF": "224",
        "H": "66",
        "2B": "13",
        "3B": "1",
        "HR": "9",
        "BB": "26",
        "HP": "2",
        "IB": "0",
        "SO": "28",
        "ROE": "0",
        "BK": "0",
        "WP": "3",
        "ERA": "7.40",
        "RA9": "7.80",
        "rRA9": "7.84",
        "rRA9pf": "7.39",
        "FIP": "6.95",
        "WHIP": "2.04",
        "WAR": "-0.04"
      }
    }
  ],
  [
    "ab35a3e9dccdeff94fcc08ea3b4ef192958f1fb7",
    {
      "id": "ab35a3e9dccdeff94fcc08ea3b4ef192958f1fb7",
      "name": "최채흥",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "28",
        "Pos.": "P",
        "G": "14",
        "GS": "1",
        "GR": "13",
        "GF": "3",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "1",
        "IP": "20.0",
        "ER": "14",
        "R": "15",
        "rRA": "16.55",
        "TBF": "96",
        "H": "24",
        "2B": "5",
        "3B": "1",
        "HR": "4",
        "BB": "12",
        "HP": "0",
        "IB": "0",
        "SO": "14",
        "ROE": "2",
        "BK": "0",
        "WP": "1",
        "ERA": "6.30",
        "RA9": "6.75",
        "rRA9": "7.45",
        "rRA9pf": "7.37",
        "FIP": "6.69",
        "WHIP": "1.80",
        "WAR": "-0.04"
      }
    }
  ],
  [
    "0fc9d5180903502b2c67e4ee3d664be8f225b7b8",
    {
      "id": "0fc9d5180903502b2c67e4ee3d664be8f225b7b8",
      "name": "이승현",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "21",
        "Pos.": "P",
        "G": "17",
        "GS": "17",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "6",
        "L": "4",
        "S": "0",
        "HD": "0",
        "IP": "87.1",
        "ER": "41",
        "R": "44",
        "rRA": "41.90",
        "TBF": "384",
        "H": "88",
        "2B": "11",
        "3B": "1",
        "HR": "9",
        "BB": "37",
        "HP": "6",
        "IB": "0",
        "SO": "68",
        "ROE": "3",
        "BK": "0",
        "WP": "4",
        "ERA": "4.23",
        "RA9": "4.53",
        "rRA9": "4.32",
        "rRA9pf": "4.11",
        "FIP": "5.01",
        "WHIP": "1.43",
        "WAR": "2.70"
      }
    }
  ],
  [
    "1e115f0bfa34a666c36109e717ce31ad1a2d15ef",
    {
      "id": "1e115f0bfa34a666c36109e717ce31ad1a2d15ef",
      "name": "와이드너",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2023",
        "Team": "삼성",
        "Age": "28",
        "Pos.": "P",
        "G": "10",
        "GS": "9",
        "GR": "1",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "3",
        "L": "3",
        "S": "0",
        "HD": "0",
        "IP": "53.1",
        "ER": "27",
        "R": "30",
        "rRA": "28.80",
        "TBF": "236",
        "H": "61",
        "2B": "9",
        "3B": "0",
        "HR": "3",
        "BB": "17",
        "HP": "3",
        "IB": "0",
        "SO": "42",
        "ROE": "4",
        "BK": "0",
        "WP": "2",
        "ERA": "4.56",
        "RA9": "5.06",
        "rRA9": "4.86",
        "rRA9pf": "4.63",
        "FIP": "3.71",
        "WHIP": "1.46",
        "WAR": "0.94"
      }
    }
  ],
  [
    "2070857ac74b2b5bf90b2b47bf2237a06ded0223",
    {
      "id": "2070857ac74b2b5bf90b2b47bf2237a06ded0223",
      "name": "김지찬",
      "team": "삼성",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "22",
        "Pos.": "CF",
        "oWAR": "3.58",
        "dWAR": "0.41",
        "G": "135",
        "PA": "535",
        "ePA": "521",
        "AB": "453",
        "R": "102",
        "H": "143",
        "2B": "16",
        "3B": "3",
        "HR": "3",
        "TB": "174",
        "RBI": "36",
        "SB": "42",
        "CS": "4",
        "BB": "60",
        "HP": "9",
        "IB": "2",
        "SO": "40",
        "GDP": "5",
        "SH": "12",
        "SF": "1",
        "AVG": "0.316",
        "OBP": "0.405",
        "SLG": "0.384",
        "OPS": "0.789",
        "R/ePA": "0.157",
        "wRC+": "112.7",
        "WAR": "3.98"
      }
    }
  ],
  [
    "bacbf0a1611e5252b5b16502d5e39e633c86814c",
    {
      "id": "bacbf0a1611e5252b5b16502d5e39e633c86814c",
      "name": "박병호",
      "team": "삼성",
      "position": "1B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "37",
        "Pos.": "1B",
        "oWAR": "0.40",
        "dWAR": "0.13",
        "G": "76",
        "PA": "285",
        "ePA": "285",
        "AB": "249",
        "R": "42",
        "H": "61",
        "2B": "5",
        "3B": "0",
        "HR": "20",
        "TB": "126",
        "RBI": "60",
        "SB": "3",
        "CS": "0",
        "BB": "31",
        "HP": "3",
        "IB": "0",
        "SO": "83",
        "GDP": "5",
        "SH": "0",
        "SF": "2",
        "AVG": "0.245",
        "OBP": "0.333",
        "SLG": "0.506",
        "OPS": "0.839",
        "R/ePA": "0.149",
        "wRC+": "106.7",
        "WAR": "0.53"
      }
    }
  ],
  [
    "5213076f5ea0a33cdab6ad52f0640dd0565c0c37",
    {
      "id": "5213076f5ea0a33cdab6ad52f0640dd0565c0c37",
      "name": "김호진",
      "team": "삼성",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "18",
        "Pos.": "3B",
        "oWAR": "-0.47",
        "dWAR": "0.07",
        "G": "26",
        "PA": "32",
        "ePA": "32",
        "AB": "29",
        "R": "4",
        "H": "2",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "2",
        "RBI": "3",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "1",
        "IB": "0",
        "SO": "11",
        "GDP": "1",
        "SH": "0",
        "SF": "1",
        "AVG": "0.069",
        "OBP": "0.125",
        "SLG": "0.069",
        "OPS": "0.194",
        "R/ePA": "-0.088",
        "wRC+": "-60.7",
        "WAR": "-0.40"
      }
    }
  ],
  [
    "d96233f2cfdac69a3be43a12e000c71661116b4a",
    {
      "id": "d96233f2cfdac69a3be43a12e000c71661116b4a",
      "name": "황동재",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "22",
        "Pos.": "P",
        "G": "15",
        "GS": "6",
        "GR": "9",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "2",
        "S": "0",
        "HD": "0",
        "IP": "42.0",
        "ER": "19",
        "R": "20",
        "rRA": "20.00",
        "TBF": "180",
        "H": "38",
        "2B": "9",
        "3B": "1",
        "HR": "2",
        "BB": "18",
        "HP": "4",
        "IB": "0",
        "SO": "30",
        "ROE": "0",
        "BK": "0",
        "WP": "5",
        "ERA": "4.07",
        "RA9": "4.29",
        "rRA9": "4.29",
        "rRA9pf": "4.25",
        "FIP": "4.60",
        "WHIP": "1.33",
        "WAR": "1.18"
      }
    }
  ],
  [
    "243769b3e1b90ae3c12ce2c18e0d546efa68dfff",
    {
      "id": "243769b3e1b90ae3c12ce2c18e0d546efa68dfff",
      "name": "김재윤",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "33",
        "Pos.": "P",
        "G": "65",
        "GS": "0",
        "GR": "65",
        "GF": "21",
        "CG": "0",
        "SHO": "0",
        "W": "4",
        "L": "8",
        "S": "11",
        "HD": "25",
        "IP": "66.0",
        "ER": "30",
        "R": "37",
        "rRA": "37.45",
        "TBF": "284",
        "H": "58",
        "2B": "9",
        "3B": "1",
        "HR": "13",
        "BB": "25",
        "HP": "1",
        "IB": "5",
        "SO": "51",
        "ROE": "5",
        "BK": "0",
        "WP": "2",
        "ERA": "4.09",
        "RA9": "5.05",
        "rRA9": "5.11",
        "rRA9pf": "4.81",
        "FIP": "5.69",
        "WHIP": "1.26",
        "WAR": "1.42"
      }
    }
  ],
  [
    "06c5a1bf3077f5549028b093af7a8c6ba3ff0f03",
    {
      "id": "06c5a1bf3077f5549028b093af7a8c6ba3ff0f03",
      "name": "피렐라",
      "team": "삼성",
      "position": "LF",
      "record": {
        "Div.": "베스트",
        "Year": "2022",
        "Team": "삼성",
        "Age": "32",
        "Pos.": "LF",
        "oWAR": "6.85",
        "dWAR": "-0.41",
        "G": "141",
        "PA": "630",
        "ePA": "619",
        "AB": "561",
        "R": "102",
        "H": "192",
        "2B": "33",
        "3B": "4",
        "HR": "28",
        "TB": "317",
        "RBI": "109",
        "SB": "15",
        "CS": "5",
        "BB": "55",
        "HP": "12",
        "IB": "12",
        "SO": "81",
        "GDP": "19",
        "SH": "0",
        "SF": "2",
        "AVG": "0.342",
        "OBP": "0.411",
        "SLG": "0.565",
        "OPS": "0.976",
        "R/ePA": "0.205",
        "wRC+": "170.8",
        "WAR": "6.44"
      }
    }
  ],
  [
    "b643eff0db7ac3715a904679e6fdcfe8ed0c32dd",
    {
      "id": "b643eff0db7ac3715a904679e6fdcfe8ed0c32dd",
      "name": "양우현",
      "team": "삼성",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "23",
        "Pos.": "2B",
        "oWAR": "-0.32",
        "dWAR": "0.15",
        "G": "7",
        "PA": "15",
        "ePA": "14",
        "AB": "13",
        "R": "0",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "1",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "6",
        "GDP": "1",
        "SH": "1",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.071",
        "SLG": "0.000",
        "OPS": "0.071",
        "R/ePA": "-0.138",
        "wRC+": "-92.5",
        "WAR": "-0.17"
      }
    }
  ],
  [
    "3c6292189243f8bf8f26b933c1059aad7cce342d",
    {
      "id": "3c6292189243f8bf8f26b933c1059aad7cce342d",
      "name": "홍원표",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "22",
        "Pos.": "P",
        "G": "4",
        "GS": "0",
        "GR": "4",
        "GF": "4",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "6.1",
        "ER": "3",
        "R": "3",
        "rRA": "4.20",
        "TBF": "28",
        "H": "4",
        "2B": "1",
        "3B": "0",
        "HR": "1",
        "BB": "6",
        "HP": "0",
        "IB": "0",
        "SO": "5",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "4.26",
        "RA9": "4.26",
        "rRA9": "5.97",
        "rRA9pf": "5.43",
        "FIP": "7.04",
        "WHIP": "1.58",
        "WAR": "0.08"
      }
    }
  ],
  [
    "ca7d2501ad140fc529608bed122bad0be68d0ac0",
    {
      "id": "ca7d2501ad140fc529608bed122bad0be68d0ac0",
      "name": "박준용",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "c7b5de5d593e4364ce2550ab071136bb52e1c86e",
    {
      "id": "c7b5de5d593e4364ce2550ab071136bb52e1c86e",
      "name": "정민성",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "8d450720de30d2b8ce35b9badcdbe09f2bdacff1",
    {
      "id": "8d450720de30d2b8ce35b9badcdbe09f2bdacff1",
      "name": "이민호",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2016",
        "Team": "NC",
        "Age": "22",
        "Pos.": "P",
        "G": "45",
        "GS": "21",
        "GR": "24",
        "GF": "7",
        "CG": "0",
        "SHO": "0",
        "W": "9",
        "L": "9",
        "S": "2",
        "HD": "3",
        "IP": "130.2",
        "ER": "80",
        "R": "90",
        "rRA": "91.20",
        "TBF": "603",
        "H": "159",
        "2B": "29",
        "3B": "3",
        "HR": "18",
        "BB": "55",
        "HP": "6",
        "IB": "0",
        "SO": "102",
        "ROE": "7",
        "BK": "0",
        "WP": "10",
        "ERA": "5.51",
        "RA9": "6.20",
        "rRA9": "6.28",
        "rRA9pf": "6.44",
        "FIP": "5.38",
        "WHIP": "1.64",
        "WAR": "1.51"
      }
    }
  ],
  [
    "ff9ac6e3093b239baa00e6d6bf89423a7152e32f",
    {
      "id": "ff9ac6e3093b239baa00e6d6bf89423a7152e32f",
      "name": "김세민",
      "team": "삼성",
      "position": "C",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "204777aade9771985dd8570cba5e0647134da457",
    {
      "id": "204777aade9771985dd8570cba5e0647134da457",
      "name": "유병선",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "fab82a0e1b54467b9030a370722cfc89723467f8",
    {
      "id": "fab82a0e1b54467b9030a370722cfc89723467f8",
      "name": "오현석",
      "team": "삼성",
      "position": "1B",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "1bdb6411d6e3edebf93f8cb921e8acf167737a6d",
    {
      "id": "1bdb6411d6e3edebf93f8cb921e8acf167737a6d",
      "name": "주한울",
      "team": "삼성",
      "position": "OF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "84d78b88f57af27c94587a9be286b39dc2f5e390",
    {
      "id": "84d78b88f57af27c94587a9be286b39dc2f5e390",
      "name": "홍승원",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "e5c9b424968ba5d031ff73e91296007597b78966",
    {
      "id": "e5c9b424968ba5d031ff73e91296007597b78966",
      "name": "박시원",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "b953eff793ec4340cd1bbf7c3e64b88f4d130267",
    {
      "id": "b953eff793ec4340cd1bbf7c3e64b88f4d130267",
      "name": "신경민",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "6744deb128bc0db56869092f9d80286bc023ba00",
    {
      "id": "6744deb128bc0db56869092f9d80286bc023ba00",
      "name": "박진우",
      "team": "삼성",
      "position": "C",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "94b66e127e29820da1c7805ee04a5a872d322e53",
    {
      "id": "94b66e127e29820da1c7805ee04a5a872d322e53",
      "name": "정진수",
      "team": "삼성",
      "position": "C",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "54a20c7b20ee385c9bad14fa65f50bdf8dd7abf5",
    {
      "id": "54a20c7b20ee385c9bad14fa65f50bdf8dd7abf5",
      "name": "김태우",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "8c768ff6bfc2e83fecb35c368e1da4333098fcb3",
    {
      "id": "8c768ff6bfc2e83fecb35c368e1da4333098fcb3",
      "name": "양도근",
      "team": "삼성",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "20",
        "Pos.": "2B",
        "oWAR": "0.01",
        "dWAR": "0.03",
        "G": "16",
        "PA": "29",
        "ePA": "28",
        "AB": "23",
        "R": "5",
        "H": "4",
        "2B": "0",
        "3B": "1",
        "HR": "0",
        "TB": "6",
        "RBI": "2",
        "SB": "0",
        "CS": "0",
        "BB": "5",
        "HP": "0",
        "IB": "0",
        "SO": "8",
        "GDP": "0",
        "SH": "1",
        "SF": "0",
        "AVG": "0.174",
        "OBP": "0.321",
        "SLG": "0.261",
        "OPS": "0.582",
        "R/ePA": "0.085",
        "wRC+": "58.1",
        "WAR": "0.04"
      }
    }
  ],
  [
    "1d93fca7cb1d1e56e2c908254f3d97f58d224053",
    {
      "id": "1d93fca7cb1d1e56e2c908254f3d97f58d224053",
      "name": "한연욱",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "5d50c2499b05bb8701378601e56ef6566f9f6aa3",
    {
      "id": "5d50c2499b05bb8701378601e56ef6566f9f6aa3",
      "name": "박장민",
      "team": "삼성",
      "position": "2B",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "c74405ba1fa26e5ca13d861258302f8e49272ac3",
    {
      "id": "c74405ba1fa26e5ca13d861258302f8e49272ac3",
      "name": "장재혁",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "53eccb6953b953e703d61c43457fd1f2ae58c285",
    {
      "id": "53eccb6953b953e703d61c43457fd1f2ae58c285",
      "name": "이현준",
      "team": "삼성",
      "position": "2B",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "6e49c183ce941f55dedef715888a95f7eff3ba0d",
    {
      "id": "6e49c183ce941f55dedef715888a95f7eff3ba0d",
      "name": "이재호",
      "team": "삼성",
      "position": "3B",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "14090a720ac0a0878c91ee0b643030134a4c3934",
    {
      "id": "14090a720ac0a0878c91ee0b643030134a4c3934",
      "name": "서주원",
      "team": "삼성",
      "position": "C",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "f5846d106762fe0334f4096aefc224512fc3223a",
    {
      "id": "f5846d106762fe0334f4096aefc224512fc3223a",
      "name": "김대호",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "삼성",
        "Age": "22",
        "Pos.": "P",
        "G": "1",
        "GS": "1",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "4.0",
        "ER": "5",
        "R": "6",
        "rRA": "6.00",
        "TBF": "22",
        "H": "7",
        "2B": "2",
        "3B": "0",
        "HR": "1",
        "BB": "2",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "ROE": "1",
        "BK": "0",
        "WP": "0",
        "ERA": "11.25",
        "RA9": "13.50",
        "rRA9": "13.50",
        "rRA9pf": "13.55",
        "FIP": "8.10",
        "WHIP": "2.25",
        "WAR": "-0.17"
      }
    }
  ],
  [
    "fa3cec9e7c31e3d83ab9b562a0774221c30e85ce",
    {
      "id": "fa3cec9e7c31e3d83ab9b562a0774221c30e85ce",
      "name": "김민호",
      "team": "삼성",
      "position": "2B",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "9fe4d5e4cf7c8ecc60979f664e24de90e18be2ca",
    {
      "id": "9fe4d5e4cf7c8ecc60979f664e24de90e18be2ca",
      "name": "김성경",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "6ddc8ec675663af6b538b4071ececae80fd44abf",
    {
      "id": "6ddc8ec675663af6b538b4071ececae80fd44abf",
      "name": "김동현",
      "team": "삼성",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "73f1f0fee6f66d5f2f8ce3e6a630ff4280c9f423",
    {
      "id": "73f1f0fee6f66d5f2f8ce3e6a630ff4280c9f423",
      "name": "김재형",
      "team": "삼성",
      "position": "C",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "87b19a34673ffbbaf5db3770baab24b1bbac32b0",
    {
      "id": "87b19a34673ffbbaf5db3770baab24b1bbac32b0",
      "name": "곽도규",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "19",
        "Pos.": "P",
        "G": "71",
        "GS": "0",
        "GR": "71",
        "GF": "3",
        "CG": "0",
        "SHO": "0",
        "W": "4",
        "L": "2",
        "S": "2",
        "HD": "16",
        "IP": "55.2",
        "ER": "22",
        "R": "24",
        "rRA": "28.60",
        "TBF": "248",
        "H": "43",
        "2B": "7",
        "3B": "0",
        "HR": "4",
        "BB": "34",
        "HP": "4",
        "IB": "1",
        "SO": "64",
        "ROE": "5",
        "BK": "0",
        "WP": "7",
        "ERA": "3.56",
        "RA9": "3.88",
        "rRA9": "4.62",
        "rRA9pf": "4.56",
        "FIP": "4.24",
        "WHIP": "1.38",
        "WAR": "1.33"
      }
    }
  ],
  [
    "72f4994adf4a0b8bdce30d9c3c43d4cae557169a",
    {
      "id": "72f4994adf4a0b8bdce30d9c3c43d4cae557169a",
      "name": "박찬호",
      "team": "KIA",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "28",
        "Pos.": "SS",
        "oWAR": "2.58",
        "dWAR": "0.20",
        "G": "134",
        "PA": "577",
        "ePA": "570",
        "AB": "515",
        "R": "86",
        "H": "158",
        "2B": "24",
        "3B": "1",
        "HR": "5",
        "TB": "199",
        "RBI": "61",
        "SB": "20",
        "CS": "13",
        "BB": "48",
        "HP": "1",
        "IB": "1",
        "SO": "44",
        "GDP": "9",
        "SH": "7",
        "SF": "6",
        "AVG": "0.307",
        "OBP": "0.363",
        "SLG": "0.386",
        "OPS": "0.749",
        "R/ePA": "0.129",
        "wRC+": "95.7",
        "WAR": "2.78"
      }
    }
  ],
  [
    "51bf2f989f467c44f73ca3f081bdd8604b632f39",
    {
      "id": "51bf2f989f467c44f73ca3f081bdd8604b632f39",
      "name": "장시현",
      "team": "KIA",
      "position": "SS",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "5973547c310592b820eab2b0e65d8dce2f04e0bb",
    {
      "id": "5973547c310592b820eab2b0e65d8dce2f04e0bb",
      "name": "박승훈",
      "team": "KIA",
      "position": "IF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "cde7b02d9de6725260c2cedc97a993745879f1ab",
    {
      "id": "cde7b02d9de6725260c2cedc97a993745879f1ab",
      "name": "고명성",
      "team": "KIA",
      "position": "2B",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "d6e1c89ce9714ef39d3e5d57513b71901877509f",
    {
      "id": "d6e1c89ce9714ef39d3e5d57513b71901877509f",
      "name": "김도월",
      "team": "KIA",
      "position": "2B",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "223fe2db4038a010f76521f9ac04165fee50de6b",
    {
      "id": "223fe2db4038a010f76521f9ac04165fee50de6b",
      "name": "이중석",
      "team": "KIA",
      "position": "LF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "20886f00230e10cab5c74a0eceb58792c0cec767",
    {
      "id": "20886f00230e10cab5c74a0eceb58792c0cec767",
      "name": "손호원",
      "team": "KIA",
      "position": "IF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "c8b96e166e0a7cde5d92170a825027355f609834",
    {
      "id": "c8b96e166e0a7cde5d92170a825027355f609834",
      "name": "김선빈",
      "team": "KIA",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "34",
        "Pos.": "2B",
        "oWAR": "2.98",
        "dWAR": "-0.60",
        "G": "116",
        "PA": "466",
        "ePA": "458",
        "AB": "423",
        "R": "48",
        "H": "139",
        "2B": "23",
        "3B": "0",
        "HR": "9",
        "TB": "189",
        "RBI": "57",
        "SB": "5",
        "CS": "1",
        "BB": "30",
        "HP": "5",
        "IB": "0",
        "SO": "39",
        "GDP": "13",
        "SH": "7",
        "SF": "0",
        "AVG": "0.329",
        "OBP": "0.380",
        "SLG": "0.447",
        "OPS": "0.827",
        "R/ePA": "0.156",
        "wRC+": "117.4",
        "WAR": "2.38"
      }
    }
  ],
  [
    "fba9aaba150b2288e2d0e918ecd80f65be379340",
    {
      "id": "fba9aaba150b2288e2d0e918ecd80f65be379340",
      "name": "박건우",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2021",
        "Team": "KIA",
        "Age": "22",
        "Pos.": "P",
        "G": "3",
        "GS": "0",
        "GR": "3",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "3.0",
        "ER": "0",
        "R": "0",
        "rRA": "0.00",
        "TBF": "11",
        "H": "2",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "BB": "1",
        "HP": "1",
        "IB": "0",
        "SO": "3",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "0.00",
        "RA9": "0.00",
        "rRA9": "0.00",
        "rRA9pf": "0.00",
        "FIP": "3.33",
        "WHIP": "1.00",
        "WAR": "0.12"
      }
    }
  ],
  [
    "51b4b7e7336452e1900ee672cc3921eafa621a59",
    {
      "id": "51b4b7e7336452e1900ee672cc3921eafa621a59",
      "name": "유지성",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "23",
        "Pos.": "P",
        "G": "3",
        "GS": "0",
        "GR": "3",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "2.1",
        "ER": "2",
        "R": "4",
        "rRA": "2.80",
        "TBF": "14",
        "H": "5",
        "2B": "0",
        "3B": "0",
        "HR": "1",
        "BB": "0",
        "HP": "1",
        "IB": "0",
        "SO": "3",
        "ROE": "1",
        "BK": "0",
        "WP": "0",
        "ERA": "7.71",
        "RA9": "15.43",
        "rRA9": "10.80",
        "rRA9pf": "10.82",
        "FIP": "7.41",
        "WHIP": "2.14",
        "WAR": "-0.04"
      }
    }
  ],
  [
    "971486047f913f62f36b1e087c3847697c066425",
    {
      "id": "971486047f913f62f36b1e087c3847697c066425",
      "name": "김민수",
      "team": "KIA",
      "position": "RF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "fbc7546bc6a5ccef1ee5d10f4012d84051e2916e",
    {
      "id": "fbc7546bc6a5ccef1ee5d10f4012d84051e2916e",
      "name": "배현호",
      "team": "KIA",
      "position": "C",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "5c16ad5a8738b42efe4c0548ce6494ad2493ae4b",
    {
      "id": "5c16ad5a8738b42efe4c0548ce6494ad2493ae4b",
      "name": "박상준",
      "team": "KIA",
      "position": "1B",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "2d5a0de4a9c7cb4487317a9fc240d41760d991eb",
    {
      "id": "2d5a0de4a9c7cb4487317a9fc240d41760d991eb",
      "name": "김도영",
      "team": "KIA",
      "position": "3B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "20",
        "Pos.": "3B",
        "oWAR": "8.51",
        "dWAR": "-0.18",
        "G": "141",
        "PA": "625",
        "ePA": "619",
        "AB": "544",
        "R": "143",
        "H": "189",
        "2B": "29",
        "3B": "10",
        "HR": "38",
        "TB": "352",
        "RBI": "109",
        "SB": "40",
        "CS": "4",
        "BB": "66",
        "HP": "7",
        "IB": "7",
        "SO": "110",
        "GDP": "10",
        "SH": "1",
        "SF": "7",
        "AVG": "0.347",
        "OBP": "0.420",
        "SLG": "0.647",
        "OPS": "1.067",
        "R/ePA": "0.232",
        "wRC+": "172.5",
        "WAR": "8.32"
      }
    }
  ],
  [
    "47209aa6437073db1b361d4c076e4d4a1d6fbb5d",
    {
      "id": "47209aa6437073db1b361d4c076e4d4a1d6fbb5d",
      "name": "이성주",
      "team": "KIA",
      "position": "C",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "3dc9116455b71a2f867f141f3ed8c56695de12f3",
    {
      "id": "3dc9116455b71a2f867f141f3ed8c56695de12f3",
      "name": "홍종표",
      "team": "KIA",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "23",
        "Pos.": "2B",
        "oWAR": "0.48",
        "dWAR": "-0.32",
        "G": "100",
        "PA": "115",
        "ePA": "112",
        "AB": "105",
        "R": "27",
        "H": "31",
        "2B": "4",
        "3B": "3",
        "HR": "0",
        "TB": "41",
        "RBI": "11",
        "SB": "5",
        "CS": "2",
        "BB": "6",
        "HP": "1",
        "IB": "0",
        "SO": "31",
        "GDP": "0",
        "SH": "3",
        "SF": "0",
        "AVG": "0.295",
        "OBP": "0.339",
        "SLG": "0.390",
        "OPS": "0.729",
        "R/ePA": "0.120",
        "wRC+": "89.0",
        "WAR": "0.16"
      }
    }
  ],
  [
    "81b76b7cfb4d3ab8ebc87fa1ce14f9e4a8a0becb",
    {
      "id": "81b76b7cfb4d3ab8ebc87fa1ce14f9e4a8a0becb",
      "name": "김원경",
      "team": "KIA",
      "position": "2B",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "0f486e9d9cc3fd6fc33b52a26a5fa59986a61e47",
    {
      "id": "0f486e9d9cc3fd6fc33b52a26a5fa59986a61e47",
      "name": "이창진",
      "team": "KIA",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "32",
        "Pos.": "LF",
        "oWAR": "0.80",
        "dWAR": "-0.26",
        "G": "103",
        "PA": "247",
        "ePA": "242",
        "AB": "191",
        "R": "36",
        "H": "50",
        "2B": "10",
        "3B": "0",
        "HR": "1",
        "TB": "63",
        "RBI": "18",
        "SB": "4",
        "CS": "3",
        "BB": "45",
        "HP": "2",
        "IB": "0",
        "SO": "36",
        "GDP": "6",
        "SH": "5",
        "SF": "4",
        "AVG": "0.262",
        "OBP": "0.401",
        "SLG": "0.330",
        "OPS": "0.731",
        "R/ePA": "0.143",
        "wRC+": "107.0",
        "WAR": "0.54"
      }
    }
  ],
  [
    "26051394fce65020a83561faf8b589dffd3c6bc8",
    {
      "id": "26051394fce65020a83561faf8b589dffd3c6bc8",
      "name": "김양수",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "420e41b07222aec2e61ed766c8cd86869c436fae",
    {
      "id": "420e41b07222aec2e61ed766c8cd86869c436fae",
      "name": "김선우",
      "team": "KIA",
      "position": "C",
      "record": {
        "Div.": "베스트",
        "Year": "2022",
        "Team": "KIA",
        "Age": "20",
        "Pos.": "C",
        "oWAR": "0.08",
        "dWAR": "-0.03",
        "G": "3",
        "PA": "4",
        "ePA": "4",
        "AB": "3",
        "R": "1",
        "H": "1",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "1",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "1",
        "IB": "0",
        "SO": "0",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.333",
        "OBP": "0.500",
        "SLG": "0.333",
        "OPS": "0.833",
        "R/ePA": "0.213",
        "wRC+": "183.6",
        "WAR": "0.05"
      }
    }
  ],
  [
    "cc5ddd3417bff06f1c32170dd5714dc43f3091c2",
    {
      "id": "cc5ddd3417bff06f1c32170dd5714dc43f3091c2",
      "name": "나용기",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "a5b538d9b1a1853410db5eaa783e3ea7e46e0ba9",
    {
      "id": "a5b538d9b1a1853410db5eaa783e3ea7e46e0ba9",
      "name": "이영재",
      "team": "KIA",
      "position": "LF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "7455df97d30840c7c62798233bc3f060dae40a75",
    {
      "id": "7455df97d30840c7c62798233bc3f060dae40a75",
      "name": "오정환",
      "team": "KIA",
      "position": "2B",
      "record": {
        "Div.": "베스트",
        "Year": "2021",
        "Team": "KIA",
        "Age": "21",
        "Pos.": "2B",
        "oWAR": "0.00",
        "dWAR": "-0.16",
        "G": "9",
        "PA": "18",
        "ePA": "18",
        "AB": "17",
        "R": "1",
        "H": "4",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "TB": "5",
        "RBI": "2",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "9",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.235",
        "OBP": "0.278",
        "SLG": "0.294",
        "OPS": "0.572",
        "R/ePA": "0.058",
        "wRC+": "47.6",
        "WAR": "-0.16"
      }
    }
  ],
  [
    "4a6d187309364029c1c6a96418dee227fa620e7b",
    {
      "id": "4a6d187309364029c1c6a96418dee227fa620e7b",
      "name": "김재현",
      "team": "KIA",
      "position": "2B",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "535e266b066979c066568d303d1a50c88c00ef8f",
    {
      "id": "535e266b066979c066568d303d1a50c88c00ef8f",
      "name": "유승철",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "25",
        "Pos.": "P",
        "G": "5",
        "GS": "0",
        "GR": "5",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "5.0",
        "ER": "3",
        "R": "3",
        "rRA": "1.80",
        "TBF": "23",
        "H": "3",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "BB": "7",
        "HP": "0",
        "IB": "0",
        "SO": "4",
        "ROE": "0",
        "BK": "0",
        "WP": "2",
        "ERA": "5.40",
        "RA9": "5.40",
        "rRA9": "3.24",
        "rRA9pf": "3.54",
        "FIP": "6.54",
        "WHIP": "2.00",
        "WAR": "0.13"
      }
    }
  ],
  [
    "746f2be5af888b696a0a197947a27d17066ec018",
    {
      "id": "746f2be5af888b696a0a197947a27d17066ec018",
      "name": "송후섭",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2022",
        "Team": "KIA",
        "Age": "24",
        "Pos.": "P",
        "G": "1",
        "GS": "0",
        "GR": "1",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "0.1",
        "ER": "6",
        "R": "6",
        "rRA": "5.30",
        "TBF": "7",
        "H": "4",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "BB": "1",
        "HP": "1",
        "IB": "0",
        "SO": "0",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "99.99",
        "RA9": "99.99",
        "rRA9": "143.10",
        "rRA9pf": "133.35",
        "FIP": "22.38",
        "WHIP": "15.00",
        "WAR": "-0.24"
      }
    }
  ],
  [
    "cfe0c91d39c6ff71b6209438f49adb45c3e75f29",
    {
      "id": "cfe0c91d39c6ff71b6209438f49adb45c3e75f29",
      "name": "윤도현",
      "team": "KIA",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "20",
        "Pos.": "2B",
        "oWAR": "0.33",
        "dWAR": "0.16",
        "G": "6",
        "PA": "27",
        "ePA": "27",
        "AB": "27",
        "R": "5",
        "H": "11",
        "2B": "2",
        "3B": "0",
        "HR": "1",
        "TB": "16",
        "RBI": "8",
        "SB": "1",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "9",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.407",
        "OBP": "0.407",
        "SLG": "0.593",
        "OPS": "1.000",
        "R/ePA": "0.212",
        "wRC+": "154.7",
        "WAR": "0.50"
      }
    }
  ],
  [
    "e9c508b508dcb4dc70a9b5b197bcbed3713cc7c8",
    {
      "id": "e9c508b508dcb4dc70a9b5b197bcbed3713cc7c8",
      "name": "김두현",
      "team": "KIA",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "20",
        "Pos.": "SS",
        "oWAR": "0.10",
        "dWAR": "-0.26",
        "G": "3",
        "PA": "6",
        "ePA": "6",
        "AB": "5",
        "R": "2",
        "H": "2",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "2",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "1",
        "IB": "0",
        "SO": "0",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.400",
        "OBP": "0.500",
        "SLG": "0.400",
        "OPS": "0.900",
        "R/ePA": "0.229",
        "wRC+": "164.9",
        "WAR": "-0.15"
      }
    }
  ],
  [
    "93083ff32c323d2fe3020a2ab3de44dbbf668997",
    {
      "id": "93083ff32c323d2fe3020a2ab3de44dbbf668997",
      "name": "크로우",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "29",
        "Pos.": "P",
        "G": "8",
        "GS": "8",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "5",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "40.1",
        "ER": "16",
        "R": "19",
        "rRA": "18.00",
        "TBF": "172",
        "H": "41",
        "2B": "7",
        "3B": "0",
        "HR": "2",
        "BB": "12",
        "HP": "1",
        "IB": "1",
        "SO": "43",
        "ROE": "1",
        "BK": "0",
        "WP": "0",
        "ERA": "3.57",
        "RA9": "4.24",
        "rRA9": "4.02",
        "rRA9pf": "4.21",
        "FIP": "3.05",
        "WHIP": "1.31",
        "WAR": "1.25"
      }
    }
  ],
  [
    "30df849d0ef5e9de3c8ae6435110ea119b3c2e88",
    {
      "id": "30df849d0ef5e9de3c8ae6435110ea119b3c2e88",
      "name": "김용완",
      "team": "KIA",
      "position": "IF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "a010f837ad225b84008de712165e18dfd9fef761",
    {
      "id": "a010f837ad225b84008de712165e18dfd9fef761",
      "name": "윤영철",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "19",
        "Pos.": "P",
        "G": "18",
        "GS": "18",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "7",
        "L": "4",
        "S": "0",
        "HD": "0",
        "IP": "81.2",
        "ER": "38",
        "R": "44",
        "rRA": "42.65",
        "TBF": "362",
        "H": "82",
        "2B": "14",
        "3B": "1",
        "HR": "8",
        "BB": "43",
        "HP": "1",
        "IB": "0",
        "SO": "58",
        "ROE": "7",
        "BK": "0",
        "WP": "1",
        "ERA": "4.19",
        "RA9": "4.85",
        "rRA9": "4.70",
        "rRA9pf": "4.61",
        "FIP": "5.26",
        "WHIP": "1.53",
        "WAR": "2.18"
      }
    }
  ],
  [
    "df60b990ae456f79b4bd56a22435b755a5dd3fbf",
    {
      "id": "df60b990ae456f79b4bd56a22435b755a5dd3fbf",
      "name": "최수빈",
      "team": "KIA",
      "position": "SS",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "69b6184f845516f673d8220373bdf90796ac5d81",
    {
      "id": "69b6184f845516f673d8220373bdf90796ac5d81",
      "name": "김규성",
      "team": "KIA",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "26",
        "Pos.": "3B",
        "oWAR": "0.05",
        "dWAR": "-0.25",
        "G": "27",
        "PA": "15",
        "ePA": "14",
        "AB": "12",
        "R": "5",
        "H": "3",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "TB": "4",
        "RBI": "2",
        "SB": "1",
        "CS": "0",
        "BB": "2",
        "HP": "0",
        "IB": "0",
        "SO": "4",
        "GDP": "0",
        "SH": "1",
        "SF": "0",
        "AVG": "0.250",
        "OBP": "0.357",
        "SLG": "0.333",
        "OPS": "0.690",
        "R/ePA": "0.119",
        "wRC+": "88.3",
        "WAR": "-0.19"
      }
    }
  ],
  [
    "216306fb4a038b7cb24226b9e75032e9e1901a87",
    {
      "id": "216306fb4a038b7cb24226b9e75032e9e1901a87",
      "name": "박정우",
      "team": "KIA",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "25",
        "Pos.": "CF",
        "oWAR": "0.05",
        "dWAR": "0.37",
        "G": "66",
        "PA": "69",
        "ePA": "69",
        "AB": "65",
        "R": "17",
        "H": "20",
        "2B": "3",
        "3B": "1",
        "HR": "0",
        "TB": "25",
        "RBI": "11",
        "SB": "0",
        "CS": "1",
        "BB": "4",
        "HP": "0",
        "IB": "0",
        "SO": "14",
        "GDP": "3",
        "SH": "0",
        "SF": "0",
        "AVG": "0.308",
        "OBP": "0.348",
        "SLG": "0.385",
        "OPS": "0.733",
        "R/ePA": "0.121",
        "wRC+": "89.2",
        "WAR": "0.42"
      }
    }
  ],
  [
    "3779aef776a147b9c70d0fe02d54f33aece4f90f",
    {
      "id": "3779aef776a147b9c70d0fe02d54f33aece4f90f",
      "name": "이도현",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "477db5fdb59a87a7bec6ee45058ba2ca9f5df7ea",
    {
      "id": "477db5fdb59a87a7bec6ee45058ba2ca9f5df7ea",
      "name": "박시온",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "489bf1f97d83ead54bed71e1bdff8ca505a86a3a",
    {
      "id": "489bf1f97d83ead54bed71e1bdff8ca505a86a3a",
      "name": "최원준",
      "team": "KIA",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "26",
        "Pos.": "CF",
        "oWAR": "2.43",
        "dWAR": "-0.26",
        "G": "136",
        "PA": "508",
        "ePA": "501",
        "AB": "438",
        "R": "75",
        "H": "128",
        "2B": "23",
        "3B": "3",
        "HR": "9",
        "TB": "184",
        "RBI": "56",
        "SB": "21",
        "CS": "9",
        "BB": "50",
        "HP": "8",
        "IB": "0",
        "SO": "66",
        "GDP": "6",
        "SH": "7",
        "SF": "5",
        "AVG": "0.292",
        "OBP": "0.371",
        "SLG": "0.420",
        "OPS": "0.791",
        "R/ePA": "0.147",
        "wRC+": "110.1",
        "WAR": "2.17"
      }
    }
  ],
  [
    "ec4504bebfef4b99b3a2bd2dbbc9399e04c44604",
    {
      "id": "ec4504bebfef4b99b3a2bd2dbbc9399e04c44604",
      "name": "임기영",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "30",
        "Pos.": "P",
        "G": "37",
        "GS": "3",
        "GR": "34",
        "GF": "3",
        "CG": "0",
        "SHO": "0",
        "W": "6",
        "L": "2",
        "S": "0",
        "HD": "2",
        "IP": "45.2",
        "ER": "32",
        "R": "35",
        "rRA": "37.20",
        "TBF": "218",
        "H": "61",
        "2B": "12",
        "3B": "0",
        "HR": "8",
        "BB": "17",
        "HP": "1",
        "IB": "1",
        "SO": "36",
        "ROE": "4",
        "BK": "1",
        "WP": "1",
        "ERA": "6.31",
        "RA9": "6.90",
        "rRA9": "7.33",
        "rRA9pf": "7.37",
        "FIP": "5.47",
        "WHIP": "1.71",
        "WAR": "-0.16"
      }
    }
  ],
  [
    "5fc3974c86bf5309fd547fab565aadb0c328dc44",
    {
      "id": "5fc3974c86bf5309fd547fab565aadb0c328dc44",
      "name": "윤중현",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "28",
        "Pos.": "P",
        "G": "11",
        "GS": "0",
        "GR": "11",
        "GF": "4",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "10.1",
        "ER": "16",
        "R": "17",
        "rRA": "19.25",
        "TBF": "62",
        "H": "22",
        "2B": "2",
        "3B": "1",
        "HR": "4",
        "BB": "9",
        "HP": "3",
        "IB": "1",
        "SO": "4",
        "ROE": "1",
        "BK": "0",
        "WP": "0",
        "ERA": "13.94",
        "RA9": "14.81",
        "rRA9": "16.77",
        "rRA9pf": "16.88",
        "FIP": "11.14",
        "WHIP": "3.00",
        "WAR": "-0.82"
      }
    }
  ],
  [
    "c8d41d7cbbf23787b2dc85b8072f1b3fa46a9649",
    {
      "id": "c8d41d7cbbf23787b2dc85b8072f1b3fa46a9649",
      "name": "이준영",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "31",
        "Pos.": "P",
        "G": "56",
        "GS": "0",
        "GR": "56",
        "GF": "5",
        "CG": "0",
        "SHO": "0",
        "W": "4",
        "L": "0",
        "S": "0",
        "HD": "9",
        "IP": "35.0",
        "ER": "15",
        "R": "21",
        "rRA": "22.15",
        "TBF": "160",
        "H": "37",
        "2B": "8",
        "3B": "0",
        "HR": "5",
        "BB": "17",
        "HP": "2",
        "IB": "3",
        "SO": "33",
        "ROE": "4",
        "BK": "0",
        "WP": "0",
        "ERA": "3.86",
        "RA9": "5.40",
        "rRA9": "5.70",
        "rRA9pf": "5.64",
        "FIP": "4.96",
        "WHIP": "1.54",
        "WAR": "0.42"
      }
    }
  ],
  [
    "6a06b7b92daaf17756d7f8efb7251af24f0c0305",
    {
      "id": "6a06b7b92daaf17756d7f8efb7251af24f0c0305",
      "name": "권혁경",
      "team": "KIA",
      "position": "C",
      "record": {
        "Div.": "베스트",
        "Year": "2022",
        "Team": "KIA",
        "Age": "19",
        "Pos.": "C",
        "oWAR": "0.03",
        "dWAR": "-0.03",
        "G": "2",
        "PA": "2",
        "ePA": "2",
        "AB": "2",
        "R": "0",
        "H": "1",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "1",
        "RBI": "1",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "0",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.500",
        "OBP": "0.500",
        "SLG": "0.500",
        "OPS": "1.000",
        "R/ePA": "0.215",
        "wRC+": "197.9",
        "WAR": "0.01"
      }
    }
  ],
  [
    "e0000bac970c76dfa8ce46e90f6a4d86522ca882",
    {
      "id": "e0000bac970c76dfa8ce46e90f6a4d86522ca882",
      "name": "홍원빈",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "a7987b198ef0f3df5be2092489dae776bd4fe648",
    {
      "id": "a7987b198ef0f3df5be2092489dae776bd4fe648",
      "name": "김사윤",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "29",
        "Pos.": "P",
        "G": "23",
        "GS": "2",
        "GR": "21",
        "GF": "10",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "1",
        "HD": "0",
        "IP": "37.0",
        "ER": "19",
        "R": "29",
        "rRA": "30.05",
        "TBF": "173",
        "H": "40",
        "2B": "6",
        "3B": "2",
        "HR": "2",
        "BB": "25",
        "HP": "5",
        "IB": "0",
        "SO": "36",
        "ROE": "1",
        "BK": "0",
        "WP": "4",
        "ERA": "4.62",
        "RA9": "7.05",
        "rRA9": "7.31",
        "rRA9pf": "7.29",
        "FIP": "4.92",
        "WHIP": "1.76",
        "WAR": "-0.01"
      }
    }
  ],
  [
    "8d23cceacf73e02b39976c50c8eb320b5bf0907b",
    {
      "id": "8d23cceacf73e02b39976c50c8eb320b5bf0907b",
      "name": "오규석",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "0aa2ed82d786aa9fc0dc0cf74ef53e22305ecd1a",
    {
      "id": "0aa2ed82d786aa9fc0dc0cf74ef53e22305ecd1a",
      "name": "주효상",
      "team": "KIA",
      "position": "C",
      "record": {
        "Div.": "베스트",
        "Year": "2016",
        "Team": "넥센",
        "Age": "18",
        "Pos.": "C",
        "oWAR": "-0.18",
        "dWAR": "0.12",
        "G": "12",
        "PA": "19",
        "ePA": "19",
        "AB": "18",
        "R": "3",
        "H": "4",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "TB": "5",
        "RBI": "3",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "9",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.222",
        "OBP": "0.263",
        "SLG": "0.278",
        "OPS": "0.541",
        "R/ePA": "0.039",
        "wRC+": "27.2",
        "WAR": "-0.06"
      }
    }
  ],
  [
    "3e07498876269943b12a97b8df288078ff06ecc7",
    {
      "id": "3e07498876269943b12a97b8df288078ff06ecc7",
      "name": "최정용",
      "team": "KIA",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "27",
        "Pos.": "2B",
        "oWAR": "-0.10",
        "dWAR": "-0.10",
        "G": "6",
        "PA": "12",
        "ePA": "12",
        "AB": "11",
        "R": "0",
        "H": "2",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "2",
        "RBI": "1",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "4",
        "GDP": "1",
        "SH": "0",
        "SF": "0",
        "AVG": "0.182",
        "OBP": "0.250",
        "SLG": "0.182",
        "OPS": "0.432",
        "R/ePA": "0.010",
        "wRC+": "7.5",
        "WAR": "-0.20"
      }
    }
  ],
  [
    "3aff8d736ada3faeefe7bc27ba38394c7a3031ef",
    {
      "id": "3aff8d736ada3faeefe7bc27ba38394c7a3031ef",
      "name": "김승현",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "31",
        "Pos.": "P",
        "G": "15",
        "GS": "0",
        "GR": "15",
        "GF": "4",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "18.0",
        "ER": "8",
        "R": "12",
        "rRA": "11.95",
        "TBF": "85",
        "H": "23",
        "2B": "5",
        "3B": "1",
        "HR": "3",
        "BB": "7",
        "HP": "0",
        "IB": "0",
        "SO": "11",
        "ROE": "2",
        "BK": "0",
        "WP": "0",
        "ERA": "4.00",
        "RA9": "6.00",
        "rRA9": "5.98",
        "rRA9pf": "5.85",
        "FIP": "5.86",
        "WHIP": "1.67",
        "WAR": "0.20"
      }
    }
  ],
  [
    "b7c5882592252855d0433af8b818e1bc02e30a34",
    {
      "id": "b7c5882592252855d0433af8b818e1bc02e30a34",
      "name": "이우성",
      "team": "KIA",
      "position": "RF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "29",
        "Pos.": "1B",
        "oWAR": "0.73",
        "dWAR": "-0.55",
        "G": "112",
        "PA": "449",
        "ePA": "449",
        "AB": "399",
        "R": "56",
        "H": "115",
        "2B": "16",
        "3B": "1",
        "HR": "9",
        "TB": "160",
        "RBI": "54",
        "SB": "7",
        "CS": "1",
        "BB": "44",
        "HP": "3",
        "IB": "0",
        "SO": "89",
        "GDP": "17",
        "SH": "0",
        "SF": "3",
        "AVG": "0.288",
        "OBP": "0.361",
        "SLG": "0.401",
        "OPS": "0.762",
        "R/ePA": "0.134",
        "wRC+": "99.8",
        "WAR": "0.18"
      }
    }
  ],
  [
    "63e502c580e037ae348caa879996d550528ee01a",
    {
      "id": "63e502c580e037ae348caa879996d550528ee01a",
      "name": "한승택",
      "team": "KIA",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "29",
        "Pos.": "C",
        "oWAR": "0.11",
        "dWAR": "-0.17",
        "G": "20",
        "PA": "15",
        "ePA": "15",
        "AB": "11",
        "R": "2",
        "H": "3",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "3",
        "RBI": "2",
        "SB": "0",
        "CS": "0",
        "BB": "4",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.273",
        "OBP": "0.467",
        "SLG": "0.273",
        "OPS": "0.740",
        "R/ePA": "0.171",
        "wRC+": "128.3",
        "WAR": "-0.07"
      }
    }
  ],
  [
    "aa46c633173d0482673c6b92fe9d1d15c07a8b7a",
    {
      "id": "aa46c633173d0482673c6b92fe9d1d15c07a8b7a",
      "name": "김주섭",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "29b8842b41e49289964fdc52a58abfbf3699b05d",
    {
      "id": "29b8842b41e49289964fdc52a58abfbf3699b05d",
      "name": "김호령",
      "team": "KIA",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "31",
        "Pos.": "CF",
        "oWAR": "-0.44",
        "dWAR": "0.21",
        "G": "64",
        "PA": "67",
        "ePA": "67",
        "AB": "59",
        "R": "12",
        "H": "8",
        "2B": "2",
        "3B": "0",
        "HR": "1",
        "TB": "13",
        "RBI": "4",
        "SB": "3",
        "CS": "2",
        "BB": "8",
        "HP": "0",
        "IB": "0",
        "SO": "25",
        "GDP": "1",
        "SH": "0",
        "SF": "0",
        "AVG": "0.136",
        "OBP": "0.239",
        "SLG": "0.220",
        "OPS": "0.459",
        "R/ePA": "0.021",
        "wRC+": "16.0",
        "WAR": "-0.23"
      }
    }
  ],
  [
    "fecd0b11d0d74b38ad153115ddeee3b7b21a252a",
    {
      "id": "fecd0b11d0d74b38ad153115ddeee3b7b21a252a",
      "name": "임석진",
      "team": "KIA",
      "position": "3B",
      "record": {
        "Div.": "베스트",
        "Year": "2016",
        "Team": "SK",
        "Age": "18",
        "Pos.": "3B",
        "oWAR": "-0.09",
        "dWAR": "0.05",
        "G": "11",
        "PA": "12",
        "ePA": "12",
        "AB": "11",
        "R": "3",
        "H": "2",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "2",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "6",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.182",
        "OBP": "0.250",
        "SLG": "0.182",
        "OPS": "0.432",
        "R/ePA": "0.006",
        "wRC+": "4.2",
        "WAR": "-0.04"
      }
    }
  ],
  [
    "704459839e41b036658960e6d507d558436ef67f",
    {
      "id": "704459839e41b036658960e6d507d558436ef67f",
      "name": "김민재",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "20",
        "Pos.": "P",
        "G": "4",
        "GS": "0",
        "GR": "4",
        "GF": "4",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "4.0",
        "ER": "7",
        "R": "7",
        "rRA": "7.00",
        "TBF": "22",
        "H": "9",
        "2B": "0",
        "3B": "0",
        "HR": "3",
        "BB": "1",
        "HP": "1",
        "IB": "0",
        "SO": "3",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "15.75",
        "RA9": "15.75",
        "rRA9": "15.75",
        "rRA9pf": "14.94",
        "FIP": "12.80",
        "WHIP": "2.50",
        "WAR": "-0.15"
      }
    }
  ],
  [
    "a3b62d8a4cc3b341db3af88e0fb6fab274bfd558",
    {
      "id": "a3b62d8a4cc3b341db3af88e0fb6fab274bfd558",
      "name": "한승연",
      "team": "KIA",
      "position": "LF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "d8e84cde32fa17b6a6b00f7d576f9b8d8328e5d9",
    {
      "id": "d8e84cde32fa17b6a6b00f7d576f9b8d8328e5d9",
      "name": "변우혁",
      "team": "KIA",
      "position": "1B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "23",
        "Pos.": "1B",
        "oWAR": "0.77",
        "dWAR": "-0.04",
        "G": "69",
        "PA": "187",
        "ePA": "187",
        "AB": "168",
        "R": "22",
        "H": "51",
        "2B": "9",
        "3B": "2",
        "HR": "5",
        "TB": "79",
        "RBI": "21",
        "SB": "1",
        "CS": "0",
        "BB": "16",
        "HP": "2",
        "IB": "0",
        "SO": "48",
        "GDP": "8",
        "SH": "0",
        "SF": "1",
        "AVG": "0.304",
        "OBP": "0.369",
        "SLG": "0.470",
        "OPS": "0.839",
        "R/ePA": "0.159",
        "wRC+": "119.0",
        "WAR": "0.72"
      }
    }
  ],
  [
    "f5145ec85d276d8fdab3fb3d705faf9216d164c2",
    {
      "id": "f5145ec85d276d8fdab3fb3d705faf9216d164c2",
      "name": "박일훈",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "62fe209920341216fd36c340cc347fe27d174836",
    {
      "id": "62fe209920341216fd36c340cc347fe27d174836",
      "name": "소크라테스",
      "team": "KIA",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "31",
        "Pos.": "LF",
        "oWAR": "3.52",
        "dWAR": "-0.10",
        "G": "140",
        "PA": "602",
        "ePA": "599",
        "AB": "552",
        "R": "92",
        "H": "171",
        "2B": "30",
        "3B": "3",
        "HR": "26",
        "TB": "285",
        "RBI": "97",
        "SB": "13",
        "CS": "5",
        "BB": "41",
        "HP": "4",
        "IB": "4",
        "SO": "91",
        "GDP": "7",
        "SH": "0",
        "SF": "5",
        "AVG": "0.310",
        "OBP": "0.359",
        "SLG": "0.516",
        "OPS": "0.875",
        "R/ePA": "0.163",
        "wRC+": "121.2",
        "WAR": "3.42"
      }
    }
  ],
  [
    "33272de04c4b2d5832395ef41a57d61ca590d987",
    {
      "id": "33272de04c4b2d5832395ef41a57d61ca590d987",
      "name": "박준표",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "31",
        "Pos.": "P",
        "G": "8",
        "GS": "0",
        "GR": "8",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "1",
        "IP": "6.0",
        "ER": "4",
        "R": "4",
        "rRA": "5.20",
        "TBF": "29",
        "H": "10",
        "2B": "2",
        "3B": "0",
        "HR": "0",
        "BB": "3",
        "HP": "1",
        "IB": "0",
        "SO": "4",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "6.00",
        "RA9": "6.00",
        "rRA9": "7.80",
        "rRA9pf": "7.71",
        "FIP": "4.60",
        "WHIP": "2.17",
        "WAR": "-0.03"
      }
    }
  ],
  [
    "fee20225f6610fe7ef97a8602b40d95fce39e378",
    {
      "id": "fee20225f6610fe7ef97a8602b40d95fce39e378",
      "name": "김현수",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "23",
        "Pos.": "P",
        "G": "5",
        "GS": "0",
        "GR": "5",
        "GF": "4",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "5.0",
        "ER": "10",
        "R": "10",
        "rRA": "10.15",
        "TBF": "28",
        "H": "7",
        "2B": "4",
        "3B": "0",
        "HR": "0",
        "BB": "5",
        "HP": "1",
        "IB": "0",
        "SO": "4",
        "ROE": "0",
        "BK": "0",
        "WP": "2",
        "ERA": "18.00",
        "RA9": "18.00",
        "rRA9": "18.27",
        "rRA9pf": "18.33",
        "FIP": "5.92",
        "WHIP": "2.40",
        "WAR": "-0.31"
      }
    }
  ],
  [
    "995355529ab324bb3d8c5784b664b89e04ad3e36",
    {
      "id": "995355529ab324bb3d8c5784b664b89e04ad3e36",
      "name": "김태윤",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "16dbd8185323f57e29a251f8c9e6c290107a6f03",
    {
      "id": "16dbd8185323f57e29a251f8c9e6c290107a6f03",
      "name": "이형범",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "29",
        "Pos.": "P",
        "G": "16",
        "GS": "0",
        "GR": "16",
        "GF": "5",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "2",
        "IP": "15.0",
        "ER": "13",
        "R": "17",
        "rRA": "15.80",
        "TBF": "83",
        "H": "26",
        "2B": "4",
        "3B": "0",
        "HR": "2",
        "BB": "7",
        "HP": "5",
        "IB": "1",
        "SO": "9",
        "ROE": "2",
        "BK": "0",
        "WP": "0",
        "ERA": "7.80",
        "RA9": "10.20",
        "rRA9": "9.48",
        "rRA9pf": "9.66",
        "FIP": "6.77",
        "WHIP": "2.20",
        "WAR": "-0.30"
      }
    }
  ],
  [
    "4a31328f22ffd2d880365fe39465a2731862417b",
    {
      "id": "4a31328f22ffd2d880365fe39465a2731862417b",
      "name": "강동훈",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "e1de8a56671029204f8c25d8dde60034cb48c6ce",
    {
      "id": "e1de8a56671029204f8c25d8dde60034cb48c6ce",
      "name": "최형우",
      "team": "KIA",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "40",
        "Pos.": "DH",
        "oWAR": "1.83",
        "dWAR": "-0.29",
        "G": "116",
        "PA": "487",
        "ePA": "486",
        "AB": "425",
        "R": "67",
        "H": "119",
        "2B": "23",
        "3B": "2",
        "HR": "22",
        "TB": "212",
        "RBI": "109",
        "SB": "1",
        "CS": "1",
        "BB": "52",
        "HP": "5",
        "IB": "4",
        "SO": "86",
        "GDP": "5",
        "SH": "0",
        "SF": "5",
        "AVG": "0.280",
        "OBP": "0.361",
        "SLG": "0.499",
        "OPS": "0.860",
        "R/ePA": "0.161",
        "wRC+": "119.0",
        "WAR": "1.54"
      }
    }
  ],
  [
    "a2d333142d94b6f5283877e34b1d9d72b0444073",
    {
      "id": "a2d333142d94b6f5283877e34b1d9d72b0444073",
      "name": "김석환",
      "team": "KIA",
      "position": "1B",
      "record": {
        "Div.": "베스트",
        "Year": "2018",
        "Team": "KIA",
        "Age": "18",
        "Pos.": "1B",
        "oWAR": "-0.05",
        "dWAR": "0.02",
        "G": "1",
        "PA": "2",
        "ePA": "2",
        "AB": "2",
        "R": "0",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.000",
        "SLG": "0.000",
        "OPS": "0.000",
        "R/ePA": "-0.189",
        "wRC+": "-153.1",
        "WAR": "-0.04"
      }
    }
  ],
  [
    "bbf284f4e9bfbcdd6b8c855427c728d4c74529e8",
    {
      "id": "bbf284f4e9bfbcdd6b8c855427c728d4c74529e8",
      "name": "최지웅",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "4e0b22ccf1da250bff1eb37da00a79aaaac8a53c",
    {
      "id": "4e0b22ccf1da250bff1eb37da00a79aaaac8a53c",
      "name": "강이준",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2019",
        "Team": "KIA",
        "Age": "20",
        "Pos.": "P",
        "G": "3",
        "GS": "3",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "2",
        "S": "0",
        "HD": "0",
        "IP": "9.0",
        "ER": "11",
        "R": "11",
        "rRA": "10.65",
        "TBF": "53",
        "H": "14",
        "2B": "4",
        "3B": "0",
        "HR": "1",
        "BB": "9",
        "HP": "2",
        "IB": "1",
        "SO": "3",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "11.00",
        "RA9": "11.00",
        "rRA9": "10.65",
        "rRA9pf": "11.03",
        "FIP": "8.09",
        "WHIP": "2.56",
        "WAR": "-0.27"
      }
    }
  ],
  [
    "b264f187b4cb364507fff420b5fef0834cfa8f59",
    {
      "id": "b264f187b4cb364507fff420b5fef0834cfa8f59",
      "name": "박민",
      "team": "KIA",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "22",
        "Pos.": "SS",
        "oWAR": "0.00",
        "dWAR": "-0.01",
        "G": "16",
        "PA": "30",
        "ePA": "30",
        "AB": "29",
        "R": "5",
        "H": "8",
        "2B": "4",
        "3B": "0",
        "HR": "0",
        "TB": "12",
        "RBI": "2",
        "SB": "0",
        "CS": "1",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "7",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.276",
        "OBP": "0.300",
        "SLG": "0.414",
        "OPS": "0.714",
        "R/ePA": "0.105",
        "wRC+": "77.5",
        "WAR": "-0.01"
      }
    }
  ],
  [
    "ce465678f387c20d387bcc4db6d2170a6ac75e9b",
    {
      "id": "ce465678f387c20d387bcc4db6d2170a6ac75e9b",
      "name": "김찬민",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "352e4339dbe297fc20a423fc6c25eaab472241dd",
    {
      "id": "352e4339dbe297fc20a423fc6c25eaab472241dd",
      "name": "강민제",
      "team": "KIA",
      "position": "2B",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "c4165d0c9403a26592d5790244a7cc048dfbf3ba",
    {
      "id": "c4165d0c9403a26592d5790244a7cc048dfbf3ba",
      "name": "최지민",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "20",
        "Pos.": "P",
        "G": "56",
        "GS": "0",
        "GR": "56",
        "GF": "4",
        "CG": "0",
        "SHO": "0",
        "W": "3",
        "L": "3",
        "S": "3",
        "HD": "12",
        "IP": "46.0",
        "ER": "26",
        "R": "28",
        "rRA": "29.90",
        "TBF": "225",
        "H": "44",
        "2B": "10",
        "3B": "1",
        "HR": "2",
        "BB": "40",
        "HP": "4",
        "IB": "0",
        "SO": "37",
        "ROE": "4",
        "BK": "0",
        "WP": "6",
        "ERA": "5.09",
        "RA9": "5.48",
        "rRA9": "5.85",
        "rRA9pf": "5.88",
        "FIP": "5.67",
        "WHIP": "1.83",
        "WAR": "0.41"
      }
    }
  ],
  [
    "0803fa99b44bafbde96bd75e36cb2d2d7110d130",
    {
      "id": "0803fa99b44bafbde96bd75e36cb2d2d7110d130",
      "name": "성영탁",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "1cd5c7718c4cc74cd011cc850044b6fd84323120",
    {
      "id": "1cd5c7718c4cc74cd011cc850044b6fd84323120",
      "name": "네일",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "30",
        "Pos.": "P",
        "G": "26",
        "GS": "26",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "12",
        "L": "5",
        "S": "0",
        "HD": "0",
        "IP": "149.1",
        "ER": "42",
        "R": "69",
        "rRA": "67.30",
        "TBF": "642",
        "H": "154",
        "2B": "27",
        "3B": "1",
        "HR": "11",
        "BB": "35",
        "HP": "9",
        "IB": "0",
        "SO": "138",
        "ROE": "17",
        "BK": "1",
        "WP": "3",
        "ERA": "2.53",
        "RA9": "4.16",
        "rRA9": "4.06",
        "rRA9pf": "4.04",
        "FIP": "3.68",
        "WHIP": "1.27",
        "WAR": "4.90"
      }
    }
  ],
  [
    "a03ca2bb9a1e5b284050e7c752fe5ce19fd598f6",
    {
      "id": "a03ca2bb9a1e5b284050e7c752fe5ce19fd598f6",
      "name": "남하준",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2020",
        "Team": "KIA",
        "Age": "23",
        "Pos.": "P",
        "G": "8",
        "GS": "0",
        "GR": "8",
        "GF": "5",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "15.2",
        "ER": "7",
        "R": "12",
        "rRA": "14.25",
        "TBF": "79",
        "H": "20",
        "2B": "5",
        "3B": "1",
        "HR": "1",
        "BB": "11",
        "HP": "1",
        "IB": "0",
        "SO": "8",
        "ROE": "3",
        "BK": "0",
        "WP": "0",
        "ERA": "4.02",
        "RA9": "6.89",
        "rRA9": "8.19",
        "rRA9pf": "7.83",
        "FIP": "5.85",
        "WHIP": "1.98",
        "WAR": "-0.09"
      }
    }
  ],
  [
    "38f8154d600d3b3bb3ae529016ab790a691a5a95",
    {
      "id": "38f8154d600d3b3bb3ae529016ab790a691a5a95",
      "name": "황동하",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "21",
        "Pos.": "P",
        "G": "25",
        "GS": "21",
        "GR": "4",
        "GF": "4",
        "CG": "0",
        "SHO": "0",
        "W": "5",
        "L": "7",
        "S": "0",
        "HD": "0",
        "IP": "103.1",
        "ER": "51",
        "R": "64",
        "rRA": "59.55",
        "TBF": "464",
        "H": "109",
        "2B": "18",
        "3B": "0",
        "HR": "13",
        "BB": "44",
        "HP": "8",
        "IB": "0",
        "SO": "81",
        "ROE": "7",
        "BK": "0",
        "WP": "5",
        "ERA": "4.44",
        "RA9": "5.57",
        "rRA9": "5.19",
        "rRA9pf": "5.19",
        "FIP": "5.30",
        "WHIP": "1.48",
        "WAR": "2.13"
      }
    }
  ],
  [
    "91e37f32d17aef3fe3d86847b706522663c90ff6",
    {
      "id": "91e37f32d17aef3fe3d86847b706522663c90ff6",
      "name": "김태군",
      "team": "KIA",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "34",
        "Pos.": "C",
        "oWAR": "0.81",
        "dWAR": "0.32",
        "G": "105",
        "PA": "270",
        "ePA": "259",
        "AB": "235",
        "R": "24",
        "H": "62",
        "2B": "7",
        "3B": "0",
        "HR": "7",
        "TB": "90",
        "RBI": "34",
        "SB": "0",
        "CS": "0",
        "BB": "12",
        "HP": "11",
        "IB": "0",
        "SO": "24",
        "GDP": "14",
        "SH": "11",
        "SF": "1",
        "AVG": "0.264",
        "OBP": "0.328",
        "SLG": "0.383",
        "OPS": "0.711",
        "R/ePA": "0.115",
        "wRC+": "85.3",
        "WAR": "1.13"
      }
    }
  ],
  [
    "a4f3f791f981d3eaaa51aca1cb6209d8311c8070",
    {
      "id": "a4f3f791f981d3eaaa51aca1cb6209d8311c8070",
      "name": "김건국",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "35",
        "Pos.": "P",
        "G": "20",
        "GS": "3",
        "GR": "17",
        "GF": "3",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "34.1",
        "ER": "30",
        "R": "33",
        "rRA": "31.10",
        "TBF": "165",
        "H": "48",
        "2B": "8",
        "3B": "0",
        "HR": "5",
        "BB": "14",
        "HP": "2",
        "IB": "0",
        "SO": "20",
        "ROE": "3",
        "BK": "0",
        "WP": "3",
        "ERA": "7.86",
        "RA9": "8.65",
        "rRA9": "8.15",
        "rRA9pf": "8.14",
        "FIP": "5.92",
        "WHIP": "1.81",
        "WAR": "-0.23"
      }
    }
  ],
  [
    "92a890a0a9c69d416bdda05d8f1c88617c21222e",
    {
      "id": "92a890a0a9c69d416bdda05d8f1c88617c21222e",
      "name": "이상준",
      "team": "KIA",
      "position": "C",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "8b06e032202acfdc8d5b85a94f12ac7b961b91b2",
    {
      "id": "8b06e032202acfdc8d5b85a94f12ac7b961b91b2",
      "name": "파노니",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2022",
        "Team": "KIA",
        "Age": "27",
        "Pos.": "P",
        "G": "14",
        "GS": "14",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "3",
        "L": "4",
        "S": "0",
        "HD": "0",
        "IP": "82.2",
        "ER": "25",
        "R": "33",
        "rRA": "29.40",
        "TBF": "348",
        "H": "78",
        "2B": "6",
        "3B": "1",
        "HR": "5",
        "BB": "24",
        "HP": "2",
        "IB": "0",
        "SO": "73",
        "ROE": "7",
        "BK": "0",
        "WP": "5",
        "ERA": "2.72",
        "RA9": "3.59",
        "rRA9": "3.20",
        "rRA9pf": "3.28",
        "FIP": "3.27",
        "WHIP": "1.23",
        "WAR": "2.75"
      }
    }
  ],
  [
    "12ea7dbc87e3658812c8f255e303650f8298714b",
    {
      "id": "12ea7dbc87e3658812c8f255e303650f8298714b",
      "name": "김민주",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "21",
        "Pos.": "P",
        "G": "2",
        "GS": "0",
        "GR": "2",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "1.0",
        "ER": "1",
        "R": "2",
        "rRA": "2.85",
        "TBF": "8",
        "H": "3",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "2",
        "ROE": "1",
        "BK": "0",
        "WP": "1",
        "ERA": "9.00",
        "RA9": "18.00",
        "rRA9": "25.65",
        "rRA9pf": "25.68",
        "FIP": "2.30",
        "WHIP": "4.00",
        "WAR": "-0.13"
      }
    }
  ],
  [
    "49d4e8374c8af6f4a60a08c514c57b68037b2e0b",
    {
      "id": "49d4e8374c8af6f4a60a08c514c57b68037b2e0b",
      "name": "장민기",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2021",
        "Team": "KIA",
        "Age": "19",
        "Pos.": "P",
        "G": "21",
        "GS": "0",
        "GR": "21",
        "GF": "7",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "1",
        "S": "0",
        "HD": "2",
        "IP": "23.1",
        "ER": "9",
        "R": "11",
        "rRA": "14.75",
        "TBF": "114",
        "H": "13",
        "2B": "3",
        "3B": "1",
        "HR": "1",
        "BB": "29",
        "HP": "4",
        "IB": "0",
        "SO": "22",
        "ROE": "1",
        "BK": "0",
        "WP": "2",
        "ERA": "3.47",
        "RA9": "4.24",
        "rRA9": "5.69",
        "rRA9pf": "5.51",
        "FIP": "6.30",
        "WHIP": "1.80",
        "WAR": "0.15"
      }
    }
  ],
  [
    "ac684e950025f99893389aaaaf6c6c03b5c55467",
    {
      "id": "ac684e950025f99893389aaaaf6c6c03b5c55467",
      "name": "나성범",
      "team": "KIA",
      "position": "RF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "34",
        "Pos.": "RF",
        "oWAR": "1.89",
        "dWAR": "-0.16",
        "G": "102",
        "PA": "424",
        "ePA": "422",
        "AB": "374",
        "R": "51",
        "H": "109",
        "2B": "17",
        "3B": "1",
        "HR": "21",
        "TB": "191",
        "RBI": "80",
        "SB": "0",
        "CS": "0",
        "BB": "38",
        "HP": "4",
        "IB": "1",
        "SO": "98",
        "GDP": "8",
        "SH": "0",
        "SF": "7",
        "AVG": "0.291",
        "OBP": "0.357",
        "SLG": "0.511",
        "OPS": "0.868",
        "R/ePA": "0.160",
        "wRC+": "118.4",
        "WAR": "1.73"
      }
    }
  ],
  [
    "ad97ebbce424fb66cb2e3e19a06b370db6240b74",
    {
      "id": "ad97ebbce424fb66cb2e3e19a06b370db6240b74",
      "name": "이의리",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "21",
        "Pos.": "P",
        "G": "4",
        "GS": "4",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "13.1",
        "ER": "8",
        "R": "10",
        "rRA": "10.00",
        "TBF": "69",
        "H": "17",
        "2B": "3",
        "3B": "0",
        "HR": "3",
        "BB": "14",
        "HP": "0",
        "IB": "0",
        "SO": "14",
        "ROE": "1",
        "BK": "0",
        "WP": "0",
        "ERA": "5.40",
        "RA9": "6.75",
        "rRA9": "6.75",
        "rRA9pf": "6.90",
        "FIP": "7.51",
        "WHIP": "2.33",
        "WAR": "0.07"
      }
    }
  ],
  [
    "d7ad376b0662a96196649885661a52182062be18",
    {
      "id": "d7ad376b0662a96196649885661a52182062be18",
      "name": "김유신",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2018",
        "Team": "KIA",
        "Age": "18",
        "Pos.": "P",
        "G": "10",
        "GS": "2",
        "GR": "8",
        "GF": "4",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "13.0",
        "ER": "14",
        "R": "14",
        "rRA": "11.10",
        "TBF": "57",
        "H": "13",
        "2B": "2",
        "3B": "1",
        "HR": "0",
        "BB": "6",
        "HP": "1",
        "IB": "0",
        "SO": "6",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "9.69",
        "RA9": "9.69",
        "rRA9": "7.68",
        "rRA9pf": "8.01",
        "FIP": "4.86",
        "WHIP": "1.46",
        "WAR": "-0.05"
      }
    }
  ],
  [
    "02be7989a898571700a57a68b4d345ce18cd3a22",
    {
      "id": "02be7989a898571700a57a68b4d345ce18cd3a22",
      "name": "장현식",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "28",
        "Pos.": "P",
        "G": "75",
        "GS": "0",
        "GR": "75",
        "GF": "6",
        "CG": "0",
        "SHO": "0",
        "W": "5",
        "L": "4",
        "S": "0",
        "HD": "16",
        "IP": "75.1",
        "ER": "33",
        "R": "41",
        "rRA": "37.40",
        "TBF": "331",
        "H": "75",
        "2B": "16",
        "3B": "0",
        "HR": "8",
        "BB": "34",
        "HP": "2",
        "IB": "2",
        "SO": "75",
        "ROE": "5",
        "BK": "0",
        "WP": "4",
        "ERA": "3.94",
        "RA9": "4.90",
        "rRA9": "4.47",
        "rRA9pf": "4.43",
        "FIP": "4.37",
        "WHIP": "1.45",
        "WAR": "1.94"
      }
    }
  ],
  [
    "e4eb2f7443eaf52bd67949e17465a2987457e318",
    {
      "id": "e4eb2f7443eaf52bd67949e17465a2987457e318",
      "name": "전상현",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "27",
        "Pos.": "P",
        "G": "66",
        "GS": "0",
        "GR": "66",
        "GF": "16",
        "CG": "0",
        "SHO": "0",
        "W": "10",
        "L": "5",
        "S": "7",
        "HD": "19",
        "IP": "66.0",
        "ER": "30",
        "R": "32",
        "rRA": "33.75",
        "TBF": "272",
        "H": "55",
        "2B": "7",
        "3B": "2",
        "HR": "5",
        "BB": "20",
        "HP": "5",
        "IB": "1",
        "SO": "54",
        "ROE": "1",
        "BK": "0",
        "WP": "2",
        "ERA": "4.09",
        "RA9": "4.36",
        "rRA9": "4.60",
        "rRA9pf": "4.70",
        "FIP": "4.18",
        "WHIP": "1.14",
        "WAR": "1.64"
      }
    }
  ],
  [
    "db224f6db4fa15ee9bf9cacf06437434cab9bdea",
    {
      "id": "db224f6db4fa15ee9bf9cacf06437434cab9bdea",
      "name": "황대인",
      "team": "KIA",
      "position": "1B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "27",
        "Pos.": "1B",
        "oWAR": "-0.01",
        "dWAR": "-0.04",
        "G": "3",
        "PA": "7",
        "ePA": "7",
        "AB": "7",
        "R": "1",
        "H": "2",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "TB": "3",
        "RBI": "3",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.286",
        "OBP": "0.286",
        "SLG": "0.429",
        "OPS": "0.715",
        "R/ePA": "0.100",
        "wRC+": "74.4",
        "WAR": "-0.05"
      }
    }
  ],
  [
    "b530121d16e37e0fa924c97c010fc86093b2f014",
    {
      "id": "b530121d16e37e0fa924c97c010fc86093b2f014",
      "name": "김기훈",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "23",
        "Pos.": "P",
        "G": "17",
        "GS": "0",
        "GR": "17",
        "GF": "4",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "19.2",
        "ER": "11",
        "R": "13",
        "rRA": "14.60",
        "TBF": "95",
        "H": "16",
        "2B": "4",
        "3B": "1",
        "HR": "1",
        "BB": "15",
        "HP": "3",
        "IB": "0",
        "SO": "17",
        "ROE": "3",
        "BK": "1",
        "WP": "1",
        "ERA": "5.03",
        "RA9": "5.95",
        "rRA9": "6.68",
        "rRA9pf": "6.71",
        "FIP": "5.48",
        "WHIP": "1.58",
        "WAR": "0.06"
      }
    }
  ],
  [
    "e18c01161fda099b15de5d5762f05b6435b89608",
    {
      "id": "e18c01161fda099b15de5d5762f05b6435b89608",
      "name": "양현종",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "35",
        "Pos.": "P",
        "G": "29",
        "GS": "29",
        "GR": "0",
        "GF": "3",
        "CG": "3",
        "SHO": "0",
        "W": "11",
        "L": "5",
        "S": "0",
        "HD": "0",
        "IP": "171.1",
        "ER": "78",
        "R": "86",
        "rRA": "83.10",
        "TBF": "727",
        "H": "174",
        "2B": "37",
        "3B": "6",
        "HR": "21",
        "BB": "41",
        "HP": "6",
        "IB": "0",
        "SO": "129",
        "ROE": "11",
        "BK": "0",
        "WP": "2",
        "ERA": "4.10",
        "RA9": "4.52",
        "rRA9": "4.37",
        "rRA9pf": "4.37",
        "FIP": "4.63",
        "WHIP": "1.25",
        "WAR": "5.01"
      }
    }
  ],
  [
    "79bedf9119c5a71fa7e917a4032d8ba98ff4d4f0",
    {
      "id": "79bedf9119c5a71fa7e917a4032d8ba98ff4d4f0",
      "name": "한준수",
      "team": "KIA",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "24",
        "Pos.": "C",
        "oWAR": "1.66",
        "dWAR": "-0.12",
        "G": "115",
        "PA": "316",
        "ePA": "316",
        "AB": "287",
        "R": "39",
        "H": "88",
        "2B": "22",
        "3B": "0",
        "HR": "7",
        "TB": "131",
        "RBI": "41",
        "SB": "0",
        "CS": "0",
        "BB": "21",
        "HP": "2",
        "IB": "1",
        "SO": "48",
        "GDP": "7",
        "SH": "0",
        "SF": "6",
        "AVG": "0.307",
        "OBP": "0.351",
        "SLG": "0.456",
        "OPS": "0.807",
        "R/ePA": "0.141",
        "wRC+": "105.2",
        "WAR": "1.54"
      }
    }
  ],
  [
    "a612b064e9d42753bf78a4b61ce8184b3940e603",
    {
      "id": "a612b064e9d42753bf78a4b61ce8184b3940e603",
      "name": "오선우",
      "team": "KIA",
      "position": "RF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "27",
        "Pos.": "RF",
        "oWAR": "-0.03",
        "dWAR": "-0.04",
        "G": "3",
        "PA": "7",
        "ePA": "7",
        "AB": "7",
        "R": "0",
        "H": "2",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "2",
        "RBI": "1",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "3",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.286",
        "OBP": "0.286",
        "SLG": "0.286",
        "OPS": "0.572",
        "R/ePA": "0.049",
        "wRC+": "36.1",
        "WAR": "-0.07"
      }
    }
  ],
  [
    "33bc81998e893815d51aeffa37b6d2cfb4ef033b",
    {
      "id": "33bc81998e893815d51aeffa37b6d2cfb4ef033b",
      "name": "고종욱",
      "team": "KIA",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "34",
        "Pos.": "LF",
        "oWAR": "0.06",
        "dWAR": "-0.09",
        "G": "28",
        "PA": "36",
        "ePA": "36",
        "AB": "32",
        "R": "3",
        "H": "8",
        "2B": "2",
        "3B": "0",
        "HR": "1",
        "TB": "13",
        "RBI": "4",
        "SB": "0",
        "CS": "0",
        "BB": "4",
        "HP": "0",
        "IB": "0",
        "SO": "6",
        "GDP": "2",
        "SH": "0",
        "SF": "0",
        "AVG": "0.250",
        "OBP": "0.333",
        "SLG": "0.406",
        "OPS": "0.739",
        "R/ePA": "0.122",
        "wRC+": "92.0",
        "WAR": "-0.02"
      }
    }
  ],
  [
    "82e0577ab8c0ce356f7f54d81ae4b890460acaf5",
    {
      "id": "82e0577ab8c0ce356f7f54d81ae4b890460acaf5",
      "name": "서건창",
      "team": "KIA",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "34",
        "Pos.": "1B",
        "oWAR": "1.54",
        "dWAR": "-0.02",
        "G": "94",
        "PA": "248",
        "ePA": "245",
        "AB": "203",
        "R": "40",
        "H": "63",
        "2B": "14",
        "3B": "1",
        "HR": "1",
        "TB": "82",
        "RBI": "26",
        "SB": "3",
        "CS": "1",
        "BB": "36",
        "HP": "3",
        "IB": "0",
        "SO": "31",
        "GDP": "2",
        "SH": "3",
        "SF": "3",
        "AVG": "0.310",
        "OBP": "0.416",
        "SLG": "0.404",
        "OPS": "0.820",
        "R/ePA": "0.169",
        "wRC+": "124.5",
        "WAR": "1.51"
      }
    }
  ],
  [
    "9cc2af0e99e61acc45c23ff3f095be718246edf5",
    {
      "id": "9cc2af0e99e61acc45c23ff3f095be718246edf5",
      "name": "정해원",
      "team": "KIA",
      "position": "2B",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "259fe8a9262050e5911bdbdc9901d0ae55561c7e",
    {
      "id": "259fe8a9262050e5911bdbdc9901d0ae55561c7e",
      "name": "김도현",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "23",
        "Pos.": "P",
        "G": "35",
        "GS": "10",
        "GR": "25",
        "GF": "6",
        "CG": "0",
        "SHO": "0",
        "W": "4",
        "L": "6",
        "S": "0",
        "HD": "3",
        "IP": "75.0",
        "ER": "41",
        "R": "45",
        "rRA": "45.85",
        "TBF": "345",
        "H": "87",
        "2B": "11",
        "3B": "1",
        "HR": "9",
        "BB": "31",
        "HP": "9",
        "IB": "0",
        "SO": "59",
        "ROE": "2",
        "BK": "0",
        "WP": "4",
        "ERA": "4.92",
        "RA9": "5.40",
        "rRA9": "5.50",
        "rRA9pf": "5.42",
        "FIP": "5.31",
        "WHIP": "1.57",
        "WAR": "1.23"
      }
    }
  ],
  [
    "328fc48285a6deb000acd2b37a2507d74a252b9c",
    {
      "id": "328fc48285a6deb000acd2b37a2507d74a252b9c",
      "name": "고영창",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2019",
        "Team": "KIA",
        "Age": "29",
        "Pos.": "P",
        "G": "55",
        "GS": "0",
        "GR": "55",
        "GF": "8",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "3",
        "S": "1",
        "HD": "10",
        "IP": "54.0",
        "ER": "21",
        "R": "22",
        "rRA": "31.25",
        "TBF": "243",
        "H": "63",
        "2B": "8",
        "3B": "3",
        "HR": "2",
        "BB": "14",
        "HP": "8",
        "IB": "2",
        "SO": "22",
        "ROE": "7",
        "BK": "0",
        "WP": "2",
        "ERA": "3.50",
        "RA9": "3.67",
        "rRA9": "5.21",
        "rRA9pf": "5.16",
        "FIP": "4.38",
        "WHIP": "1.43",
        "WAR": "0.34"
      }
    }
  ],
  [
    "e312c98a51fb2a9631f9ba6fb2148cc80cfb03df",
    {
      "id": "e312c98a51fb2a9631f9ba6fb2148cc80cfb03df",
      "name": "예진원",
      "team": "KIA",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "24",
        "Pos.": "CF",
        "oWAR": "-0.10",
        "dWAR": "-0.39",
        "G": "22",
        "PA": "21",
        "ePA": "21",
        "AB": "19",
        "R": "2",
        "H": "4",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "TB": "5",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "2",
        "HP": "0",
        "IB": "0",
        "SO": "8",
        "GDP": "1",
        "SH": "0",
        "SF": "0",
        "AVG": "0.211",
        "OBP": "0.286",
        "SLG": "0.263",
        "OPS": "0.549",
        "R/ePA": "0.056",
        "wRC+": "42.8",
        "WAR": "-0.50"
      }
    }
  ],
  [
    "c0167929ca6d344d3a07b5255bb6381a70cb6c2f",
    {
      "id": "c0167929ca6d344d3a07b5255bb6381a70cb6c2f",
      "name": "정해영",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "22",
        "Pos.": "P",
        "G": "53",
        "GS": "0",
        "GR": "53",
        "GF": "44",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "3",
        "S": "31",
        "HD": "1",
        "IP": "50.2",
        "ER": "14",
        "R": "14",
        "rRA": "16.90",
        "TBF": "210",
        "H": "47",
        "2B": "5",
        "3B": "1",
        "HR": "8",
        "BB": "16",
        "HP": "1",
        "IB": "2",
        "SO": "50",
        "ROE": "1",
        "BK": "0",
        "WP": "0",
        "ERA": "2.49",
        "RA9": "2.49",
        "rRA9": "3.00",
        "rRA9pf": "2.98",
        "FIP": "4.59",
        "WHIP": "1.24",
        "WAR": "2.44"
      }
    }
  ],
  [
    "8569536f891826e0efb7dcfd982eb22bfd6bfcc3",
    {
      "id": "8569536f891826e0efb7dcfd982eb22bfd6bfcc3",
      "name": "이송찬",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "398d3bebef5498fcab14f0e2ad10b39578ff42dc",
    {
      "id": "398d3bebef5498fcab14f0e2ad10b39578ff42dc",
      "name": "알드레드",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "27",
        "Pos.": "P",
        "G": "9",
        "GS": "9",
        "GR": "0",
        "GF": "1",
        "CG": "1",
        "SHO": "1",
        "W": "3",
        "L": "2",
        "S": "0",
        "HD": "0",
        "IP": "43.2",
        "ER": "22",
        "R": "24",
        "rRA": "20.35",
        "TBF": "184",
        "H": "38",
        "2B": "4",
        "3B": "0",
        "HR": "4",
        "BB": "17",
        "HP": "2",
        "IB": "0",
        "SO": "52",
        "ROE": "4",
        "BK": "0",
        "WP": "1",
        "ERA": "4.53",
        "RA9": "4.95",
        "rRA9": "4.19",
        "rRA9pf": "4.18",
        "FIP": "3.66",
        "WHIP": "1.26",
        "WAR": "1.37"
      }
    }
  ],
  [
    "4086c891c76b149f5ffcb9ebaedcb9dcc3d0519b",
    {
      "id": "4086c891c76b149f5ffcb9ebaedcb9dcc3d0519b",
      "name": "산체스",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2023",
        "Team": "KIA",
        "Age": "28",
        "Pos.": "P",
        "G": "12",
        "GS": "11",
        "GR": "1",
        "GF": "1",
        "CG": "1",
        "SHO": "0",
        "W": "4",
        "L": "4",
        "S": "0",
        "HD": "0",
        "IP": "63.2",
        "ER": "42",
        "R": "46",
        "rRA": "43.60",
        "TBF": "285",
        "H": "79",
        "2B": "19",
        "3B": "1",
        "HR": "7",
        "BB": "15",
        "HP": "4",
        "IB": "1",
        "SO": "59",
        "ROE": "3",
        "BK": "1",
        "WP": "1",
        "ERA": "5.94",
        "RA9": "6.50",
        "rRA9": "6.16",
        "rRA9pf": "6.10",
        "FIP": "3.77",
        "WHIP": "1.48",
        "WAR": "0.27"
      }
    }
  ],
  [
    "a559556d22816f090cc0f598d87c2f0a933d3816",
    {
      "id": "a559556d22816f090cc0f598d87c2f0a933d3816",
      "name": "장재혁",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "22",
        "Pos.": "P",
        "G": "1",
        "GS": "0",
        "GR": "1",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "1.1",
        "ER": "0",
        "R": "0",
        "rRA": "0.00",
        "TBF": "7",
        "H": "2",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "0",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "0.00",
        "RA9": "0.00",
        "rRA9": "0.00",
        "rRA9pf": "0.00",
        "FIP": "6.52",
        "WHIP": "2.25",
        "WAR": "0.06"
      }
    }
  ],
  [
    "1e2dec8faa9d7b53208f4aa3b9351ae318dd71ac",
    {
      "id": "1e2dec8faa9d7b53208f4aa3b9351ae318dd71ac",
      "name": "라우어",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "28",
        "Pos.": "P",
        "G": "7",
        "GS": "7",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "2",
        "S": "0",
        "HD": "0",
        "IP": "34.2",
        "ER": "19",
        "R": "19",
        "rRA": "17.80",
        "TBF": "150",
        "H": "35",
        "2B": "7",
        "3B": "1",
        "HR": "3",
        "BB": "12",
        "HP": "3",
        "IB": "0",
        "SO": "37",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "4.93",
        "RA9": "4.93",
        "rRA9": "4.62",
        "rRA9pf": "4.70",
        "FIP": "3.90",
        "WHIP": "1.36",
        "WAR": "0.89"
      }
    }
  ],
  [
    "b6bef38ccb01935e759f9f3632be37aea69a3e4d",
    {
      "id": "b6bef38ccb01935e759f9f3632be37aea69a3e4d",
      "name": "조대현",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "676ca431a8e81048660daa336590692145e7e2f5",
    {
      "id": "676ca431a8e81048660daa336590692145e7e2f5",
      "name": "김대유",
      "team": "KIA",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KIA",
        "Age": "32",
        "Pos.": "P",
        "G": "37",
        "GS": "0",
        "GR": "37",
        "GF": "5",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "8",
        "IP": "25.0",
        "ER": "23",
        "R": "23",
        "rRA": "26.45",
        "TBF": "122",
        "H": "35",
        "2B": "5",
        "3B": "0",
        "HR": "4",
        "BB": "12",
        "HP": "2",
        "IB": "0",
        "SO": "26",
        "ROE": "0",
        "BK": "0",
        "WP": "1",
        "ERA": "8.28",
        "RA9": "8.28",
        "rRA9": "9.52",
        "rRA9pf": "9.57",
        "FIP": "5.24",
        "WHIP": "1.88",
        "WAR": "-0.61"
      }
    }
  ],
  [
    "6e447029e3ab6aa96c7876937290131d1d18db86",
    {
      "id": "6e447029e3ab6aa96c7876937290131d1d18db86",
      "name": "손성빈",
      "team": "롯데",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "21",
        "Pos.": "C",
        "oWAR": "0.19",
        "dWAR": "-0.11",
        "G": "86",
        "PA": "171",
        "ePA": "170",
        "AB": "152",
        "R": "24",
        "H": "30",
        "2B": "6",
        "3B": "2",
        "HR": "6",
        "TB": "58",
        "RBI": "21",
        "SB": "0",
        "CS": "0",
        "BB": "13",
        "HP": "3",
        "IB": "0",
        "SO": "38",
        "GDP": "3",
        "SH": "1",
        "SF": "2",
        "AVG": "0.197",
        "OBP": "0.271",
        "SLG": "0.382",
        "OPS": "0.653",
        "R/ePA": "0.082",
        "wRC+": "58.7",
        "WAR": "0.08"
      }
    }
  ],
  [
    "a1be611009f2a7969c5489e1c077806b6a0fda6a",
    {
      "id": "a1be611009f2a7969c5489e1c077806b6a0fda6a",
      "name": "황성빈",
      "team": "롯데",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "26",
        "Pos.": "CF",
        "oWAR": "2.46",
        "dWAR": "-0.13",
        "G": "125",
        "PA": "406",
        "ePA": "400",
        "AB": "366",
        "R": "94",
        "H": "117",
        "2B": "15",
        "3B": "8",
        "HR": "4",
        "TB": "160",
        "RBI": "26",
        "SB": "51",
        "CS": "10",
        "BB": "31",
        "HP": "2",
        "IB": "0",
        "SO": "56",
        "GDP": "3",
        "SH": "6",
        "SF": "1",
        "AVG": "0.320",
        "OBP": "0.375",
        "SLG": "0.437",
        "OPS": "0.812",
        "R/ePA": "0.153",
        "wRC+": "109.0",
        "WAR": "2.33"
      }
    }
  ],
  [
    "69d594e81324d47c69741da0db0d8a39dcfea623",
    {
      "id": "69d594e81324d47c69741da0db0d8a39dcfea623",
      "name": "한현희",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "30",
        "Pos.": "P",
        "G": "57",
        "GS": "5",
        "GR": "52",
        "GF": "5",
        "CG": "0",
        "SHO": "0",
        "W": "5",
        "L": "3",
        "S": "0",
        "HD": "8",
        "IP": "76.1",
        "ER": "44",
        "R": "45",
        "rRA": "44.45",
        "TBF": "342",
        "H": "92",
        "2B": "18",
        "3B": "1",
        "HR": "7",
        "BB": "24",
        "HP": "10",
        "IB": "1",
        "SO": "70",
        "ROE": "3",
        "BK": "0",
        "WP": "4",
        "ERA": "5.19",
        "RA9": "5.31",
        "rRA9": "5.24",
        "rRA9pf": "4.97",
        "FIP": "4.38",
        "WHIP": "1.52",
        "WAR": "1.58"
      }
    }
  ],
  [
    "4599f5e63a9a212ab7bdcf5e97bd8a4b6d703ee9",
    {
      "id": "4599f5e63a9a212ab7bdcf5e97bd8a4b6d703ee9",
      "name": "김민석",
      "team": "롯데",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "19",
        "Pos.": "LF",
        "oWAR": "-0.42",
        "dWAR": "0.01",
        "G": "41",
        "PA": "83",
        "ePA": "82",
        "AB": "76",
        "R": "14",
        "H": "16",
        "2B": "3",
        "3B": "1",
        "HR": "0",
        "TB": "21",
        "RBI": "6",
        "SB": "3",
        "CS": "1",
        "BB": "2",
        "HP": "4",
        "IB": "0",
        "SO": "20",
        "GDP": "4",
        "SH": "1",
        "SF": "0",
        "AVG": "0.211",
        "OBP": "0.268",
        "SLG": "0.276",
        "OPS": "0.544",
        "R/ePA": "0.054",
        "wRC+": "38.9",
        "WAR": "-0.41"
      }
    }
  ],
  [
    "d170e356ac293e27d3444ad4f126a1940a2c2e89",
    {
      "id": "d170e356ac293e27d3444ad4f126a1940a2c2e89",
      "name": "신윤후",
      "team": "롯데",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "27",
        "Pos.": "RF",
        "oWAR": "-0.29",
        "dWAR": "0.41",
        "G": "39",
        "PA": "35",
        "ePA": "34",
        "AB": "31",
        "R": "5",
        "H": "5",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "5",
        "RBI": "1",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "2",
        "IB": "0",
        "SO": "8",
        "GDP": "1",
        "SH": "1",
        "SF": "0",
        "AVG": "0.161",
        "OBP": "0.235",
        "SLG": "0.161",
        "OPS": "0.396",
        "R/ePA": "0.004",
        "wRC+": "2.9",
        "WAR": "0.12"
      }
    }
  ],
  [
    "7654a68ad78c09f5ec1ef682a20e002cbb0b7515",
    {
      "id": "7654a68ad78c09f5ec1ef682a20e002cbb0b7515",
      "name": "오선진",
      "team": "롯데",
      "position": "3B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "34",
        "Pos.": "3B",
        "oWAR": "0.04",
        "dWAR": "0.12",
        "G": "26",
        "PA": "29",
        "ePA": "24",
        "AB": "20",
        "R": "0",
        "H": "4",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "TB": "5",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "2",
        "HP": "2",
        "IB": "0",
        "SO": "8",
        "GDP": "0",
        "SH": "5",
        "SF": "0",
        "AVG": "0.200",
        "OBP": "0.333",
        "SLG": "0.250",
        "OPS": "0.583",
        "R/ePA": "0.095",
        "wRC+": "67.4",
        "WAR": "0.16"
      }
    }
  ],
  [
    "8946e994a7ca75be7f43065a286ad1bdb59fa3ad",
    {
      "id": "8946e994a7ca75be7f43065a286ad1bdb59fa3ad",
      "name": "이학주",
      "team": "롯데",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "33",
        "Pos.": "SS",
        "oWAR": "0.25",
        "dWAR": "-0.40",
        "G": "43",
        "PA": "105",
        "ePA": "103",
        "AB": "95",
        "R": "13",
        "H": "25",
        "2B": "2",
        "3B": "2",
        "HR": "2",
        "TB": "37",
        "RBI": "4",
        "SB": "0",
        "CS": "1",
        "BB": "6",
        "HP": "2",
        "IB": "0",
        "SO": "44",
        "GDP": "0",
        "SH": "2",
        "SF": "0",
        "AVG": "0.263",
        "OBP": "0.320",
        "SLG": "0.389",
        "OPS": "0.709",
        "R/ePA": "0.111",
        "wRC+": "79.9",
        "WAR": "-0.15"
      }
    }
  ],
  [
    "931ca37a4df39f181f6d127e08affef33906f334",
    {
      "id": "931ca37a4df39f181f6d127e08affef33906f334",
      "name": "전준우",
      "team": "롯데",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "37",
        "Pos.": "DH",
        "oWAR": "2.15",
        "dWAR": "-0.36",
        "G": "109",
        "PA": "483",
        "ePA": "482",
        "AB": "423",
        "R": "57",
        "H": "124",
        "2B": "26",
        "3B": "2",
        "HR": "17",
        "TB": "205",
        "RBI": "82",
        "SB": "3",
        "CS": "1",
        "BB": "49",
        "HP": "5",
        "IB": "0",
        "SO": "84",
        "GDP": "11",
        "SH": "1",
        "SF": "5",
        "AVG": "0.293",
        "OBP": "0.369",
        "SLG": "0.485",
        "OPS": "0.854",
        "R/ePA": "0.163",
        "wRC+": "115.7",
        "WAR": "1.80"
      }
    }
  ],
  [
    "af9e83fd89344bcf314e4c14e9b92738092fc462",
    {
      "id": "af9e83fd89344bcf314e4c14e9b92738092fc462",
      "name": "정훈",
      "team": "롯데",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "36",
        "Pos.": "1B",
        "oWAR": "0.76",
        "dWAR": "0.00",
        "G": "109",
        "PA": "325",
        "ePA": "324",
        "AB": "285",
        "R": "31",
        "H": "76",
        "2B": "20",
        "3B": "0",
        "HR": "9",
        "TB": "123",
        "RBI": "47",
        "SB": "1",
        "CS": "1",
        "BB": "35",
        "HP": "0",
        "IB": "0",
        "SO": "63",
        "GDP": "3",
        "SH": "1",
        "SF": "4",
        "AVG": "0.267",
        "OBP": "0.343",
        "SLG": "0.432",
        "OPS": "0.775",
        "R/ePA": "0.133",
        "wRC+": "94.9",
        "WAR": "0.76"
      }
    }
  ],
  [
    "6dc7e9cc80745536c5c12a8b726938835901b50f",
    {
      "id": "6dc7e9cc80745536c5c12a8b726938835901b50f",
      "name": "추재현",
      "team": "롯데",
      "position": "RF",
      "record": {
        "Div.": "베스트",
        "Year": "2021",
        "Team": "롯데",
        "Age": "21",
        "Pos.": "RF",
        "oWAR": "0.61",
        "dWAR": "0.16",
        "G": "95",
        "PA": "300",
        "ePA": "295",
        "AB": "262",
        "R": "37",
        "H": "66",
        "2B": "15",
        "3B": "0",
        "HR": "5",
        "TB": "96",
        "RBI": "26",
        "SB": "4",
        "CS": "3",
        "BB": "26",
        "HP": "7",
        "IB": "0",
        "SO": "57",
        "GDP": "3",
        "SH": "5",
        "SF": "0",
        "AVG": "0.252",
        "OBP": "0.336",
        "SLG": "0.366",
        "OPS": "0.702",
        "R/ePA": "0.113",
        "wRC+": "87.9",
        "WAR": "0.77"
      }
    }
  ],
  [
    "6bc5f751ef69d4b1c6cd6fbb232810cd80c38823",
    {
      "id": "6bc5f751ef69d4b1c6cd6fbb232810cd80c38823",
      "name": "정우준",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "23",
        "Pos.": "P",
        "G": "8",
        "GS": "0",
        "GR": "8",
        "GF": "5",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "8.1",
        "ER": "4",
        "R": "4",
        "rRA": "6.60",
        "TBF": "40",
        "H": "10",
        "2B": "2",
        "3B": "0",
        "HR": "2",
        "BB": "7",
        "HP": "0",
        "IB": "0",
        "SO": "5",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "4.32",
        "RA9": "4.32",
        "rRA9": "7.13",
        "rRA9pf": "6.76",
        "FIP": "8.16",
        "WHIP": "2.04",
        "WAR": "0.03"
      }
    }
  ],
  [
    "b029575a066dff0e6b0e824e30ca8bca2e51ac71",
    {
      "id": "b029575a066dff0e6b0e824e30ca8bca2e51ac71",
      "name": "장두성",
      "team": "롯데",
      "position": "RF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "24",
        "Pos.": "CF",
        "oWAR": "-0.14",
        "dWAR": "0.01",
        "G": "71",
        "PA": "37",
        "ePA": "37",
        "AB": "32",
        "R": "23",
        "H": "5",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "5",
        "RBI": "1",
        "SB": "14",
        "CS": "3",
        "BB": "3",
        "HP": "2",
        "IB": "0",
        "SO": "9",
        "GDP": "2",
        "SH": "0",
        "SF": "0",
        "AVG": "0.156",
        "OBP": "0.270",
        "SLG": "0.156",
        "OPS": "0.426",
        "R/ePA": "0.028",
        "wRC+": "19.8",
        "WAR": "-0.12"
      }
    }
  ],
  [
    "cff4208d8475650280f51c41bb0d5b614a9d8c7c",
    {
      "id": "cff4208d8475650280f51c41bb0d5b614a9d8c7c",
      "name": "최항",
      "team": "롯데",
      "position": "3B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "29",
        "Pos.": "2B",
        "oWAR": "-0.41",
        "dWAR": "0.10",
        "G": "72",
        "PA": "143",
        "ePA": "143",
        "AB": "132",
        "R": "17",
        "H": "33",
        "2B": "3",
        "3B": "1",
        "HR": "0",
        "TB": "38",
        "RBI": "12",
        "SB": "0",
        "CS": "1",
        "BB": "10",
        "HP": "1",
        "IB": "0",
        "SO": "32",
        "GDP": "3",
        "SH": "0",
        "SF": "0",
        "AVG": "0.250",
        "OBP": "0.308",
        "SLG": "0.288",
        "OPS": "0.596",
        "R/ePA": "0.073",
        "wRC+": "51.3",
        "WAR": "-0.31"
      }
    }
  ],
  [
    "bfaf10c6b1257bcc17c4d7449a2d1532d11070c1",
    {
      "id": "bfaf10c6b1257bcc17c4d7449a2d1532d11070c1",
      "name": "김진욱",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "21",
        "Pos.": "P",
        "G": "19",
        "GS": "18",
        "GR": "1",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "4",
        "L": "3",
        "S": "0",
        "HD": "0",
        "IP": "84.2",
        "ER": "50",
        "R": "54",
        "rRA": "49.85",
        "TBF": "388",
        "H": "89",
        "2B": "21",
        "3B": "3",
        "HR": "13",
        "BB": "44",
        "HP": "3",
        "IB": "0",
        "SO": "87",
        "ROE": "6",
        "BK": "0",
        "WP": "5",
        "ERA": "5.31",
        "RA9": "5.74",
        "rRA9": "5.30",
        "rRA9pf": "5.04",
        "FIP": "5.18",
        "WHIP": "1.57",
        "WAR": "2.02"
      }
    }
  ],
  [
    "dff40b42eae84e6e68a3374b1d2eb6b1fdd3191e",
    {
      "id": "dff40b42eae84e6e68a3374b1d2eb6b1fdd3191e",
      "name": "김민성",
      "team": "롯데",
      "position": "3B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "35",
        "Pos.": "3B",
        "oWAR": "0.06",
        "dWAR": "0.03",
        "G": "35",
        "PA": "84",
        "ePA": "84",
        "AB": "70",
        "R": "6",
        "H": "14",
        "2B": "5",
        "3B": "0",
        "HR": "2",
        "TB": "25",
        "RBI": "8",
        "SB": "0",
        "CS": "1",
        "BB": "10",
        "HP": "3",
        "IB": "0",
        "SO": "18",
        "GDP": "1",
        "SH": "0",
        "SF": "1",
        "AVG": "0.200",
        "OBP": "0.321",
        "SLG": "0.357",
        "OPS": "0.678",
        "R/ePA": "0.109",
        "wRC+": "79.9",
        "WAR": "0.10"
      }
    }
  ],
  [
    "3955e9279963172c28138d4d57dfbe02a84de8fa",
    {
      "id": "3955e9279963172c28138d4d57dfbe02a84de8fa",
      "name": "현도훈",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "30",
        "Pos.": "P",
        "G": "8",
        "GS": "0",
        "GR": "8",
        "GF": "5",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "10.0",
        "ER": "10",
        "R": "11",
        "rRA": "12.05",
        "TBF": "50",
        "H": "15",
        "2B": "3",
        "3B": "1",
        "HR": "2",
        "BB": "6",
        "HP": "0",
        "IB": "0",
        "SO": "8",
        "ROE": "2",
        "BK": "0",
        "WP": "1",
        "ERA": "9.00",
        "RA9": "9.90",
        "rRA9": "10.85",
        "rRA9pf": "9.99",
        "FIP": "6.44",
        "WHIP": "2.10",
        "WAR": "-0.30"
      }
    }
  ],
  [
    "9d58d2ec51c1e729e97b40190762563865d222f9",
    {
      "id": "9d58d2ec51c1e729e97b40190762563865d222f9",
      "name": "정성종",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2023",
        "Team": "롯데",
        "Age": "27",
        "Pos.": "P",
        "G": "12",
        "GS": "3",
        "GR": "9",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "1",
        "IP": "22.0",
        "ER": "13",
        "R": "14",
        "rRA": "13.50",
        "TBF": "98",
        "H": "21",
        "2B": "5",
        "3B": "0",
        "HR": "1",
        "BB": "15",
        "HP": "1",
        "IB": "1",
        "SO": "10",
        "ROE": "0",
        "BK": "0",
        "WP": "1",
        "ERA": "5.32",
        "RA9": "5.73",
        "rRA9": "5.52",
        "rRA9pf": "5.16",
        "FIP": "5.37",
        "WHIP": "1.64",
        "WAR": "0.21"
      }
    }
  ],
  [
    "b938f10f4cfd8e8db8c4c623925abc49c095b95c",
    {
      "id": "b938f10f4cfd8e8db8c4c623925abc49c095b95c",
      "name": "김강현",
      "team": "롯데",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "28",
        "Pos.": "P",
        "oWAR": "",
        "dWAR": "",
        "G": "26",
        "PA": "",
        "ePA": "",
        "AB": "",
        "R": "13",
        "H": "28",
        "2B": "4",
        "3B": "0",
        "HR": "2",
        "TB": "",
        "RBI": "",
        "SB": "",
        "CS": "",
        "BB": "18",
        "HP": "2",
        "IB": "1",
        "SO": "24",
        "GDP": "",
        "SH": "",
        "SF": "",
        "AVG": "",
        "OBP": "",
        "SLG": "",
        "OPS": "",
        "R/ePA": "",
        "wRC+": "",
        "WAR": "0.53"
      }
    }
  ],
  [
    "09fa3e42abfcebc8aec1053b15b86c5d83a09901",
    {
      "id": "09fa3e42abfcebc8aec1053b15b86c5d83a09901",
      "name": "박세웅",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "28",
        "Pos.": "P",
        "G": "30",
        "GS": "30",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "6",
        "L": "11",
        "S": "0",
        "HD": "0",
        "IP": "173.1",
        "ER": "92",
        "R": "103",
        "rRA": "97.65",
        "TBF": "759",
        "H": "188",
        "2B": "33",
        "3B": "0",
        "HR": "13",
        "BB": "56",
        "HP": "11",
        "IB": "0",
        "SO": "124",
        "ROE": "12",
        "BK": "0",
        "WP": "10",
        "ERA": "4.78",
        "RA9": "5.35",
        "rRA9": "5.07",
        "rRA9pf": "4.97",
        "FIP": "4.50",
        "WHIP": "1.41",
        "WAR": "4.06"
      }
    }
  ],
  [
    "5173855656270eea0100e5b4670ad57a06ef116d",
    {
      "id": "5173855656270eea0100e5b4670ad57a06ef116d",
      "name": "구승민",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "33",
        "Pos.": "P",
        "G": "66",
        "GS": "0",
        "GR": "66",
        "GF": "7",
        "CG": "0",
        "SHO": "0",
        "W": "5",
        "L": "3",
        "S": "0",
        "HD": "13",
        "IP": "57.2",
        "ER": "31",
        "R": "37",
        "rRA": "42.70",
        "TBF": "279",
        "H": "68",
        "2B": "10",
        "3B": "0",
        "HR": "6",
        "BB": "35",
        "HP": "6",
        "IB": "1",
        "SO": "62",
        "ROE": "5",
        "BK": "0",
        "WP": "7",
        "ERA": "4.84",
        "RA9": "5.77",
        "rRA9": "6.66",
        "rRA9pf": "6.44",
        "FIP": "4.90",
        "WHIP": "1.79",
        "WAR": "0.18"
      }
    }
  ],
  [
    "4d7c0687664b478087600914162441c2a256ccf5",
    {
      "id": "4d7c0687664b478087600914162441c2a256ccf5",
      "name": "김도규",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "25",
        "Pos.": "P",
        "G": "5",
        "GS": "0",
        "GR": "5",
        "GF": "3",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "4.0",
        "ER": "4",
        "R": "4",
        "rRA": "3.30",
        "TBF": "25",
        "H": "5",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "BB": "6",
        "HP": "2",
        "IB": "1",
        "SO": "5",
        "ROE": "0",
        "BK": "1",
        "WP": "0",
        "ERA": "9.00",
        "RA9": "9.00",
        "rRA9": "7.43",
        "rRA9pf": "8.06",
        "FIP": "7.27",
        "WHIP": "2.75",
        "WAR": "-0.03"
      }
    }
  ],
  [
    "f3ca05f424663455e1b1dddde6314188214d31ce",
    {
      "id": "f3ca05f424663455e1b1dddde6314188214d31ce",
      "name": "김상수",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "35",
        "Pos.": "P",
        "G": "74",
        "GS": "0",
        "GR": "74",
        "GF": "10",
        "CG": "0",
        "SHO": "0",
        "W": "8",
        "L": "4",
        "S": "2",
        "HD": "17",
        "IP": "73.2",
        "ER": "34",
        "R": "40",
        "rRA": "45.40",
        "TBF": "322",
        "H": "71",
        "2B": "11",
        "3B": "1",
        "HR": "5",
        "BB": "26",
        "HP": "5",
        "IB": "2",
        "SO": "56",
        "ROE": "6",
        "BK": "1",
        "WP": "6",
        "ERA": "4.15",
        "RA9": "4.89",
        "rRA9": "5.55",
        "rRA9pf": "5.38",
        "FIP": "4.33",
        "WHIP": "1.32",
        "WAR": "1.12"
      }
    }
  ],
  [
    "25d08617b2156f11a0fab1db6f80beca39084eca",
    {
      "id": "25d08617b2156f11a0fab1db6f80beca39084eca",
      "name": "한동희",
      "team": "롯데",
      "position": "3B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "24",
        "Pos.": "DH",
        "oWAR": "-0.31",
        "dWAR": "-0.25",
        "G": "14",
        "PA": "36",
        "ePA": "36",
        "AB": "35",
        "R": "1",
        "H": "9",
        "2B": "2",
        "3B": "0",
        "HR": "0",
        "TB": "11",
        "RBI": "3",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "0",
        "IB": "1",
        "SO": "9",
        "GDP": "1",
        "SH": "0",
        "SF": "0",
        "AVG": "0.257",
        "OBP": "0.278",
        "SLG": "0.314",
        "OPS": "0.592",
        "R/ePA": "0.052",
        "wRC+": "37.4",
        "WAR": "-0.55"
      }
    }
  ],
  [
    "ac35b50de5e9a0429420a249db7bfcaec667dfa3",
    {
      "id": "ac35b50de5e9a0429420a249db7bfcaec667dfa3",
      "name": "진승현",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "20",
        "Pos.": "P",
        "G": "7",
        "GS": "0",
        "GR": "7",
        "GF": "3",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "6.1",
        "ER": "6",
        "R": "6",
        "rRA": "5.00",
        "TBF": "32",
        "H": "7",
        "2B": "3",
        "3B": "0",
        "HR": "0",
        "BB": "5",
        "HP": "0",
        "IB": "0",
        "SO": "5",
        "ROE": "2",
        "BK": "0",
        "WP": "0",
        "ERA": "8.53",
        "RA9": "8.53",
        "rRA9": "7.11",
        "rRA9pf": "6.48",
        "FIP": "4.67",
        "WHIP": "1.89",
        "WAR": "0.03"
      }
    }
  ],
  [
    "2105df3737f19d2c5318a387fee7ee4b6bd7a453",
    {
      "id": "2105df3737f19d2c5318a387fee7ee4b6bd7a453",
      "name": "유강남",
      "team": "롯데",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "31",
        "Pos.": "C",
        "oWAR": "-0.08",
        "dWAR": "0.16",
        "G": "52",
        "PA": "155",
        "ePA": "153",
        "AB": "136",
        "R": "11",
        "H": "26",
        "2B": "3",
        "3B": "0",
        "HR": "5",
        "TB": "44",
        "RBI": "20",
        "SB": "0",
        "CS": "0",
        "BB": "9",
        "HP": "7",
        "IB": "0",
        "SO": "25",
        "GDP": "4",
        "SH": "2",
        "SF": "1",
        "AVG": "0.191",
        "OBP": "0.275",
        "SLG": "0.324",
        "OPS": "0.599",
        "R/ePA": "0.071",
        "wRC+": "50.3",
        "WAR": "0.08"
      }
    }
  ],
  [
    "f2efc4ff7f494889f9208b9594713e383de9b209",
    {
      "id": "f2efc4ff7f494889f9208b9594713e383de9b209",
      "name": "반즈",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "28",
        "Pos.": "P",
        "G": "25",
        "GS": "25",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "9",
        "L": "6",
        "S": "0",
        "HD": "0",
        "IP": "150.2",
        "ER": "56",
        "R": "59",
        "rRA": "55.90",
        "TBF": "624",
        "H": "140",
        "2B": "17",
        "3B": "4",
        "HR": "18",
        "BB": "46",
        "HP": "5",
        "IB": "0",
        "SO": "171",
        "ROE": "8",
        "BK": "0",
        "WP": "6",
        "ERA": "3.35",
        "RA9": "3.52",
        "rRA9": "3.34",
        "rRA9pf": "3.18",
        "FIP": "3.83",
        "WHIP": "1.23",
        "WAR": "6.43"
      }
    }
  ],
  [
    "294be4b09da0646c6e3af9f4b5529be3698c57e6",
    {
      "id": "294be4b09da0646c6e3af9f4b5529be3698c57e6",
      "name": "레이예스",
      "team": "롯데",
      "position": "RF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "29",
        "Pos.": "RF",
        "oWAR": "3.98",
        "dWAR": "-0.58",
        "G": "144",
        "PA": "632",
        "ePA": "631",
        "AB": "574",
        "R": "88",
        "H": "202",
        "2B": "40",
        "3B": "3",
        "HR": "15",
        "TB": "293",
        "RBI": "111",
        "SB": "5",
        "CS": "0",
        "BB": "46",
        "HP": "1",
        "IB": "2",
        "SO": "82",
        "GDP": "16",
        "SH": "0",
        "SF": "11",
        "AVG": "0.352",
        "OBP": "0.394",
        "SLG": "0.510",
        "OPS": "0.904",
        "R/ePA": "0.179",
        "wRC+": "127.5",
        "WAR": "3.40"
      }
    }
  ],
  [
    "ebcb37e0a08b503b1be37dc52dde29861202df9e",
    {
      "id": "ebcb37e0a08b503b1be37dc52dde29861202df9e",
      "name": "이민석",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "20",
        "Pos.": "P",
        "G": "18",
        "GS": "5",
        "GR": "13",
        "GF": "5",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "2",
        "S": "0",
        "HD": "1",
        "IP": "31.0",
        "ER": "25",
        "R": "27",
        "rRA": "25.60",
        "TBF": "153",
        "H": "38",
        "2B": "5",
        "3B": "3",
        "HR": "3",
        "BB": "25",
        "HP": "2",
        "IB": "0",
        "SO": "20",
        "ROE": "1",
        "BK": "0",
        "WP": "1",
        "ERA": "7.26",
        "RA9": "7.84",
        "rRA9": "7.43",
        "rRA9pf": "7.14",
        "FIP": "6.44",
        "WHIP": "2.03",
        "WAR": "0.07"
      }
    }
  ],
  [
    "20cbf87a8eed0d6a0d96cdc3418feee94e052fab",
    {
      "id": "20cbf87a8eed0d6a0d96cdc3418feee94e052fab",
      "name": "진해수",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "37",
        "Pos.": "P",
        "G": "54",
        "GS": "0",
        "GR": "54",
        "GF": "3",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "1",
        "S": "0",
        "HD": "5",
        "IP": "27.2",
        "ER": "19",
        "R": "21",
        "rRA": "23.55",
        "TBF": "128",
        "H": "36",
        "2B": "3",
        "3B": "0",
        "HR": "5",
        "BB": "10",
        "HP": "3",
        "IB": "1",
        "SO": "22",
        "ROE": "0",
        "BK": "0",
        "WP": "2",
        "ERA": "6.18",
        "RA9": "6.83",
        "rRA9": "7.66",
        "rRA9pf": "7.29",
        "FIP": "5.82",
        "WHIP": "1.66",
        "WAR": "-0.17"
      }
    }
  ],
  [
    "ff0bdefdd876aaaa27c717d900679479e0d161a0",
    {
      "id": "ff0bdefdd876aaaa27c717d900679479e0d161a0",
      "name": "강태율",
      "team": "롯데",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "27",
        "Pos.": "C",
        "oWAR": "-0.24",
        "dWAR": "-0.02",
        "G": "10",
        "PA": "10",
        "ePA": "10",
        "AB": "10",
        "R": "0",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "4",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.000",
        "SLG": "0.000",
        "OPS": "0.000",
        "R/ePA": "-0.190",
        "wRC+": "-132.2",
        "WAR": "-0.26"
      }
    }
  ],
  [
    "ac565dbc9a17e4ef9a9cffcccc0d8cf69f54cadb",
    {
      "id": "ac565dbc9a17e4ef9a9cffcccc0d8cf69f54cadb",
      "name": "손호영",
      "team": "롯데",
      "position": "3B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "29",
        "Pos.": "3B",
        "oWAR": "2.96",
        "dWAR": "-0.57",
        "G": "100",
        "PA": "428",
        "ePA": "425",
        "AB": "396",
        "R": "70",
        "H": "126",
        "2B": "26",
        "3B": "4",
        "HR": "18",
        "TB": "214",
        "RBI": "78",
        "SB": "7",
        "CS": "4",
        "BB": "17",
        "HP": "9",
        "IB": "2",
        "SO": "65",
        "GDP": "5",
        "SH": "1",
        "SF": "5",
        "AVG": "0.318",
        "OBP": "0.356",
        "SLG": "0.540",
        "OPS": "0.896",
        "R/ePA": "0.170",
        "wRC+": "121.7",
        "WAR": "2.40"
      }
    }
  ],
  [
    "718653948f7e2acf746eba526735cde7bb3ee4f5",
    {
      "id": "718653948f7e2acf746eba526735cde7bb3ee4f5",
      "name": "김원중",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "30",
        "Pos.": "P",
        "G": "56",
        "GS": "0",
        "GR": "56",
        "GF": "42",
        "CG": "0",
        "SHO": "0",
        "W": "3",
        "L": "6",
        "S": "25",
        "HD": "0",
        "IP": "63.1",
        "ER": "25",
        "R": "27",
        "rRA": "28.50",
        "TBF": "277",
        "H": "59",
        "2B": "8",
        "3B": "0",
        "HR": "4",
        "BB": "31",
        "HP": "1",
        "IB": "7",
        "SO": "68",
        "ROE": "2",
        "BK": "0",
        "WP": "5",
        "ERA": "3.55",
        "RA9": "3.84",
        "rRA9": "4.05",
        "rRA9pf": "3.85",
        "FIP": "3.64",
        "WHIP": "1.42",
        "WAR": "2.44"
      }
    }
  ],
  [
    "7fb26ef6b375aac76dd3f847460975f79be68251",
    {
      "id": "7fb26ef6b375aac76dd3f847460975f79be68251",
      "name": "이인복",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "32",
        "Pos.": "P",
        "G": "8",
        "GS": "6",
        "GR": "2",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "3",
        "S": "0",
        "HD": "0",
        "IP": "33.0",
        "ER": "35",
        "R": "35",
        "rRA": "36.05",
        "TBF": "159",
        "H": "49",
        "2B": "14",
        "3B": "0",
        "HR": "10",
        "BB": "13",
        "HP": "3",
        "IB": "0",
        "SO": "20",
        "ROE": "1",
        "BK": "0",
        "WP": "4",
        "ERA": "9.55",
        "RA9": "9.55",
        "rRA9": "9.83",
        "rRA9pf": "9.35",
        "FIP": "7.79",
        "WHIP": "1.88",
        "WAR": "-0.31"
      }
    }
  ],
  [
    "acf20c54c129e621064216f7dc319a49ef43ac4b",
    {
      "id": "acf20c54c129e621064216f7dc319a49ef43ac4b",
      "name": "신정락",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2013",
        "Team": "LG",
        "Age": "25",
        "Pos.": "P",
        "G": "26",
        "GS": "21",
        "GR": "5",
        "GF": "3",
        "CG": "0",
        "SHO": "0",
        "W": "9",
        "L": "5",
        "S": "0",
        "HD": "0",
        "IP": "122.2",
        "ER": "58",
        "R": "62",
        "rRA": "58.40",
        "TBF": "525",
        "H": "128",
        "2B": "17",
        "3B": "3",
        "HR": "8",
        "BB": "30",
        "HP": "16",
        "IB": "1",
        "SO": "82",
        "ROE": "3",
        "BK": "0",
        "WP": "1",
        "ERA": "4.26",
        "RA9": "4.55",
        "rRA9": "4.28",
        "rRA9pf": "4.47",
        "FIP": "4.07",
        "WHIP": "1.29",
        "WAR": "2.14"
      }
    }
  ],
  [
    "7985f92902b326b890229e113a63eb4dfba50b4d",
    {
      "id": "7985f92902b326b890229e113a63eb4dfba50b4d",
      "name": "정현수",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "22",
        "Pos.": "P",
        "G": "18",
        "GS": "4",
        "GR": "14",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "1",
        "S": "0",
        "HD": "1",
        "IP": "23.2",
        "ER": "12",
        "R": "12",
        "rRA": "10.75",
        "TBF": "104",
        "H": "20",
        "2B": "7",
        "3B": "0",
        "HR": "0",
        "BB": "12",
        "HP": "2",
        "IB": "0",
        "SO": "25",
        "ROE": "1",
        "BK": "0",
        "WP": "0",
        "ERA": "4.56",
        "RA9": "4.56",
        "rRA9": "4.09",
        "rRA9pf": "3.85",
        "FIP": "3.39",
        "WHIP": "1.35",
        "WAR": "0.83"
      }
    }
  ],
  [
    "1861aab8d7ef13a964b4d3f41b8e088afcd272d9",
    {
      "id": "1861aab8d7ef13a964b4d3f41b8e088afcd272d9",
      "name": "홍민기",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "22",
        "Pos.": "P",
        "G": "3",
        "GS": "1",
        "GR": "2",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "3.2",
        "ER": "5",
        "R": "7",
        "rRA": "5.15",
        "TBF": "24",
        "H": "8",
        "2B": "3",
        "3B": "0",
        "HR": "0",
        "BB": "1",
        "HP": "2",
        "IB": "0",
        "SO": "2",
        "ROE": "2",
        "BK": "0",
        "WP": "3",
        "ERA": "12.27",
        "RA9": "17.18",
        "rRA9": "12.64",
        "rRA9pf": "11.95",
        "FIP": "5.37",
        "WHIP": "2.45",
        "WAR": "-0.06"
      }
    }
  ],
  [
    "5d560eba4613ccedf515039d50d9df96f0ef4bc9",
    {
      "id": "5d560eba4613ccedf515039d50d9df96f0ef4bc9",
      "name": "심재민",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2021",
        "Team": "KT",
        "Age": "26",
        "Pos.": "P",
        "G": "28",
        "GS": "2",
        "GR": "26",
        "GF": "9",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "1",
        "IP": "46.2",
        "ER": "15",
        "R": "15",
        "rRA": "16.40",
        "TBF": "189",
        "H": "41",
        "2B": "4",
        "3B": "0",
        "HR": "4",
        "BB": "11",
        "HP": "0",
        "IB": "1",
        "SO": "35",
        "ROE": "2",
        "BK": "0",
        "WP": "0",
        "ERA": "2.89",
        "RA9": "2.89",
        "rRA9": "3.16",
        "rRA9pf": "3.08",
        "FIP": "3.58",
        "WHIP": "1.11",
        "WAR": "1.40"
      }
    }
  ],
  [
    "f6796f5497692611300fd778e411de46f984ae39",
    {
      "id": "f6796f5497692611300fd778e411de46f984ae39",
      "name": "박진형",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "29",
        "Pos.": "P",
        "G": "7",
        "GS": "0",
        "GR": "7",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "6.1",
        "ER": "3",
        "R": "3",
        "rRA": "2.65",
        "TBF": "24",
        "H": "6",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "BB": "1",
        "HP": "1",
        "IB": "0",
        "SO": "4",
        "ROE": "0",
        "BK": "0",
        "WP": "1",
        "ERA": "4.26",
        "RA9": "4.26",
        "rRA9": "3.77",
        "rRA9pf": "3.76",
        "FIP": "3.60",
        "WHIP": "1.11",
        "WAR": "0.21"
      }
    }
  ],
  [
    "25a02bcc5c7e8a9025ed7f8de9407312cfb65f7d",
    {
      "id": "25a02bcc5c7e8a9025ed7f8de9407312cfb65f7d",
      "name": "정보근",
      "team": "롯데",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "24",
        "Pos.": "C",
        "oWAR": "-0.61",
        "dWAR": "-0.02",
        "G": "89",
        "PA": "140",
        "ePA": "136",
        "AB": "133",
        "R": "9",
        "H": "30",
        "2B": "0",
        "3B": "1",
        "HR": "2",
        "TB": "38",
        "RBI": "7",
        "SB": "0",
        "CS": "0",
        "BB": "3",
        "HP": "0",
        "IB": "0",
        "SO": "31",
        "GDP": "5",
        "SH": "4",
        "SF": "0",
        "AVG": "0.226",
        "OBP": "0.243",
        "SLG": "0.286",
        "OPS": "0.529",
        "R/ePA": "0.030",
        "wRC+": "21.4",
        "WAR": "-0.63"
      }
    }
  ],
  [
    "89a864ee85d6005b5db1bc370cf36f3b9b9e6f28",
    {
      "id": "89a864ee85d6005b5db1bc370cf36f3b9b9e6f28",
      "name": "나균안",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "25",
        "Pos.": "P",
        "G": "26",
        "GS": "14",
        "GR": "12",
        "GF": "4",
        "CG": "0",
        "SHO": "0",
        "W": "4",
        "L": "7",
        "S": "0",
        "HD": "0",
        "IP": "73.0",
        "ER": "69",
        "R": "73",
        "rRA": "73.50",
        "TBF": "380",
        "H": "114",
        "2B": "19",
        "3B": "0",
        "HR": "15",
        "BB": "47",
        "HP": "5",
        "IB": "0",
        "SO": "74",
        "ROE": "2",
        "BK": "0",
        "WP": "10",
        "ERA": "8.51",
        "RA9": "9.00",
        "rRA9": "9.06",
        "rRA9pf": "8.73",
        "FIP": "6.32",
        "WHIP": "2.21",
        "WAR": "-0.64"
      }
    }
  ],
  [
    "fcf9cdf9f007939c65aa615a6fb9f20c71e6d5e0",
    {
      "id": "fcf9cdf9f007939c65aa615a6fb9f20c71e6d5e0",
      "name": "박진",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "24",
        "Pos.": "P",
        "G": "38",
        "GS": "3",
        "GR": "35",
        "GF": "11",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "4",
        "S": "1",
        "HD": "0",
        "IP": "49.1",
        "ER": "24",
        "R": "28",
        "rRA": "27.65",
        "TBF": "214",
        "H": "60",
        "2B": "9",
        "3B": "1",
        "HR": "4",
        "BB": "11",
        "HP": "2",
        "IB": "0",
        "SO": "35",
        "ROE": "0",
        "BK": "0",
        "WP": "4",
        "ERA": "4.38",
        "RA9": "5.11",
        "rRA9": "5.04",
        "rRA9pf": "4.72",
        "FIP": "4.21",
        "WHIP": "1.44",
        "WAR": "1.12"
      }
    }
  ],
  [
    "356ce2c7d9c68dfca5e522d7b1bae0638a019dbe",
    {
      "id": "356ce2c7d9c68dfca5e522d7b1bae0638a019dbe",
      "name": "윤성빈",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "24",
        "Pos.": "P",
        "G": "1",
        "GS": "1",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "1.0",
        "ER": "5",
        "R": "5",
        "rRA": "4.30",
        "TBF": "9",
        "H": "4",
        "2B": "1",
        "3B": "0",
        "HR": "1",
        "BB": "2",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "45.00",
        "RA9": "45.00",
        "rRA9": "38.70",
        "rRA9pf": "36.04",
        "FIP": "19.80",
        "WHIP": "6.00",
        "WAR": "-0.21"
      }
    }
  ],
  [
    "c937e20ec06b22d68a21c5211d8d5d2471566d0c",
    {
      "id": "c937e20ec06b22d68a21c5211d8d5d2471566d0c",
      "name": "최설우",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2021",
        "Team": "롯데",
        "Age": "28",
        "Pos.": "P",
        "G": "20",
        "GS": "7",
        "GR": "13",
        "GF": "3",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "2",
        "S": "0",
        "HD": "0",
        "IP": "40.2",
        "ER": "28",
        "R": "30",
        "rRA": "28.65",
        "TBF": "181",
        "H": "45",
        "2B": "10",
        "3B": "0",
        "HR": "4",
        "BB": "24",
        "HP": "2",
        "IB": "0",
        "SO": "28",
        "ROE": "0",
        "BK": "0",
        "WP": "4",
        "ERA": "6.20",
        "RA9": "6.64",
        "rRA9": "6.34",
        "rRA9pf": "6.17",
        "FIP": "5.20",
        "WHIP": "1.70",
        "WAR": "0.26"
      }
    }
  ],
  [
    "64503cc2e90aa82907ddf5910a7d339ad69e8fc0",
    {
      "id": "64503cc2e90aa82907ddf5910a7d339ad69e8fc0",
      "name": "윌커슨",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "34",
        "Pos.": "P",
        "G": "32",
        "GS": "32",
        "GR": "0",
        "GF": "1",
        "CG": "1",
        "SHO": "1",
        "W": "12",
        "L": "8",
        "S": "0",
        "HD": "0",
        "IP": "196.2",
        "ER": "84",
        "R": "90",
        "rRA": "88.80",
        "TBF": "816",
        "H": "210",
        "2B": "37",
        "3B": "3",
        "HR": "18",
        "BB": "27",
        "HP": "2",
        "IB": "0",
        "SO": "167",
        "ROE": "13",
        "BK": "0",
        "WP": "3",
        "ERA": "3.84",
        "RA9": "4.12",
        "rRA9": "4.06",
        "rRA9pf": "3.91",
        "FIP": "3.62",
        "WHIP": "1.21",
        "WAR": "6.81"
      }
    }
  ],
  [
    "444a0b49dd7b1bdc694dc84725dd2003424f934c",
    {
      "id": "444a0b49dd7b1bdc694dc84725dd2003424f934c",
      "name": "이정훈",
      "team": "롯데",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "29",
        "Pos.": "DH",
        "oWAR": "0.19",
        "dWAR": "-0.06",
        "G": "65",
        "PA": "116",
        "ePA": "114",
        "AB": "100",
        "R": "10",
        "H": "30",
        "2B": "8",
        "3B": "0",
        "HR": "0",
        "TB": "38",
        "RBI": "18",
        "SB": "0",
        "CS": "0",
        "BB": "13",
        "HP": "0",
        "IB": "1",
        "SO": "21",
        "GDP": "4",
        "SH": "1",
        "SF": "2",
        "AVG": "0.300",
        "OBP": "0.374",
        "SLG": "0.380",
        "OPS": "0.754",
        "R/ePA": "0.133",
        "wRC+": "94.9",
        "WAR": "0.14"
      }
    }
  ],
  [
    "caa3faa991dd578396cb890434f8307145bf8183",
    {
      "id": "caa3faa991dd578396cb890434f8307145bf8183",
      "name": "최이준",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "24",
        "Pos.": "P",
        "G": "23",
        "GS": "0",
        "GR": "23",
        "GF": "11",
        "CG": "0",
        "SHO": "0",
        "W": "3",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "24.2",
        "ER": "20",
        "R": "22",
        "rRA": "27.85",
        "TBF": "126",
        "H": "40",
        "2B": "6",
        "3B": "1",
        "HR": "3",
        "BB": "16",
        "HP": "1",
        "IB": "1",
        "SO": "17",
        "ROE": "1",
        "BK": "0",
        "WP": "1",
        "ERA": "7.30",
        "RA9": "8.03",
        "rRA9": "10.16",
        "rRA9pf": "9.76",
        "FIP": "6.06",
        "WHIP": "2.27",
        "WAR": "-0.70"
      }
    }
  ],
  [
    "e81be1b9a607238f0d08bbcd031bd91fdccacf32",
    {
      "id": "e81be1b9a607238f0d08bbcd031bd91fdccacf32",
      "name": "김동혁",
      "team": "롯데",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "23",
        "Pos.": "CF",
        "oWAR": "0.15",
        "dWAR": "0.01",
        "G": "39",
        "PA": "17",
        "ePA": "17",
        "AB": "15",
        "R": "8",
        "H": "3",
        "2B": "0",
        "3B": "1",
        "HR": "0",
        "TB": "5",
        "RBI": "1",
        "SB": "3",
        "CS": "1",
        "BB": "2",
        "HP": "0",
        "IB": "0",
        "SO": "5",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.200",
        "OBP": "0.294",
        "SLG": "0.333",
        "OPS": "0.627",
        "R/ePA": "0.086",
        "wRC+": "60.6",
        "WAR": "0.16"
      }
    }
  ],
  [
    "f2eeef848b0c1f58adc7180f628c6ae6f3ac5add",
    {
      "id": "f2eeef848b0c1f58adc7180f628c6ae6f3ac5add",
      "name": "나승엽",
      "team": "롯데",
      "position": "3B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "21",
        "Pos.": "1B",
        "oWAR": "2.83",
        "dWAR": "-0.61",
        "G": "121",
        "PA": "489",
        "ePA": "486",
        "AB": "407",
        "R": "59",
        "H": "127",
        "2B": "35",
        "3B": "4",
        "HR": "7",
        "TB": "191",
        "RBI": "66",
        "SB": "1",
        "CS": "0",
        "BB": "69",
        "HP": "4",
        "IB": "1",
        "SO": "83",
        "GDP": "4",
        "SH": "2",
        "SF": "7",
        "AVG": "0.312",
        "OBP": "0.411",
        "SLG": "0.469",
        "OPS": "0.880",
        "R/ePA": "0.182",
        "wRC+": "129.7",
        "WAR": "2.22"
      }
    }
  ],
  [
    "17ee691d97dfc317d5f16f7c73feed3849e2df94",
    {
      "id": "17ee691d97dfc317d5f16f7c73feed3849e2df94",
      "name": "노진혁",
      "team": "롯데",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "34",
        "Pos.": "SS",
        "oWAR": "-0.18",
        "dWAR": "-0.08",
        "G": "73",
        "PA": "157",
        "ePA": "154",
        "AB": "137",
        "R": "13",
        "H": "30",
        "2B": "6",
        "3B": "0",
        "HR": "2",
        "TB": "42",
        "RBI": "13",
        "SB": "0",
        "CS": "0",
        "BB": "15",
        "HP": "1",
        "IB": "1",
        "SO": "39",
        "GDP": "1",
        "SH": "2",
        "SF": "2",
        "AVG": "0.219",
        "OBP": "0.297",
        "SLG": "0.307",
        "OPS": "0.604",
        "R/ePA": "0.072",
        "wRC+": "51.0",
        "WAR": "-0.26"
      }
    }
  ],
  [
    "3e5407df2ab890a18c8cb44d09858ce2c0b5c6b1",
    {
      "id": "3e5407df2ab890a18c8cb44d09858ce2c0b5c6b1",
      "name": "박승욱",
      "team": "롯데",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "31",
        "Pos.": "SS",
        "oWAR": "1.65",
        "dWAR": "-0.64",
        "G": "139",
        "PA": "468",
        "ePA": "463",
        "AB": "405",
        "R": "57",
        "H": "106",
        "2B": "19",
        "3B": "1",
        "HR": "7",
        "TB": "148",
        "RBI": "53",
        "SB": "4",
        "CS": "5",
        "BB": "47",
        "HP": "10",
        "IB": "3",
        "SO": "120",
        "GDP": "7",
        "SH": "3",
        "SF": "3",
        "AVG": "0.262",
        "OBP": "0.351",
        "SLG": "0.365",
        "OPS": "0.716",
        "R/ePA": "0.121",
        "wRC+": "86.4",
        "WAR": "1.01"
      }
    }
  ],
  [
    "ed9fc0eea469e458fefb81e46878958545de4b88",
    {
      "id": "ed9fc0eea469e458fefb81e46878958545de4b88",
      "name": "이태연",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2023",
        "Team": "롯데",
        "Age": "18",
        "Pos.": "P",
        "G": "15",
        "GS": "0",
        "GR": "15",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "1",
        "IP": "5.2",
        "ER": "10",
        "R": "10",
        "rRA": "10.35",
        "TBF": "37",
        "H": "12",
        "2B": "3",
        "3B": "0",
        "HR": "1",
        "BB": "9",
        "HP": "0",
        "IB": "0",
        "SO": "4",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "15.88",
        "RA9": "15.88",
        "rRA9": "16.44",
        "rRA9pf": "15.90",
        "FIP": "9.11",
        "WHIP": "3.71",
        "WAR": "-0.41"
      }
    }
  ],
  [
    "63b7351fcf3bfc78b24c0d39749a12962693d037",
    {
      "id": "63b7351fcf3bfc78b24c0d39749a12962693d037",
      "name": "백두산",
      "team": "롯데",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "22",
        "Pos.": "C",
        "oWAR": "-0.02",
        "dWAR": "0.00",
        "G": "1",
        "PA": "1",
        "ePA": "1",
        "AB": "1",
        "R": "0",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.000",
        "SLG": "0.000",
        "OPS": "0.000",
        "R/ePA": "-0.190",
        "wRC+": "-127.9",
        "WAR": "-0.02"
      }
    }
  ],
  [
    "75e9b25bfc7588b029c416fc781ce99ab2377059",
    {
      "id": "75e9b25bfc7588b029c416fc781ce99ab2377059",
      "name": "최준용",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "22",
        "Pos.": "P",
        "G": "27",
        "GS": "0",
        "GR": "27",
        "GF": "4",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "2",
        "S": "0",
        "HD": "3",
        "IP": "21.2",
        "ER": "13",
        "R": "13",
        "rRA": "15.55",
        "TBF": "105",
        "H": "28",
        "2B": "2",
        "3B": "1",
        "HR": "2",
        "BB": "14",
        "HP": "1",
        "IB": "1",
        "SO": "12",
        "ROE": "1",
        "BK": "0",
        "WP": "1",
        "ERA": "5.40",
        "RA9": "5.40",
        "rRA9": "6.46",
        "rRA9pf": "6.02",
        "FIP": "5.92",
        "WHIP": "1.94",
        "WAR": "0.16"
      }
    }
  ],
  [
    "cdd3a322d12473997573fadbbfa343d89ed0aae7",
    {
      "id": "cdd3a322d12473997573fadbbfa343d89ed0aae7",
      "name": "임준섭",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "34",
        "Pos.": "P",
        "G": "24",
        "GS": "0",
        "GR": "24",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "2",
        "IP": "11.1",
        "ER": "10",
        "R": "11",
        "rRA": "8.95",
        "TBF": "56",
        "H": "12",
        "2B": "2",
        "3B": "1",
        "HR": "0",
        "BB": "11",
        "HP": "3",
        "IB": "0",
        "SO": "3",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "7.94",
        "RA9": "8.74",
        "rRA9": "7.11",
        "rRA9pf": "6.62",
        "FIP": "7.37",
        "WHIP": "2.03",
        "WAR": "0.06"
      }
    }
  ],
  [
    "318e8d1fd53dddeae3b1b024db1bac20626aa7fd",
    {
      "id": "318e8d1fd53dddeae3b1b024db1bac20626aa7fd",
      "name": "송재영",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "21",
        "Pos.": "P",
        "G": "19",
        "GS": "0",
        "GR": "19",
        "GF": "4",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "1",
        "HD": "3",
        "IP": "8.1",
        "ER": "10",
        "R": "10",
        "rRA": "10.40",
        "TBF": "40",
        "H": "9",
        "2B": "3",
        "3B": "0",
        "HR": "2",
        "BB": "5",
        "HP": "1",
        "IB": "0",
        "SO": "9",
        "ROE": "0",
        "BK": "1",
        "WP": "1",
        "ERA": "10.80",
        "RA9": "10.80",
        "rRA9": "11.23",
        "rRA9pf": "10.38",
        "FIP": "6.59",
        "WHIP": "1.68",
        "WAR": "-0.36"
      }
    }
  ],
  [
    "52a366828452bc419b138ef6907885901292e3c6",
    {
      "id": "52a366828452bc419b138ef6907885901292e3c6",
      "name": "신병률",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2018",
        "Team": "KT",
        "Age": "21",
        "Pos.": "P",
        "G": "21",
        "GS": "1",
        "GR": "20",
        "GF": "10",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "1",
        "HD": "2",
        "IP": "25.2",
        "ER": "20",
        "R": "22",
        "rRA": "22.85",
        "TBF": "120",
        "H": "35",
        "2B": "10",
        "3B": "1",
        "HR": "6",
        "BB": "7",
        "HP": "3",
        "IB": "0",
        "SO": "14",
        "ROE": "2",
        "BK": "0",
        "WP": "1",
        "ERA": "7.01",
        "RA9": "7.71",
        "rRA9": "8.01",
        "rRA9pf": "7.89",
        "FIP": "6.97",
        "WHIP": "1.64",
        "WAR": "-0.15"
      }
    }
  ],
  [
    "392b8e36beda7780d5a480679434ed55bf385b76",
    {
      "id": "392b8e36beda7780d5a480679434ed55bf385b76",
      "name": "전미르",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "18",
        "Pos.": "P",
        "G": "36",
        "GS": "0",
        "GR": "36",
        "GF": "6",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "5",
        "S": "1",
        "HD": "5",
        "IP": "33.2",
        "ER": "22",
        "R": "28",
        "rRA": "22.50",
        "TBF": "155",
        "H": "33",
        "2B": "10",
        "3B": "0",
        "HR": "4",
        "BB": "21",
        "HP": "1",
        "IB": "2",
        "SO": "34",
        "ROE": "4",
        "BK": "0",
        "WP": "3",
        "ERA": "5.88",
        "RA9": "7.49",
        "rRA9": "6.01",
        "rRA9pf": "5.62",
        "FIP": "5.02",
        "WHIP": "1.60",
        "WAR": "0.42"
      }
    }
  ],
  [
    "f0c778fd518edd8d088645719203935bcc199158",
    {
      "id": "f0c778fd518edd8d088645719203935bcc199158",
      "name": "이진하",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2023",
        "Team": "롯데",
        "Age": "18",
        "Pos.": "P",
        "G": "9",
        "GS": "0",
        "GR": "9",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "8.0",
        "ER": "3",
        "R": "3",
        "rRA": "2.50",
        "TBF": "31",
        "H": "4",
        "2B": "3",
        "3B": "0",
        "HR": "0",
        "BB": "3",
        "HP": "1",
        "IB": "0",
        "SO": "2",
        "ROE": "1",
        "BK": "0",
        "WP": "1",
        "ERA": "3.38",
        "RA9": "3.38",
        "rRA9": "2.81",
        "rRA9pf": "2.88",
        "FIP": "4.77",
        "WHIP": "0.88",
        "WAR": "0.20"
      }
    }
  ],
  [
    "d021d3b3593a8df07c29ab175a1d79f3f56bf435",
    {
      "id": "d021d3b3593a8df07c29ab175a1d79f3f56bf435",
      "name": "이주찬",
      "team": "롯데",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "25",
        "Pos.": "3B",
        "oWAR": "-0.11",
        "dWAR": "-0.32",
        "G": "17",
        "PA": "25",
        "ePA": "25",
        "AB": "24",
        "R": "7",
        "H": "4",
        "2B": "1",
        "3B": "0",
        "HR": "1",
        "TB": "8",
        "RBI": "2",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "9",
        "GDP": "1",
        "SH": "0",
        "SF": "0",
        "AVG": "0.167",
        "OBP": "0.200",
        "SLG": "0.333",
        "OPS": "0.533",
        "R/ePA": "0.025",
        "wRC+": "17.0",
        "WAR": "-0.43"
      }
    }
  ],
  [
    "2029af23776c6219763263cbeb4155884403def0",
    {
      "id": "2029af23776c6219763263cbeb4155884403def0",
      "name": "고승민",
      "team": "롯데",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "23",
        "Pos.": "2B",
        "oWAR": "2.96",
        "dWAR": "0.12",
        "G": "120",
        "PA": "532",
        "ePA": "531",
        "AB": "481",
        "R": "79",
        "H": "148",
        "2B": "27",
        "3B": "6",
        "HR": "14",
        "TB": "229",
        "RBI": "87",
        "SB": "5",
        "CS": "4",
        "BB": "41",
        "HP": "1",
        "IB": "0",
        "SO": "78",
        "GDP": "10",
        "SH": "1",
        "SF": "8",
        "AVG": "0.308",
        "OBP": "0.358",
        "SLG": "0.476",
        "OPS": "0.834",
        "R/ePA": "0.152",
        "wRC+": "107.9",
        "WAR": "3.08"
      }
    }
  ],
  [
    "bb6fa0a6ad83035101a98874ea92cf1595c4b4e9",
    {
      "id": "bb6fa0a6ad83035101a98874ea92cf1595c4b4e9",
      "name": "지시완",
      "team": "롯데",
      "position": "C",
      "record": {
        "Div.": "베스트",
        "Year": "2021",
        "Team": "롯데",
        "Age": "26",
        "Pos.": "C",
        "oWAR": "0.91",
        "dWAR": "-0.03",
        "G": "73",
        "PA": "187",
        "ePA": "185",
        "AB": "166",
        "R": "23",
        "H": "40",
        "2B": "9",
        "3B": "0",
        "HR": "7",
        "TB": "70",
        "RBI": "26",
        "SB": "0",
        "CS": "0",
        "BB": "14",
        "HP": "5",
        "IB": "0",
        "SO": "54",
        "GDP": "6",
        "SH": "2",
        "SF": "0",
        "AVG": "0.241",
        "OBP": "0.319",
        "SLG": "0.422",
        "OPS": "0.741",
        "R/ePA": "0.121",
        "wRC+": "95.0",
        "WAR": "0.88"
      }
    }
  ],
  [
    "425c9b615f105156c70793b1cea3d41a83af02eb",
    {
      "id": "425c9b615f105156c70793b1cea3d41a83af02eb",
      "name": "강성우",
      "team": "롯데",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "18",
        "Pos.": "DH",
        "oWAR": "0.00",
        "dWAR": "0.00",
        "G": "2",
        "PA": "3",
        "ePA": "2",
        "AB": "2",
        "R": "0",
        "H": "1",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "1",
        "RBI": "0",
        "SB": "0",
        "CS": "1",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "0",
        "GDP": "0",
        "SH": "1",
        "SF": "0",
        "AVG": "0.500",
        "OBP": "0.500",
        "SLG": "0.500",
        "OPS": "1.000",
        "R/ePA": "0.229",
        "wRC+": "173.1",
        "WAR": "0.00"
      }
    }
  ],
  [
    "3604585dfc4425fe9ec0bce6683d0f3945c3b59e",
    {
      "id": "3604585dfc4425fe9ec0bce6683d0f3945c3b59e",
      "name": "정대선",
      "team": "롯데",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "19",
        "Pos.": "2B",
        "oWAR": "-0.05",
        "dWAR": "0.09",
        "G": "3",
        "PA": "2",
        "ePA": "2",
        "AB": "2",
        "R": "0",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "2",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.000",
        "SLG": "0.000",
        "OPS": "0.000",
        "R/ePA": "-0.190",
        "wRC+": "-142.1",
        "WAR": "0.04"
      }
    }
  ],
  [
    "0cc3eeb43798a10eb0d82b36ac39edfbd162cfd4",
    {
      "id": "0cc3eeb43798a10eb0d82b36ac39edfbd162cfd4",
      "name": "서동욱",
      "team": "롯데",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "23",
        "Pos.": "C",
        "oWAR": "-0.35",
        "dWAR": "-0.10",
        "G": "33",
        "PA": "27",
        "ePA": "27",
        "AB": "26",
        "R": "2",
        "H": "3",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "TB": "4",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "1",
        "IB": "0",
        "SO": "8",
        "GDP": "1",
        "SH": "0",
        "SF": "0",
        "AVG": "0.115",
        "OBP": "0.148",
        "SLG": "0.154",
        "OPS": "0.302",
        "R/ePA": "-0.052",
        "wRC+": "-37.8",
        "WAR": "-0.45"
      }
    }
  ],
  [
    "a3bfa39ec50687f204c95634cf80d11af76cbac2",
    {
      "id": "a3bfa39ec50687f204c95634cf80d11af76cbac2",
      "name": "윤동희",
      "team": "롯데",
      "position": "RF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "20",
        "Pos.": "CF",
        "oWAR": "2.90",
        "dWAR": "0.23",
        "G": "141",
        "PA": "613",
        "ePA": "610",
        "AB": "532",
        "R": "97",
        "H": "156",
        "2B": "35",
        "3B": "4",
        "HR": "14",
        "TB": "241",
        "RBI": "85",
        "SB": "7",
        "CS": "8",
        "BB": "67",
        "HP": "7",
        "IB": "1",
        "SO": "114",
        "GDP": "10",
        "SH": "2",
        "SF": "5",
        "AVG": "0.293",
        "OBP": "0.376",
        "SLG": "0.453",
        "OPS": "0.829",
        "R/ePA": "0.159",
        "wRC+": "113.0",
        "WAR": "3.13"
      }
    }
  ],
  [
    "59384fac79730256a6df2c5ef7f90a3c81929dec",
    {
      "id": "59384fac79730256a6df2c5ef7f90a3c81929dec",
      "name": "이선우",
      "team": "롯데",
      "position": "RF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "d8f0ab83715402c5f205f2f2e08866e821a9f811",
    {
      "id": "d8f0ab83715402c5f205f2f2e08866e821a9f811",
      "name": "이호준",
      "team": "롯데",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "19",
        "Pos.": "2B",
        "oWAR": "0.10",
        "dWAR": "0.01",
        "G": "12",
        "PA": "7",
        "ePA": "7",
        "AB": "6",
        "R": "5",
        "H": "2",
        "2B": "1",
        "3B": "1",
        "HR": "0",
        "TB": "5",
        "RBI": "3",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.333",
        "OBP": "0.429",
        "SLG": "0.833",
        "OPS": "1.262",
        "R/ePA": "0.307",
        "wRC+": "214.0",
        "WAR": "0.11"
      }
    }
  ],
  [
    "74f10022efe81476b3f90bf76f0d51153c22cd61",
    {
      "id": "74f10022efe81476b3f90bf76f0d51153c22cd61",
      "name": "전하원",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "73ddb9e3ea45750c2f9ddbfc859f7046f9bd666e",
    {
      "id": "73ddb9e3ea45750c2f9ddbfc859f7046f9bd666e",
      "name": "고경표",
      "team": "롯데",
      "position": "1B",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "55deb6de0cac9c44e7cd1b931f23a3b28833b6ce",
    {
      "id": "55deb6de0cac9c44e7cd1b931f23a3b28833b6ce",
      "name": "유제모",
      "team": "롯데",
      "position": "RF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "879ee52878655058b44b430fdde6d56d14b890cd",
    {
      "id": "879ee52878655058b44b430fdde6d56d14b890cd",
      "name": "박준우",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "18",
        "Pos.": "P",
        "G": "2",
        "GS": "0",
        "GR": "2",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "2.0",
        "ER": "2",
        "R": "2",
        "rRA": "2.00",
        "TBF": "10",
        "H": "3",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "9.00",
        "RA9": "9.00",
        "rRA9": "9.00",
        "rRA9pf": "9.03",
        "FIP": "4.50",
        "WHIP": "2.00",
        "WAR": "-0.01"
      }
    }
  ],
  [
    "08cea6a073c42bfe174e1c913df6abf0d5641998",
    {
      "id": "08cea6a073c42bfe174e1c913df6abf0d5641998",
      "name": "최종은",
      "team": "롯데",
      "position": "2B",
      "record": {
        "Div.": "베스트",
        "Year": "2021",
        "Team": "롯데",
        "Age": "22",
        "Pos.": "2B",
        "oWAR": "-0.02",
        "dWAR": "0.02",
        "G": "1",
        "PA": "1",
        "ePA": "1",
        "AB": "1",
        "R": "0",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.000",
        "SLG": "0.000",
        "OPS": "0.000",
        "R/ePA": "-0.179",
        "wRC+": "-166.5",
        "WAR": "0.00"
      }
    }
  ],
  [
    "4400e81c3e8ac65c315095359a3cbdf149707615",
    {
      "id": "4400e81c3e8ac65c315095359a3cbdf149707615",
      "name": "박성준",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "27926cc8b6f15957192dac5683b83ea1b5e20b25",
    {
      "id": "27926cc8b6f15957192dac5683b83ea1b5e20b25",
      "name": "배세종",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "51f3b2a9bf8c4beedb24521c77d7ccf9f6730778",
    {
      "id": "51f3b2a9bf8c4beedb24521c77d7ccf9f6730778",
      "name": "안우진",
      "team": "롯데",
      "position": "SS",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "702e4446599fe0c31219739a4551b53aaed5b5fb",
    {
      "id": "702e4446599fe0c31219739a4551b53aaed5b5fb",
      "name": "김대현",
      "team": "롯데",
      "position": "LF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "afc28eb89dc9fb22fcaf4ba904b8b72053bb2de6",
    {
      "id": "afc28eb89dc9fb22fcaf4ba904b8b72053bb2de6",
      "name": "기민성",
      "team": "롯데",
      "position": "CF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "434de4847a2c677e7a782c1cfc33496a6b775960",
    {
      "id": "434de4847a2c677e7a782c1cfc33496a6b775960",
      "name": "소한빈",
      "team": "롯데",
      "position": "LF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "c46ce7f63ca9d519f3542de34ffc183991457130",
    {
      "id": "c46ce7f63ca9d519f3542de34ffc183991457130",
      "name": "강승구",
      "team": "롯데",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "20",
        "Pos.": "C",
        "oWAR": "-0.05",
        "dWAR": "-0.03",
        "G": "5",
        "PA": "2",
        "ePA": "2",
        "AB": "2",
        "R": "0",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.000",
        "SLG": "0.000",
        "OPS": "0.000",
        "R/ePA": "-0.190",
        "wRC+": "-127.9",
        "WAR": "-0.08"
      }
    }
  ],
  [
    "a5cfccfb4419d646aa7fbd9414583a4e709094b7",
    {
      "id": "a5cfccfb4419d646aa7fbd9414583a4e709094b7",
      "name": "김동규",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "32f6b9a5b40ed4a85f694ff06e97487afe4a1c03",
    {
      "id": "32f6b9a5b40ed4a85f694ff06e97487afe4a1c03",
      "name": "박명현",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2020",
        "Team": "롯데",
        "Age": "18",
        "Pos.": "P",
        "G": "2",
        "GS": "0",
        "GR": "2",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "0.2",
        "ER": "2",
        "R": "2",
        "rRA": "2.00",
        "TBF": "6",
        "H": "3",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "0",
        "ROE": "0",
        "BK": "0",
        "WP": "1",
        "ERA": "27.00",
        "RA9": "27.00",
        "rRA9": "27.00",
        "rRA9pf": "24.64",
        "FIP": "8.65",
        "WHIP": "6.00",
        "WAR": "-0.08"
      }
    }
  ],
  [
    "afde8080918a983088719162b8a1c890a3393b5f",
    {
      "id": "afde8080918a983088719162b8a1c890a3393b5f",
      "name": "이병준",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "ebe56013d3f086cb7bfcaf5de9185e05df4b21f1",
    {
      "id": "ebe56013d3f086cb7bfcaf5de9185e05df4b21f1",
      "name": "최우인",
      "team": "롯데",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "4a193d7247caac4fa95223ea11340a290ab30128",
    {
      "id": "4a193d7247caac4fa95223ea11340a290ab30128",
      "name": "이인한",
      "team": "롯데",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "롯데",
        "Age": "25",
        "Pos.": "CF",
        "oWAR": "0.10",
        "dWAR": "0.00",
        "G": "5",
        "PA": "5",
        "ePA": "5",
        "AB": "4",
        "R": "0",
        "H": "2",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "2",
        "RBI": "2",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "1",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.500",
        "OBP": "0.600",
        "SLG": "0.500",
        "OPS": "1.100",
        "R/ePA": "0.313",
        "wRC+": "217.3",
        "WAR": "0.10"
      }
    }
  ],
  [
    "e01032c44ed4fccd303d1370a9b892d649fc7288",
    {
      "id": "e01032c44ed4fccd303d1370a9b892d649fc7288",
      "name": "김유영",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "29",
        "Pos.": "P",
        "G": "53",
        "GS": "2",
        "GR": "51",
        "GF": "6",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "2",
        "S": "1",
        "HD": "6",
        "IP": "47.2",
        "ER": "20",
        "R": "27",
        "rRA": "25.70",
        "TBF": "212",
        "H": "50",
        "2B": "7",
        "3B": "0",
        "HR": "2",
        "BB": "16",
        "HP": "4",
        "IB": "0",
        "SO": "42",
        "ROE": "8",
        "BK": "1",
        "WP": "3",
        "ERA": "3.78",
        "RA9": "5.10",
        "rRA9": "4.85",
        "rRA9pf": "4.99",
        "FIP": "3.80",
        "WHIP": "1.38",
        "WAR": "0.84"
      }
    }
  ],
  [
    "d492a5bd833c3cc959ce507a530e98b152d67a2e",
    {
      "id": "d492a5bd833c3cc959ce507a530e98b152d67a2e",
      "name": "임찬규",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "31",
        "Pos.": "P",
        "G": "25",
        "GS": "24",
        "GR": "1",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "10",
        "L": "6",
        "S": "0",
        "HD": "1",
        "IP": "134.0",
        "ER": "57",
        "R": "58",
        "rRA": "56.10",
        "TBF": "577",
        "H": "144",
        "2B": "29",
        "3B": "1",
        "HR": "12",
        "BB": "42",
        "HP": "5",
        "IB": "1",
        "SO": "136",
        "ROE": "0",
        "BK": "0",
        "WP": "1",
        "ERA": "3.83",
        "RA9": "3.90",
        "rRA9": "3.77",
        "rRA9pf": "3.94",
        "FIP": "3.79",
        "WHIP": "1.39",
        "WAR": "4.46"
      }
    }
  ],
  [
    "f0caf009b3377da8d33b5bfb70fa6ecaa046d8d6",
    {
      "id": "f0caf009b3377da8d33b5bfb70fa6ecaa046d8d6",
      "name": "문보경",
      "team": "LG",
      "position": "3B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "23",
        "Pos.": "3B",
        "oWAR": "4.47",
        "dWAR": "-0.15",
        "G": "144",
        "PA": "602",
        "ePA": "597",
        "AB": "519",
        "R": "80",
        "H": "156",
        "2B": "35",
        "3B": "3",
        "HR": "22",
        "TB": "263",
        "RBI": "101",
        "SB": "7",
        "CS": "6",
        "BB": "65",
        "HP": "2",
        "IB": "3",
        "SO": "112",
        "GDP": "14",
        "SH": "3",
        "SF": "13",
        "AVG": "0.301",
        "OBP": "0.372",
        "SLG": "0.507",
        "OPS": "0.879",
        "R/ePA": "0.169",
        "wRC+": "130.4",
        "WAR": "4.32"
      }
    }
  ],
  [
    "4d9972d76cbcf9a0a28f86409ebaaf63f03a4881",
    {
      "id": "4d9972d76cbcf9a0a28f86409ebaaf63f03a4881",
      "name": "켈리",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "34",
        "Pos.": "P",
        "G": "19",
        "GS": "19",
        "GR": "0",
        "GF": "2",
        "CG": "2",
        "SHO": "1",
        "W": "5",
        "L": "8",
        "S": "0",
        "HD": "0",
        "IP": "113.2",
        "ER": "57",
        "R": "65",
        "rRA": "65.00",
        "TBF": "495",
        "H": "131",
        "2B": "18",
        "3B": "1",
        "HR": "13",
        "BB": "25",
        "HP": "6",
        "IB": "0",
        "SO": "69",
        "ROE": "4",
        "BK": "0",
        "WP": "9",
        "ERA": "4.51",
        "RA9": "5.15",
        "rRA9": "5.15",
        "rRA9pf": "5.28",
        "FIP": "4.89",
        "WHIP": "1.37",
        "WAR": "2.20"
      }
    }
  ],
  [
    "2f4ca45b284bc5db9589152dc228c587062d3d9c",
    {
      "id": "2f4ca45b284bc5db9589152dc228c587062d3d9c",
      "name": "신민재",
      "team": "LG",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "27",
        "Pos.": "2B",
        "oWAR": "3.11",
        "dWAR": "-0.47",
        "G": "128",
        "PA": "474",
        "ePA": "459",
        "AB": "387",
        "R": "78",
        "H": "115",
        "2B": "11",
        "3B": "6",
        "HR": "0",
        "TB": "138",
        "RBI": "40",
        "SB": "32",
        "CS": "11",
        "BB": "64",
        "HP": "5",
        "IB": "1",
        "SO": "47",
        "GDP": "7",
        "SH": "15",
        "SF": "3",
        "AVG": "0.297",
        "OBP": "0.401",
        "SLG": "0.357",
        "OPS": "0.758",
        "R/ePA": "0.148",
        "wRC+": "114.2",
        "WAR": "2.64"
      }
    }
  ],
  [
    "708decd2c08255c1d2e7c92d9dc809f7cb724ef2",
    {
      "id": "708decd2c08255c1d2e7c92d9dc809f7cb724ef2",
      "name": "김주성",
      "team": "LG",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "25",
        "Pos.": "SS",
        "oWAR": "0.07",
        "dWAR": "0.05",
        "G": "12",
        "PA": "9",
        "ePA": "9",
        "AB": "6",
        "R": "1",
        "H": "1",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "1",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "3",
        "HP": "0",
        "IB": "0",
        "SO": "0",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.167",
        "OBP": "0.444",
        "SLG": "0.167",
        "OPS": "0.611",
        "R/ePA": "0.145",
        "wRC+": "110.3",
        "WAR": "0.12"
      }
    }
  ],
  [
    "19ffe079edfdb708a3fe17366167e7e71371d4f6",
    {
      "id": "19ffe079edfdb708a3fe17366167e7e71371d4f6",
      "name": "구본혁",
      "team": "LG",
      "position": "3B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "26",
        "Pos.": "SS",
        "oWAR": "0.73",
        "dWAR": "0.56",
        "G": "133",
        "PA": "389",
        "ePA": "385",
        "AB": "339",
        "R": "48",
        "H": "87",
        "2B": "14",
        "3B": "2",
        "HR": "2",
        "TB": "111",
        "RBI": "43",
        "SB": "8",
        "CS": "4",
        "BB": "38",
        "HP": "4",
        "IB": "0",
        "SO": "51",
        "GDP": "7",
        "SH": "4",
        "SF": "4",
        "AVG": "0.257",
        "OBP": "0.335",
        "SLG": "0.327",
        "OPS": "0.662",
        "R/ePA": "0.101",
        "wRC+": "77.6",
        "WAR": "1.29"
      }
    }
  ],
  [
    "215ee1e23f87bb85dce6d01455ef3b3e30d6139a",
    {
      "id": "215ee1e23f87bb85dce6d01455ef3b3e30d6139a",
      "name": "이영빈",
      "team": "LG",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "21",
        "Pos.": "1B",
        "oWAR": "-0.13",
        "dWAR": "-0.05",
        "G": "31",
        "PA": "71",
        "ePA": "69",
        "AB": "63",
        "R": "11",
        "H": "14",
        "2B": "2",
        "3B": "0",
        "HR": "2",
        "TB": "22",
        "RBI": "12",
        "SB": "0",
        "CS": "1",
        "BB": "4",
        "HP": "0",
        "IB": "0",
        "SO": "20",
        "GDP": "0",
        "SH": "2",
        "SF": "2",
        "AVG": "0.222",
        "OBP": "0.261",
        "SLG": "0.349",
        "OPS": "0.610",
        "R/ePA": "0.059",
        "wRC+": "45.5",
        "WAR": "-0.18"
      }
    }
  ],
  [
    "f047575f03e63c13cb604e09d7e455222773b392",
    {
      "id": "f047575f03e63c13cb604e09d7e455222773b392",
      "name": "문성주",
      "team": "LG",
      "position": "RF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "26",
        "Pos.": "LF",
        "oWAR": "1.85",
        "dWAR": "0.56",
        "G": "96",
        "PA": "361",
        "ePA": "360",
        "AB": "305",
        "R": "47",
        "H": "96",
        "2B": "16",
        "3B": "2",
        "HR": "0",
        "TB": "116",
        "RBI": "48",
        "SB": "13",
        "CS": "7",
        "BB": "49",
        "HP": "3",
        "IB": "0",
        "SO": "38",
        "GDP": "4",
        "SH": "1",
        "SF": "3",
        "AVG": "0.315",
        "OBP": "0.411",
        "SLG": "0.380",
        "OPS": "0.791",
        "R/ePA": "0.158",
        "wRC+": "120.8",
        "WAR": "2.41"
      }
    }
  ],
  [
    "d75e66cff1e8e7a177564c0d72f2c5d1d3b16105",
    {
      "id": "d75e66cff1e8e7a177564c0d72f2c5d1d3b16105",
      "name": "오지환",
      "team": "LG",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "33",
        "Pos.": "SS",
        "oWAR": "2.67",
        "dWAR": "0.33",
        "G": "108",
        "PA": "428",
        "ePA": "426",
        "AB": "370",
        "R": "67",
        "H": "94",
        "2B": "24",
        "3B": "2",
        "HR": "10",
        "TB": "152",
        "RBI": "59",
        "SB": "17",
        "CS": "4",
        "BB": "51",
        "HP": "4",
        "IB": "0",
        "SO": "106",
        "GDP": "8",
        "SH": "2",
        "SF": "1",
        "AVG": "0.254",
        "OBP": "0.350",
        "SLG": "0.411",
        "OPS": "0.761",
        "R/ePA": "0.136",
        "wRC+": "104.5",
        "WAR": "3.00"
      }
    }
  ],
  [
    "1fbefbb9d2dea023b9837145b3466a5ae151f8f7",
    {
      "id": "1fbefbb9d2dea023b9837145b3466a5ae151f8f7",
      "name": "함덕주",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "28",
        "Pos.": "P",
        "G": "15",
        "GS": "0",
        "GR": "15",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "3",
        "IP": "11.2",
        "ER": "7",
        "R": "7",
        "rRA": "4.95",
        "TBF": "54",
        "H": "13",
        "2B": "0",
        "3B": "1",
        "HR": "2",
        "BB": "7",
        "HP": "1",
        "IB": "0",
        "SO": "8",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "5.40",
        "RA9": "5.40",
        "rRA9": "3.82",
        "rRA9pf": "4.02",
        "FIP": "6.65",
        "WHIP": "1.71",
        "WAR": "0.32"
      }
    }
  ],
  [
    "0ab0fd67f85e2126cc25bdecf0708b6e9a6194ee",
    {
      "id": "0ab0fd67f85e2126cc25bdecf0708b6e9a6194ee",
      "name": "김대현",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "26",
        "Pos.": "P",
        "G": "37",
        "GS": "0",
        "GR": "37",
        "GF": "8",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "0",
        "S": "1",
        "HD": "1",
        "IP": "35.0",
        "ER": "21",
        "R": "21",
        "rRA": "25.00",
        "TBF": "155",
        "H": "33",
        "2B": "6",
        "3B": "1",
        "HR": "6",
        "BB": "18",
        "HP": "2",
        "IB": "0",
        "SO": "30",
        "ROE": "0",
        "BK": "0",
        "WP": "5",
        "ERA": "5.40",
        "RA9": "5.40",
        "rRA9": "6.43",
        "rRA9pf": "6.56",
        "FIP": "5.87",
        "WHIP": "1.46",
        "WAR": "0.11"
      }
    }
  ],
  [
    "2792291049d9fbf0c923a5ba8ac1f446648ab33c",
    {
      "id": "2792291049d9fbf0c923a5ba8ac1f446648ab33c",
      "name": "최동환",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "34",
        "Pos.": "P",
        "G": "26",
        "GS": "0",
        "GR": "26",
        "GF": "7",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "2",
        "IP": "22.0",
        "ER": "17",
        "R": "18",
        "rRA": "19.70",
        "TBF": "105",
        "H": "35",
        "2B": "6",
        "3B": "0",
        "HR": "4",
        "BB": "9",
        "HP": "0",
        "IB": "0",
        "SO": "14",
        "ROE": "1",
        "BK": "0",
        "WP": "0",
        "ERA": "6.95",
        "RA9": "7.36",
        "rRA9": "8.06",
        "rRA9pf": "8.52",
        "FIP": "6.04",
        "WHIP": "2.00",
        "WAR": "-0.23"
      }
    }
  ],
  [
    "ed1115276d86c795a1062c2ac8f01626551b8d77",
    {
      "id": "ed1115276d86c795a1062c2ac8f01626551b8d77",
      "name": "송찬의",
      "team": "LG",
      "position": "RF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "24",
        "Pos.": "DH",
        "oWAR": "-0.12",
        "dWAR": "-0.01",
        "G": "10",
        "PA": "21",
        "ePA": "20",
        "AB": "15",
        "R": "2",
        "H": "1",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "1",
        "RBI": "1",
        "SB": "1",
        "CS": "0",
        "BB": "3",
        "HP": "1",
        "IB": "0",
        "SO": "6",
        "GDP": "0",
        "SH": "1",
        "SF": "1",
        "AVG": "0.067",
        "OBP": "0.250",
        "SLG": "0.067",
        "OPS": "0.317",
        "R/ePA": "0.003",
        "wRC+": "2.3",
        "WAR": "-0.13"
      }
    }
  ],
  [
    "7cb63402ae123fe53e89ecb6fe8568ddec601ef8",
    {
      "id": "7cb63402ae123fe53e89ecb6fe8568ddec601ef8",
      "name": "안익훈",
      "team": "LG",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "27",
        "Pos.": "LF",
        "oWAR": "-0.42",
        "dWAR": "0.40",
        "G": "37",
        "PA": "56",
        "ePA": "54",
        "AB": "49",
        "R": "8",
        "H": "9",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "TB": "10",
        "RBI": "3",
        "SB": "2",
        "CS": "0",
        "BB": "4",
        "HP": "0",
        "IB": "0",
        "SO": "13",
        "GDP": "2",
        "SH": "2",
        "SF": "1",
        "AVG": "0.184",
        "OBP": "0.241",
        "SLG": "0.204",
        "OPS": "0.445",
        "R/ePA": "0.010",
        "wRC+": "7.8",
        "WAR": "-0.02"
      }
    }
  ],
  [
    "e71e6592027e1f7a6d47f435224e07a253d08a91",
    {
      "id": "e71e6592027e1f7a6d47f435224e07a253d08a91",
      "name": "정우영",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "24",
        "Pos.": "P",
        "G": "27",
        "GS": "0",
        "GR": "27",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "1",
        "S": "0",
        "HD": "3",
        "IP": "22.2",
        "ER": "12",
        "R": "15",
        "rRA": "15.00",
        "TBF": "108",
        "H": "31",
        "2B": "6",
        "3B": "0",
        "HR": "0",
        "BB": "11",
        "HP": "6",
        "IB": "0",
        "SO": "17",
        "ROE": "1",
        "BK": "0",
        "WP": "3",
        "ERA": "4.76",
        "RA9": "5.96",
        "rRA9": "5.96",
        "rRA9pf": "6.38",
        "FIP": "4.65",
        "WHIP": "1.85",
        "WAR": "0.09"
      }
    }
  ],
  [
    "8c8f0a87541852142703d1c9e1368cbd7b2cd3f8",
    {
      "id": "8c8f0a87541852142703d1c9e1368cbd7b2cd3f8",
      "name": "박해민",
      "team": "LG",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "33",
        "Pos.": "CF",
        "oWAR": "1.44",
        "dWAR": "0.59",
        "G": "144",
        "PA": "553",
        "ePA": "548",
        "AB": "482",
        "R": "72",
        "H": "127",
        "2B": "16",
        "3B": "6",
        "HR": "6",
        "TB": "173",
        "RBI": "56",
        "SB": "43",
        "CS": "13",
        "BB": "46",
        "HP": "11",
        "IB": "0",
        "SO": "101",
        "GDP": "3",
        "SH": "5",
        "SF": "9",
        "AVG": "0.263",
        "OBP": "0.336",
        "SLG": "0.359",
        "OPS": "0.695",
        "R/ePA": "0.112",
        "wRC+": "86.1",
        "WAR": "2.03"
      }
    }
  ],
  [
    "09aecf0d927be5090b6fb4b4db680d54a805b405",
    {
      "id": "09aecf0d927be5090b6fb4b4db680d54a805b405",
      "name": "백승현",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "28",
        "Pos.": "P",
        "G": "36",
        "GS": "0",
        "GR": "36",
        "GF": "7",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "1",
        "S": "2",
        "HD": "1",
        "IP": "26.2",
        "ER": "27",
        "R": "28",
        "rRA": "25.60",
        "TBF": "135",
        "H": "33",
        "2B": "7",
        "3B": "0",
        "HR": "3",
        "BB": "22",
        "HP": "2",
        "IB": "2",
        "SO": "14",
        "ROE": "0",
        "BK": "0",
        "WP": "1",
        "ERA": "9.11",
        "RA9": "9.45",
        "rRA9": "8.64",
        "rRA9pf": "8.92",
        "FIP": "6.90",
        "WHIP": "2.06",
        "WAR": "-0.50"
      }
    }
  ],
  [
    "3c25bd0a57bf8cee36388a365b455409621f78b5",
    {
      "id": "3c25bd0a57bf8cee36388a365b455409621f78b5",
      "name": "에르난데스",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "28",
        "Pos.": "P",
        "G": "11",
        "GS": "9",
        "GR": "2",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "3",
        "L": "2",
        "S": "1",
        "HD": "1",
        "IP": "47.0",
        "ER": "21",
        "R": "21",
        "rRA": "19.45",
        "TBF": "195",
        "H": "41",
        "2B": "6",
        "3B": "1",
        "HR": "5",
        "BB": "16",
        "HP": "2",
        "IB": "0",
        "SO": "55",
        "ROE": "1",
        "BK": "0",
        "WP": "1",
        "ERA": "4.02",
        "RA9": "4.02",
        "rRA9": "3.72",
        "rRA9pf": "3.92",
        "FIP": "3.73",
        "WHIP": "1.21",
        "WAR": "1.59"
      }
    }
  ],
  [
    "98e75eaa71cb0c648d242dfa9c879942c85b1a4d",
    {
      "id": "98e75eaa71cb0c648d242dfa9c879942c85b1a4d",
      "name": "최원태",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "26",
        "Pos.": "P",
        "G": "24",
        "GS": "23",
        "GR": "1",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "9",
        "L": "7",
        "S": "0",
        "HD": "0",
        "IP": "126.2",
        "ER": "60",
        "R": "66",
        "rRA": "62.20",
        "TBF": "554",
        "H": "126",
        "2B": "20",
        "3B": "1",
        "HR": "10",
        "BB": "57",
        "HP": "5",
        "IB": "0",
        "SO": "103",
        "ROE": "6",
        "BK": "0",
        "WP": "5",
        "ERA": "4.26",
        "RA9": "4.69",
        "rRA9": "4.42",
        "rRA9pf": "4.58",
        "FIP": "4.62",
        "WHIP": "1.44",
        "WAR": "3.30"
      }
    }
  ],
  [
    "8e93585f1be98a81c14eba866796ab4cbfa707e9",
    {
      "id": "8e93585f1be98a81c14eba866796ab4cbfa707e9",
      "name": "이우찬",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "31",
        "Pos.": "P",
        "G": "42",
        "GS": "1",
        "GR": "41",
        "GF": "3",
        "CG": "0",
        "SHO": "0",
        "W": "3",
        "L": "2",
        "S": "0",
        "HD": "6",
        "IP": "33.2",
        "ER": "33",
        "R": "35",
        "rRA": "33.15",
        "TBF": "172",
        "H": "42",
        "2B": "6",
        "3B": "1",
        "HR": "2",
        "BB": "30",
        "HP": "1",
        "IB": "0",
        "SO": "31",
        "ROE": "4",
        "BK": "1",
        "WP": "6",
        "ERA": "8.82",
        "RA9": "9.36",
        "rRA9": "8.86",
        "rRA9pf": "8.94",
        "FIP": "5.46",
        "WHIP": "2.14",
        "WAR": "-0.73"
      }
    }
  ],
  [
    "20b1e9fff424c41d3dd892010032c57767ec255a",
    {
      "id": "20b1e9fff424c41d3dd892010032c57767ec255a",
      "name": "김현수",
      "team": "LG",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "35",
        "Pos.": "LF",
        "oWAR": "1.49",
        "dWAR": "-0.43",
        "G": "137",
        "PA": "583",
        "ePA": "582",
        "AB": "517",
        "R": "61",
        "H": "152",
        "2B": "36",
        "3B": "2",
        "HR": "8",
        "TB": "216",
        "RBI": "69",
        "SB": "6",
        "CS": "1",
        "BB": "47",
        "HP": "9",
        "IB": "1",
        "SO": "76",
        "GDP": "10",
        "SH": "1",
        "SF": "9",
        "AVG": "0.294",
        "OBP": "0.357",
        "SLG": "0.418",
        "OPS": "0.775",
        "R/ePA": "0.136",
        "wRC+": "104.7",
        "WAR": "1.06"
      }
    }
  ],
  [
    "d3a27ecafd91cb27740a20d69f70ea55c86f4db8",
    {
      "id": "d3a27ecafd91cb27740a20d69f70ea55c86f4db8",
      "name": "오스틴",
      "team": "LG",
      "position": "1B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "30",
        "Pos.": "1B",
        "oWAR": "4.90",
        "dWAR": "0.17",
        "G": "140",
        "PA": "604",
        "ePA": "600",
        "AB": "527",
        "R": "99",
        "H": "168",
        "2B": "32",
        "3B": "3",
        "HR": "32",
        "TB": "302",
        "RBI": "132",
        "SB": "12",
        "CS": "8",
        "BB": "61",
        "HP": "3",
        "IB": "6",
        "SO": "82",
        "GDP": "9",
        "SH": "0",
        "SF": "13",
        "AVG": "0.319",
        "OBP": "0.384",
        "SLG": "0.573",
        "OPS": "0.957",
        "R/ePA": "0.192",
        "wRC+": "147.8",
        "WAR": "5.06"
      }
    }
  ],
  [
    "2b970f20154bd66283055f0aedf314b793ee8a5d",
    {
      "id": "2b970f20154bd66283055f0aedf314b793ee8a5d",
      "name": "함창건",
      "team": "LG",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "22",
        "Pos.": "LF",
        "oWAR": "-0.20",
        "dWAR": "0.01",
        "G": "15",
        "PA": "17",
        "ePA": "16",
        "AB": "14",
        "R": "3",
        "H": "2",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "2",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "2",
        "HP": "0",
        "IB": "0",
        "SO": "3",
        "GDP": "1",
        "SH": "1",
        "SF": "0",
        "AVG": "0.143",
        "OBP": "0.250",
        "SLG": "0.143",
        "OPS": "0.393",
        "R/ePA": "0.006",
        "wRC+": "4.4",
        "WAR": "-0.19"
      }
    }
  ],
  [
    "c0f5be22037c02bb56bcc46645b62543df4cc8e6",
    {
      "id": "c0f5be22037c02bb56bcc46645b62543df4cc8e6",
      "name": "배재준",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2022",
        "Team": "LG",
        "Age": "27",
        "Pos.": "P",
        "G": "17",
        "GS": "5",
        "GR": "12",
        "GF": "7",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "31.1",
        "ER": "8",
        "R": "11",
        "rRA": "9.45",
        "TBF": "129",
        "H": "22",
        "2B": "4",
        "3B": "2",
        "HR": "0",
        "BB": "15",
        "HP": "4",
        "IB": "0",
        "SO": "23",
        "ROE": "2",
        "BK": "0",
        "WP": "0",
        "ERA": "2.30",
        "RA9": "3.16",
        "rRA9": "2.71",
        "rRA9pf": "2.92",
        "FIP": "3.83",
        "WHIP": "1.18",
        "WAR": "1.00"
      }
    }
  ],
  [
    "69b06b8c937127de21d731074e24d3e4603ad210",
    {
      "id": "69b06b8c937127de21d731074e24d3e4603ad210",
      "name": "이상영",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "23",
        "Pos.": "P",
        "G": "8",
        "GS": "3",
        "GR": "5",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "1",
        "IP": "16.1",
        "ER": "6",
        "R": "6",
        "rRA": "7.05",
        "TBF": "75",
        "H": "15",
        "2B": "0",
        "3B": "0",
        "HR": "1",
        "BB": "8",
        "HP": "4",
        "IB": "0",
        "SO": "8",
        "ROE": "0",
        "BK": "0",
        "WP": "2",
        "ERA": "3.31",
        "RA9": "3.31",
        "rRA9": "3.88",
        "rRA9pf": "4.20",
        "FIP": "5.98",
        "WHIP": "1.41",
        "WAR": "0.53"
      }
    }
  ],
  [
    "8d5296f75808b11db2cf2cbf4d8ac52a22b907d5",
    {
      "id": "8d5296f75808b11db2cf2cbf4d8ac52a22b907d5",
      "name": "박동원",
      "team": "LG",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "33",
        "Pos.": "C",
        "oWAR": "3.43",
        "dWAR": "0.96",
        "G": "130",
        "PA": "498",
        "ePA": "495",
        "AB": "434",
        "R": "58",
        "H": "118",
        "2B": "22",
        "3B": "0",
        "HR": "20",
        "TB": "200",
        "RBI": "80",
        "SB": "1",
        "CS": "0",
        "BB": "55",
        "HP": "0",
        "IB": "2",
        "SO": "112",
        "GDP": "14",
        "SH": "2",
        "SF": "7",
        "AVG": "0.272",
        "OBP": "0.349",
        "SLG": "0.461",
        "OPS": "0.810",
        "R/ePA": "0.144",
        "wRC+": "111.0",
        "WAR": "4.40"
      }
    }
  ],
  [
    "90945ebb2374cf90a2dd49f3735f49d7145bcdfd",
    {
      "id": "90945ebb2374cf90a2dd49f3735f49d7145bcdfd",
      "name": "윤호솔",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "29",
        "Pos.": "P",
        "G": "7",
        "GS": "0",
        "GR": "7",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "4.1",
        "ER": "2",
        "R": "2",
        "rRA": "1.65",
        "TBF": "24",
        "H": "4",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "BB": "8",
        "HP": "0",
        "IB": "0",
        "SO": "5",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "4.15",
        "RA9": "4.15",
        "rRA9": "3.43",
        "rRA9pf": "3.44",
        "FIP": "7.04",
        "WHIP": "2.77",
        "WAR": "0.11"
      }
    }
  ],
  [
    "3f75e368a759a171207a264f06273265ca535cc9",
    {
      "id": "3f75e368a759a171207a264f06273265ca535cc9",
      "name": "손주영",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "25",
        "Pos.": "P",
        "G": "28",
        "GS": "27",
        "GR": "1",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "9",
        "L": "10",
        "S": "0",
        "HD": "1",
        "IP": "144.2",
        "ER": "61",
        "R": "71",
        "rRA": "65.30",
        "TBF": "637",
        "H": "157",
        "2B": "16",
        "3B": "0",
        "HR": "11",
        "BB": "54",
        "HP": "10",
        "IB": "0",
        "SO": "112",
        "ROE": "9",
        "BK": "0",
        "WP": "6",
        "ERA": "3.79",
        "RA9": "4.42",
        "rRA9": "4.06",
        "rRA9pf": "4.26",
        "FIP": "4.54",
        "WHIP": "1.46",
        "WAR": "4.31"
      }
    }
  ],
  [
    "e4ca8f35e39cf162af7557b11b78933eb58330c8",
    {
      "id": "e4ca8f35e39cf162af7557b11b78933eb58330c8",
      "name": "허도환",
      "team": "LG",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "39",
        "Pos.": "C",
        "oWAR": "-0.22",
        "dWAR": "-0.42",
        "G": "59",
        "PA": "105",
        "ePA": "100",
        "AB": "80",
        "R": "10",
        "H": "11",
        "2B": "2",
        "3B": "0",
        "HR": "0",
        "TB": "13",
        "RBI": "11",
        "SB": "0",
        "CS": "1",
        "BB": "9",
        "HP": "10",
        "IB": "0",
        "SO": "26",
        "GDP": "4",
        "SH": "5",
        "SF": "1",
        "AVG": "0.138",
        "OBP": "0.300",
        "SLG": "0.163",
        "OPS": "0.463",
        "R/ePA": "0.059",
        "wRC+": "44.6",
        "WAR": "-0.64"
      }
    }
  ],
  [
    "a04d176fdf169247db08a623cf8bb9534107f786",
    {
      "id": "a04d176fdf169247db08a623cf8bb9534107f786",
      "name": "전준호",
      "team": "LG",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "25",
        "Pos.": "C",
        "oWAR": "-0.03",
        "dWAR": "0.01",
        "G": "3",
        "PA": "1",
        "ePA": "1",
        "AB": "1",
        "R": "0",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.000",
        "SLG": "0.000",
        "OPS": "0.000",
        "R/ePA": "-0.190",
        "wRC+": "-134.5",
        "WAR": "-0.01"
      }
    }
  ],
  [
    "650387f88e07a55b41b4a98b0a2d555015890399",
    {
      "id": "650387f88e07a55b41b4a98b0a2d555015890399",
      "name": "엔스",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "32",
        "Pos.": "P",
        "G": "30",
        "GS": "30",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "13",
        "L": "6",
        "S": "0",
        "HD": "0",
        "IP": "167.2",
        "ER": "78",
        "R": "84",
        "rRA": "83.65",
        "TBF": "714",
        "H": "169",
        "2B": "29",
        "3B": "3",
        "HR": "16",
        "BB": "50",
        "HP": "6",
        "IB": "0",
        "SO": "157",
        "ROE": "9",
        "BK": "0",
        "WP": "4",
        "ERA": "4.19",
        "RA9": "4.51",
        "rRA9": "4.49",
        "rRA9pf": "4.64",
        "FIP": "4.03",
        "WHIP": "1.31",
        "WAR": "4.33"
      }
    }
  ],
  [
    "b964b6f5bcfa792fa5d27666c25f83a5a6b4aba5",
    {
      "id": "b964b6f5bcfa792fa5d27666c25f83a5a6b4aba5",
      "name": "김영준",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "24",
        "Pos.": "P",
        "G": "14",
        "GS": "0",
        "GR": "14",
        "GF": "5",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "15.1",
        "ER": "14",
        "R": "17",
        "rRA": "16.65",
        "TBF": "76",
        "H": "21",
        "2B": "3",
        "3B": "0",
        "HR": "2",
        "BB": "9",
        "HP": "0",
        "IB": "1",
        "SO": "11",
        "ROE": "3",
        "BK": "0",
        "WP": "2",
        "ERA": "8.22",
        "RA9": "9.98",
        "rRA9": "9.77",
        "rRA9pf": "10.23",
        "FIP": "5.58",
        "WHIP": "1.96",
        "WAR": "-0.33"
      }
    }
  ],
  [
    "ccf4d1bc6da3dc4a416d9d4f8c9f303608fa0be0",
    {
      "id": "ccf4d1bc6da3dc4a416d9d4f8c9f303608fa0be0",
      "name": "김성진",
      "team": "LG",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "23",
        "Pos.": "1B",
        "oWAR": "0.01",
        "dWAR": "-0.07",
        "G": "14",
        "PA": "16",
        "ePA": "16",
        "AB": "15",
        "R": "1",
        "H": "4",
        "2B": "0",
        "3B": "0",
        "HR": "1",
        "TB": "7",
        "RBI": "2",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "4",
        "GDP": "1",
        "SH": "0",
        "SF": "0",
        "AVG": "0.267",
        "OBP": "0.313",
        "SLG": "0.467",
        "OPS": "0.780",
        "R/ePA": "0.123",
        "wRC+": "94.7",
        "WAR": "-0.07"
      }
    }
  ],
  [
    "e7e560f73eff299559a021e0be546f04dfcf5417",
    {
      "id": "e7e560f73eff299559a021e0be546f04dfcf5417",
      "name": "이믿음",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "23",
        "Pos.": "P",
        "G": "1",
        "GS": "1",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "4.0",
        "ER": "7",
        "R": "7",
        "rRA": "7.00",
        "TBF": "27",
        "H": "11",
        "2B": "2",
        "3B": "0",
        "HR": "0",
        "BB": "4",
        "HP": "0",
        "IB": "0",
        "SO": "2",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "15.75",
        "RA9": "15.75",
        "rRA9": "15.75",
        "rRA9pf": "16.98",
        "FIP": "6.05",
        "WHIP": "3.75",
        "WAR": "-0.27"
      }
    }
  ],
  [
    "7b4efb98a2df661b3fda9d0b131bdbddeefdf6be",
    {
      "id": "7b4efb98a2df661b3fda9d0b131bdbddeefdf6be",
      "name": "최원영",
      "team": "LG",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "20",
        "Pos.": "LF",
        "oWAR": "0.17",
        "dWAR": "0.00",
        "G": "57",
        "PA": "41",
        "ePA": "41",
        "AB": "37",
        "R": "18",
        "H": "10",
        "2B": "2",
        "3B": "0",
        "HR": "1",
        "TB": "15",
        "RBI": "5",
        "SB": "6",
        "CS": "4",
        "BB": "1",
        "HP": "3",
        "IB": "0",
        "SO": "7",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.270",
        "OBP": "0.341",
        "SLG": "0.405",
        "OPS": "0.746",
        "R/ePA": "0.134",
        "wRC+": "102.6",
        "WAR": "0.17"
      }
    }
  ],
  [
    "71981c2b3c71d85e9b10048d04236908af738351",
    {
      "id": "71981c2b3c71d85e9b10048d04236908af738351",
      "name": "박명근",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "19",
        "Pos.": "P",
        "G": "33",
        "GS": "0",
        "GR": "33",
        "GF": "3",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "2",
        "S": "1",
        "HD": "8",
        "IP": "25.1",
        "ER": "18",
        "R": "20",
        "rRA": "20.65",
        "TBF": "120",
        "H": "29",
        "2B": "4",
        "3B": "0",
        "HR": "6",
        "BB": "12",
        "HP": "3",
        "IB": "0",
        "SO": "13",
        "ROE": "3",
        "BK": "0",
        "WP": "0",
        "ERA": "6.39",
        "RA9": "7.11",
        "rRA9": "7.34",
        "rRA9pf": "7.79",
        "FIP": "7.57",
        "WHIP": "1.62",
        "WAR": "-0.34"
      }
    }
  ],
  [
    "68c2887a01de111068bb79d227e583c8928207fe",
    {
      "id": "68c2887a01de111068bb79d227e583c8928207fe",
      "name": "이종준",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "22",
        "Pos.": "P",
        "G": "27",
        "GS": "0",
        "GR": "27",
        "GF": "13",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "2",
        "S": "0",
        "HD": "1",
        "IP": "26.0",
        "ER": "12",
        "R": "13",
        "rRA": "12.65",
        "TBF": "119",
        "H": "26",
        "2B": "5",
        "3B": "0",
        "HR": "4",
        "BB": "16",
        "HP": "0",
        "IB": "0",
        "SO": "28",
        "ROE": "1",
        "BK": "0",
        "WP": "1",
        "ERA": "4.15",
        "RA9": "4.50",
        "rRA9": "4.38",
        "rRA9pf": "4.50",
        "FIP": "5.25",
        "WHIP": "1.62",
        "WAR": "0.52"
      }
    }
  ],
  [
    "98e734332524d9483c692a5ad557c708a0d559e5",
    {
      "id": "98e734332524d9483c692a5ad557c708a0d559e5",
      "name": "김진성",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "38",
        "Pos.": "P",
        "G": "71",
        "GS": "0",
        "GR": "71",
        "GF": "6",
        "CG": "0",
        "SHO": "0",
        "W": "3",
        "L": "3",
        "S": "1",
        "HD": "27",
        "IP": "70.1",
        "ER": "31",
        "R": "34",
        "rRA": "38.35",
        "TBF": "294",
        "H": "62",
        "2B": "11",
        "3B": "1",
        "HR": "11",
        "BB": "23",
        "HP": "7",
        "IB": "3",
        "SO": "61",
        "ROE": "4",
        "BK": "0",
        "WP": "2",
        "ERA": "3.97",
        "RA9": "4.35",
        "rRA9": "4.91",
        "rRA9pf": "5.13",
        "FIP": "5.17",
        "WHIP": "1.21",
        "WAR": "1.23"
      }
    }
  ],
  [
    "dab7f997a42a5b17d01c36fbe5e5edaed40b75a8",
    {
      "id": "dab7f997a42a5b17d01c36fbe5e5edaed40b75a8",
      "name": "김태우",
      "team": "LG",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "26",
        "Pos.": "2B",
        "oWAR": "-0.08",
        "dWAR": "-0.06",
        "G": "4",
        "PA": "3",
        "ePA": "3",
        "AB": "3",
        "R": "0",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "2",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.000",
        "SLG": "0.000",
        "OPS": "0.000",
        "R/ePA": "-0.190",
        "wRC+": "-136.0",
        "WAR": "-0.13"
      }
    }
  ],
  [
    "0f50c4dae7e04efdca961367e95386d995c1e29b",
    {
      "id": "0f50c4dae7e04efdca961367e95386d995c1e29b",
      "name": "김성우",
      "team": "LG",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "20",
        "Pos.": "C",
        "oWAR": "-0.12",
        "dWAR": "-0.14",
        "G": "4",
        "PA": "5",
        "ePA": "5",
        "AB": "5",
        "R": "0",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "4",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.000",
        "SLG": "0.000",
        "OPS": "0.000",
        "R/ePA": "-0.190",
        "wRC+": "-139.9",
        "WAR": "-0.26"
      }
    }
  ],
  [
    "35bfa1c75c4c9304aeeebe8999de9a3feaf095df",
    {
      "id": "35bfa1c75c4c9304aeeebe8999de9a3feaf095df",
      "name": "김진수",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "25",
        "Pos.": "P",
        "G": "8",
        "GS": "0",
        "GR": "8",
        "GF": "3",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "13.1",
        "ER": "10",
        "R": "10",
        "rRA": "9.15",
        "TBF": "58",
        "H": "17",
        "2B": "2",
        "3B": "0",
        "HR": "3",
        "BB": "4",
        "HP": "0",
        "IB": "0",
        "SO": "6",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "6.75",
        "RA9": "6.75",
        "rRA9": "6.18",
        "rRA9pf": "6.15",
        "FIP": "6.68",
        "WHIP": "1.58",
        "WAR": "0.09"
      }
    }
  ],
  [
    "2d9d11d731068a6aaf3ffb98298fd51e1d29797f",
    {
      "id": "2d9d11d731068a6aaf3ffb98298fd51e1d29797f",
      "name": "강효종",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "21",
        "Pos.": "P",
        "G": "1",
        "GS": "1",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "1.1",
        "ER": "3",
        "R": "3",
        "rRA": "3.00",
        "TBF": "9",
        "H": "2",
        "2B": "0",
        "3B": "0",
        "HR": "1",
        "BB": "3",
        "HP": "0",
        "IB": "0",
        "SO": "2",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "20.25",
        "RA9": "20.25",
        "rRA9": "20.25",
        "rRA9pf": "18.46",
        "FIP": "16.35",
        "WHIP": "3.75",
        "WAR": "-0.10"
      }
    }
  ],
  [
    "d717c10d4c6ba4f4774ca7c8c184b577d8feb339",
    {
      "id": "d717c10d4c6ba4f4774ca7c8c184b577d8feb339",
      "name": "김윤식",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "23",
        "Pos.": "P",
        "G": "1",
        "GS": "1",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "3.0",
        "ER": "4",
        "R": "4",
        "rRA": "4.00",
        "TBF": "17",
        "H": "7",
        "2B": "2",
        "3B": "0",
        "HR": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "ROE": "1",
        "BK": "0",
        "WP": "0",
        "ERA": "12.00",
        "RA9": "12.00",
        "rRA9": "12.00",
        "rRA9pf": "12.94",
        "FIP": "4.40",
        "WHIP": "2.67",
        "WAR": "-0.11"
      }
    }
  ],
  [
    "0b8396e90d1030a100e0e8b86effe829a3786b66",
    {
      "id": "0b8396e90d1030a100e0e8b86effe829a3786b66",
      "name": "진우영",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "22",
        "Pos.": "P",
        "G": "6",
        "GS": "0",
        "GR": "6",
        "GF": "6",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "7.2",
        "ER": "4",
        "R": "4",
        "rRA": "4.00",
        "TBF": "35",
        "H": "8",
        "2B": "3",
        "3B": "0",
        "HR": "1",
        "BB": "4",
        "HP": "0",
        "IB": "0",
        "SO": "6",
        "ROE": "0",
        "BK": "0",
        "WP": "2",
        "ERA": "4.70",
        "RA9": "4.70",
        "rRA9": "4.70",
        "rRA9pf": "4.89",
        "FIP": "5.41",
        "WHIP": "1.57",
        "WAR": "0.12"
      }
    }
  ],
  [
    "a91f0ec45fc43f37ac3fde1f3600256a99122cfc",
    {
      "id": "a91f0ec45fc43f37ac3fde1f3600256a99122cfc",
      "name": "정지헌",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "20",
        "Pos.": "P",
        "G": "17",
        "GS": "0",
        "GR": "17",
        "GF": "4",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "1",
        "IP": "12.1",
        "ER": "16",
        "R": "18",
        "rRA": "15.65",
        "TBF": "69",
        "H": "19",
        "2B": "2",
        "3B": "0",
        "HR": "2",
        "BB": "11",
        "HP": "3",
        "IB": "0",
        "SO": "11",
        "ROE": "2",
        "BK": "0",
        "WP": "3",
        "ERA": "11.68",
        "RA9": "13.14",
        "rRA9": "11.42",
        "rRA9pf": "11.87",
        "FIP": "7.42",
        "WHIP": "2.43",
        "WAR": "-0.41"
      }
    }
  ],
  [
    "60b5003625f60d7543b37673d920947f557a924a",
    {
      "id": "60b5003625f60d7543b37673d920947f557a924a",
      "name": "이지강",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "24",
        "Pos.": "P",
        "G": "46",
        "GS": "2",
        "GR": "44",
        "GF": "6",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "3",
        "S": "1",
        "HD": "1",
        "IP": "53.2",
        "ER": "27",
        "R": "35",
        "rRA": "38.75",
        "TBF": "250",
        "H": "61",
        "2B": "9",
        "3B": "0",
        "HR": "5",
        "BB": "26",
        "HP": "2",
        "IB": "0",
        "SO": "39",
        "ROE": "5",
        "BK": "0",
        "WP": "5",
        "ERA": "4.53",
        "RA9": "5.87",
        "rRA9": "6.50",
        "rRA9pf": "6.69",
        "FIP": "5.11",
        "WHIP": "1.62",
        "WAR": "0.11"
      }
    }
  ],
  [
    "3dfc136f201fb10a250d8cdbe1fa96a65adb1844",
    {
      "id": "3dfc136f201fb10a250d8cdbe1fa96a65adb1844",
      "name": "홍창기",
      "team": "LG",
      "position": "RF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "30",
        "Pos.": "RF",
        "oWAR": "4.48",
        "dWAR": "0.59",
        "G": "139",
        "PA": "637",
        "ePA": "635",
        "AB": "524",
        "R": "96",
        "H": "176",
        "2B": "18",
        "3B": "3",
        "HR": "5",
        "TB": "215",
        "RBI": "73",
        "SB": "10",
        "CS": "11",
        "BB": "96",
        "HP": "12",
        "IB": "3",
        "SO": "93",
        "GDP": "8",
        "SH": "1",
        "SF": "4",
        "AVG": "0.336",
        "OBP": "0.447",
        "SLG": "0.410",
        "OPS": "0.857",
        "R/ePA": "0.185",
        "wRC+": "142.3",
        "WAR": "5.06"
      }
    }
  ],
  [
    "c95278001a8cfdc37442f54b044f42601858b63b",
    {
      "id": "c95278001a8cfdc37442f54b044f42601858b63b",
      "name": "김주온",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "27",
        "Pos.": "P",
        "G": "2",
        "GS": "0",
        "GR": "2",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "4.2",
        "ER": "8",
        "R": "8",
        "rRA": "9.55",
        "TBF": "25",
        "H": "8",
        "2B": "1",
        "3B": "0",
        "HR": "2",
        "BB": "3",
        "HP": "0",
        "IB": "0",
        "SO": "4",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "15.43",
        "RA9": "15.43",
        "rRA9": "18.42",
        "rRA9pf": "18.21",
        "FIP": "9.15",
        "WHIP": "2.36",
        "WAR": "-0.26"
      }
    }
  ],
  [
    "5217e78dbadd1b201be32277dc478ce24d8cc8fd",
    {
      "id": "5217e78dbadd1b201be32277dc478ce24d8cc8fd",
      "name": "이재원",
      "team": "LG",
      "position": "RF",
      "record": {
        "Div.": "베스트",
        "Year": "2022",
        "Team": "LG",
        "Age": "22",
        "Pos.": "DH",
        "oWAR": "1.42",
        "dWAR": "-0.21",
        "G": "85",
        "PA": "253",
        "ePA": "253",
        "AB": "223",
        "R": "31",
        "H": "50",
        "2B": "8",
        "3B": "2",
        "HR": "13",
        "TB": "101",
        "RBI": "43",
        "SB": "3",
        "CS": "1",
        "BB": "18",
        "HP": "12",
        "IB": "2",
        "SO": "77",
        "GDP": "3",
        "SH": "0",
        "SF": "0",
        "AVG": "0.224",
        "OBP": "0.316",
        "SLG": "0.453",
        "OPS": "0.769",
        "R/ePA": "0.137",
        "wRC+": "121.6",
        "WAR": "1.22"
      }
    }
  ],
  [
    "ca9809d319eb74d3ac14d6a8fd79fbc5d1dd1fa0",
    {
      "id": "ca9809d319eb74d3ac14d6a8fd79fbc5d1dd1fa0",
      "name": "김민수",
      "team": "LG",
      "position": "3B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "25",
        "Pos.": "3B",
        "oWAR": "-0.22",
        "dWAR": "-0.11",
        "G": "19",
        "PA": "22",
        "ePA": "22",
        "AB": "20",
        "R": "1",
        "H": "3",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "3",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "1",
        "IB": "0",
        "SO": "4",
        "GDP": "1",
        "SH": "0",
        "SF": "0",
        "AVG": "0.150",
        "OBP": "0.227",
        "SLG": "0.150",
        "OPS": "0.377",
        "R/ePA": "-0.004",
        "wRC+": "-3.4",
        "WAR": "-0.33"
      }
    }
  ],
  [
    "f56da100d76ee6c5972d65e6621824490be5e4ba",
    {
      "id": "f56da100d76ee6c5972d65e6621824490be5e4ba",
      "name": "유영찬",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "26",
        "Pos.": "P",
        "G": "62",
        "GS": "0",
        "GR": "62",
        "GF": "50",
        "CG": "0",
        "SHO": "0",
        "W": "7",
        "L": "5",
        "S": "26",
        "HD": "1",
        "IP": "63.2",
        "ER": "21",
        "R": "24",
        "rRA": "35.50",
        "TBF": "286",
        "H": "61",
        "2B": "15",
        "3B": "0",
        "HR": "2",
        "BB": "30",
        "HP": "3",
        "IB": "3",
        "SO": "77",
        "ROE": "3",
        "BK": "2",
        "WP": "5",
        "ERA": "2.97",
        "RA9": "3.39",
        "rRA9": "5.02",
        "rRA9pf": "5.22",
        "FIP": "3.06",
        "WHIP": "1.43",
        "WAR": "1.06"
      }
    }
  ],
  [
    "3d9d469a26270932756ac361674be614f35f7d51",
    {
      "id": "3d9d469a26270932756ac361674be614f35f7d51",
      "name": "김범석",
      "team": "LG",
      "position": "DH",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "19",
        "Pos.": "DH",
        "oWAR": "-0.25",
        "dWAR": "-0.08",
        "G": "70",
        "PA": "180",
        "ePA": "179",
        "AB": "162",
        "R": "14",
        "H": "39",
        "2B": "4",
        "3B": "0",
        "HR": "6",
        "TB": "61",
        "RBI": "24",
        "SB": "0",
        "CS": "0",
        "BB": "14",
        "HP": "2",
        "IB": "2",
        "SO": "49",
        "GDP": "4",
        "SH": "0",
        "SF": "2",
        "AVG": "0.241",
        "OBP": "0.306",
        "SLG": "0.377",
        "OPS": "0.683",
        "R/ePA": "0.095",
        "wRC+": "71.7",
        "WAR": "-0.33"
      }
    }
  ],
  [
    "2deec15d29ef611c85808925b080ae664e40bbe2",
    {
      "id": "2deec15d29ef611c85808925b080ae664e40bbe2",
      "name": "손용준",
      "team": "LG",
      "position": "SS",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "d7fc1d6f589cbd96248606f1ea3c944140fdefeb",
    {
      "id": "d7fc1d6f589cbd96248606f1ea3c944140fdefeb",
      "name": "최명경",
      "team": "LG",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "22",
        "Pos.": "3B",
        "oWAR": "0.00",
        "dWAR": "-0.06",
        "G": "1",
        "PA": "3",
        "ePA": "3",
        "AB": "3",
        "R": "0",
        "H": "1",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "1",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.333",
        "OBP": "0.333",
        "SLG": "0.333",
        "OPS": "0.666",
        "R/ePA": "0.089",
        "wRC+": "63.3",
        "WAR": "-0.06"
      }
    }
  ],
  [
    "392064b87a655072d70295b383053501f9876724",
    {
      "id": "392064b87a655072d70295b383053501f9876724",
      "name": "성동현",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "24",
        "Pos.": "P",
        "G": "2",
        "GS": "0",
        "GR": "2",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "2.0",
        "ER": "0",
        "R": "0",
        "rRA": "0.00",
        "TBF": "8",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "BB": "3",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "0.00",
        "RA9": "0.00",
        "rRA9": "0.00",
        "rRA9pf": "0.00",
        "FIP": "7.60",
        "WHIP": "1.50",
        "WAR": "0.08"
      }
    }
  ],
  [
    "29dc4ad51f33fa47f82566baa3f15d6671241332",
    {
      "id": "29dc4ad51f33fa47f82566baa3f15d6671241332",
      "name": "임준형",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "23",
        "Pos.": "P",
        "G": "18",
        "GS": "1",
        "GR": "17",
        "GF": "3",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "13.2",
        "ER": "10",
        "R": "10",
        "rRA": "7.95",
        "TBF": "62",
        "H": "16",
        "2B": "4",
        "3B": "0",
        "HR": "2",
        "BB": "4",
        "HP": "3",
        "IB": "0",
        "SO": "13",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "6.59",
        "RA9": "6.59",
        "rRA9": "5.24",
        "rRA9pf": "5.48",
        "FIP": "5.15",
        "WHIP": "1.46",
        "WAR": "0.19"
      }
    }
  ],
  [
    "25ac15991f37a1b4d0b08a5b998ff27be10a9777",
    {
      "id": "25ac15991f37a1b4d0b08a5b998ff27be10a9777",
      "name": "우강훈",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "21",
        "Pos.": "P",
        "G": "13",
        "GS": "0",
        "GR": "13",
        "GF": "5",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "11.2",
        "ER": "3",
        "R": "5",
        "rRA": "5.20",
        "TBF": "54",
        "H": "11",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "BB": "6",
        "HP": "5",
        "IB": "0",
        "SO": "10",
        "ROE": "0",
        "BK": "0",
        "WP": "2",
        "ERA": "2.31",
        "RA9": "3.86",
        "rRA9": "4.01",
        "rRA9pf": "4.11",
        "FIP": "4.98",
        "WHIP": "1.46",
        "WAR": "0.27"
      }
    }
  ],
  [
    "d670734c101e8926744d667a7b7700e4ca3900ae",
    {
      "id": "d670734c101e8926744d667a7b7700e4ca3900ae",
      "name": "최승민",
      "team": "LG",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "27",
        "Pos.": "LF",
        "oWAR": "-0.29",
        "dWAR": "0.20",
        "G": "57",
        "PA": "16",
        "ePA": "15",
        "AB": "13",
        "R": "20",
        "H": "1",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "1",
        "RBI": "1",
        "SB": "11",
        "CS": "7",
        "BB": "2",
        "HP": "0",
        "IB": "0",
        "SO": "3",
        "GDP": "0",
        "SH": "1",
        "SF": "0",
        "AVG": "0.077",
        "OBP": "0.200",
        "SLG": "0.077",
        "OPS": "0.277",
        "R/ePA": "-0.037",
        "wRC+": "-28.6",
        "WAR": "-0.09"
      }
    }
  ],
  [
    "ccc42b2c00c17b3a934152f6fbb0835368e1be44",
    {
      "id": "ccc42b2c00c17b3a934152f6fbb0835368e1be44",
      "name": "김대원",
      "team": "LG",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "22",
        "Pos.": "DH",
        "oWAR": "0.06",
        "dWAR": "-0.02",
        "G": "19",
        "PA": "4",
        "ePA": "4",
        "AB": "4",
        "R": "5",
        "H": "2",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "2",
        "RBI": "0",
        "SB": "2",
        "CS": "1",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.500",
        "OBP": "0.500",
        "SLG": "0.500",
        "OPS": "1.000",
        "R/ePA": "0.229",
        "wRC+": "174.5",
        "WAR": "0.04"
      }
    }
  ],
  [
    "ad07027a76c6b7b0e109f85d955fd0cfa753d0b1",
    {
      "id": "ad07027a76c6b7b0e109f85d955fd0cfa753d0b1",
      "name": "김현종",
      "team": "LG",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "LG",
        "Age": "19",
        "Pos.": "CF",
        "oWAR": "-0.03",
        "dWAR": "0.09",
        "G": "17",
        "PA": "16",
        "ePA": "16",
        "AB": "15",
        "R": "8",
        "H": "3",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "TB": "4",
        "RBI": "2",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "1",
        "IB": "0",
        "SO": "4",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.200",
        "OBP": "0.250",
        "SLG": "0.267",
        "OPS": "0.517",
        "R/ePA": "0.042",
        "wRC+": "31.9",
        "WAR": "0.06"
      }
    }
  ],
  [
    "b1b89e3b686588bffd83d20e89be38bd13debd5d",
    {
      "id": "b1b89e3b686588bffd83d20e89be38bd13debd5d",
      "name": "강민",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "1f153e482f6a9ae93ff62fa0bb1ca32b4dc0b2f6",
    {
      "id": "1f153e482f6a9ae93ff62fa0bb1ca32b4dc0b2f6",
      "name": "조건희",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "e4b86dfd6d523e2b951a8493cb87f57d3e23833b",
    {
      "id": "e4b86dfd6d523e2b951a8493cb87f57d3e23833b",
      "name": "김형욱",
      "team": "LG",
      "position": "1B",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "54fffb1c0a89268c055039c7e82f242901d9eb9e",
    {
      "id": "54fffb1c0a89268c055039c7e82f242901d9eb9e",
      "name": "김종우",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "68cb7909b57567c00005e59c0d937e713419a358",
    {
      "id": "68cb7909b57567c00005e59c0d937e713419a358",
      "name": "강석현",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "11d60da2dab45aab1419a8129c0da8180cad4f2f",
    {
      "id": "11d60da2dab45aab1419a8129c0da8180cad4f2f",
      "name": "오승윤",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "8a34b3081a0aaed4bb49f913327669d7a78daaea",
    {
      "id": "8a34b3081a0aaed4bb49f913327669d7a78daaea",
      "name": "박민호",
      "team": "LG",
      "position": "C",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "8999efa29ee21fc6e82d18204d15c088442d146d",
    {
      "id": "8999efa29ee21fc6e82d18204d15c088442d146d",
      "name": "김도윤",
      "team": "LG",
      "position": "2B",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "2a133c385a32c6147fa73cc46b7bd90cccabb17a",
    {
      "id": "2a133c385a32c6147fa73cc46b7bd90cccabb17a",
      "name": "심규빈",
      "team": "LG",
      "position": "LF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "36983eba3c54d78e696c1f5495f6c8091d6b8a1b",
    {
      "id": "36983eba3c54d78e696c1f5495f6c8091d6b8a1b",
      "name": "김단우",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "6ee63994673843ac35595d183c401de2c5fa5966",
    {
      "id": "6ee63994673843ac35595d183c401de2c5fa5966",
      "name": "이태겸",
      "team": "LG",
      "position": "RF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "df546f7588b516b5f34fc650ce2ba64bb1259329",
    {
      "id": "df546f7588b516b5f34fc650ce2ba64bb1259329",
      "name": "허용주",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "e64024ab7396160ed5326a0a99573b0cd5266e6e",
    {
      "id": "e64024ab7396160ed5326a0a99573b0cd5266e6e",
      "name": "한지용",
      "team": "LG",
      "position": "C",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "ae9e5190b5d9f0b9bcf9a24436c8c7b296cacddb",
    {
      "id": "ae9e5190b5d9f0b9bcf9a24436c8c7b296cacddb",
      "name": "백선기",
      "team": "LG",
      "position": "LF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "890ecc8ab3802057d3c209c3fda62d2d6b470e02",
    {
      "id": "890ecc8ab3802057d3c209c3fda62d2d6b470e02",
      "name": "김의준",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "a638124288fbddd1d7a5138c222985f5838e80a4",
    {
      "id": "a638124288fbddd1d7a5138c222985f5838e80a4",
      "name": "배강",
      "team": "LG",
      "position": "C",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "7fcb60fa744af91d357026bf85b34ee23b29d6ea",
    {
      "id": "7fcb60fa744af91d357026bf85b34ee23b29d6ea",
      "name": "김유민",
      "team": "LG",
      "position": "2B",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "62474db38a9a33756ba4289fee843f6bf363cc97",
    {
      "id": "62474db38a9a33756ba4289fee843f6bf363cc97",
      "name": "하영진",
      "team": "LG",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "13aac9fba96c507928deb98b0b4a3130156d37b5",
    {
      "id": "13aac9fba96c507928deb98b0b4a3130156d37b5",
      "name": "박치국",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "25",
        "Pos.": "P",
        "G": "52",
        "GS": "0",
        "GR": "52",
        "GF": "15",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "3",
        "S": "1",
        "HD": "3",
        "IP": "48.0",
        "ER": "34",
        "R": "34",
        "rRA": "35.05",
        "TBF": "222",
        "H": "59",
        "2B": "6",
        "3B": "0",
        "HR": "8",
        "BB": "18",
        "HP": "10",
        "IB": "1",
        "SO": "35",
        "ROE": "1",
        "BK": "1",
        "WP": "1",
        "ERA": "6.38",
        "RA9": "6.38",
        "rRA9": "6.57",
        "rRA9pf": "6.70",
        "FIP": "6.10",
        "WHIP": "1.60",
        "WAR": "0.03"
      }
    }
  ],
  [
    "7c5235c576f84c18f055d8b797d845f47f0d8e2d",
    {
      "id": "7c5235c576f84c18f055d8b797d845f47f0d8e2d",
      "name": "박지훈",
      "team": "두산",
      "position": "2B",
      "record": {
        "Div.": "베스트",
        "Year": "2021",
        "Team": "두산",
        "Age": "20",
        "Pos.": "2B",
        "oWAR": "0.07",
        "dWAR": "0.18",
        "G": "34",
        "PA": "14",
        "ePA": "14",
        "AB": "12",
        "R": "6",
        "H": "4",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "TB": "5",
        "RBI": "2",
        "SB": "3",
        "CS": "1",
        "BB": "1",
        "HP": "1",
        "IB": "0",
        "SO": "3",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.333",
        "OBP": "0.429",
        "SLG": "0.417",
        "OPS": "0.846",
        "R/ePA": "0.176",
        "wRC+": "156.6",
        "WAR": "0.25"
      }
    }
  ],
  [
    "35004d48525073db9ee05d82def2a466d021d70b",
    {
      "id": "35004d48525073db9ee05d82def2a466d021d70b",
      "name": "이주엽",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2020",
        "Team": "두산",
        "Age": "18",
        "Pos.": "P",
        "G": "4",
        "GS": "0",
        "GR": "4",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "3.1",
        "ER": "3",
        "R": "3",
        "rRA": "3.00",
        "TBF": "19",
        "H": "3",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "BB": "7",
        "HP": "0",
        "IB": "0",
        "SO": "2",
        "ROE": "0",
        "BK": "0",
        "WP": "1",
        "ERA": "8.10",
        "RA9": "8.10",
        "rRA9": "8.10",
        "rRA9pf": "7.28",
        "FIP": "9.01",
        "WHIP": "3.00",
        "WAR": "0.01"
      }
    }
  ],
  [
    "e34422c32cb61795a0cae2928786f4dd369cad77",
    {
      "id": "e34422c32cb61795a0cae2928786f4dd369cad77",
      "name": "라모스",
      "team": "두산",
      "position": "RF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "31",
        "Pos.": "RF",
        "oWAR": "1.90",
        "dWAR": "-0.22",
        "G": "80",
        "PA": "348",
        "ePA": "346",
        "AB": "311",
        "R": "43",
        "H": "95",
        "2B": "19",
        "3B": "3",
        "HR": "10",
        "TB": "150",
        "RBI": "48",
        "SB": "3",
        "CS": "3",
        "BB": "27",
        "HP": "3",
        "IB": "1",
        "SO": "60",
        "GDP": "5",
        "SH": "1",
        "SF": "6",
        "AVG": "0.305",
        "OBP": "0.360",
        "SLG": "0.482",
        "OPS": "0.842",
        "R/ePA": "0.154",
        "wRC+": "119.6",
        "WAR": "1.68"
      }
    }
  ],
  [
    "111f2361880f657827506019c0716715790ea341",
    {
      "id": "111f2361880f657827506019c0716715790ea341",
      "name": "여동건",
      "team": "두산",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "18",
        "Pos.": "2B",
        "oWAR": "-0.02",
        "dWAR": "-0.01",
        "G": "9",
        "PA": "11",
        "ePA": "11",
        "AB": "10",
        "R": "2",
        "H": "4",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "4",
        "RBI": "0",
        "SB": "3",
        "CS": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "2",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.400",
        "OBP": "0.455",
        "SLG": "0.400",
        "OPS": "0.855",
        "R/ePA": "0.181",
        "wRC+": "140.6",
        "WAR": "-0.03"
      }
    }
  ],
  [
    "b53022b95806a7e502b65c7471baea11d6cd2ee3",
    {
      "id": "b53022b95806a7e502b65c7471baea11d6cd2ee3",
      "name": "오명진",
      "team": "두산",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "22",
        "Pos.": "2B",
        "oWAR": "0.01",
        "dWAR": "-0.02",
        "G": "2",
        "PA": "2",
        "ePA": "2",
        "AB": "1",
        "R": "0",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "1",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.500",
        "SLG": "0.000",
        "OPS": "0.500",
        "R/ePA": "0.173",
        "wRC+": "130.2",
        "WAR": "-0.01"
      }
    }
  ],
  [
    "23092a81515585f5528eddf945cd6cee698a589d",
    {
      "id": "23092a81515585f5528eddf945cd6cee698a589d",
      "name": "이유찬",
      "team": "두산",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "25",
        "Pos.": "3B",
        "oWAR": "0.67",
        "dWAR": "0.33",
        "G": "103",
        "PA": "262",
        "ePA": "255",
        "AB": "231",
        "R": "39",
        "H": "64",
        "2B": "11",
        "3B": "0",
        "HR": "3",
        "TB": "84",
        "RBI": "23",
        "SB": "16",
        "CS": "6",
        "BB": "22",
        "HP": "1",
        "IB": "0",
        "SO": "51",
        "GDP": "9",
        "SH": "7",
        "SF": "1",
        "AVG": "0.277",
        "OBP": "0.341",
        "SLG": "0.364",
        "OPS": "0.705",
        "R/ePA": "0.115",
        "wRC+": "87.9",
        "WAR": "1.01"
      }
    }
  ],
  [
    "f3afc73a740de185398031fe3480f5f1b0455e84",
    {
      "id": "f3afc73a740de185398031fe3480f5f1b0455e84",
      "name": "김태근",
      "team": "두산",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "27",
        "Pos.": "LF",
        "oWAR": "-0.50",
        "dWAR": "-0.20",
        "G": "43",
        "PA": "27",
        "ePA": "26",
        "AB": "23",
        "R": "7",
        "H": "2",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "2",
        "RBI": "1",
        "SB": "0",
        "CS": "1",
        "BB": "0",
        "HP": "3",
        "IB": "0",
        "SO": "10",
        "GDP": "0",
        "SH": "1",
        "SF": "0",
        "AVG": "0.087",
        "OBP": "0.192",
        "SLG": "0.087",
        "OPS": "0.279",
        "R/ePA": "-0.028",
        "wRC+": "-21.7",
        "WAR": "-0.70"
      }
    }
  ],
  [
    "415e60af0f0f7d86768c83e46e5e8ab98e177922",
    {
      "id": "415e60af0f0f7d86768c83e46e5e8ab98e177922",
      "name": "박준영",
      "team": "두산",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "26",
        "Pos.": "SS",
        "oWAR": "0.91",
        "dWAR": "0.56",
        "G": "65",
        "PA": "212",
        "ePA": "211",
        "AB": "186",
        "R": "25",
        "H": "42",
        "2B": "9",
        "3B": "2",
        "HR": "7",
        "TB": "76",
        "RBI": "28",
        "SB": "2",
        "CS": "0",
        "BB": "21",
        "HP": "3",
        "IB": "0",
        "SO": "58",
        "GDP": "3",
        "SH": "1",
        "SF": "1",
        "AVG": "0.226",
        "OBP": "0.313",
        "SLG": "0.409",
        "OPS": "0.722",
        "R/ePA": "0.114",
        "wRC+": "86.6",
        "WAR": "1.47"
      }
    }
  ],
  [
    "317ef2d23a434cc47d0ae98a46bc7d1df3a3535d",
    {
      "id": "317ef2d23a434cc47d0ae98a46bc7d1df3a3535d",
      "name": "김민혁",
      "team": "두산",
      "position": "1B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "27",
        "Pos.": "DH",
        "oWAR": "0.19",
        "dWAR": "0.00",
        "G": "5",
        "PA": "8",
        "ePA": "8",
        "AB": "5",
        "R": "1",
        "H": "1",
        "2B": "0",
        "3B": "0",
        "HR": "1",
        "TB": "4",
        "RBI": "2",
        "SB": "0",
        "CS": "0",
        "BB": "3",
        "HP": "0",
        "IB": "0",
        "SO": "3",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.200",
        "OBP": "0.500",
        "SLG": "0.800",
        "OPS": "1.300",
        "R/ePA": "0.303",
        "wRC+": "237.7",
        "WAR": "0.19"
      }
    }
  ],
  [
    "eae662a97d125baf22c48da98ca6321322118839",
    {
      "id": "eae662a97d125baf22c48da98ca6321322118839",
      "name": "시라카와",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "22",
        "Pos.": "P",
        "G": "7",
        "GS": "7",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "3",
        "S": "0",
        "HD": "0",
        "IP": "34.1",
        "ER": "23",
        "R": "25",
        "rRA": "23.45",
        "TBF": "158",
        "H": "30",
        "2B": "2",
        "3B": "1",
        "HR": "3",
        "BB": "24",
        "HP": "2",
        "IB": "0",
        "SO": "19",
        "ROE": "3",
        "BK": "0",
        "WP": "5",
        "ERA": "6.03",
        "RA9": "6.55",
        "rRA9": "6.15",
        "rRA9pf": "6.57",
        "FIP": "6.20",
        "WHIP": "1.57",
        "WAR": "0.26"
      }
    }
  ],
  [
    "2ef866aeec80c07999b10b98a43970d3015e5195",
    {
      "id": "2ef866aeec80c07999b10b98a43970d3015e5195",
      "name": "박정수",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "27",
        "Pos.": "P",
        "G": "29",
        "GS": "1",
        "GR": "28",
        "GF": "7",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "2",
        "S": "0",
        "HD": "4",
        "IP": "29.2",
        "ER": "17",
        "R": "18",
        "rRA": "19.90",
        "TBF": "137",
        "H": "38",
        "2B": "4",
        "3B": "1",
        "HR": "2",
        "BB": "12",
        "HP": "2",
        "IB": "0",
        "SO": "22",
        "ROE": "0",
        "BK": "0",
        "WP": "2",
        "ERA": "5.16",
        "RA9": "5.46",
        "rRA9": "6.04",
        "rRA9pf": "6.18",
        "FIP": "4.61",
        "WHIP": "1.69",
        "WAR": "0.20"
      }
    }
  ],
  [
    "120c8155547db2f8937e909557260a5045709216",
    {
      "id": "120c8155547db2f8937e909557260a5045709216",
      "name": "허경민",
      "team": "두산",
      "position": "3B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "33",
        "Pos.": "3B",
        "oWAR": "2.99",
        "dWAR": "0.21",
        "G": "115",
        "PA": "477",
        "ePA": "477",
        "AB": "417",
        "R": "69",
        "H": "129",
        "2B": "28",
        "3B": "0",
        "HR": "7",
        "TB": "178",
        "RBI": "61",
        "SB": "5",
        "CS": "1",
        "BB": "36",
        "HP": "18",
        "IB": "0",
        "SO": "25",
        "GDP": "8",
        "SH": "0",
        "SF": "6",
        "AVG": "0.309",
        "OBP": "0.384",
        "SLG": "0.427",
        "OPS": "0.811",
        "R/ePA": "0.160",
        "wRC+": "122.0",
        "WAR": "3.20"
      }
    }
  ],
  [
    "7d33091bb80706bd693218d24d47ce3c9ef27d4c",
    {
      "id": "7d33091bb80706bd693218d24d47ce3c9ef27d4c",
      "name": "박계범",
      "team": "두산",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "27",
        "Pos.": "2B",
        "oWAR": "0.11",
        "dWAR": "-0.44",
        "G": "24",
        "PA": "20",
        "ePA": "20",
        "AB": "15",
        "R": "5",
        "H": "3",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "TB": "4",
        "RBI": "0",
        "SB": "1",
        "CS": "0",
        "BB": "5",
        "HP": "0",
        "IB": "0",
        "SO": "7",
        "GDP": "2",
        "SH": "0",
        "SF": "0",
        "AVG": "0.200",
        "OBP": "0.400",
        "SLG": "0.267",
        "OPS": "0.667",
        "R/ePA": "0.135",
        "wRC+": "102.1",
        "WAR": "-0.33"
      }
    }
  ],
  [
    "ea64784547553619bd140393663d996577c8600e",
    {
      "id": "ea64784547553619bd140393663d996577c8600e",
      "name": "전민재",
      "team": "두산",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "24",
        "Pos.": "SS",
        "oWAR": "-0.39",
        "dWAR": "0.07",
        "G": "100",
        "PA": "276",
        "ePA": "272",
        "AB": "248",
        "R": "34",
        "H": "61",
        "2B": "5",
        "3B": "1",
        "HR": "2",
        "TB": "74",
        "RBI": "32",
        "SB": "7",
        "CS": "3",
        "BB": "17",
        "HP": "4",
        "IB": "1",
        "SO": "53",
        "GDP": "8",
        "SH": "4",
        "SF": "3",
        "AVG": "0.246",
        "OBP": "0.301",
        "SLG": "0.298",
        "OPS": "0.599",
        "R/ePA": "0.071",
        "wRC+": "54.5",
        "WAR": "-0.32"
      }
    }
  ],
  [
    "0976a464b8ca28b93cd5486ef3ae1a8f9e3ff69c",
    {
      "id": "0976a464b8ca28b93cd5486ef3ae1a8f9e3ff69c",
      "name": "서예일",
      "team": "두산",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "30",
        "Pos.": "3B",
        "oWAR": "-0.09",
        "dWAR": "0.12",
        "G": "24",
        "PA": "20",
        "ePA": "20",
        "AB": "18",
        "R": "2",
        "H": "4",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "4",
        "RBI": "1",
        "SB": "0",
        "CS": "0",
        "BB": "2",
        "HP": "0",
        "IB": "0",
        "SO": "5",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.222",
        "OBP": "0.300",
        "SLG": "0.222",
        "OPS": "0.522",
        "R/ePA": "0.050",
        "wRC+": "38.9",
        "WAR": "0.03"
      }
    }
  ],
  [
    "ff880efe3d2f704811fcef7853eccb1969e0b702",
    {
      "id": "ff880efe3d2f704811fcef7853eccb1969e0b702",
      "name": "홍건희",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "31",
        "Pos.": "P",
        "G": "65",
        "GS": "0",
        "GR": "65",
        "GF": "18",
        "CG": "0",
        "SHO": "0",
        "W": "4",
        "L": "3",
        "S": "9",
        "HD": "11",
        "IP": "59.1",
        "ER": "18",
        "R": "22",
        "rRA": "27.65",
        "TBF": "264",
        "H": "55",
        "2B": "6",
        "3B": "1",
        "HR": "5",
        "BB": "33",
        "HP": "5",
        "IB": "4",
        "SO": "45",
        "ROE": "2",
        "BK": "0",
        "WP": "5",
        "ERA": "2.73",
        "RA9": "3.34",
        "rRA9": "4.19",
        "rRA9pf": "4.36",
        "FIP": "5.19",
        "WHIP": "1.48",
        "WAR": "1.59"
      }
    }
  ],
  [
    "b3329822fbf4892c426b94c833b4b58127c5dd7e",
    {
      "id": "b3329822fbf4892c426b94c833b4b58127c5dd7e",
      "name": "박소준",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "28",
        "Pos.": "P",
        "G": "1",
        "GS": "1",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "4.0",
        "ER": "5",
        "R": "5",
        "rRA": "5.00",
        "TBF": "20",
        "H": "6",
        "2B": "0",
        "3B": "0",
        "HR": "2",
        "BB": "2",
        "HP": "0",
        "IB": "0",
        "SO": "2",
        "ROE": "0",
        "BK": "0",
        "WP": "1",
        "ERA": "11.25",
        "RA9": "11.25",
        "rRA9": "11.25",
        "rRA9pf": "10.20",
        "FIP": "10.45",
        "WHIP": "2.00",
        "WAR": "-0.07"
      }
    }
  ],
  [
    "832a688ab210a70c7069731e2c253a90568bf8ab",
    {
      "id": "832a688ab210a70c7069731e2c253a90568bf8ab",
      "name": "김민규",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "24",
        "Pos.": "P",
        "G": "13",
        "GS": "4",
        "GR": "9",
        "GF": "4",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "25.0",
        "ER": "12",
        "R": "14",
        "rRA": "12.15",
        "TBF": "117",
        "H": "23",
        "2B": "7",
        "3B": "2",
        "HR": "4",
        "BB": "19",
        "HP": "4",
        "IB": "0",
        "SO": "19",
        "ROE": "1",
        "BK": "0",
        "WP": "3",
        "ERA": "4.32",
        "RA9": "5.04",
        "rRA9": "4.37",
        "rRA9pf": "4.30",
        "FIP": "7.06",
        "WHIP": "1.68",
        "WAR": "0.75"
      }
    }
  ],
  [
    "904f6a0c5743c1cb9b9b098ebf5bc8a3a8b61e59",
    {
      "id": "904f6a0c5743c1cb9b9b098ebf5bc8a3a8b61e59",
      "name": "안승한",
      "team": "두산",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "31",
        "Pos.": "C",
        "oWAR": "-0.05",
        "dWAR": "0.02",
        "G": "4",
        "PA": "5",
        "ePA": "5",
        "AB": "5",
        "R": "0",
        "H": "1",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "1",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.200",
        "OBP": "0.200",
        "SLG": "0.200",
        "OPS": "0.400",
        "R/ePA": "-0.022",
        "wRC+": "-17.2",
        "WAR": "-0.03"
      }
    }
  ],
  [
    "f42392bf126698858b14206f3c0a8bd1b1c7d60c",
    {
      "id": "f42392bf126698858b14206f3c0a8bd1b1c7d60c",
      "name": "장승현",
      "team": "두산",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "29",
        "Pos.": "C",
        "oWAR": "-0.07",
        "dWAR": "-0.04",
        "G": "9",
        "PA": "23",
        "ePA": "22",
        "AB": "20",
        "R": "1",
        "H": "4",
        "2B": "2",
        "3B": "0",
        "HR": "0",
        "TB": "6",
        "RBI": "0",
        "SB": "0",
        "CS": "1",
        "BB": "2",
        "HP": "0",
        "IB": "0",
        "SO": "5",
        "GDP": "0",
        "SH": "1",
        "SF": "0",
        "AVG": "0.200",
        "OBP": "0.273",
        "SLG": "0.300",
        "OPS": "0.573",
        "R/ePA": "0.061",
        "wRC+": "43.3",
        "WAR": "-0.10"
      }
    }
  ],
  [
    "48fc4894f0d691de333bb1ce18c811e4d73e6ed3",
    {
      "id": "48fc4894f0d691de333bb1ce18c811e4d73e6ed3",
      "name": "강승호",
      "team": "두산",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "29",
        "Pos.": "2B",
        "oWAR": "2.90",
        "dWAR": "0.81",
        "G": "140",
        "PA": "566",
        "ePA": "561",
        "AB": "521",
        "R": "81",
        "H": "146",
        "2B": "34",
        "3B": "7",
        "HR": "18",
        "TB": "248",
        "RBI": "81",
        "SB": "16",
        "CS": "5",
        "BB": "32",
        "HP": "7",
        "IB": "4",
        "SO": "158",
        "GDP": "8",
        "SH": "2",
        "SF": "4",
        "AVG": "0.280",
        "OBP": "0.328",
        "SLG": "0.476",
        "OPS": "0.804",
        "R/ePA": "0.136",
        "wRC+": "104.3",
        "WAR": "3.71"
      }
    }
  ],
  [
    "ddad08171e9e9d2b4f6a75807315c182774436b8",
    {
      "id": "ddad08171e9e9d2b4f6a75807315c182774436b8",
      "name": "제환유",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2023",
        "Team": "두산",
        "Age": "22",
        "Pos.": "P",
        "G": "1",
        "GS": "0",
        "GR": "1",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "2.0",
        "ER": "3",
        "R": "4",
        "rRA": "3.65",
        "TBF": "14",
        "H": "3",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "BB": "4",
        "HP": "0",
        "IB": "0",
        "SO": "2",
        "ROE": "1",
        "BK": "0",
        "WP": "1",
        "ERA": "13.50",
        "RA9": "18.00",
        "rRA9": "16.43",
        "rRA9pf": "15.26",
        "FIP": "7.55",
        "WHIP": "3.50",
        "WAR": "-0.11"
      }
    }
  ],
  [
    "53aeb45fa219a590591df5e97e333849b35627f3",
    {
      "id": "53aeb45fa219a590591df5e97e333849b35627f3",
      "name": "양의지",
      "team": "두산",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "36",
        "Pos.": "C",
        "oWAR": "3.40",
        "dWAR": "-0.19",
        "G": "119",
        "PA": "485",
        "ePA": "482",
        "AB": "430",
        "R": "57",
        "H": "135",
        "2B": "18",
        "3B": "1",
        "HR": "17",
        "TB": "206",
        "RBI": "94",
        "SB": "2",
        "CS": "1",
        "BB": "40",
        "HP": "9",
        "IB": "5",
        "SO": "56",
        "GDP": "13",
        "SH": "0",
        "SF": "6",
        "AVG": "0.314",
        "OBP": "0.379",
        "SLG": "0.479",
        "OPS": "0.858",
        "R/ePA": "0.164",
        "wRC+": "125.3",
        "WAR": "3.21"
      }
    }
  ],
  [
    "005a08a34e31a4c353bbf53b5973b0e66450e96f",
    {
      "id": "005a08a34e31a4c353bbf53b5973b0e66450e96f",
      "name": "박민준",
      "team": "두산",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "21",
        "Pos.": "C",
        "oWAR": "-0.12",
        "dWAR": "-0.01",
        "G": "5",
        "PA": "5",
        "ePA": "5",
        "AB": "5",
        "R": "0",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "3",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.000",
        "SLG": "0.000",
        "OPS": "0.000",
        "R/ePA": "-0.190",
        "wRC+": "-144.6",
        "WAR": "-0.14"
      }
    }
  ],
  [
    "e40bac59b949d1f950d7958fd3464a1f6f709c41",
    {
      "id": "e40bac59b949d1f950d7958fd3464a1f6f709c41",
      "name": "김강률",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "35",
        "Pos.": "P",
        "G": "53",
        "GS": "0",
        "GR": "53",
        "GF": "3",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "2",
        "S": "1",
        "HD": "12",
        "IP": "42.0",
        "ER": "14",
        "R": "16",
        "rRA": "11.15",
        "TBF": "187",
        "H": "41",
        "2B": "7",
        "3B": "0",
        "HR": "1",
        "BB": "21",
        "HP": "2",
        "IB": "0",
        "SO": "33",
        "ROE": "1",
        "BK": "0",
        "WP": "1",
        "ERA": "3.00",
        "RA9": "3.43",
        "rRA9": "2.39",
        "rRA9pf": "2.48",
        "FIP": "4.22",
        "WHIP": "1.48",
        "WAR": "1.78"
      }
    }
  ],
  [
    "8f0fd49020f4ff2be485cf8d50442f01d7d26b9f",
    {
      "id": "8f0fd49020f4ff2be485cf8d50442f01d7d26b9f",
      "name": "최승용",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "22",
        "Pos.": "P",
        "G": "12",
        "GS": "6",
        "GR": "6",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "0",
        "S": "0",
        "HD": "1",
        "IP": "27.0",
        "ER": "18",
        "R": "19",
        "rRA": "20.40",
        "TBF": "124",
        "H": "37",
        "2B": "5",
        "3B": "2",
        "HR": "6",
        "BB": "7",
        "HP": "0",
        "IB": "0",
        "SO": "21",
        "ROE": "2",
        "BK": "0",
        "WP": "0",
        "ERA": "6.00",
        "RA9": "6.33",
        "rRA9": "6.80",
        "rRA9pf": "6.85",
        "FIP": "5.70",
        "WHIP": "1.63",
        "WAR": "0.14"
      }
    }
  ],
  [
    "b18c7a1ba65e2b295889b6863402cc634065832c",
    {
      "id": "b18c7a1ba65e2b295889b6863402cc634065832c",
      "name": "이병헌",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "20",
        "Pos.": "P",
        "G": "77",
        "GS": "0",
        "GR": "77",
        "GF": "6",
        "CG": "0",
        "SHO": "0",
        "W": "6",
        "L": "1",
        "S": "1",
        "HD": "22",
        "IP": "65.1",
        "ER": "21",
        "R": "25",
        "rRA": "29.90",
        "TBF": "285",
        "H": "61",
        "2B": "7",
        "3B": "1",
        "HR": "3",
        "BB": "34",
        "HP": "3",
        "IB": "1",
        "SO": "57",
        "ROE": "1",
        "BK": "1",
        "WP": "2",
        "ERA": "2.89",
        "RA9": "3.44",
        "rRA9": "4.12",
        "rRA9pf": "4.21",
        "FIP": "4.27",
        "WHIP": "1.45",
        "WAR": "1.91"
      }
    }
  ],
  [
    "ab0337e33477eb48fccd4c55235e1cc799dbf1c0",
    {
      "id": "ab0337e33477eb48fccd4c55235e1cc799dbf1c0",
      "name": "김정우",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "24",
        "Pos.": "P",
        "G": "1",
        "GS": "0",
        "GR": "1",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "0.1",
        "ER": "3",
        "R": "3",
        "rRA": "2.50",
        "TBF": "4",
        "H": "3",
        "2B": "2",
        "3B": "0",
        "HR": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "0",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "81.00",
        "RA9": "81.00",
        "rRA9": "67.50",
        "rRA9pf": "72.77",
        "FIP": "4.20",
        "WHIP": "9.00",
        "WAR": "-0.13"
      }
    }
  ],
  [
    "ee60969ced88042d303e677f7db260fdba166d4c",
    {
      "id": "ee60969ced88042d303e677f7db260fdba166d4c",
      "name": "정수빈",
      "team": "두산",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "33",
        "Pos.": "CF",
        "oWAR": "2.52",
        "dWAR": "0.45",
        "G": "136",
        "PA": "608",
        "ePA": "598",
        "AB": "510",
        "R": "95",
        "H": "145",
        "2B": "21",
        "3B": "3",
        "HR": "4",
        "TB": "184",
        "RBI": "47",
        "SB": "52",
        "CS": "13",
        "BB": "71",
        "HP": "9",
        "IB": "1",
        "SO": "72",
        "GDP": "8",
        "SH": "10",
        "SF": "8",
        "AVG": "0.284",
        "OBP": "0.376",
        "SLG": "0.361",
        "OPS": "0.737",
        "R/ePA": "0.131",
        "wRC+": "100.2",
        "WAR": "2.97"
      }
    }
  ],
  [
    "91fa4eb6cd6e9eb2242b53e3781e39deaf2582b4",
    {
      "id": "91fa4eb6cd6e9eb2242b53e3781e39deaf2582b4",
      "name": "김재환",
      "team": "두산",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "35",
        "Pos.": "DH",
        "oWAR": "3.37",
        "dWAR": "-0.28",
        "G": "136",
        "PA": "552",
        "ePA": "549",
        "AB": "474",
        "R": "78",
        "H": "134",
        "2B": "28",
        "3B": "0",
        "HR": "29",
        "TB": "249",
        "RBI": "92",
        "SB": "1",
        "CS": "1",
        "BB": "63",
        "HP": "6",
        "IB": "3",
        "SO": "168",
        "GDP": "8",
        "SH": "0",
        "SF": "9",
        "AVG": "0.283",
        "OBP": "0.368",
        "SLG": "0.525",
        "OPS": "0.893",
        "R/ePA": "0.173",
        "wRC+": "132.0",
        "WAR": "3.09"
      }
    }
  ],
  [
    "035f2bec23670bc00ecd256b3556092fa5d2d549",
    {
      "id": "035f2bec23670bc00ecd256b3556092fa5d2d549",
      "name": "김인태",
      "team": "두산",
      "position": "RF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "29",
        "Pos.": "LF",
        "oWAR": "-0.24",
        "dWAR": "-0.15",
        "G": "10",
        "PA": "26",
        "ePA": "26",
        "AB": "23",
        "R": "3",
        "H": "4",
        "2B": "0",
        "3B": "0",
        "HR": "1",
        "TB": "7",
        "RBI": "2",
        "SB": "0",
        "CS": "0",
        "BB": "2",
        "HP": "0",
        "IB": "0",
        "SO": "7",
        "GDP": "1",
        "SH": "0",
        "SF": "1",
        "AVG": "0.174",
        "OBP": "0.231",
        "SLG": "0.304",
        "OPS": "0.535",
        "R/ePA": "0.031",
        "wRC+": "22.0",
        "WAR": "-0.39"
      }
    }
  ],
  [
    "9e0738001c6f20d0a3a31e0fccfb2b0a9580faa3",
    {
      "id": "9e0738001c6f20d0a3a31e0fccfb2b0a9580faa3",
      "name": "권민석",
      "team": "두산",
      "position": "3B",
      "record": {
        "Div.": "베스트",
        "Year": "2021",
        "Team": "두산",
        "Age": "21",
        "Pos.": "3B",
        "oWAR": "-0.01",
        "dWAR": "0.14",
        "G": "33",
        "PA": "15",
        "ePA": "15",
        "AB": "14",
        "R": "8",
        "H": "3",
        "2B": "2",
        "3B": "0",
        "HR": "0",
        "TB": "5",
        "RBI": "1",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "7",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.214",
        "OBP": "0.267",
        "SLG": "0.357",
        "OPS": "0.624",
        "R/ePA": "0.072",
        "wRC+": "62.8",
        "WAR": "0.13"
      }
    }
  ],
  [
    "21f62072eb7bda05507fe81a2573a93590995cc4",
    {
      "id": "21f62072eb7bda05507fe81a2573a93590995cc4",
      "name": "전형근",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "3b20fafa561259ebb1ba640d18f57ba543a12f45",
    {
      "id": "3b20fafa561259ebb1ba640d18f57ba543a12f45",
      "name": "임종성",
      "team": "두산",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "18",
        "Pos.": "3B",
        "oWAR": "-0.06",
        "dWAR": "0.04",
        "G": "1",
        "PA": "4",
        "ePA": "4",
        "AB": "3",
        "R": "0",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.250",
        "SLG": "0.000",
        "OPS": "0.250",
        "R/ePA": "-0.008",
        "wRC+": "-6.5",
        "WAR": "-0.01"
      }
    }
  ],
  [
    "0f3caedf4efe5628c1ec669c2be7fbb2e41df3f3",
    {
      "id": "0f3caedf4efe5628c1ec669c2be7fbb2e41df3f3",
      "name": "김대한",
      "team": "두산",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "23",
        "Pos.": "RF",
        "oWAR": "-0.70",
        "dWAR": "0.01",
        "G": "61",
        "PA": "89",
        "ePA": "87",
        "AB": "75",
        "R": "10",
        "H": "10",
        "2B": "1",
        "3B": "0",
        "HR": "1",
        "TB": "14",
        "RBI": "7",
        "SB": "1",
        "CS": "1",
        "BB": "8",
        "HP": "2",
        "IB": "0",
        "SO": "26",
        "GDP": "1",
        "SH": "2",
        "SF": "2",
        "AVG": "0.133",
        "OBP": "0.230",
        "SLG": "0.187",
        "OPS": "0.417",
        "R/ePA": "0.007",
        "wRC+": "5.6",
        "WAR": "-0.69"
      }
    }
  ],
  [
    "605a462058f18891959cd9860bed34c5bdc87ccc",
    {
      "id": "605a462058f18891959cd9860bed34c5bdc87ccc",
      "name": "조제영",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2020",
        "Team": "두산",
        "Age": "18",
        "Pos.": "P",
        "G": "4",
        "GS": "1",
        "GR": "3",
        "GF": "3",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "6.0",
        "ER": "7",
        "R": "7",
        "rRA": "5.45",
        "TBF": "31",
        "H": "6",
        "2B": "1",
        "3B": "0",
        "HR": "1",
        "BB": "7",
        "HP": "1",
        "IB": "0",
        "SO": "6",
        "ROE": "0",
        "BK": "0",
        "WP": "1",
        "ERA": "10.50",
        "RA9": "10.50",
        "rRA9": "8.18",
        "rRA9pf": "7.58",
        "FIP": "7.61",
        "WHIP": "2.17",
        "WAR": "-0.05"
      }
    }
  ],
  [
    "c42b597c5cb539c80346e832b6feb1e869e40cfa",
    {
      "id": "c42b597c5cb539c80346e832b6feb1e869e40cfa",
      "name": "권휘",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "23",
        "Pos.": "P",
        "G": "8",
        "GS": "0",
        "GR": "8",
        "GF": "3",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "9.0",
        "ER": "11",
        "R": "11",
        "rRA": "8.80",
        "TBF": "49",
        "H": "15",
        "2B": "3",
        "3B": "0",
        "HR": "0",
        "BB": "7",
        "HP": "0",
        "IB": "0",
        "SO": "11",
        "ROE": "0",
        "BK": "0",
        "WP": "1",
        "ERA": "11.00",
        "RA9": "11.00",
        "rRA9": "8.80",
        "rRA9pf": "9.22",
        "FIP": "3.55",
        "WHIP": "2.44",
        "WAR": "-0.10"
      }
    }
  ],
  [
    "f99db56d118b9d0876b1c92fbafc9c4fb7d222fb",
    {
      "id": "f99db56d118b9d0876b1c92fbafc9c4fb7d222fb",
      "name": "최종인",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "22",
        "Pos.": "P",
        "G": "11",
        "GS": "0",
        "GR": "11",
        "GF": "4",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "0",
        "S": "1",
        "HD": "2",
        "IP": "9.2",
        "ER": "4",
        "R": "4",
        "rRA": "5.00",
        "TBF": "42",
        "H": "8",
        "2B": "0",
        "3B": "0",
        "HR": "2",
        "BB": "5",
        "HP": "0",
        "IB": "0",
        "SO": "8",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "3.72",
        "RA9": "3.72",
        "rRA9": "4.66",
        "rRA9pf": "4.71",
        "FIP": "6.20",
        "WHIP": "1.34",
        "WAR": "0.20"
      }
    }
  ],
  [
    "8315c13b985faddc0383a0522c5c76a2b404682b",
    {
      "id": "8315c13b985faddc0383a0522c5c76a2b404682b",
      "name": "김동주",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "21",
        "Pos.": "P",
        "G": "14",
        "GS": "9",
        "GR": "5",
        "GF": "3",
        "CG": "1",
        "SHO": "0",
        "W": "1",
        "L": "3",
        "S": "0",
        "HD": "0",
        "IP": "50.0",
        "ER": "38",
        "R": "46",
        "rRA": "42.45",
        "TBF": "244",
        "H": "67",
        "2B": "12",
        "3B": "3",
        "HR": "6",
        "BB": "21",
        "HP": "4",
        "IB": "0",
        "SO": "35",
        "ROE": "3",
        "BK": "1",
        "WP": "7",
        "ERA": "6.84",
        "RA9": "8.28",
        "rRA9": "7.64",
        "rRA9pf": "7.90",
        "FIP": "5.43",
        "WHIP": "1.76",
        "WAR": "-0.25"
      }
    }
  ],
  [
    "ff77d3f1c0f37e1f43ef7372ddc301ecc6a9e0f5",
    {
      "id": "ff77d3f1c0f37e1f43ef7372ddc301ecc6a9e0f5",
      "name": "최지강",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "22",
        "Pos.": "P",
        "G": "55",
        "GS": "0",
        "GR": "55",
        "GF": "4",
        "CG": "0",
        "SHO": "0",
        "W": "3",
        "L": "1",
        "S": "1",
        "HD": "15",
        "IP": "50.0",
        "ER": "18",
        "R": "19",
        "rRA": "24.55",
        "TBF": "222",
        "H": "48",
        "2B": "7",
        "3B": "2",
        "HR": "6",
        "BB": "24",
        "HP": "6",
        "IB": "2",
        "SO": "45",
        "ROE": "2",
        "BK": "0",
        "WP": "3",
        "ERA": "3.24",
        "RA9": "3.42",
        "rRA9": "4.42",
        "rRA9pf": "4.52",
        "FIP": "5.18",
        "WHIP": "1.44",
        "WAR": "1.26"
      }
    }
  ],
  [
    "e9c0ff85d0ed2abbb7ba78f6440837882dfb2404",
    {
      "id": "e9c0ff85d0ed2abbb7ba78f6440837882dfb2404",
      "name": "알칸타라",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "31",
        "Pos.": "P",
        "G": "12",
        "GS": "12",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "2",
        "S": "0",
        "HD": "0",
        "IP": "64.1",
        "ER": "34",
        "R": "35",
        "rRA": "34.65",
        "TBF": "270",
        "H": "58",
        "2B": "8",
        "3B": "3",
        "HR": "10",
        "BB": "22",
        "HP": "2",
        "IB": "0",
        "SO": "34",
        "ROE": "3",
        "BK": "0",
        "WP": "0",
        "ERA": "4.76",
        "RA9": "4.90",
        "rRA9": "4.85",
        "rRA9pf": "5.09",
        "FIP": "5.88",
        "WHIP": "1.24",
        "WAR": "1.36"
      }
    }
  ],
  [
    "035d8e733d0104ff1fa35e7713ce81f262c1d91a",
    {
      "id": "035d8e733d0104ff1fa35e7713ce81f262c1d91a",
      "name": "발라조빅",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "25",
        "Pos.": "P",
        "G": "12",
        "GS": "11",
        "GR": "1",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "6",
        "S": "0",
        "HD": "1",
        "IP": "57.0",
        "ER": "27",
        "R": "29",
        "rRA": "28.00",
        "TBF": "250",
        "H": "54",
        "2B": "13",
        "3B": "1",
        "HR": "3",
        "BB": "28",
        "HP": "2",
        "IB": "0",
        "SO": "69",
        "ROE": "1",
        "BK": "0",
        "WP": "2",
        "ERA": "4.26",
        "RA9": "4.58",
        "rRA9": "4.42",
        "rRA9pf": "4.67",
        "FIP": "3.22",
        "WHIP": "1.44",
        "WAR": "1.44"
      }
    }
  ],
  [
    "6c130ed4faed140a843cde14a07aaba2d8fa8656",
    {
      "id": "6c130ed4faed140a843cde14a07aaba2d8fa8656",
      "name": "홍성호",
      "team": "두산",
      "position": "RF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "26",
        "Pos.": "1B",
        "oWAR": "0.03",
        "dWAR": "0.03",
        "G": "15",
        "PA": "15",
        "ePA": "15",
        "AB": "14",
        "R": "0",
        "H": "4",
        "2B": "2",
        "3B": "0",
        "HR": "0",
        "TB": "6",
        "RBI": "1",
        "SB": "1",
        "CS": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "7",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.286",
        "OBP": "0.333",
        "SLG": "0.429",
        "OPS": "0.762",
        "R/ePA": "0.129",
        "wRC+": "100.8",
        "WAR": "0.07"
      }
    }
  ],
  [
    "f767d95cae71e9d31c74882386c249b3ce4f2588",
    {
      "id": "f767d95cae71e9d31c74882386c249b3ce4f2588",
      "name": "김기연",
      "team": "두산",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "26",
        "Pos.": "C",
        "oWAR": "0.85",
        "dWAR": "0.31",
        "G": "95",
        "PA": "283",
        "ePA": "279",
        "AB": "252",
        "R": "31",
        "H": "70",
        "2B": "10",
        "3B": "0",
        "HR": "5",
        "TB": "95",
        "RBI": "31",
        "SB": "0",
        "CS": "2",
        "BB": "22",
        "HP": "2",
        "IB": "0",
        "SO": "47",
        "GDP": "5",
        "SH": "4",
        "SF": "3",
        "AVG": "0.278",
        "OBP": "0.337",
        "SLG": "0.377",
        "OPS": "0.714",
        "R/ePA": "0.113",
        "wRC+": "86.7",
        "WAR": "1.16"
      }
    }
  ],
  [
    "a64944f79b034f1020b98a5959f4dbd33777a2c0",
    {
      "id": "a64944f79b034f1020b98a5959f4dbd33777a2c0",
      "name": "김명신",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "30",
        "Pos.": "P",
        "G": "35",
        "GS": "0",
        "GR": "35",
        "GF": "5",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "1",
        "S": "0",
        "HD": "4",
        "IP": "32.2",
        "ER": "34",
        "R": "34",
        "rRA": "38.50",
        "TBF": "168",
        "H": "63",
        "2B": "7",
        "3B": "0",
        "HR": "8",
        "BB": "8",
        "HP": "2",
        "IB": "0",
        "SO": "25",
        "ROE": "1",
        "BK": "0",
        "WP": "1",
        "ERA": "9.37",
        "RA9": "9.37",
        "rRA9": "10.61",
        "rRA9pf": "11.17",
        "FIP": "6.15",
        "WHIP": "2.17",
        "WAR": "-1.42"
      }
    }
  ],
  [
    "a34a2f611eb936fc93e6f7dc8784862bfaae0e12",
    {
      "id": "a34a2f611eb936fc93e6f7dc8784862bfaae0e12",
      "name": "곽빈",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "24",
        "Pos.": "P",
        "G": "30",
        "GS": "30",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "15",
        "L": "9",
        "S": "0",
        "HD": "0",
        "IP": "167.2",
        "ER": "79",
        "R": "83",
        "rRA": "76.70",
        "TBF": "716",
        "H": "142",
        "2B": "29",
        "3B": "1",
        "HR": "11",
        "BB": "76",
        "HP": "6",
        "IB": "0",
        "SO": "154",
        "ROE": "5",
        "BK": "1",
        "WP": "11",
        "ERA": "4.24",
        "RA9": "4.46",
        "rRA9": "4.12",
        "rRA9pf": "4.25",
        "FIP": "4.13",
        "WHIP": "1.30",
        "WAR": "5.00"
      }
    }
  ],
  [
    "27812e26bee207f4477a5aa9f785b4a9d631483a",
    {
      "id": "27812e26bee207f4477a5aa9f785b4a9d631483a",
      "name": "브랜든",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "29",
        "Pos.": "P",
        "G": "14",
        "GS": "14",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "7",
        "L": "4",
        "S": "0",
        "HD": "0",
        "IP": "75.0",
        "ER": "26",
        "R": "34",
        "rRA": "31.75",
        "TBF": "315",
        "H": "75",
        "2B": "11",
        "3B": "2",
        "HR": "3",
        "BB": "11",
        "HP": "4",
        "IB": "0",
        "SO": "75",
        "ROE": "9",
        "BK": "0",
        "WP": "2",
        "ERA": "3.12",
        "RA9": "4.08",
        "rRA9": "3.81",
        "rRA9pf": "3.96",
        "FIP": "2.80",
        "WHIP": "1.15",
        "WAR": "2.47"
      }
    }
  ],
  [
    "26bf5cb8e3b7c4404a3b6270ac75fdf314055246",
    {
      "id": "26bf5cb8e3b7c4404a3b6270ac75fdf314055246",
      "name": "박신지",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "24",
        "Pos.": "P",
        "G": "6",
        "GS": "1",
        "GR": "5",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "8.2",
        "ER": "2",
        "R": "3",
        "rRA": "4.05",
        "TBF": "41",
        "H": "8",
        "2B": "2",
        "3B": "0",
        "HR": "0",
        "BB": "8",
        "HP": "1",
        "IB": "0",
        "SO": "3",
        "ROE": "1",
        "BK": "0",
        "WP": "0",
        "ERA": "2.08",
        "RA9": "3.12",
        "rRA9": "4.21",
        "rRA9pf": "4.18",
        "FIP": "6.55",
        "WHIP": "1.85",
        "WAR": "0.22"
      }
    }
  ],
  [
    "95e0109edaa2584b233a2bedfab7591d0cc7cca1",
    {
      "id": "95e0109edaa2584b233a2bedfab7591d0cc7cca1",
      "name": "이영하",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "26",
        "Pos.": "P",
        "G": "59",
        "GS": "1",
        "GR": "58",
        "GF": "8",
        "CG": "0",
        "SHO": "0",
        "W": "5",
        "L": "4",
        "S": "2",
        "HD": "5",
        "IP": "65.1",
        "ER": "29",
        "R": "34",
        "rRA": "41.05",
        "TBF": "298",
        "H": "62",
        "2B": "9",
        "3B": "3",
        "HR": "4",
        "BB": "36",
        "HP": "2",
        "IB": "1",
        "SO": "59",
        "ROE": "6",
        "BK": "0",
        "WP": "7",
        "ERA": "3.99",
        "RA9": "4.68",
        "rRA9": "5.65",
        "rRA9pf": "5.85",
        "FIP": "4.47",
        "WHIP": "1.50",
        "WAR": "0.60"
      }
    }
  ],
  [
    "a5b5dde9755865dedf46ee484d69a1061616d169",
    {
      "id": "a5b5dde9755865dedf46ee484d69a1061616d169",
      "name": "조수행",
      "team": "두산",
      "position": "RF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "30",
        "Pos.": "LF",
        "oWAR": "0.40",
        "dWAR": "0.57",
        "G": "130",
        "PA": "382",
        "ePA": "368",
        "AB": "328",
        "R": "60",
        "H": "87",
        "2B": "5",
        "3B": "2",
        "HR": "0",
        "TB": "96",
        "RBI": "30",
        "SB": "64",
        "CS": "8",
        "BB": "33",
        "HP": "3",
        "IB": "1",
        "SO": "53",
        "GDP": "2",
        "SH": "14",
        "SF": "4",
        "AVG": "0.265",
        "OBP": "0.334",
        "SLG": "0.293",
        "OPS": "0.627",
        "R/ePA": "0.085",
        "wRC+": "65.5",
        "WAR": "0.97"
      }
    }
  ],
  [
    "71fb2eef8bc8f5704174343f69465f8bce556bef",
    {
      "id": "71fb2eef8bc8f5704174343f69465f8bce556bef",
      "name": "김재호",
      "team": "두산",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "38",
        "Pos.": "SS",
        "oWAR": "0.99",
        "dWAR": "-0.31",
        "G": "57",
        "PA": "154",
        "ePA": "147",
        "AB": "126",
        "R": "20",
        "H": "38",
        "2B": "5",
        "3B": "0",
        "HR": "1",
        "TB": "46",
        "RBI": "11",
        "SB": "0",
        "CS": "0",
        "BB": "19",
        "HP": "1",
        "IB": "0",
        "SO": "23",
        "GDP": "4",
        "SH": "7",
        "SF": "1",
        "AVG": "0.302",
        "OBP": "0.395",
        "SLG": "0.365",
        "OPS": "0.760",
        "R/ePA": "0.144",
        "wRC+": "112.2",
        "WAR": "0.68"
      }
    }
  ],
  [
    "0e3a81145edfca21b8dd710269f92e401f29a4da",
    {
      "id": "0e3a81145edfca21b8dd710269f92e401f29a4da",
      "name": "양석환",
      "team": "두산",
      "position": "1B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "32",
        "Pos.": "1B",
        "oWAR": "1.79",
        "dWAR": "0.37",
        "G": "142",
        "PA": "593",
        "ePA": "592",
        "AB": "533",
        "R": "83",
        "H": "131",
        "2B": "25",
        "3B": "1",
        "HR": "34",
        "TB": "260",
        "RBI": "107",
        "SB": "5",
        "CS": "3",
        "BB": "49",
        "HP": "7",
        "IB": "1",
        "SO": "128",
        "GDP": "15",
        "SH": "1",
        "SF": "3",
        "AVG": "0.246",
        "OBP": "0.316",
        "SLG": "0.488",
        "OPS": "0.804",
        "R/ePA": "0.136",
        "wRC+": "104.5",
        "WAR": "2.17"
      }
    }
  ],
  [
    "e9b842e8ffe9015ab0021f0cbd8d0435599039b2",
    {
      "id": "e9b842e8ffe9015ab0021f0cbd8d0435599039b2",
      "name": "이승진",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2021",
        "Team": "두산",
        "Age": "25",
        "Pos.": "P",
        "G": "47",
        "GS": "0",
        "GR": "47",
        "GF": "9",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "4",
        "S": "2",
        "HD": "13",
        "IP": "48.1",
        "ER": "21",
        "R": "24",
        "rRA": "19.40",
        "TBF": "210",
        "H": "41",
        "2B": "3",
        "3B": "0",
        "HR": "3",
        "BB": "31",
        "HP": "2",
        "IB": "2",
        "SO": "28",
        "ROE": "2",
        "BK": "2",
        "WP": "2",
        "ERA": "3.91",
        "RA9": "4.47",
        "rRA9": "3.61",
        "rRA9pf": "3.76",
        "FIP": "5.11",
        "WHIP": "1.49",
        "WAR": "1.30"
      }
    }
  ],
  [
    "ddb1a1ab5367b7f5b791dceb6e04f999e4493cee",
    {
      "id": "ddb1a1ab5367b7f5b791dceb6e04f999e4493cee",
      "name": "김호준",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "25",
        "Pos.": "P",
        "G": "16",
        "GS": "1",
        "GR": "15",
        "GF": "6",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "2",
        "S": "0",
        "HD": "0",
        "IP": "13.1",
        "ER": "13",
        "R": "14",
        "rRA": "15.00",
        "TBF": "68",
        "H": "22",
        "2B": "6",
        "3B": "1",
        "HR": "1",
        "BB": "4",
        "HP": "5",
        "IB": "0",
        "SO": "6",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "8.78",
        "RA9": "9.45",
        "rRA9": "10.13",
        "rRA9pf": "9.94",
        "FIP": "6.06",
        "WHIP": "1.95",
        "WAR": "-0.31"
      }
    }
  ],
  [
    "f1813494b90c94bb47605dce567c22a0038f0964",
    {
      "id": "f1813494b90c94bb47605dce567c22a0038f0964",
      "name": "양찬열",
      "team": "두산",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "26",
        "Pos.": "RF",
        "oWAR": "0.02",
        "dWAR": "0.07",
        "G": "11",
        "PA": "12",
        "ePA": "12",
        "AB": "8",
        "R": "2",
        "H": "1",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "1",
        "RBI": "1",
        "SB": "0",
        "CS": "0",
        "BB": "4",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.125",
        "OBP": "0.417",
        "SLG": "0.125",
        "OPS": "0.542",
        "R/ePA": "0.122",
        "wRC+": "96.2",
        "WAR": "0.09"
      }
    }
  ],
  [
    "56bb7e50aa5a01de905d492f66ac21f36a347246",
    {
      "id": "56bb7e50aa5a01de905d492f66ac21f36a347246",
      "name": "전다민",
      "team": "두산",
      "position": "RF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "22",
        "Pos.": "LF",
        "oWAR": "0.34",
        "dWAR": "-0.04",
        "G": "25",
        "PA": "34",
        "ePA": "34",
        "AB": "27",
        "R": "10",
        "H": "9",
        "2B": "2",
        "3B": "0",
        "HR": "0",
        "TB": "11",
        "RBI": "1",
        "SB": "1",
        "CS": "1",
        "BB": "7",
        "HP": "0",
        "IB": "0",
        "SO": "8",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.333",
        "OBP": "0.471",
        "SLG": "0.407",
        "OPS": "0.878",
        "R/ePA": "0.202",
        "wRC+": "156.9",
        "WAR": "0.30"
      }
    }
  ],
  [
    "db860c9bc09e5b29626d746f041bf21beb74b33a",
    {
      "id": "db860c9bc09e5b29626d746f041bf21beb74b33a",
      "name": "최준호",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "19",
        "Pos.": "P",
        "G": "17",
        "GS": "15",
        "GR": "2",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "3",
        "L": "6",
        "S": "0",
        "HD": "0",
        "IP": "72.1",
        "ER": "41",
        "R": "44",
        "rRA": "41.70",
        "TBF": "314",
        "H": "69",
        "2B": "8",
        "3B": "2",
        "HR": "11",
        "BB": "30",
        "HP": "4",
        "IB": "0",
        "SO": "58",
        "ROE": "2",
        "BK": "0",
        "WP": "6",
        "ERA": "5.10",
        "RA9": "5.47",
        "rRA9": "5.19",
        "rRA9pf": "5.24",
        "FIP": "5.46",
        "WHIP": "1.37",
        "WAR": "1.40"
      }
    }
  ],
  [
    "6f06931f11b349418bc29e86d0dbac546206892d",
    {
      "id": "6f06931f11b349418bc29e86d0dbac546206892d",
      "name": "강현구",
      "team": "두산",
      "position": "LF",
      "record": {
        "Div.": "베스트",
        "Year": "2022",
        "Team": "두산",
        "Age": "19",
        "Pos.": "LF",
        "oWAR": "0.09",
        "dWAR": "-0.22",
        "G": "3",
        "PA": "5",
        "ePA": "5",
        "AB": "3",
        "R": "0",
        "H": "1",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "1",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "1",
        "IB": "0",
        "SO": "0",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.333",
        "OBP": "0.600",
        "SLG": "0.333",
        "OPS": "0.933",
        "R/ePA": "0.267",
        "wRC+": "228.6",
        "WAR": "-0.13"
      }
    }
  ],
  [
    "e4255b4fb1a1a2ae7c559fbb83e3900c1f920ca4",
    {
      "id": "e4255b4fb1a1a2ae7c559fbb83e3900c1f920ca4",
      "name": "최원준",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "29",
        "Pos.": "P",
        "G": "24",
        "GS": "24",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "6",
        "L": "7",
        "S": "0",
        "HD": "0",
        "IP": "110.0",
        "ER": "79",
        "R": "79",
        "rRA": "70.35",
        "TBF": "490",
        "H": "125",
        "2B": "24",
        "3B": "2",
        "HR": "21",
        "BB": "34",
        "HP": "10",
        "IB": "1",
        "SO": "72",
        "ROE": "3",
        "BK": "0",
        "WP": "1",
        "ERA": "6.46",
        "RA9": "6.46",
        "rRA9": "5.76",
        "rRA9pf": "5.94",
        "FIP": "6.07",
        "WHIP": "1.45",
        "WAR": "1.42"
      }
    }
  ],
  [
    "4fd2d56246fb282fbff539d76e4dcce7b64d60cf",
    {
      "id": "4fd2d56246fb282fbff539d76e4dcce7b64d60cf",
      "name": "김유성",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "21",
        "Pos.": "P",
        "G": "17",
        "GS": "7",
        "GR": "10",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "2",
        "S": "0",
        "HD": "0",
        "IP": "28.0",
        "ER": "20",
        "R": "23",
        "rRA": "19.20",
        "TBF": "139",
        "H": "28",
        "2B": "10",
        "3B": "0",
        "HR": "1",
        "BB": "24",
        "HP": "4",
        "IB": "0",
        "SO": "29",
        "ROE": "1",
        "BK": "0",
        "WP": "2",
        "ERA": "6.43",
        "RA9": "7.39",
        "rRA9": "6.17",
        "rRA9pf": "6.50",
        "FIP": "5.13",
        "WHIP": "1.86",
        "WAR": "0.23"
      }
    }
  ],
  [
    "d4988461d9a1550388907abe0ed7a6d1ba297413",
    {
      "id": "d4988461d9a1550388907abe0ed7a6d1ba297413",
      "name": "김택연",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "18",
        "Pos.": "P",
        "G": "60",
        "GS": "0",
        "GR": "60",
        "GF": "32",
        "CG": "0",
        "SHO": "0",
        "W": "3",
        "L": "2",
        "S": "19",
        "HD": "4",
        "IP": "65.0",
        "ER": "15",
        "R": "17",
        "rRA": "18.90",
        "TBF": "277",
        "H": "51",
        "2B": "11",
        "3B": "0",
        "HR": "2",
        "BB": "31",
        "HP": "4",
        "IB": "1",
        "SO": "78",
        "ROE": "1",
        "BK": "0",
        "WP": "3",
        "ERA": "2.08",
        "RA9": "2.35",
        "rRA9": "2.62",
        "rRA9pf": "2.67",
        "FIP": "3.19",
        "WHIP": "1.26",
        "WAR": "3.23"
      }
    }
  ],
  [
    "0ecaae27b6360d34ff04d369b6b9ab3a844e8b88",
    {
      "id": "0ecaae27b6360d34ff04d369b6b9ab3a844e8b88",
      "name": "이원재",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2023",
        "Team": "두산",
        "Age": "19",
        "Pos.": "P",
        "G": "1",
        "GS": "1",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "1.0",
        "ER": "3",
        "R": "3",
        "rRA": "2.65",
        "TBF": "9",
        "H": "3",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "BB": "3",
        "HP": "0",
        "IB": "0",
        "SO": "0",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "27.00",
        "RA9": "27.00",
        "rRA9": "23.85",
        "rRA9pf": "24.88",
        "FIP": "13.15",
        "WHIP": "6.00",
        "WAR": "-0.13"
      }
    }
  ],
  [
    "e544e1207bb21b0641d0ef823413599171954538",
    {
      "id": "e544e1207bb21b0641d0ef823413599171954538",
      "name": "정철원",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "24",
        "Pos.": "P",
        "G": "36",
        "GS": "0",
        "GR": "36",
        "GF": "14",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "1",
        "S": "6",
        "HD": "1",
        "IP": "32.1",
        "ER": "23",
        "R": "24",
        "rRA": "25.55",
        "TBF": "165",
        "H": "39",
        "2B": "7",
        "3B": "2",
        "HR": "5",
        "BB": "26",
        "HP": "5",
        "IB": "0",
        "SO": "39",
        "ROE": "1",
        "BK": "0",
        "WP": "0",
        "ERA": "6.40",
        "RA9": "6.68",
        "rRA9": "7.11",
        "rRA9pf": "7.01",
        "FIP": "6.00",
        "WHIP": "2.01",
        "WAR": "-0.11"
      }
    }
  ],
  [
    "be8f94deee3575406d3d6aa77ec543c812cb2f7e",
    {
      "id": "be8f94deee3575406d3d6aa77ec543c812cb2f7e",
      "name": "김도윤",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "21",
        "Pos.": "P",
        "G": "2",
        "GS": "0",
        "GR": "2",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "2.1",
        "ER": "1",
        "R": "2",
        "rRA": "2.00",
        "TBF": "12",
        "H": "2",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "BB": "2",
        "HP": "1",
        "IB": "0",
        "SO": "1",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "3.86",
        "RA9": "7.71",
        "rRA9": "7.71",
        "rRA9pf": "8.32",
        "FIP": "7.11",
        "WHIP": "1.71",
        "WAR": "-0.01"
      }
    }
  ],
  [
    "4a6d8d8855fda22f1b87bd7edad88613e37bf376",
    {
      "id": "4a6d8d8855fda22f1b87bd7edad88613e37bf376",
      "name": "윤준호",
      "team": "두산",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "23",
        "Pos.": "C",
        "oWAR": "-0.02",
        "dWAR": "-0.06",
        "G": "3",
        "PA": "4",
        "ePA": "4",
        "AB": "4",
        "R": "1",
        "H": "1",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "1",
        "RBI": "1",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.250",
        "OBP": "0.250",
        "SLG": "0.250",
        "OPS": "0.500",
        "R/ePA": "0.020",
        "wRC+": "15.3",
        "WAR": "-0.08"
      }
    }
  ],
  [
    "9b106e639259cff055e8816b5ba17bf9a3024b52",
    {
      "id": "9b106e639259cff055e8816b5ba17bf9a3024b52",
      "name": "강태완",
      "team": "두산",
      "position": "LF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "ad332ad787eb44363587446fcc85a9c8b786a552",
    {
      "id": "ad332ad787eb44363587446fcc85a9c8b786a552",
      "name": "백승우",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2023",
        "Team": "두산",
        "Age": "22",
        "Pos.": "P",
        "G": "6",
        "GS": "0",
        "GR": "6",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "4.0",
        "ER": "0",
        "R": "0",
        "rRA": "0.50",
        "TBF": "18",
        "H": "3",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "BB": "3",
        "HP": "1",
        "IB": "0",
        "SO": "1",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "0.00",
        "RA9": "0.00",
        "rRA9": "1.13",
        "rRA9pf": "1.23",
        "FIP": "6.32",
        "WHIP": "1.50",
        "WAR": "0.17"
      }
    }
  ],
  [
    "4bd5dfe555256a741805c573cd56343c40bc3751",
    {
      "id": "4bd5dfe555256a741805c573cd56343c40bc3751",
      "name": "임서준",
      "team": "두산",
      "position": "2B",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "7b87914027e3be29f458c59e5680f87aefd894f9",
    {
      "id": "7b87914027e3be29f458c59e5680f87aefd894f9",
      "name": "제러드",
      "team": "두산",
      "position": "RF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "28",
        "Pos.": "RF",
        "oWAR": "2.09",
        "dWAR": "-0.50",
        "G": "38",
        "PA": "169",
        "ePA": "169",
        "AB": "144",
        "R": "29",
        "H": "47",
        "2B": "16",
        "3B": "1",
        "HR": "10",
        "TB": "95",
        "RBI": "39",
        "SB": "4",
        "CS": "0",
        "BB": "21",
        "HP": "3",
        "IB": "0",
        "SO": "35",
        "GDP": "0",
        "SH": "0",
        "SF": "1",
        "AVG": "0.326",
        "OBP": "0.420",
        "SLG": "0.660",
        "OPS": "1.080",
        "R/ePA": "0.241",
        "wRC+": "183.7",
        "WAR": "1.59"
      }
    }
  ],
  [
    "0f226a2f6779e117e862c43a1718961f0d46d071",
    {
      "id": "0f226a2f6779e117e862c43a1718961f0d46d071",
      "name": "장규빈",
      "team": "두산",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "22",
        "Pos.": "C",
        "oWAR": "-0.02",
        "dWAR": "0.00",
        "G": "3",
        "PA": "4",
        "ePA": "4",
        "AB": "4",
        "R": "1",
        "H": "1",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "1",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.250",
        "OBP": "0.250",
        "SLG": "0.250",
        "OPS": "0.500",
        "R/ePA": "0.020",
        "wRC+": "15.1",
        "WAR": "-0.02"
      }
    }
  ],
  [
    "589d4af350184ce7dc5a94a1547f64d303d7b2b7",
    {
      "id": "589d4af350184ce7dc5a94a1547f64d303d7b2b7",
      "name": "이교훈",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "23",
        "Pos.": "P",
        "G": "33",
        "GS": "0",
        "GR": "33",
        "GF": "7",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "1",
        "S": "0",
        "HD": "1",
        "IP": "35.1",
        "ER": "29",
        "R": "32",
        "rRA": "32.65",
        "TBF": "166",
        "H": "43",
        "2B": "11",
        "3B": "1",
        "HR": "7",
        "BB": "17",
        "HP": "2",
        "IB": "0",
        "SO": "27",
        "ROE": "2",
        "BK": "0",
        "WP": "0",
        "ERA": "7.39",
        "RA9": "8.15",
        "rRA9": "8.32",
        "rRA9pf": "8.78",
        "FIP": "6.31",
        "WHIP": "1.70",
        "WAR": "-0.56"
      }
    }
  ],
  [
    "456da09eeacbc282d0f88a5221339deee554c601",
    {
      "id": "456da09eeacbc282d0f88a5221339deee554c601",
      "name": "문원",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2021",
        "Team": "두산",
        "Age": "22",
        "Pos.": "P",
        "G": "2",
        "GS": "0",
        "GR": "2",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "1.1",
        "ER": "1",
        "R": "1",
        "rRA": "1.35",
        "TBF": "9",
        "H": "2",
        "2B": "0",
        "3B": "0",
        "HR": "1",
        "BB": "2",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "ROE": "1",
        "BK": "0",
        "WP": "0",
        "ERA": "6.75",
        "RA9": "6.75",
        "rRA9": "9.11",
        "rRA9pf": "9.12",
        "FIP": "15.47",
        "WHIP": "3.00",
        "WAR": "-0.02"
      }
    }
  ],
  [
    "e70e79a89caa36e8d62e4cb16f0b5d32b009e20b",
    {
      "id": "e70e79a89caa36e8d62e4cb16f0b5d32b009e20b",
      "name": "김문수",
      "team": "두산",
      "position": "LF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "a0c8084151f1d13dc7cf120326f126f40dfa1c1c",
    {
      "id": "a0c8084151f1d13dc7cf120326f126f40dfa1c1c",
      "name": "이상연",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "31bd915cb4cf9b1d697ba35b51ae4b5baa1ef5ac",
    {
      "id": "31bd915cb4cf9b1d697ba35b51ae4b5baa1ef5ac",
      "name": "남율",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "9f8b8dcb226d2fe6b49067b7d773dd946a7f3a7a",
    {
      "id": "9f8b8dcb226d2fe6b49067b7d773dd946a7f3a7a",
      "name": "최세창",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2021",
        "Team": "두산",
        "Age": "19",
        "Pos.": "P",
        "G": "1",
        "GS": "0",
        "GR": "1",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "1.1",
        "ER": "0",
        "R": "0",
        "rRA": "0.00",
        "TBF": "5",
        "H": "1",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "0",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "0.00",
        "RA9": "0.00",
        "rRA9": "0.00",
        "rRA9pf": "0.00",
        "FIP": "6.09",
        "WHIP": "1.50",
        "WAR": "0.06"
      }
    }
  ],
  [
    "4a6effe37a69a563c2a57d49cf9a316993ae6927",
    {
      "id": "4a6effe37a69a563c2a57d49cf9a316993ae6927",
      "name": "양현진",
      "team": "두산",
      "position": "RF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "5bfc35488cc2e22e5b178bf78dfa3a020b55f158",
    {
      "id": "5bfc35488cc2e22e5b178bf78dfa3a020b55f158",
      "name": "한충희",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "53bc6a88959412a573541a8f689aec657f1a0f76",
    {
      "id": "53bc6a88959412a573541a8f689aec657f1a0f76",
      "name": "배창현",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2020",
        "Team": "두산",
        "Age": "21",
        "Pos.": "P",
        "G": "1",
        "GS": "0",
        "GR": "1",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "0.1",
        "ER": "0",
        "R": "0",
        "rRA": "0.00",
        "TBF": "3",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "BB": "2",
        "HP": "0",
        "IB": "0",
        "SO": "0",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "0.00",
        "RA9": "0.00",
        "rRA9": "0.00",
        "rRA9pf": "0.00",
        "FIP": "22.60",
        "WHIP": "6.00",
        "WAR": "0.01"
      }
    }
  ],
  [
    "c3ea762fe47fbb53632f5b1a40630818087c562c",
    {
      "id": "c3ea762fe47fbb53632f5b1a40630818087c562c",
      "name": "이민혁",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "cae0490c15b61c4b04bd772705d73e0dd3d96c22",
    {
      "id": "cae0490c15b61c4b04bd772705d73e0dd3d96c22",
      "name": "박지호",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "20",
        "Pos.": "P",
        "G": "1",
        "GS": "0",
        "GR": "1",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "1",
        "IP": "0.2",
        "ER": "0",
        "R": "0",
        "rRA": "0.00",
        "TBF": "3",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "2",
        "ROE": "0",
        "BK": "0",
        "WP": "1",
        "ERA": "0.00",
        "RA9": "0.00",
        "rRA9": "0.00",
        "rRA9pf": "0.00",
        "FIP": "1.35",
        "WHIP": "1.50",
        "WAR": "0.10"
      }
    }
  ],
  [
    "b90f067aab935f62cd6fd1a0ce0a5a56ca14210f",
    {
      "id": "b90f067aab935f62cd6fd1a0ce0a5a56ca14210f",
      "name": "김무빈",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "2a485713241ca99e777ffdc3cb25f136ca96f517",
    {
      "id": "2a485713241ca99e777ffdc3cb25f136ca96f517",
      "name": "손율기",
      "team": "두산",
      "position": "LF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "4f7377cb311a7b29d34bde599bea32059ae71ef5",
    {
      "id": "4f7377cb311a7b29d34bde599bea32059ae71ef5",
      "name": "김태완",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "203f76d912fdb017f3e2d94fc0846d25d8470503",
    {
      "id": "203f76d912fdb017f3e2d94fc0846d25d8470503",
      "name": "류현준",
      "team": "두산",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "두산",
        "Age": "18",
        "Pos.": "C",
        "oWAR": "0.11",
        "dWAR": "0.01",
        "G": "2",
        "PA": "3",
        "ePA": "3",
        "AB": "3",
        "R": "0",
        "H": "2",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "TB": "3",
        "RBI": "1",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "0",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.667",
        "OBP": "0.667",
        "SLG": "1.000",
        "OPS": "1.667",
        "R/ePA": "0.487",
        "wRC+": "380.3",
        "WAR": "0.12"
      }
    }
  ],
  [
    "00f5318e9347b2955612cfd8e5bb006497f45911",
    {
      "id": "00f5318e9347b2955612cfd8e5bb006497f45911",
      "name": "장원호",
      "team": "두산",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "3b20abd01613868937ac7c4c01940711b7620ec2",
    {
      "id": "3b20abd01613868937ac7c4c01940711b7620ec2",
      "name": "이민석",
      "team": "두산",
      "position": "3B",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "674f3feeefa7afa85d16595de2459b733f949da3",
    {
      "id": "674f3feeefa7afa85d16595de2459b733f949da3",
      "name": "문동주",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "20",
        "Pos.": "P",
        "G": "21",
        "GS": "21",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "7",
        "L": "7",
        "S": "0",
        "HD": "0",
        "IP": "111.1",
        "ER": "64",
        "R": "71",
        "rRA": "69.60",
        "TBF": "502",
        "H": "148",
        "2B": "25",
        "3B": "3",
        "HR": "14",
        "BB": "38",
        "HP": "4",
        "IB": "1",
        "SO": "96",
        "ROE": "4",
        "BK": "1",
        "WP": "8",
        "ERA": "5.17",
        "RA9": "5.74",
        "rRA9": "5.63",
        "rRA9pf": "5.61",
        "FIP": "4.71",
        "WHIP": "1.67",
        "WAR": "1.77"
      }
    }
  ],
  [
    "134f003aae94b865619efdb936181e96fec6747c",
    {
      "id": "134f003aae94b865619efdb936181e96fec6747c",
      "name": "서정훈",
      "team": "한화",
      "position": "C",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "4939675facb4e55529cd759d07f67f7c7a6efa86",
    {
      "id": "4939675facb4e55529cd759d07f67f7c7a6efa86",
      "name": "김민기",
      "team": "한화",
      "position": "2B",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "95d22baf788c2ed3b4c83196877b8bd3ae7b8808",
    {
      "id": "95d22baf788c2ed3b4c83196877b8bd3ae7b8808",
      "name": "권동욱",
      "team": "한화",
      "position": "LF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "f66a279ccdceb115d5ff7985e93ca58a05c3617c",
    {
      "id": "f66a279ccdceb115d5ff7985e93ca58a05c3617c",
      "name": "안치홍",
      "team": "한화",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "33",
        "Pos.": "DH",
        "oWAR": "1.65",
        "dWAR": "-0.31",
        "G": "128",
        "PA": "533",
        "ePA": "531",
        "AB": "473",
        "R": "64",
        "H": "142",
        "2B": "21",
        "3B": "0",
        "HR": "13",
        "TB": "202",
        "RBI": "66",
        "SB": "3",
        "CS": "2",
        "BB": "49",
        "HP": "6",
        "IB": "1",
        "SO": "70",
        "GDP": "10",
        "SH": "1",
        "SF": "4",
        "AVG": "0.300",
        "OBP": "0.370",
        "SLG": "0.427",
        "OPS": "0.797",
        "R/ePA": "0.146",
        "wRC+": "107.9",
        "WAR": "1.33"
      }
    }
  ],
  [
    "8553ad4633b10694ad02969e9bd184b7693cfe00",
    {
      "id": "8553ad4633b10694ad02969e9bd184b7693cfe00",
      "name": "김선동",
      "team": "한화",
      "position": "LF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "50ed62612342595408c0db77453d4066d4e3dfd2",
    {
      "id": "50ed62612342595408c0db77453d4066d4e3dfd2",
      "name": "김도빈",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "22",
        "Pos.": "P",
        "G": "1",
        "GS": "1",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "0.1",
        "ER": "2",
        "R": "2",
        "rRA": "2.00",
        "TBF": "5",
        "H": "1",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "BB": "3",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "54.00",
        "RA9": "54.00",
        "rRA9": "54.00",
        "rRA9pf": "54.00",
        "FIP": "24.60",
        "WHIP": "12.00",
        "WAR": "-0.11"
      }
    }
  ],
  [
    "8241f4f01e83be0521fc416781e4e6af57084387",
    {
      "id": "8241f4f01e83be0521fc416781e4e6af57084387",
      "name": "윤대경",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "29",
        "Pos.": "P",
        "G": "7",
        "GS": "0",
        "GR": "7",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "7.2",
        "ER": "9",
        "R": "9",
        "rRA": "10.20",
        "TBF": "40",
        "H": "10",
        "2B": "3",
        "3B": "0",
        "HR": "1",
        "BB": "6",
        "HP": "1",
        "IB": "1",
        "SO": "7",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "10.57",
        "RA9": "10.57",
        "rRA9": "11.97",
        "rRA9pf": "11.77",
        "FIP": "6.30",
        "WHIP": "2.09",
        "WAR": "-0.26"
      }
    }
  ],
  [
    "a59bc50108ffeef2883d5333c4f8881857fe7830",
    {
      "id": "a59bc50108ffeef2883d5333c4f8881857fe7830",
      "name": "한경빈",
      "team": "한화",
      "position": "IF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "25",
        "Pos.": "SS",
        "oWAR": "-0.02",
        "dWAR": "0.01",
        "G": "1",
        "PA": "1",
        "ePA": "1",
        "AB": "1",
        "R": "0",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.000",
        "SLG": "0.000",
        "OPS": "0.000",
        "R/ePA": "-0.190",
        "wRC+": "-151.2",
        "WAR": "-0.01"
      }
    }
  ],
  [
    "1d665b6604c00436ef59002b55f7e9b5760f8fdc",
    {
      "id": "1d665b6604c00436ef59002b55f7e9b5760f8fdc",
      "name": "이도윤",
      "team": "한화",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "27",
        "Pos.": "SS",
        "oWAR": "0.74",
        "dWAR": "-0.14",
        "G": "134",
        "PA": "374",
        "ePA": "368",
        "AB": "336",
        "R": "49",
        "H": "93",
        "2B": "10",
        "3B": "3",
        "HR": "1",
        "TB": "112",
        "RBI": "46",
        "SB": "6",
        "CS": "4",
        "BB": "26",
        "HP": "3",
        "IB": "0",
        "SO": "56",
        "GDP": "5",
        "SH": "6",
        "SF": "3",
        "AVG": "0.277",
        "OBP": "0.332",
        "SLG": "0.333",
        "OPS": "0.665",
        "R/ePA": "0.098",
        "wRC+": "72.4",
        "WAR": "0.59"
      }
    }
  ],
  [
    "11a92c186bf2c166ff729294f01f480417a80793",
    {
      "id": "11a92c186bf2c166ff729294f01f480417a80793",
      "name": "노시환",
      "team": "한화",
      "position": "3B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "23",
        "Pos.": "3B",
        "oWAR": "2.50",
        "dWAR": "-0.08",
        "G": "136",
        "PA": "601",
        "ePA": "597",
        "AB": "526",
        "R": "88",
        "H": "143",
        "2B": "20",
        "3B": "2",
        "HR": "24",
        "TB": "239",
        "RBI": "89",
        "SB": "6",
        "CS": "0",
        "BB": "60",
        "HP": "11",
        "IB": "9",
        "SO": "129",
        "GDP": "10",
        "SH": "0",
        "SF": "4",
        "AVG": "0.272",
        "OBP": "0.356",
        "SLG": "0.454",
        "OPS": "0.810",
        "R/ePA": "0.145",
        "wRC+": "106.5",
        "WAR": "2.41"
      }
    }
  ],
  [
    "12ba75c854ed66c6168aea6c97a8366caecac51f",
    {
      "id": "12ba75c854ed66c6168aea6c97a8366caecac51f",
      "name": "김강민",
      "team": "한화",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "41",
        "Pos.": "CF",
        "oWAR": "-0.17",
        "dWAR": "0.19",
        "G": "41",
        "PA": "87",
        "ePA": "87",
        "AB": "76",
        "R": "5",
        "H": "17",
        "2B": "0",
        "3B": "0",
        "HR": "1",
        "TB": "20",
        "RBI": "7",
        "SB": "0",
        "CS": "0",
        "BB": "8",
        "HP": "3",
        "IB": "0",
        "SO": "21",
        "GDP": "1",
        "SH": "0",
        "SF": "0",
        "AVG": "0.224",
        "OBP": "0.322",
        "SLG": "0.263",
        "OPS": "0.585",
        "R/ePA": "0.080",
        "wRC+": "58.8",
        "WAR": "0.02"
      }
    }
  ],
  [
    "cefdde6e6289ba2f2ed448e555f827f6af4f9c43",
    {
      "id": "cefdde6e6289ba2f2ed448e555f827f6af4f9c43",
      "name": "박정현",
      "team": "한화",
      "position": "SS",
      "record": {
        "Div.": "베스트",
        "Year": "2022",
        "Team": "한화",
        "Age": "20",
        "Pos.": "SS",
        "oWAR": "0.02",
        "dWAR": "0.87",
        "G": "81",
        "PA": "220",
        "ePA": "218",
        "AB": "205",
        "R": "23",
        "H": "50",
        "2B": "4",
        "3B": "1",
        "HR": "3",
        "TB": "65",
        "RBI": "19",
        "SB": "6",
        "CS": "1",
        "BB": "12",
        "HP": "1",
        "IB": "0",
        "SO": "56",
        "GDP": "2",
        "SH": "2",
        "SF": "0",
        "AVG": "0.244",
        "OBP": "0.289",
        "SLG": "0.317",
        "OPS": "0.606",
        "R/ePA": "0.074",
        "wRC+": "63.9",
        "WAR": "0.89"
      }
    }
  ],
  [
    "ce56c0ef6fd01903cff192a5576a116966f97b76",
    {
      "id": "ce56c0ef6fd01903cff192a5576a116966f97b76",
      "name": "허관회",
      "team": "한화",
      "position": "C",
      "record": {
        "Div.": "베스트",
        "Year": "2020",
        "Team": "한화",
        "Age": "20",
        "Pos.": "C",
        "oWAR": "0.03",
        "dWAR": "0.04",
        "G": "1",
        "PA": "2",
        "ePA": "2",
        "AB": "2",
        "R": "0",
        "H": "1",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "1",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.500",
        "OBP": "0.500",
        "SLG": "0.500",
        "OPS": "1.000",
        "R/ePA": "0.231",
        "wRC+": "185.0",
        "WAR": "0.07"
      }
    }
  ],
  [
    "69f421338c4e5e8732e81e4562621350daf9d31c",
    {
      "id": "69f421338c4e5e8732e81e4562621350daf9d31c",
      "name": "남지민",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "22",
        "Pos.": "P",
        "G": "2",
        "GS": "0",
        "GR": "2",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "4.0",
        "ER": "0",
        "R": "0",
        "rRA": "1.20",
        "TBF": "17",
        "H": "2",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "BB": "2",
        "HP": "1",
        "IB": "0",
        "SO": "3",
        "ROE": "0",
        "BK": "0",
        "WP": "1",
        "ERA": "0.00",
        "RA9": "0.00",
        "rRA9": "2.70",
        "rRA9pf": "2.70",
        "FIP": "4.65",
        "WHIP": "1.00",
        "WAR": "0.12"
      }
    }
  ],
  [
    "d2fab85ab1175d4a5d903009b5f87ab92e27cf58",
    {
      "id": "d2fab85ab1175d4a5d903009b5f87ab92e27cf58",
      "name": "이재용",
      "team": "한화",
      "position": "C",
      "record": {
        "Div.": "베스트",
        "Year": "2023",
        "Team": "한화",
        "Age": "23",
        "Pos.": "C",
        "oWAR": "-0.01",
        "dWAR": "0.03",
        "G": "2",
        "PA": "2",
        "ePA": "2",
        "AB": "2",
        "R": "1",
        "H": "1",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "1",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.500",
        "OBP": "0.500",
        "SLG": "0.500",
        "OPS": "1.000",
        "R/ePA": "0.222",
        "wRC+": "173.2",
        "WAR": "0.03"
      }
    }
  ],
  [
    "1c06fe2d87fcd9ec9e2d2c7da45fc7125d884fe2",
    {
      "id": "1c06fe2d87fcd9ec9e2d2c7da45fc7125d884fe2",
      "name": "최재훈",
      "team": "한화",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "34",
        "Pos.": "C",
        "oWAR": "1.69",
        "dWAR": "0.13",
        "G": "116",
        "PA": "348",
        "ePA": "340",
        "AB": "280",
        "R": "34",
        "H": "72",
        "2B": "10",
        "3B": "0",
        "HR": "4",
        "TB": "94",
        "RBI": "37",
        "SB": "0",
        "CS": "0",
        "BB": "33",
        "HP": "21",
        "IB": "0",
        "SO": "58",
        "GDP": "10",
        "SH": "8",
        "SF": "6",
        "AVG": "0.257",
        "OBP": "0.371",
        "SLG": "0.336",
        "OPS": "0.707",
        "R/ePA": "0.131",
        "wRC+": "96.6",
        "WAR": "1.82"
      }
    }
  ],
  [
    "31fdc1bbae5421a50db22811c2fc9f398fc90feb",
    {
      "id": "31fdc1bbae5421a50db22811c2fc9f398fc90feb",
      "name": "이명기",
      "team": "한화",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "36",
        "Pos.": "DH",
        "oWAR": "-0.21",
        "dWAR": "0.00",
        "G": "4",
        "PA": "8",
        "ePA": "7",
        "AB": "7",
        "R": "0",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "1",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "3",
        "GDP": "0",
        "SH": "1",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.000",
        "SLG": "0.000",
        "OPS": "0.000",
        "R/ePA": "-0.190",
        "wRC+": "-132.5",
        "WAR": "-0.21"
      }
    }
  ],
  [
    "76d9162430c6e8776b1ec6b364321c3e7547fd4d",
    {
      "id": "76d9162430c6e8776b1ec6b364321c3e7547fd4d",
      "name": "김기중",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "21",
        "Pos.": "P",
        "G": "27",
        "GS": "11",
        "GR": "16",
        "GF": "4",
        "CG": "0",
        "SHO": "0",
        "W": "5",
        "L": "4",
        "S": "0",
        "HD": "0",
        "IP": "59.0",
        "ER": "43",
        "R": "44",
        "rRA": "42.60",
        "TBF": "286",
        "H": "83",
        "2B": "12",
        "3B": "1",
        "HR": "8",
        "BB": "27",
        "HP": "6",
        "IB": "0",
        "SO": "39",
        "ROE": "2",
        "BK": "0",
        "WP": "2",
        "ERA": "6.56",
        "RA9": "6.71",
        "rRA9": "6.50",
        "rRA9pf": "6.56",
        "FIP": "5.89",
        "WHIP": "1.86",
        "WAR": "0.33"
      }
    }
  ],
  [
    "c33b51313b463339128451c155fa32d6c4cdd800",
    {
      "id": "c33b51313b463339128451c155fa32d6c4cdd800",
      "name": "하주석",
      "team": "한화",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "29",
        "Pos.": "SS",
        "oWAR": "0.70",
        "dWAR": "-0.45",
        "G": "64",
        "PA": "151",
        "ePA": "149",
        "AB": "137",
        "R": "16",
        "H": "40",
        "2B": "9",
        "3B": "1",
        "HR": "1",
        "TB": "54",
        "RBI": "11",
        "SB": "1",
        "CS": "2",
        "BB": "10",
        "HP": "2",
        "IB": "0",
        "SO": "33",
        "GDP": "1",
        "SH": "2",
        "SF": "0",
        "AVG": "0.292",
        "OBP": "0.349",
        "SLG": "0.394",
        "OPS": "0.743",
        "R/ePA": "0.127",
        "wRC+": "94.7",
        "WAR": "0.25"
      }
    }
  ],
  [
    "055a33245152dbd4b453e528053a2d9309e00f95",
    {
      "id": "055a33245152dbd4b453e528053a2d9309e00f95",
      "name": "권광민",
      "team": "한화",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "26",
        "Pos.": "LF",
        "oWAR": "0.31",
        "dWAR": "0.09",
        "G": "19",
        "PA": "51",
        "ePA": "51",
        "AB": "48",
        "R": "10",
        "H": "13",
        "2B": "3",
        "3B": "0",
        "HR": "4",
        "TB": "28",
        "RBI": "9",
        "SB": "0",
        "CS": "0",
        "BB": "3",
        "HP": "0",
        "IB": "0",
        "SO": "17",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.271",
        "OBP": "0.314",
        "SLG": "0.583",
        "OPS": "0.897",
        "R/ePA": "0.160",
        "wRC+": "119.1",
        "WAR": "0.40"
      }
    }
  ],
  [
    "25bf2dd7344f6682e7d3f2ef4487677fc542bced",
    {
      "id": "25bf2dd7344f6682e7d3f2ef4487677fc542bced",
      "name": "이상규",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "27",
        "Pos.": "P",
        "G": "21",
        "GS": "3",
        "GR": "18",
        "GF": "4",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "4",
        "S": "0",
        "HD": "0",
        "IP": "32.0",
        "ER": "20",
        "R": "20",
        "rRA": "18.95",
        "TBF": "149",
        "H": "39",
        "2B": "7",
        "3B": "1",
        "HR": "5",
        "BB": "15",
        "HP": "1",
        "IB": "1",
        "SO": "27",
        "ROE": "0",
        "BK": "0",
        "WP": "1",
        "ERA": "5.63",
        "RA9": "5.63",
        "rRA9": "5.33",
        "rRA9pf": "5.29",
        "FIP": "5.40",
        "WHIP": "1.69",
        "WAR": "0.48"
      }
    }
  ],
  [
    "25a39e5efe97a93127b26c3aa4d98c9ffb3886b5",
    {
      "id": "25a39e5efe97a93127b26c3aa4d98c9ffb3886b5",
      "name": "이충호",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "29",
        "Pos.": "P",
        "G": "3",
        "GS": "0",
        "GR": "3",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "2.1",
        "ER": "1",
        "R": "1",
        "rRA": "3.05",
        "TBF": "13",
        "H": "4",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "BB": "3",
        "HP": "0",
        "IB": "0",
        "SO": "3",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "3.86",
        "RA9": "3.86",
        "rRA9": "11.76",
        "rRA9pf": "11.02",
        "FIP": "4.97",
        "WHIP": "3.00",
        "WAR": "-0.12"
      }
    }
  ],
  [
    "4c86f4eb4f38c870ee3cffc7b527b14e48c04051",
    {
      "id": "4c86f4eb4f38c870ee3cffc7b527b14e48c04051",
      "name": "페냐",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "33",
        "Pos.": "P",
        "G": "9",
        "GS": "9",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "3",
        "L": "5",
        "S": "0",
        "HD": "0",
        "IP": "37.1",
        "ER": "26",
        "R": "28",
        "rRA": "27.30",
        "TBF": "171",
        "H": "41",
        "2B": "6",
        "3B": "0",
        "HR": "6",
        "BB": "20",
        "HP": "0",
        "IB": "0",
        "SO": "29",
        "ROE": "1",
        "BK": "0",
        "WP": "1",
        "ERA": "6.27",
        "RA9": "6.75",
        "rRA9": "6.58",
        "rRA9pf": "6.62",
        "FIP": "5.83",
        "WHIP": "1.63",
        "WAR": "0.26"
      }
    }
  ],
  [
    "1004bd117e6bdaf2a1996a74839df0318fcb1b5a",
    {
      "id": "1004bd117e6bdaf2a1996a74839df0318fcb1b5a",
      "name": "바리아",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "27",
        "Pos.": "P",
        "G": "20",
        "GS": "19",
        "GR": "1",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "6",
        "L": "7",
        "S": "0",
        "HD": "0",
        "IP": "92.2",
        "ER": "53",
        "R": "58",
        "rRA": "56.60",
        "TBF": "405",
        "H": "114",
        "2B": "20",
        "3B": "1",
        "HR": "10",
        "BB": "18",
        "HP": "0",
        "IB": "0",
        "SO": "83",
        "ROE": "7",
        "BK": "0",
        "WP": "6",
        "ERA": "5.15",
        "RA9": "5.63",
        "rRA9": "5.50",
        "rRA9pf": "5.39",
        "FIP": "3.85",
        "WHIP": "1.42",
        "WAR": "1.69"
      }
    }
  ],
  [
    "5f71dfa7a95646647b83dee7a3bac037a7a30378",
    {
      "id": "5f71dfa7a95646647b83dee7a3bac037a7a30378",
      "name": "채은성",
      "team": "한화",
      "position": "RF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "33",
        "Pos.": "1B",
        "oWAR": "1.32",
        "dWAR": "-0.18",
        "G": "124",
        "PA": "498",
        "ePA": "498",
        "AB": "436",
        "R": "61",
        "H": "118",
        "2B": "24",
        "3B": "0",
        "HR": "20",
        "TB": "202",
        "RBI": "83",
        "SB": "1",
        "CS": "1",
        "BB": "49",
        "HP": "8",
        "IB": "1",
        "SO": "83",
        "GDP": "12",
        "SH": "0",
        "SF": "5",
        "AVG": "0.271",
        "OBP": "0.351",
        "SLG": "0.463",
        "OPS": "0.814",
        "R/ePA": "0.147",
        "wRC+": "108.9",
        "WAR": "1.14"
      }
    }
  ],
  [
    "01bf040b010605cdb2ed68ded64f7b252fcf4d47",
    {
      "id": "01bf040b010605cdb2ed68ded64f7b252fcf4d47",
      "name": "임종찬",
      "team": "한화",
      "position": "RF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "22",
        "Pos.": "CF",
        "oWAR": "-0.20",
        "dWAR": "-0.03",
        "G": "24",
        "PA": "64",
        "ePA": "64",
        "AB": "57",
        "R": "10",
        "H": "9",
        "2B": "3",
        "3B": "2",
        "HR": "0",
        "TB": "16",
        "RBI": "7",
        "SB": "0",
        "CS": "1",
        "BB": "6",
        "HP": "1",
        "IB": "0",
        "SO": "19",
        "GDP": "2",
        "SH": "0",
        "SF": "0",
        "AVG": "0.158",
        "OBP": "0.250",
        "SLG": "0.281",
        "OPS": "0.531",
        "R/ePA": "0.048",
        "wRC+": "35.5",
        "WAR": "-0.24"
      }
    }
  ],
  [
    "16e91aeccfcf36dd3be15c2822ae2d1f04066547",
    {
      "id": "16e91aeccfcf36dd3be15c2822ae2d1f04066547",
      "name": "김태연",
      "team": "한화",
      "position": "3B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "26",
        "Pos.": "RF",
        "oWAR": "1.43",
        "dWAR": "-0.82",
        "G": "126",
        "PA": "472",
        "ePA": "466",
        "AB": "413",
        "R": "59",
        "H": "120",
        "2B": "24",
        "3B": "0",
        "HR": "12",
        "TB": "180",
        "RBI": "61",
        "SB": "5",
        "CS": "2",
        "BB": "45",
        "HP": "4",
        "IB": "0",
        "SO": "89",
        "GDP": "9",
        "SH": "6",
        "SF": "4",
        "AVG": "0.291",
        "OBP": "0.363",
        "SLG": "0.436",
        "OPS": "0.799",
        "R/ePA": "0.145",
        "wRC+": "106.5",
        "WAR": "0.61"
      }
    }
  ],
  [
    "16c8706890342b1fece25c70e13d02e2221206e3",
    {
      "id": "16c8706890342b1fece25c70e13d02e2221206e3",
      "name": "한승혁",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "30",
        "Pos.": "P",
        "G": "70",
        "GS": "0",
        "GR": "70",
        "GF": "4",
        "CG": "0",
        "SHO": "0",
        "W": "5",
        "L": "5",
        "S": "0",
        "HD": "19",
        "IP": "62.2",
        "ER": "35",
        "R": "39",
        "rRA": "37.10",
        "TBF": "289",
        "H": "63",
        "2B": "7",
        "3B": "1",
        "HR": "2",
        "BB": "38",
        "HP": "6",
        "IB": "2",
        "SO": "64",
        "ROE": "2",
        "BK": "0",
        "WP": "6",
        "ERA": "5.03",
        "RA9": "5.60",
        "rRA9": "5.33",
        "rRA9pf": "5.27",
        "FIP": "4.20",
        "WHIP": "1.61",
        "WAR": "0.94"
      }
    }
  ],
  [
    "471fb6068644ef222a5974da374f66b15b9e9d66",
    {
      "id": "471fb6068644ef222a5974da374f66b15b9e9d66",
      "name": "이민우",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "30",
        "Pos.": "P",
        "G": "64",
        "GS": "0",
        "GR": "64",
        "GF": "7",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "1",
        "S": "1",
        "HD": "10",
        "IP": "55.0",
        "ER": "23",
        "R": "25",
        "rRA": "31.35",
        "TBF": "235",
        "H": "56",
        "2B": "10",
        "3B": "2",
        "HR": "5",
        "BB": "23",
        "HP": "1",
        "IB": "0",
        "SO": "49",
        "ROE": "2",
        "BK": "0",
        "WP": "1",
        "ERA": "3.76",
        "RA9": "4.09",
        "rRA9": "5.13",
        "rRA9pf": "4.97",
        "FIP": "4.41",
        "WHIP": "1.44",
        "WAR": "0.99"
      }
    }
  ],
  [
    "219bd4d4e30ddf6868c1deb5b7fc5e7017c7e4f7",
    {
      "id": "219bd4d4e30ddf6868c1deb5b7fc5e7017c7e4f7",
      "name": "장시환",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "36",
        "Pos.": "P",
        "G": "30",
        "GS": "0",
        "GR": "30",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "2",
        "S": "0",
        "HD": "2",
        "IP": "33.1",
        "ER": "19",
        "R": "27",
        "rRA": "22.05",
        "TBF": "156",
        "H": "34",
        "2B": "6",
        "3B": "1",
        "HR": "3",
        "BB": "22",
        "HP": "1",
        "IB": "0",
        "SO": "28",
        "ROE": "5",
        "BK": "0",
        "WP": "0",
        "ERA": "5.13",
        "RA9": "7.29",
        "rRA9": "5.95",
        "rRA9pf": "5.96",
        "FIP": "5.31",
        "WHIP": "1.68",
        "WAR": "0.28"
      }
    }
  ],
  [
    "936daf3841bac4a29c094f0f28fcb3d031dd34ab",
    {
      "id": "936daf3841bac4a29c094f0f28fcb3d031dd34ab",
      "name": "황준서",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "18",
        "Pos.": "P",
        "G": "36",
        "GS": "11",
        "GR": "25",
        "GF": "6",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "8",
        "S": "0",
        "HD": "1",
        "IP": "72.0",
        "ER": "43",
        "R": "44",
        "rRA": "44.05",
        "TBF": "341",
        "H": "80",
        "2B": "16",
        "3B": "0",
        "HR": "9",
        "BB": "51",
        "HP": "5",
        "IB": "0",
        "SO": "70",
        "ROE": "1",
        "BK": "1",
        "WP": "3",
        "ERA": "5.38",
        "RA9": "5.50",
        "rRA9": "5.51",
        "rRA9pf": "5.42",
        "FIP": "5.67",
        "WHIP": "1.82",
        "WAR": "1.24"
      }
    }
  ],
  [
    "b12ad846361606d9a17bd048f7aaca4c9b36a3fe",
    {
      "id": "b12ad846361606d9a17bd048f7aaca4c9b36a3fe",
      "name": "페라자",
      "team": "한화",
      "position": "RF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "25",
        "Pos.": "LF",
        "oWAR": "2.27",
        "dWAR": "-0.14",
        "G": "122",
        "PA": "522",
        "ePA": "518",
        "AB": "455",
        "R": "75",
        "H": "125",
        "2B": "24",
        "3B": "0",
        "HR": "24",
        "TB": "221",
        "RBI": "70",
        "SB": "7",
        "CS": "5",
        "BB": "63",
        "HP": "2",
        "IB": "5",
        "SO": "129",
        "GDP": "6",
        "SH": "0",
        "SF": "2",
        "AVG": "0.275",
        "OBP": "0.364",
        "SLG": "0.486",
        "OPS": "0.850",
        "R/ePA": "0.158",
        "wRC+": "116.7",
        "WAR": "2.13"
      }
    }
  ],
  [
    "041742f996df8481bd7b7ea8c78afae935fe5ab8",
    {
      "id": "041742f996df8481bd7b7ea8c78afae935fe5ab8",
      "name": "정이황",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "85f72c9e8f78a1662581d1c1ec5b6aa7e5550130",
    {
      "id": "85f72c9e8f78a1662581d1c1ec5b6aa7e5550130",
      "name": "이재원",
      "team": "한화",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "35",
        "Pos.": "C",
        "oWAR": "-0.24",
        "dWAR": "-0.05",
        "G": "72",
        "PA": "152",
        "ePA": "150",
        "AB": "134",
        "R": "10",
        "H": "32",
        "2B": "4",
        "3B": "0",
        "HR": "1",
        "TB": "39",
        "RBI": "16",
        "SB": "0",
        "CS": "0",
        "BB": "7",
        "HP": "4",
        "IB": "1",
        "SO": "21",
        "GDP": "4",
        "SH": "2",
        "SF": "5",
        "AVG": "0.239",
        "OBP": "0.287",
        "SLG": "0.291",
        "OPS": "0.578",
        "R/ePA": "0.059",
        "wRC+": "44.0",
        "WAR": "-0.29"
      }
    }
  ],
  [
    "f87617cfa191b06f347a7e01064c4435577c62cb",
    {
      "id": "f87617cfa191b06f347a7e01064c4435577c62cb",
      "name": "윤산흠",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2022",
        "Team": "한화",
        "Age": "22",
        "Pos.": "P",
        "G": "37",
        "GS": "0",
        "GR": "37",
        "GF": "7",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "1",
        "S": "0",
        "HD": "3",
        "IP": "33.2",
        "ER": "10",
        "R": "12",
        "rRA": "11.45",
        "TBF": "155",
        "H": "23",
        "2B": "7",
        "3B": "0",
        "HR": "1",
        "BB": "30",
        "HP": "2",
        "IB": "4",
        "SO": "46",
        "ROE": "5",
        "BK": "0",
        "WP": "6",
        "ERA": "2.67",
        "RA9": "3.21",
        "rRA9": "3.06",
        "rRA9pf": "3.04",
        "FIP": "3.48",
        "WHIP": "1.57",
        "WAR": "1.06"
      }
    }
  ],
  [
    "acbc44d923d3060b48ff2ca8b159e8e63eee2629",
    {
      "id": "acbc44d923d3060b48ff2ca8b159e8e63eee2629",
      "name": "유로결",
      "team": "한화",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "23",
        "Pos.": "RF",
        "oWAR": "-0.23",
        "dWAR": "0.24",
        "G": "23",
        "PA": "39",
        "ePA": "39",
        "AB": "36",
        "R": "6",
        "H": "8",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "TB": "9",
        "RBI": "4",
        "SB": "0",
        "CS": "1",
        "BB": "3",
        "HP": "0",
        "IB": "0",
        "SO": "11",
        "GDP": "4",
        "SH": "0",
        "SF": "0",
        "AVG": "0.222",
        "OBP": "0.282",
        "SLG": "0.250",
        "OPS": "0.532",
        "R/ePA": "0.047",
        "wRC+": "34.4",
        "WAR": "0.01"
      }
    }
  ],
  [
    "65a36a6c760b87bf412c96eed52effb965863dbb",
    {
      "id": "65a36a6c760b87bf412c96eed52effb965863dbb",
      "name": "산체스",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "26",
        "Pos.": "P",
        "G": "11",
        "GS": "11",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "3",
        "S": "0",
        "HD": "0",
        "IP": "53.1",
        "ER": "25",
        "R": "28",
        "rRA": "25.80",
        "TBF": "245",
        "H": "60",
        "2B": "9",
        "3B": "1",
        "HR": "2",
        "BB": "21",
        "HP": "10",
        "IB": "1",
        "SO": "56",
        "ROE": "4",
        "BK": "1",
        "WP": "2",
        "ERA": "4.22",
        "RA9": "4.73",
        "rRA9": "4.35",
        "rRA9pf": "4.43",
        "FIP": "3.82",
        "WHIP": "1.52",
        "WAR": "1.48"
      }
    }
  ],
  [
    "ce8275c671bb2fe249d73379c0bdbb68663bc797",
    {
      "id": "ce8275c671bb2fe249d73379c0bdbb68663bc797",
      "name": "장민재",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "33",
        "Pos.": "P",
        "G": "26",
        "GS": "0",
        "GR": "26",
        "GF": "9",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "29.0",
        "ER": "10",
        "R": "10",
        "rRA": "12.75",
        "TBF": "138",
        "H": "46",
        "2B": "7",
        "3B": "0",
        "HR": "2",
        "BB": "7",
        "HP": "1",
        "IB": "0",
        "SO": "17",
        "ROE": "0",
        "BK": "0",
        "WP": "5",
        "ERA": "3.10",
        "RA9": "3.10",
        "rRA9": "3.96",
        "rRA9pf": "3.88",
        "FIP": "4.41",
        "WHIP": "1.83",
        "WAR": "0.74"
      }
    }
  ],
  [
    "19bb34a878e379bf9a7bf1ab5261019da4508991",
    {
      "id": "19bb34a878e379bf9a7bf1ab5261019da4508991",
      "name": "김인환",
      "team": "한화",
      "position": "1B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "29",
        "Pos.": "LF",
        "oWAR": "-0.58",
        "dWAR": "-0.41",
        "G": "51",
        "PA": "143",
        "ePA": "143",
        "AB": "131",
        "R": "11",
        "H": "31",
        "2B": "2",
        "3B": "0",
        "HR": "1",
        "TB": "36",
        "RBI": "7",
        "SB": "0",
        "CS": "0",
        "BB": "10",
        "HP": "2",
        "IB": "0",
        "SO": "32",
        "GDP": "5",
        "SH": "0",
        "SF": "0",
        "AVG": "0.237",
        "OBP": "0.301",
        "SLG": "0.275",
        "OPS": "0.576",
        "R/ePA": "0.066",
        "wRC+": "48.1",
        "WAR": "-0.99"
      }
    }
  ],
  [
    "01afd9221da454da9463e6332aff73531f65a70d",
    {
      "id": "01afd9221da454da9463e6332aff73531f65a70d",
      "name": "배민서",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "24",
        "Pos.": "P",
        "G": "1",
        "GS": "0",
        "GR": "1",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "1.2",
        "ER": "3",
        "R": "3",
        "rRA": "3.00",
        "TBF": "11",
        "H": "4",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "BB": "3",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "16.20",
        "RA9": "16.20",
        "rRA9": "16.20",
        "rRA9pf": "16.17",
        "FIP": "8.28",
        "WHIP": "4.20",
        "WAR": "-0.08"
      }
    }
  ],
  [
    "d2cecbcb71c763e095e41622a7f5480eb0f86451",
    {
      "id": "d2cecbcb71c763e095e41622a7f5480eb0f86451",
      "name": "장지수",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "23",
        "Pos.": "P",
        "G": "13",
        "GS": "0",
        "GR": "13",
        "GF": "7",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "14.0",
        "ER": "17",
        "R": "18",
        "rRA": "14.75",
        "TBF": "75",
        "H": "23",
        "2B": "5",
        "3B": "2",
        "HR": "0",
        "BB": "10",
        "HP": "2",
        "IB": "2",
        "SO": "11",
        "ROE": "1",
        "BK": "0",
        "WP": "2",
        "ERA": "10.93",
        "RA9": "11.57",
        "rRA9": "9.48",
        "rRA9pf": "9.15",
        "FIP": "4.45",
        "WHIP": "2.36",
        "WAR": "-0.14"
      }
    }
  ],
  [
    "8b3f2d5ca16416f8e7946e7f167d8ef7195030f6",
    {
      "id": "8b3f2d5ca16416f8e7946e7f167d8ef7195030f6",
      "name": "최인호",
      "team": "한화",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "23",
        "Pos.": "LF",
        "oWAR": "0.86",
        "dWAR": "0.32",
        "G": "82",
        "PA": "244",
        "ePA": "240",
        "AB": "210",
        "R": "37",
        "H": "60",
        "2B": "13",
        "3B": "2",
        "HR": "2",
        "TB": "83",
        "RBI": "22",
        "SB": "3",
        "CS": "0",
        "BB": "25",
        "HP": "3",
        "IB": "0",
        "SO": "31",
        "GDP": "3",
        "SH": "4",
        "SF": "2",
        "AVG": "0.286",
        "OBP": "0.367",
        "SLG": "0.395",
        "OPS": "0.762",
        "R/ePA": "0.139",
        "wRC+": "102.2",
        "WAR": "1.18"
      }
    }
  ],
  [
    "219779dc412211837bea4970b3bfae5ac18c47d0",
    {
      "id": "219779dc412211837bea4970b3bfae5ac18c47d0",
      "name": "박상언",
      "team": "한화",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "26",
        "Pos.": "C",
        "oWAR": "0.48",
        "dWAR": "-0.02",
        "G": "27",
        "PA": "52",
        "ePA": "52",
        "AB": "42",
        "R": "4",
        "H": "12",
        "2B": "3",
        "3B": "0",
        "HR": "0",
        "TB": "15",
        "RBI": "4",
        "SB": "1",
        "CS": "0",
        "BB": "9",
        "HP": "1",
        "IB": "0",
        "SO": "12",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.286",
        "OBP": "0.423",
        "SLG": "0.357",
        "OPS": "0.780",
        "R/ePA": "0.166",
        "wRC+": "119.8",
        "WAR": "0.47"
      }
    }
  ],
  [
    "958950890464845928a86d5efc84047757ee8cbd",
    {
      "id": "958950890464845928a86d5efc84047757ee8cbd",
      "name": "정은원",
      "team": "한화",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "23",
        "Pos.": "LF",
        "oWAR": "-0.04",
        "dWAR": "0.09",
        "G": "27",
        "PA": "78",
        "ePA": "77",
        "AB": "64",
        "R": "10",
        "H": "11",
        "2B": "3",
        "3B": "1",
        "HR": "1",
        "TB": "19",
        "RBI": "6",
        "SB": "2",
        "CS": "0",
        "BB": "13",
        "HP": "0",
        "IB": "0",
        "SO": "16",
        "GDP": "0",
        "SH": "1",
        "SF": "0",
        "AVG": "0.172",
        "OBP": "0.312",
        "SLG": "0.297",
        "OPS": "0.609",
        "R/ePA": "0.088",
        "wRC+": "64.6",
        "WAR": "0.06"
      }
    }
  ],
  [
    "f203c0493bdecb9b6adcf10aec3bec7ed57a439f",
    {
      "id": "f203c0493bdecb9b6adcf10aec3bec7ed57a439f",
      "name": "이진영",
      "team": "한화",
      "position": "RF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "26",
        "Pos.": "CF",
        "oWAR": "-0.30",
        "dWAR": "0.15",
        "G": "42",
        "PA": "103",
        "ePA": "103",
        "AB": "93",
        "R": "20",
        "H": "19",
        "2B": "6",
        "3B": "0",
        "HR": "1",
        "TB": "28",
        "RBI": "13",
        "SB": "0",
        "CS": "1",
        "BB": "8",
        "HP": "2",
        "IB": "0",
        "SO": "27",
        "GDP": "3",
        "SH": "0",
        "SF": "0",
        "AVG": "0.204",
        "OBP": "0.282",
        "SLG": "0.301",
        "OPS": "0.583",
        "R/ePA": "0.067",
        "wRC+": "50.9",
        "WAR": "-0.15"
      }
    }
  ],
  [
    "4d0186b2d276c3e29cb1bd9055ba12a11cb4ae6f",
    {
      "id": "4d0186b2d276c3e29cb1bd9055ba12a11cb4ae6f",
      "name": "이태양",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "33",
        "Pos.": "P",
        "G": "10",
        "GS": "1",
        "GR": "9",
        "GF": "3",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "2",
        "S": "0",
        "HD": "0",
        "IP": "9.1",
        "ER": "12",
        "R": "13",
        "rRA": "14.20",
        "TBF": "48",
        "H": "17",
        "2B": "3",
        "3B": "0",
        "HR": "4",
        "BB": "2",
        "HP": "0",
        "IB": "0",
        "SO": "2",
        "ROE": "1",
        "BK": "0",
        "WP": "0",
        "ERA": "11.57",
        "RA9": "12.54",
        "rRA9": "13.69",
        "rRA9pf": "13.89",
        "FIP": "9.43",
        "WHIP": "2.04",
        "WAR": "-0.50"
      }
    }
  ],
  [
    "e29390f19130579336162344ab43dbf3363dd41d",
    {
      "id": "e29390f19130579336162344ab43dbf3363dd41d",
      "name": "김범수",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "28",
        "Pos.": "P",
        "G": "39",
        "GS": "0",
        "GR": "39",
        "GF": "6",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "4",
        "IP": "34.0",
        "ER": "20",
        "R": "20",
        "rRA": "25.10",
        "TBF": "157",
        "H": "33",
        "2B": "2",
        "3B": "0",
        "HR": "11",
        "BB": "20",
        "HP": "5",
        "IB": "1",
        "SO": "38",
        "ROE": "2",
        "BK": "0",
        "WP": "3",
        "ERA": "5.29",
        "RA9": "5.29",
        "rRA9": "6.64",
        "rRA9pf": "6.53",
        "FIP": "7.44",
        "WHIP": "1.56",
        "WAR": "0.08"
      }
    }
  ],
  [
    "8465bf727e01c0ca81097cf977db0bf49ed47cdd",
    {
      "id": "8465bf727e01c0ca81097cf977db0bf49ed47cdd",
      "name": "조한민",
      "team": "한화",
      "position": "SS",
      "record": {
        "Div.": "베스트",
        "Year": "2020",
        "Team": "한화",
        "Age": "19",
        "Pos.": "SS",
        "oWAR": "-0.08",
        "dWAR": "-0.28",
        "G": "25",
        "PA": "52",
        "ePA": "52",
        "AB": "50",
        "R": "7",
        "H": "11",
        "2B": "4",
        "3B": "0",
        "HR": "0",
        "TB": "15",
        "RBI": "2",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "2",
        "IB": "0",
        "SO": "22",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.220",
        "OBP": "0.250",
        "SLG": "0.300",
        "OPS": "0.550",
        "R/ePA": "0.048",
        "wRC+": "37.5",
        "WAR": "-0.36"
      }
    }
  ],
  [
    "ec6e8653aa072d88bab0ae0ac67beef77d4b7c4c",
    {
      "id": "ec6e8653aa072d88bab0ae0ac67beef77d4b7c4c",
      "name": "이민준",
      "team": "한화",
      "position": "SS",
      "record": {
        "Div.": "베스트",
        "Year": "2023",
        "Team": "한화",
        "Age": "18",
        "Pos.": "SS",
        "oWAR": "0.02",
        "dWAR": "0.05",
        "G": "4",
        "PA": "2",
        "ePA": "2",
        "AB": "1",
        "R": "0",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "0",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.500",
        "SLG": "0.000",
        "OPS": "0.500",
        "R/ePA": "0.166",
        "wRC+": "136.1",
        "WAR": "0.07"
      }
    }
  ],
  [
    "35732cc5e93acc61cfe7b13a57b94954b8509933",
    {
      "id": "35732cc5e93acc61cfe7b13a57b94954b8509933",
      "name": "이원석",
      "team": "한화",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "24",
        "Pos.": "CF",
        "oWAR": "-0.24",
        "dWAR": "0.03",
        "G": "87",
        "PA": "154",
        "ePA": "152",
        "AB": "133",
        "R": "26",
        "H": "31",
        "2B": "4",
        "3B": "1",
        "HR": "0",
        "TB": "37",
        "RBI": "13",
        "SB": "8",
        "CS": "2",
        "BB": "16",
        "HP": "2",
        "IB": "0",
        "SO": "32",
        "GDP": "3",
        "SH": "2",
        "SF": "1",
        "AVG": "0.233",
        "OBP": "0.322",
        "SLG": "0.278",
        "OPS": "0.600",
        "R/ePA": "0.082",
        "wRC+": "61.5",
        "WAR": "-0.21"
      }
    }
  ],
  [
    "eaea4a02887ae909aba7f7e1f22a959934c2bd8c",
    {
      "id": "eaea4a02887ae909aba7f7e1f22a959934c2bd8c",
      "name": "장진혁",
      "team": "한화",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "30",
        "Pos.": "CF",
        "oWAR": "0.57",
        "dWAR": "0.00",
        "G": "99",
        "PA": "327",
        "ePA": "322",
        "AB": "289",
        "R": "56",
        "H": "76",
        "2B": "16",
        "3B": "0",
        "HR": "9",
        "TB": "119",
        "RBI": "44",
        "SB": "14",
        "CS": "6",
        "BB": "29",
        "HP": "3",
        "IB": "0",
        "SO": "72",
        "GDP": "6",
        "SH": "5",
        "SF": "1",
        "AVG": "0.263",
        "OBP": "0.335",
        "SLG": "0.412",
        "OPS": "0.747",
        "R/ePA": "0.125",
        "wRC+": "91.6",
        "WAR": "0.58"
      }
    }
  ],
  [
    "8daef68f1fa29c2248d6b0f901755a7df792885c",
    {
      "id": "8daef68f1fa29c2248d6b0f901755a7df792885c",
      "name": "김민우",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "28",
        "Pos.": "P",
        "G": "3",
        "GS": "3",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "12.1",
        "ER": "3",
        "R": "3",
        "rRA": "3.00",
        "TBF": "49",
        "H": "7",
        "2B": "0",
        "3B": "0",
        "HR": "2",
        "BB": "6",
        "HP": "0",
        "IB": "0",
        "SO": "13",
        "ROE": "1",
        "BK": "0",
        "WP": "0",
        "ERA": "2.19",
        "RA9": "2.19",
        "rRA9": "2.19",
        "rRA9pf": "2.44",
        "FIP": "5.00",
        "WHIP": "1.05",
        "WAR": "0.62"
      }
    }
  ],
  [
    "f7317ccceed06fbf08a9ef7bfae1a5fc0180939f",
    {
      "id": "f7317ccceed06fbf08a9ef7bfae1a5fc0180939f",
      "name": "김서현",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "19",
        "Pos.": "P",
        "G": "37",
        "GS": "0",
        "GR": "37",
        "GF": "5",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "2",
        "S": "0",
        "HD": "10",
        "IP": "38.1",
        "ER": "16",
        "R": "20",
        "rRA": "20.15",
        "TBF": "178",
        "H": "31",
        "2B": "6",
        "3B": "2",
        "HR": "0",
        "BB": "32",
        "HP": "4",
        "IB": "0",
        "SO": "43",
        "ROE": "2",
        "BK": "0",
        "WP": "3",
        "ERA": "3.76",
        "RA9": "4.70",
        "rRA9": "4.73",
        "rRA9pf": "4.93",
        "FIP": "4.31",
        "WHIP": "1.64",
        "WAR": "0.71"
      }
    }
  ],
  [
    "3ff4b5ed3089237e9e89d79f2bb460c24e80d2c4",
    {
      "id": "3ff4b5ed3089237e9e89d79f2bb460c24e80d2c4",
      "name": "와이스",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "27",
        "Pos.": "P",
        "G": "16",
        "GS": "16",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "5",
        "L": "5",
        "S": "0",
        "HD": "0",
        "IP": "91.2",
        "ER": "38",
        "R": "43",
        "rRA": "41.95",
        "TBF": "384",
        "H": "77",
        "2B": "13",
        "3B": "5",
        "HR": "8",
        "BB": "29",
        "HP": "3",
        "IB": "0",
        "SO": "98",
        "ROE": "7",
        "BK": "0",
        "WP": "6",
        "ERA": "3.73",
        "RA9": "4.22",
        "rRA9": "4.12",
        "rRA9pf": "4.06",
        "FIP": "3.65",
        "WHIP": "1.16",
        "WAR": "2.92"
      }
    }
  ],
  [
    "43c0e6a9768bfb65ab515e8d48ba753e37131802",
    {
      "id": "43c0e6a9768bfb65ab515e8d48ba753e37131802",
      "name": "김건",
      "team": "한화",
      "position": "2B",
      "record": {
        "Div.": "베스트",
        "Year": "2020",
        "Team": "한화",
        "Age": "19",
        "Pos.": "2B",
        "oWAR": "0.01",
        "dWAR": "-0.07",
        "G": "4",
        "PA": "5",
        "ePA": "5",
        "AB": "5",
        "R": "2",
        "H": "2",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "2",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.400",
        "OBP": "0.400",
        "SLG": "0.400",
        "OPS": "0.800",
        "R/ePA": "0.148",
        "wRC+": "111.1",
        "WAR": "-0.06"
      }
    }
  ],
  [
    "0e64bc435f85eeaee7c1e63474161285fb0fe36a",
    {
      "id": "0e64bc435f85eeaee7c1e63474161285fb0fe36a",
      "name": "정우람",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "38",
        "Pos.": "P",
        "G": "1",
        "GS": "1",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "0.0",
        "ER": "0",
        "R": "0",
        "rRA": "0.00",
        "TBF": "1",
        "H": "1",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "0",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "",
        "RA9": "",
        "rRA9": "",
        "rRA9pf": "",
        "FIP": "4.20",
        "WHIP": "99.99",
        "WAR": ""
      }
    }
  ],
  [
    "23a46b8306141cc41f96f54ef6fb1711b19ee912",
    {
      "id": "23a46b8306141cc41f96f54ef6fb1711b19ee912",
      "name": "박상원",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "29",
        "Pos.": "P",
        "G": "65",
        "GS": "0",
        "GR": "65",
        "GF": "9",
        "CG": "0",
        "SHO": "0",
        "W": "3",
        "L": "3",
        "S": "2",
        "HD": "16",
        "IP": "66.2",
        "ER": "34",
        "R": "38",
        "rRA": "38.25",
        "TBF": "278",
        "H": "57",
        "2B": "18",
        "3B": "0",
        "HR": "2",
        "BB": "22",
        "HP": "7",
        "IB": "0",
        "SO": "65",
        "ROE": "3",
        "BK": "0",
        "WP": "8",
        "ERA": "4.59",
        "RA9": "5.13",
        "rRA9": "5.16",
        "rRA9pf": "5.12",
        "FIP": "3.47",
        "WHIP": "1.19",
        "WAR": "1.11"
      }
    }
  ],
  [
    "8b3e0d2ee439129058abd695e71de3d0b69eeed6",
    {
      "id": "8b3e0d2ee439129058abd695e71de3d0b69eeed6",
      "name": "한승주",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "22",
        "Pos.": "P",
        "G": "18",
        "GS": "0",
        "GR": "18",
        "GF": "6",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "2",
        "S": "0",
        "HD": "0",
        "IP": "22.0",
        "ER": "28",
        "R": "31",
        "rRA": "30.15",
        "TBF": "120",
        "H": "45",
        "2B": "6",
        "3B": "1",
        "HR": "2",
        "BB": "10",
        "HP": "3",
        "IB": "0",
        "SO": "14",
        "ROE": "2",
        "BK": "0",
        "WP": "1",
        "ERA": "11.45",
        "RA9": "12.68",
        "rRA9": "12.33",
        "rRA9pf": "12.50",
        "FIP": "5.52",
        "WHIP": "2.50",
        "WAR": "-0.95"
      }
    }
  ],
  [
    "cf4d20c7725ae35cc83d854ff828d16bdb01a607",
    {
      "id": "cf4d20c7725ae35cc83d854ff828d16bdb01a607",
      "name": "김규연",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "21",
        "Pos.": "P",
        "G": "58",
        "GS": "1",
        "GR": "57",
        "GF": "12",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "0",
        "S": "0",
        "HD": "4",
        "IP": "59.0",
        "ER": "46",
        "R": "50",
        "rRA": "46.95",
        "TBF": "270",
        "H": "66",
        "2B": "10",
        "3B": "1",
        "HR": "6",
        "BB": "28",
        "HP": "4",
        "IB": "1",
        "SO": "40",
        "ROE": "3",
        "BK": "0",
        "WP": "3",
        "ERA": "7.02",
        "RA9": "7.63",
        "rRA9": "7.16",
        "rRA9pf": "7.05",
        "FIP": "5.40",
        "WHIP": "1.59",
        "WAR": "0.01"
      }
    }
  ],
  [
    "b670ab5f91ea9a12adaf2f9ff0af6fcf42df8d9b",
    {
      "id": "b670ab5f91ea9a12adaf2f9ff0af6fcf42df8d9b",
      "name": "문현빈",
      "team": "한화",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "19",
        "Pos.": "2B",
        "oWAR": "0.63",
        "dWAR": "-0.14",
        "G": "103",
        "PA": "289",
        "ePA": "287",
        "AB": "260",
        "R": "29",
        "H": "72",
        "2B": "16",
        "3B": "2",
        "HR": "5",
        "TB": "107",
        "RBI": "47",
        "SB": "3",
        "CS": "5",
        "BB": "24",
        "HP": "2",
        "IB": "1",
        "SO": "53",
        "GDP": "9",
        "SH": "1",
        "SF": "2",
        "AVG": "0.277",
        "OBP": "0.340",
        "SLG": "0.412",
        "OPS": "0.752",
        "R/ePA": "0.126",
        "wRC+": "93.2",
        "WAR": "0.50"
      }
    }
  ],
  [
    "43f85f2e6e2a26b2d7238b0ed22cbb4fca27f19e",
    {
      "id": "43f85f2e6e2a26b2d7238b0ed22cbb4fca27f19e",
      "name": "이상혁",
      "team": "한화",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "22",
        "Pos.": "CF",
        "oWAR": "-0.09",
        "dWAR": "-0.12",
        "G": "43",
        "PA": "15",
        "ePA": "15",
        "AB": "13",
        "R": "13",
        "H": "2",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "2",
        "RBI": "0",
        "SB": "5",
        "CS": "2",
        "BB": "2",
        "HP": "0",
        "IB": "0",
        "SO": "5",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.154",
        "OBP": "0.267",
        "SLG": "0.154",
        "OPS": "0.421",
        "R/ePA": "0.019",
        "wRC+": "13.9",
        "WAR": "-0.21"
      }
    }
  ],
  [
    "855d580bcf8ef8bc3a5ce813f583cf085d42bf26",
    {
      "id": "855d580bcf8ef8bc3a5ce813f583cf085d42bf26",
      "name": "주현상",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "31",
        "Pos.": "P",
        "G": "65",
        "GS": "0",
        "GR": "65",
        "GF": "51",
        "CG": "0",
        "SHO": "0",
        "W": "8",
        "L": "4",
        "S": "23",
        "HD": "2",
        "IP": "71.1",
        "ER": "21",
        "R": "22",
        "rRA": "24.75",
        "TBF": "267",
        "H": "52",
        "2B": "8",
        "3B": "2",
        "HR": "9",
        "BB": "8",
        "HP": "1",
        "IB": "0",
        "SO": "64",
        "ROE": "0",
        "BK": "0",
        "WP": "1",
        "ERA": "2.65",
        "RA9": "2.78",
        "rRA9": "3.12",
        "rRA9pf": "3.09",
        "FIP": "3.85",
        "WHIP": "0.84",
        "WAR": "3.13"
      }
    }
  ],
  [
    "e65d9d3711fdd216298604b407c6648c8b1b43aa",
    {
      "id": "e65d9d3711fdd216298604b407c6648c8b1b43aa",
      "name": "오세훈",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "2fb0ef5ae346ad7e12db6087547733dd4f344b12",
    {
      "id": "2fb0ef5ae346ad7e12db6087547733dd4f344b12",
      "name": "조동욱",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "19",
        "Pos.": "P",
        "G": "21",
        "GS": "8",
        "GR": "13",
        "GF": "6",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "2",
        "S": "0",
        "HD": "0",
        "IP": "41.0",
        "ER": "29",
        "R": "34",
        "rRA": "35.90",
        "TBF": "198",
        "H": "57",
        "2B": "10",
        "3B": "0",
        "HR": "5",
        "BB": "14",
        "HP": "6",
        "IB": "0",
        "SO": "32",
        "ROE": "3",
        "BK": "1",
        "WP": "1",
        "ERA": "6.37",
        "RA9": "7.46",
        "rRA9": "7.88",
        "rRA9pf": "7.68",
        "FIP": "5.21",
        "WHIP": "1.73",
        "WAR": "0.00"
      }
    }
  ],
  [
    "76fc928fefe7f8864c681115172a107d277bd3fd",
    {
      "id": "76fc928fefe7f8864c681115172a107d277bd3fd",
      "name": "김범준",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "bff732cf2259ffa7d0ea049f456c1c40ac240048",
    {
      "id": "bff732cf2259ffa7d0ea049f456c1c40ac240048",
      "name": "황영묵",
      "team": "한화",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "24",
        "Pos.": "2B",
        "oWAR": "1.38",
        "dWAR": "0.01",
        "G": "123",
        "PA": "389",
        "ePA": "386",
        "AB": "349",
        "R": "52",
        "H": "105",
        "2B": "8",
        "3B": "4",
        "HR": "3",
        "TB": "130",
        "RBI": "35",
        "SB": "4",
        "CS": "7",
        "BB": "31",
        "HP": "5",
        "IB": "0",
        "SO": "56",
        "GDP": "6",
        "SH": "3",
        "SF": "1",
        "AVG": "0.301",
        "OBP": "0.365",
        "SLG": "0.372",
        "OPS": "0.737",
        "R/ePA": "0.129",
        "wRC+": "95.1",
        "WAR": "1.39"
      }
    }
  ],
  [
    "78707c49d981c32c8ef0042f8efced1e78c594d0",
    {
      "id": "78707c49d981c32c8ef0042f8efced1e78c594d0",
      "name": "장규현",
      "team": "한화",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "21",
        "Pos.": "C",
        "oWAR": "-0.10",
        "dWAR": "-0.04",
        "G": "9",
        "PA": "7",
        "ePA": "7",
        "AB": "6",
        "R": "0",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.143",
        "SLG": "0.000",
        "OPS": "0.143",
        "R/ePA": "-0.086",
        "wRC+": "-63.4",
        "WAR": "-0.14"
      }
    }
  ],
  [
    "1b02d2405f727fd08225bc372ddb085f3a4ff0c1",
    {
      "id": "1b02d2405f727fd08225bc372ddb085f3a4ff0c1",
      "name": "성지훈",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "e18b44695aefde3bbf31ac1bccbc169fb3fab418",
    {
      "id": "e18b44695aefde3bbf31ac1bccbc169fb3fab418",
      "name": "류현진",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "한화",
        "Age": "36",
        "Pos.": "P",
        "G": "28",
        "GS": "28",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "10",
        "L": "8",
        "S": "0",
        "HD": "0",
        "IP": "158.1",
        "ER": "68",
        "R": "78",
        "rRA": "76.95",
        "TBF": "679",
        "H": "182",
        "2B": "22",
        "3B": "1",
        "HR": "12",
        "BB": "33",
        "HP": "3",
        "IB": "0",
        "SO": "135",
        "ROE": "10",
        "BK": "0",
        "WP": "1",
        "ERA": "3.87",
        "RA9": "4.43",
        "rRA9": "4.37",
        "rRA9pf": "4.41",
        "FIP": "3.67",
        "WHIP": "1.36",
        "WAR": "4.44"
      }
    }
  ],
  [
    "44d005d7334f1d8af1820b7c66cf4de891d61c59",
    {
      "id": "44d005d7334f1d8af1820b7c66cf4de891d61c59",
      "name": "송호정",
      "team": "한화",
      "position": "2B",
      "record": {
        "Div.": "베스트",
        "Year": "2021",
        "Team": "한화",
        "Age": "18",
        "Pos.": "2B",
        "oWAR": "-0.15",
        "dWAR": "-0.17",
        "G": "10",
        "PA": "11",
        "ePA": "11",
        "AB": "10",
        "R": "1",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "8",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.091",
        "SLG": "0.000",
        "OPS": "0.091",
        "R/ePA": "-0.116",
        "wRC+": "-97.2",
        "WAR": "-0.32"
      }
    }
  ],
  [
    "60cd47577452547f879c567082497a9ed490e535",
    {
      "id": "60cd47577452547f879c567082497a9ed490e535",
      "name": "양경모",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "fd142e86256877b512036c89855b1a48dcc7fc7b",
    {
      "id": "fd142e86256877b512036c89855b1a48dcc7fc7b",
      "name": "이기창",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "5dcfba8508dd1eae47bc3215fbd22b92ab8a23e5",
    {
      "id": "5dcfba8508dd1eae47bc3215fbd22b92ab8a23e5",
      "name": "최준서",
      "team": "한화",
      "position": "RF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "ab74bca94f9db4a743aff13f814f074bb165a5b1",
    {
      "id": "ab74bca94f9db4a743aff13f814f074bb165a5b1",
      "name": "이승현",
      "team": "한화",
      "position": "C",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "7c12e064de1b9b96fd5e16e29878437303465688",
    {
      "id": "7c12e064de1b9b96fd5e16e29878437303465688",
      "name": "원종혁",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "caccf1e7d4eccdd301cfdd93e3c48f9742f8f6d7",
    {
      "id": "caccf1e7d4eccdd301cfdd93e3c48f9742f8f6d7",
      "name": "권현",
      "team": "한화",
      "position": "RF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "b9edaf1204579dd00e649fca9bcb186f3e593eb0",
    {
      "id": "b9edaf1204579dd00e649fca9bcb186f3e593eb0",
      "name": "승지환",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "40eaf5f566d170f5208850e5d0c4ac8e07b5cdb7",
    {
      "id": "40eaf5f566d170f5208850e5d0c4ac8e07b5cdb7",
      "name": "김진욱",
      "team": "한화",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2020",
        "Team": "한화",
        "Age": "19",
        "Pos.": "P",
        "G": "22",
        "GS": "6",
        "GR": "16",
        "GF": "6",
        "CG": "0",
        "SHO": "0",
        "W": "3",
        "L": "4",
        "S": "1",
        "HD": "0",
        "IP": "44.2",
        "ER": "28",
        "R": "29",
        "rRA": "28.50",
        "TBF": "206",
        "H": "50",
        "2B": "12",
        "3B": "1",
        "HR": "9",
        "BB": "25",
        "HP": "5",
        "IB": "0",
        "SO": "29",
        "ROE": "2",
        "BK": "0",
        "WP": "3",
        "ERA": "5.64",
        "RA9": "5.84",
        "rRA9": "5.74",
        "rRA9pf": "6.07",
        "FIP": "6.85",
        "WHIP": "1.68",
        "WAR": "0.36"
      }
    }
  ],
  [
    "84a84aac1a5dd2f7e994ad5650e18177e614c698",
    {
      "id": "84a84aac1a5dd2f7e994ad5650e18177e614c698",
      "name": "신우재",
      "team": "한화",
      "position": "LF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "6449f0f1e456f1ab2b7a00300ebc06f57352a764",
    {
      "id": "6449f0f1e456f1ab2b7a00300ebc06f57352a764",
      "name": "정안석",
      "team": "한화",
      "position": "2B",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "c90e6c72c989ca3a1431b3bf22e23a947f05b766",
    {
      "id": "c90e6c72c989ca3a1431b3bf22e23a947f05b766",
      "name": "박성빈",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "20",
        "Pos.": "P",
        "G": "3",
        "GS": "0",
        "GR": "3",
        "GF": "3",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "2.1",
        "ER": "0",
        "R": "0",
        "rRA": "0.00",
        "TBF": "9",
        "H": "1",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "BB": "0",
        "HP": "1",
        "IB": "0",
        "SO": "2",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "0.00",
        "RA9": "0.00",
        "rRA9": "0.00",
        "rRA9pf": "0.00",
        "FIP": "3.38",
        "WHIP": "0.43",
        "WAR": "0.10"
      }
    }
  ],
  [
    "fabeda3723738da553779d4dae9ca2e5b9f11dbc",
    {
      "id": "fabeda3723738da553779d4dae9ca2e5b9f11dbc",
      "name": "백준서",
      "team": "SSG",
      "position": "LF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "c9d580e51056afa943a71ed121e0482b38d72b13",
    {
      "id": "c9d580e51056afa943a71ed121e0482b38d72b13",
      "name": "정성곤",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2018",
        "Team": "KT",
        "Age": "21",
        "Pos.": "P",
        "G": "24",
        "GS": "0",
        "GR": "24",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "0",
        "S": "0",
        "HD": "5",
        "IP": "24.1",
        "ER": "8",
        "R": "8",
        "rRA": "9.20",
        "TBF": "104",
        "H": "23",
        "2B": "1",
        "3B": "0",
        "HR": "3",
        "BB": "11",
        "HP": "0",
        "IB": "0",
        "SO": "23",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "2.96",
        "RA9": "2.96",
        "rRA9": "3.40",
        "rRA9pf": "3.20",
        "FIP": "4.85",
        "WHIP": "1.40",
        "WAR": "0.89"
      }
    }
  ],
  [
    "cf7db30a7315926a37f71441a8bfe2d971a0faf2",
    {
      "id": "cf7db30a7315926a37f71441a8bfe2d971a0faf2",
      "name": "박성한",
      "team": "SSG",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "25",
        "Pos.": "SS",
        "oWAR": "3.50",
        "dWAR": "0.10",
        "G": "137",
        "PA": "564",
        "ePA": "559",
        "AB": "489",
        "R": "78",
        "H": "147",
        "2B": "24",
        "3B": "0",
        "HR": "10",
        "TB": "201",
        "RBI": "67",
        "SB": "13",
        "CS": "3",
        "BB": "64",
        "HP": "2",
        "IB": "3",
        "SO": "86",
        "GDP": "11",
        "SH": "3",
        "SF": "6",
        "AVG": "0.301",
        "OBP": "0.380",
        "SLG": "0.411",
        "OPS": "0.791",
        "R/ePA": "0.145",
        "wRC+": "104.0",
        "WAR": "3.60"
      }
    }
  ],
  [
    "7f773bdf17ed88cec36033bf3d7a530ac23c2b9b",
    {
      "id": "7f773bdf17ed88cec36033bf3d7a530ac23c2b9b",
      "name": "김규민",
      "team": "SSG",
      "position": "C",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "be64de9e114a7f838a13966c8d87dc9487fb4446",
    {
      "id": "be64de9e114a7f838a13966c8d87dc9487fb4446",
      "name": "윤성보",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "bcba959ee616431f1096b07d47cc4ba937878c08",
    {
      "id": "bcba959ee616431f1096b07d47cc4ba937878c08",
      "name": "최유빈",
      "team": "SSG",
      "position": "3B",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "12b79a3ba3624988db76b46a4724cbd20f421175",
    {
      "id": "12b79a3ba3624988db76b46a4724cbd20f421175",
      "name": "김민준",
      "team": "SSG",
      "position": "2B",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "c852ac42df9bd486a68455d901ebc7c636953b57",
    {
      "id": "c852ac42df9bd486a68455d901ebc7c636953b57",
      "name": "변건우",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "6d72120640b5a55cd827335702ef62065a92acc6",
    {
      "id": "6d72120640b5a55cd827335702ef62065a92acc6",
      "name": "김찬형",
      "team": "SSG",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "",
        "Age": "26",
        "Pos.": "2B",
        "oWAR": "-0.23",
        "dWAR": "-0.03",
        "G": "5",
        "PA": "9",
        "ePA": "9",
        "AB": "9",
        "R": "0",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "4",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.000",
        "SLG": "0.000",
        "OPS": "0.000",
        "R/ePA": "-0.190",
        "wRC+": "-139.6",
        "WAR": "-0.26"
      }
    }
  ],
  [
    "25d5c21b834850071893e24bc9228ccfa65ac70b",
    {
      "id": "25d5c21b834850071893e24bc9228ccfa65ac70b",
      "name": "김성현",
      "team": "SSG",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "",
        "Age": "36",
        "Pos.": "2B",
        "oWAR": "0.03",
        "dWAR": "-0.53",
        "G": "71",
        "PA": "167",
        "ePA": "162",
        "AB": "141",
        "R": "21",
        "H": "32",
        "2B": "3",
        "3B": "1",
        "HR": "1",
        "TB": "40",
        "RBI": "15",
        "SB": "2",
        "CS": "0",
        "BB": "16",
        "HP": "4",
        "IB": "0",
        "SO": "28",
        "GDP": "7",
        "SH": "5",
        "SF": "1",
        "AVG": "0.227",
        "OBP": "0.321",
        "SLG": "0.284",
        "OPS": "0.605",
        "R/ePA": "0.085",
        "wRC+": "60.3",
        "WAR": "-0.49"
      }
    }
  ],
  [
    "2a9e26cc69dad2d2282daaa1fb7067d90ddd530f",
    {
      "id": "2a9e26cc69dad2d2282daaa1fb7067d90ddd530f",
      "name": "최준우",
      "team": "SSG",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "24",
        "Pos.": "2B",
        "oWAR": "0.28",
        "dWAR": "-0.11",
        "G": "18",
        "PA": "34",
        "ePA": "34",
        "AB": "25",
        "R": "2",
        "H": "6",
        "2B": "2",
        "3B": "0",
        "HR": "0",
        "TB": "8",
        "RBI": "5",
        "SB": "0",
        "CS": "0",
        "BB": "9",
        "HP": "0",
        "IB": "0",
        "SO": "8",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.240",
        "OBP": "0.441",
        "SLG": "0.320",
        "OPS": "0.761",
        "R/ePA": "0.171",
        "wRC+": "126.9",
        "WAR": "0.17"
      }
    }
  ],
  [
    "0729f6fcf0f7745f2d4e6424bdd1713eaffbfdd2",
    {
      "id": "0729f6fcf0f7745f2d4e6424bdd1713eaffbfdd2",
      "name": "허진",
      "team": "SSG",
      "position": "2B",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "57a002afd55c5515be0183c79018e36e05980e4b",
    {
      "id": "57a002afd55c5515be0183c79018e36e05980e4b",
      "name": "현원회",
      "team": "SSG",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "",
        "Age": "22",
        "Pos.": "C",
        "oWAR": "-0.02",
        "dWAR": "0.00",
        "G": "1",
        "PA": "1",
        "ePA": "1",
        "AB": "1",
        "R": "0",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.000",
        "SLG": "0.000",
        "OPS": "0.000",
        "R/ePA": "-0.190",
        "wRC+": "-130.7",
        "WAR": "-0.02"
      }
    }
  ],
  [
    "c42bb12e7e4d206ab4702a2ca84f44e0b8474ce9",
    {
      "id": "c42bb12e7e4d206ab4702a2ca84f44e0b8474ce9",
      "name": "이찬혁",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2020",
        "Team": "LG",
        "Age": "21",
        "Pos.": "P",
        "G": "4",
        "GS": "0",
        "GR": "4",
        "GF": "4",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "3.1",
        "ER": "2",
        "R": "2",
        "rRA": "2.50",
        "TBF": "17",
        "H": "4",
        "2B": "0",
        "3B": "0",
        "HR": "1",
        "BB": "3",
        "HP": "1",
        "IB": "0",
        "SO": "1",
        "ROE": "0",
        "BK": "0",
        "WP": "1",
        "ERA": "5.40",
        "RA9": "5.40",
        "rRA9": "6.75",
        "rRA9pf": "6.65",
        "FIP": "10.54",
        "WHIP": "2.10",
        "WAR": "0.02"
      }
    }
  ],
  [
    "db287b97d6d7637a066ef72c1d7c678ebdd09c54",
    {
      "id": "db287b97d6d7637a066ef72c1d7c678ebdd09c54",
      "name": "박세직",
      "team": "SSG",
      "position": "LF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "dafbbb011cdb771de97549ce5a8e05bb58629e61",
    {
      "id": "dafbbb011cdb771de97549ce5a8e05bb58629e61",
      "name": "안상현",
      "team": "SSG",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "26",
        "Pos.": "2B",
        "oWAR": "-0.58",
        "dWAR": "-0.22",
        "G": "37",
        "PA": "75",
        "ePA": "74",
        "AB": "70",
        "R": "3",
        "H": "12",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "TB": "13",
        "RBI": "2",
        "SB": "1",
        "CS": "1",
        "BB": "4",
        "HP": "0",
        "IB": "0",
        "SO": "14",
        "GDP": "0",
        "SH": "1",
        "SF": "0",
        "AVG": "0.171",
        "OBP": "0.216",
        "SLG": "0.186",
        "OPS": "0.402",
        "R/ePA": "-0.010",
        "wRC+": "-7.2",
        "WAR": "-0.80"
      }
    }
  ],
  [
    "8f4bc19dca79c21de069e5d0934f6617f519d9dd",
    {
      "id": "8f4bc19dca79c21de069e5d0934f6617f519d9dd",
      "name": "백승건",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "",
        "Age": "23",
        "Pos.": "P",
        "G": "10",
        "GS": "0",
        "GR": "10",
        "GF": "7",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "9.1",
        "ER": "8",
        "R": "9",
        "rRA": "11.05",
        "TBF": "51",
        "H": "13",
        "2B": "2",
        "3B": "0",
        "HR": "2",
        "BB": "9",
        "HP": "0",
        "IB": "0",
        "SO": "5",
        "ROE": "2",
        "BK": "0",
        "WP": "1",
        "ERA": "7.71",
        "RA9": "8.68",
        "rRA9": "10.66",
        "rRA9pf": "10.05",
        "FIP": "8.40",
        "WHIP": "2.36",
        "WAR": "-0.12"
      }
    }
  ],
  [
    "8db293ba2a610b68ede5eae67c98a5a44771ea99",
    {
      "id": "8db293ba2a610b68ede5eae67c98a5a44771ea99",
      "name": "전경원",
      "team": "SSG",
      "position": "C",
      "record": {
        "Div.": "베스트",
        "Year": "2022",
        "Team": "SSG",
        "Age": "22",
        "Pos.": "C",
        "oWAR": "-0.02",
        "dWAR": "0.00",
        "G": "1",
        "PA": "1",
        "ePA": "1",
        "AB": "1",
        "R": "0",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "0",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.000",
        "SLG": "0.000",
        "OPS": "0.000",
        "R/ePA": "-0.160",
        "wRC+": "-133.8",
        "WAR": "-0.02"
      }
    }
  ],
  [
    "fcc1e38a73d36f4fa15daabd2c32b927512b2d06",
    {
      "id": "fcc1e38a73d36f4fa15daabd2c32b927512b2d06",
      "name": "하재훈",
      "team": "SSG",
      "position": "C",
      "record": {
        "Div.": "베스트",
        "Year": "2019",
        "Team": "SK",
        "Age": "28",
        "Pos.": "P",
        "oWAR": "",
        "dWAR": "",
        "G": "61",
        "PA": "",
        "ePA": "",
        "AB": "",
        "R": "13",
        "H": "47",
        "2B": "11",
        "3B": "0",
        "HR": "1",
        "TB": "",
        "RBI": "",
        "SB": "",
        "CS": "",
        "BB": "26",
        "HP": "1",
        "IB": "2",
        "SO": "64",
        "GDP": "",
        "SH": "",
        "SF": "",
        "AVG": "",
        "OBP": "",
        "SLG": "",
        "OPS": "",
        "R/ePA": "",
        "wRC+": "",
        "WAR": "3.10"
      }
    }
  ],
  [
    "04eb0b497ee64248486811f1eb788297b4b6d40f",
    {
      "id": "04eb0b497ee64248486811f1eb788297b4b6d40f",
      "name": "최정",
      "team": "SSG",
      "position": "3B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "",
        "Age": "36",
        "Pos.": "3B",
        "oWAR": "4.65",
        "dWAR": "-0.09",
        "G": "129",
        "PA": "550",
        "ePA": "550",
        "AB": "468",
        "R": "93",
        "H": "136",
        "2B": "27",
        "3B": "2",
        "HR": "37",
        "TB": "278",
        "RBI": "107",
        "SB": "5",
        "CS": "1",
        "BB": "55",
        "HP": "20",
        "IB": "1",
        "SO": "114",
        "GDP": "10",
        "SH": "0",
        "SF": "7",
        "AVG": "0.291",
        "OBP": "0.384",
        "SLG": "0.594",
        "OPS": "0.978",
        "R/ePA": "0.202",
        "wRC+": "145.9",
        "WAR": "4.55"
      }
    }
  ],
  [
    "fc07b1346c763fd3d88765808e1af35d5001796e",
    {
      "id": "fc07b1346c763fd3d88765808e1af35d5001796e",
      "name": "고효준",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "",
        "Age": "40",
        "Pos.": "P",
        "G": "26",
        "GS": "0",
        "GR": "26",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "1",
        "S": "0",
        "HD": "5",
        "IP": "22.0",
        "ER": "20",
        "R": "21",
        "rRA": "19.25",
        "TBF": "109",
        "H": "23",
        "2B": "4",
        "3B": "0",
        "HR": "4",
        "BB": "20",
        "HP": "1",
        "IB": "1",
        "SO": "27",
        "ROE": "1",
        "BK": "0",
        "WP": "2",
        "ERA": "8.18",
        "RA9": "8.59",
        "rRA9": "7.88",
        "rRA9pf": "7.36",
        "FIP": "6.25",
        "WHIP": "1.95",
        "WAR": "-0.16"
      }
    }
  ],
  [
    "bc969d3119e41546f4af46f3766bbad8fcefb150",
    {
      "id": "bc969d3119e41546f4af46f3766bbad8fcefb150",
      "name": "이건욱",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "",
        "Age": "28",
        "Pos.": "P",
        "G": "10",
        "GS": "2",
        "GR": "8",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "2",
        "S": "0",
        "HD": "0",
        "IP": "19.0",
        "ER": "24",
        "R": "25",
        "rRA": "24.30",
        "TBF": "106",
        "H": "34",
        "2B": "4",
        "3B": "0",
        "HR": "5",
        "BB": "15",
        "HP": "2",
        "IB": "0",
        "SO": "17",
        "ROE": "2",
        "BK": "0",
        "WP": "3",
        "ERA": "11.37",
        "RA9": "11.84",
        "rRA9": "11.51",
        "rRA9pf": "11.46",
        "FIP": "7.87",
        "WHIP": "2.58",
        "WAR": "-0.58"
      }
    }
  ],
  [
    "f2fbed4a6b310eeeec704fdd329a44521674f070",
    {
      "id": "f2fbed4a6b310eeeec704fdd329a44521674f070",
      "name": "추신수",
      "team": "SSG",
      "position": "RF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "41",
        "Pos.": "DH",
        "oWAR": "0.64",
        "dWAR": "0.02",
        "G": "78",
        "PA": "302",
        "ePA": "300",
        "AB": "253",
        "R": "40",
        "H": "71",
        "2B": "14",
        "3B": "1",
        "HR": "5",
        "TB": "102",
        "RBI": "37",
        "SB": "5",
        "CS": "1",
        "BB": "37",
        "HP": "4",
        "IB": "0",
        "SO": "68",
        "GDP": "2",
        "SH": "2",
        "SF": "6",
        "AVG": "0.281",
        "OBP": "0.373",
        "SLG": "0.403",
        "OPS": "0.776",
        "R/ePA": "0.143",
        "wRC+": "102.0",
        "WAR": "0.67"
      }
    }
  ],
  [
    "ead2179954544598f45b80f43c69db635c27a197",
    {
      "id": "ead2179954544598f45b80f43c69db635c27a197",
      "name": "고명준",
      "team": "SSG",
      "position": "1B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "21",
        "Pos.": "1B",
        "oWAR": "-0.97",
        "dWAR": "0.21",
        "G": "106",
        "PA": "366",
        "ePA": "366",
        "AB": "340",
        "R": "33",
        "H": "85",
        "2B": "13",
        "3B": "0",
        "HR": "11",
        "TB": "131",
        "RBI": "45",
        "SB": "3",
        "CS": "4",
        "BB": "25",
        "HP": "1",
        "IB": "0",
        "SO": "90",
        "GDP": "4",
        "SH": "0",
        "SF": "0",
        "AVG": "0.250",
        "OBP": "0.303",
        "SLG": "0.385",
        "OPS": "0.688",
        "R/ePA": "0.095",
        "wRC+": "68.1",
        "WAR": "-0.76"
      }
    }
  ],
  [
    "d0fa26a6ec3108fe37b7a7a0490011ebf079a81e",
    {
      "id": "d0fa26a6ec3108fe37b7a7a0490011ebf079a81e",
      "name": "조병현",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "21",
        "Pos.": "P",
        "G": "76",
        "GS": "0",
        "GR": "76",
        "GF": "27",
        "CG": "0",
        "SHO": "0",
        "W": "4",
        "L": "6",
        "S": "12",
        "HD": "12",
        "IP": "73.0",
        "ER": "29",
        "R": "34",
        "rRA": "32.65",
        "TBF": "302",
        "H": "52",
        "2B": "10",
        "3B": "0",
        "HR": "8",
        "BB": "31",
        "HP": "2",
        "IB": "3",
        "SO": "96",
        "ROE": "5",
        "BK": "0",
        "WP": "5",
        "ERA": "3.58",
        "RA9": "4.19",
        "rRA9": "4.03",
        "rRA9pf": "3.80",
        "FIP": "3.53",
        "WHIP": "1.14",
        "WAR": "2.37"
      }
    }
  ],
  [
    "22466670f21846a2a315d70d7324a42b935e920c",
    {
      "id": "22466670f21846a2a315d70d7324a42b935e920c",
      "name": "조형우",
      "team": "SSG",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "21",
        "Pos.": "C",
        "oWAR": "0.08",
        "dWAR": "-0.16",
        "G": "19",
        "PA": "38",
        "ePA": "38",
        "AB": "33",
        "R": "4",
        "H": "8",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "8",
        "RBI": "4",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "4",
        "IB": "0",
        "SO": "6",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.242",
        "OBP": "0.342",
        "SLG": "0.242",
        "OPS": "0.584",
        "R/ePA": "0.094",
        "wRC+": "66.6",
        "WAR": "-0.08"
      }
    }
  ],
  [
    "fc322aca02f16933a6fac6af0aa5f371b782e1ac",
    {
      "id": "fc322aca02f16933a6fac6af0aa5f371b782e1ac",
      "name": "허민혁",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "e8a19a3cc8fabda1310b426d5ff01fbf71cc01cc",
    {
      "id": "e8a19a3cc8fabda1310b426d5ff01fbf71cc01cc",
      "name": "서진용",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "31",
        "Pos.": "P",
        "G": "51",
        "GS": "0",
        "GR": "51",
        "GF": "3",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "6",
        "IP": "47.0",
        "ER": "29",
        "R": "34",
        "rRA": "40.85",
        "TBF": "211",
        "H": "52",
        "2B": "11",
        "3B": "1",
        "HR": "5",
        "BB": "26",
        "HP": "0",
        "IB": "2",
        "SO": "38",
        "ROE": "3",
        "BK": "0",
        "WP": "4",
        "ERA": "5.55",
        "RA9": "6.51",
        "rRA9": "7.82",
        "rRA9pf": "7.52",
        "FIP": "5.09",
        "WHIP": "1.66",
        "WAR": "-0.35"
      }
    }
  ],
  [
    "d598e8a8256d08f70722d8f55f4cc97a727b7233",
    {
      "id": "d598e8a8256d08f70722d8f55f4cc97a727b7233",
      "name": "최상민",
      "team": "SSG",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "24",
        "Pos.": "RF",
        "oWAR": "-0.01",
        "dWAR": "0.13",
        "G": "43",
        "PA": "13",
        "ePA": "13",
        "AB": "12",
        "R": "9",
        "H": "2",
        "2B": "2",
        "3B": "0",
        "HR": "0",
        "TB": "4",
        "RBI": "1",
        "SB": "2",
        "CS": "1",
        "BB": "0",
        "HP": "1",
        "IB": "0",
        "SO": "5",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.167",
        "OBP": "0.231",
        "SLG": "0.333",
        "OPS": "0.564",
        "R/ePA": "0.058",
        "wRC+": "42.6",
        "WAR": "0.12"
      }
    }
  ],
  [
    "b8012b76207163e6eb2b1d1b514f90c38bd4d019",
    {
      "id": "b8012b76207163e6eb2b1d1b514f90c38bd4d019",
      "name": "김민식",
      "team": "SSG",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "34",
        "Pos.": "C",
        "oWAR": "-0.05",
        "dWAR": "-0.21",
        "G": "45",
        "PA": "126",
        "ePA": "122",
        "AB": "106",
        "R": "10",
        "H": "22",
        "2B": "5",
        "3B": "0",
        "HR": "1",
        "TB": "30",
        "RBI": "10",
        "SB": "0",
        "CS": "0",
        "BB": "16",
        "HP": "0",
        "IB": "0",
        "SO": "33",
        "GDP": "2",
        "SH": "4",
        "SF": "0",
        "AVG": "0.208",
        "OBP": "0.311",
        "SLG": "0.283",
        "OPS": "0.594",
        "R/ePA": "0.079",
        "wRC+": "56.5",
        "WAR": "-0.26"
      }
    }
  ],
  [
    "bbf3939f724351e4cb148c0300d6c2a521d801c7",
    {
      "id": "bbf3939f724351e4cb148c0300d6c2a521d801c7",
      "name": "에레디아",
      "team": "SSG",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "32",
        "Pos.": "LF",
        "oWAR": "4.09",
        "dWAR": "0.33",
        "G": "136",
        "PA": "591",
        "ePA": "588",
        "AB": "541",
        "R": "82",
        "H": "195",
        "2B": "31",
        "3B": "1",
        "HR": "21",
        "TB": "291",
        "RBI": "118",
        "SB": "4",
        "CS": "3",
        "BB": "28",
        "HP": "13",
        "IB": "3",
        "SO": "73",
        "GDP": "12",
        "SH": "0",
        "SF": "9",
        "AVG": "0.360",
        "OBP": "0.399",
        "SLG": "0.538",
        "OPS": "0.937",
        "R/ePA": "0.191",
        "wRC+": "137.2",
        "WAR": "4.42"
      }
    }
  ],
  [
    "33e3efe77b31e6273dc8d81607d70fb8393d35b4",
    {
      "id": "33e3efe77b31e6273dc8d81607d70fb8393d35b4",
      "name": "송영진",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "19",
        "Pos.": "P",
        "G": "26",
        "GS": "20",
        "GR": "6",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "5",
        "L": "10",
        "S": "0",
        "HD": "0",
        "IP": "99.1",
        "ER": "64",
        "R": "74",
        "rRA": "68.15",
        "TBF": "456",
        "H": "112",
        "2B": "19",
        "3B": "2",
        "HR": "10",
        "BB": "51",
        "HP": "1",
        "IB": "0",
        "SO": "67",
        "ROE": "7",
        "BK": "2",
        "WP": "8",
        "ERA": "5.80",
        "RA9": "6.70",
        "rRA9": "6.17",
        "rRA9pf": "6.06",
        "FIP": "5.33",
        "WHIP": "1.64",
        "WAR": "1.04"
      }
    }
  ],
  [
    "53f51140ec88bf458e1a0b991f4f8b9fdf31b31f",
    {
      "id": "53f51140ec88bf458e1a0b991f4f8b9fdf31b31f",
      "name": "김광현",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "",
        "Age": "35",
        "Pos.": "P",
        "G": "31",
        "GS": "31",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "12",
        "L": "10",
        "S": "0",
        "HD": "0",
        "IP": "162.1",
        "ER": "89",
        "R": "95",
        "rRA": "94.65",
        "TBF": "709",
        "H": "162",
        "2B": "29",
        "3B": "0",
        "HR": "24",
        "BB": "73",
        "HP": "6",
        "IB": "1",
        "SO": "154",
        "ROE": "6",
        "BK": "0",
        "WP": "8",
        "ERA": "4.93",
        "RA9": "5.27",
        "rRA9": "5.25",
        "rRA9pf": "5.08",
        "FIP": "5.10",
        "WHIP": "1.45",
        "WAR": "3.42"
      }
    }
  ],
  [
    "7af757b000d86c96e186fc90df2d269c18dcd0f7",
    {
      "id": "7af757b000d86c96e186fc90df2d269c18dcd0f7",
      "name": "박대온",
      "team": "SSG",
      "position": "C",
      "record": {
        "Div.": "베스트",
        "Year": "2023",
        "Team": "NC",
        "Age": "27",
        "Pos.": "C",
        "oWAR": "0.29",
        "dWAR": "-0.07",
        "G": "25",
        "PA": "33",
        "ePA": "33",
        "AB": "28",
        "R": "6",
        "H": "8",
        "2B": "3",
        "3B": "0",
        "HR": "0",
        "TB": "11",
        "RBI": "3",
        "SB": "0",
        "CS": "0",
        "BB": "3",
        "HP": "1",
        "IB": "0",
        "SO": "8",
        "GDP": "0",
        "SH": "0",
        "SF": "1",
        "AVG": "0.286",
        "OBP": "0.364",
        "SLG": "0.393",
        "OPS": "0.757",
        "R/ePA": "0.137",
        "wRC+": "115.5",
        "WAR": "0.22"
      }
    }
  ],
  [
    "e08afb944909278204557775cb95c6087dc15155",
    {
      "id": "e08afb944909278204557775cb95c6087dc15155",
      "name": "이정범",
      "team": "SSG",
      "position": "LF",
      "record": {
        "Div.": "베스트",
        "Year": "2021",
        "Team": "SSG",
        "Age": "22",
        "Pos.": "LF",
        "oWAR": "0.28",
        "dWAR": "-0.30",
        "G": "19",
        "PA": "66",
        "ePA": "66",
        "AB": "59",
        "R": "8",
        "H": "15",
        "2B": "4",
        "3B": "0",
        "HR": "3",
        "TB": "28",
        "RBI": "9",
        "SB": "0",
        "CS": "0",
        "BB": "7",
        "HP": "0",
        "IB": "0",
        "SO": "15",
        "GDP": "1",
        "SH": "0",
        "SF": "0",
        "AVG": "0.254",
        "OBP": "0.333",
        "SLG": "0.475",
        "OPS": "0.808",
        "R/ePA": "0.143",
        "wRC+": "113.4",
        "WAR": "-0.02"
      }
    }
  ],
  [
    "d5c8b560fb384186422287ac443177068224e8a8",
    {
      "id": "d5c8b560fb384186422287ac443177068224e8a8",
      "name": "신헌민",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "21",
        "Pos.": "P",
        "G": "3",
        "GS": "0",
        "GR": "3",
        "GF": "3",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "3.0",
        "ER": "6",
        "R": "7",
        "rRA": "7.00",
        "TBF": "18",
        "H": "7",
        "2B": "2",
        "3B": "0",
        "HR": "2",
        "BB": "3",
        "HP": "1",
        "IB": "0",
        "SO": "0",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "18.00",
        "RA9": "21.00",
        "rRA9": "21.00",
        "rRA9pf": "20.88",
        "FIP": "16.27",
        "WHIP": "3.33",
        "WAR": "-0.21"
      }
    }
  ],
  [
    "8c294db28b1ba4a3e4fa29297e4a0d09cd4f045a",
    {
      "id": "8c294db28b1ba4a3e4fa29297e4a0d09cd4f045a",
      "name": "더거",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "28",
        "Pos.": "P",
        "G": "6",
        "GS": "6",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "3",
        "S": "0",
        "HD": "0",
        "IP": "22.2",
        "ER": "32",
        "R": "33",
        "rRA": "33.00",
        "TBF": "116",
        "H": "37",
        "2B": "7",
        "3B": "3",
        "HR": "2",
        "BB": "10",
        "HP": "3",
        "IB": "0",
        "SO": "18",
        "ROE": "1",
        "BK": "0",
        "WP": "0",
        "ERA": "12.71",
        "RA9": "13.10",
        "rRA9": "13.10",
        "rRA9pf": "12.80",
        "FIP": "5.04",
        "WHIP": "2.07",
        "WAR": "-0.84"
      }
    }
  ],
  [
    "ed56997916530f28426cad9e0256ec91a7119db2",
    {
      "id": "ed56997916530f28426cad9e0256ec91a7119db2",
      "name": "앤더슨",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "29",
        "Pos.": "P",
        "G": "24",
        "GS": "23",
        "GR": "1",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "11",
        "L": "3",
        "S": "0",
        "HD": "0",
        "IP": "115.2",
        "ER": "50",
        "R": "55",
        "rRA": "53.80",
        "TBF": "496",
        "H": "98",
        "2B": "13",
        "3B": "1",
        "HR": "11",
        "BB": "53",
        "HP": "8",
        "IB": "0",
        "SO": "158",
        "ROE": "4",
        "BK": "1",
        "WP": "5",
        "ERA": "3.89",
        "RA9": "4.28",
        "rRA9": "4.19",
        "rRA9pf": "3.93",
        "FIP": "3.55",
        "WHIP": "1.31",
        "WAR": "3.86"
      }
    }
  ],
  [
    "eb6f79ef2352014fe2340b4a460dda75de007d16",
    {
      "id": "eb6f79ef2352014fe2340b4a460dda75de007d16",
      "name": "한두솔",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "26",
        "Pos.": "P",
        "G": "69",
        "GS": "0",
        "GR": "69",
        "GF": "8",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "1",
        "S": "0",
        "HD": "3",
        "IP": "59.1",
        "ER": "33",
        "R": "40",
        "rRA": "35.90",
        "TBF": "273",
        "H": "62",
        "2B": "10",
        "3B": "2",
        "HR": "3",
        "BB": "31",
        "HP": "3",
        "IB": "2",
        "SO": "68",
        "ROE": "5",
        "BK": "0",
        "WP": "1",
        "ERA": "5.01",
        "RA9": "6.07",
        "rRA9": "5.45",
        "rRA9pf": "5.24",
        "FIP": "3.61",
        "WHIP": "1.57",
        "WAR": "0.86"
      }
    }
  ],
  [
    "6fcd4cacc387a72503be4123d2bbea8cae4bcd6f",
    {
      "id": "6fcd4cacc387a72503be4123d2bbea8cae4bcd6f",
      "name": "한유섬",
      "team": "SSG",
      "position": "RF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "34",
        "Pos.": "RF",
        "oWAR": "0.43",
        "dWAR": "-0.67",
        "G": "132",
        "PA": "523",
        "ePA": "519",
        "AB": "464",
        "R": "64",
        "H": "109",
        "2B": "29",
        "3B": "0",
        "HR": "24",
        "TB": "210",
        "RBI": "87",
        "SB": "0",
        "CS": "1",
        "BB": "46",
        "HP": "9",
        "IB": "3",
        "SO": "142",
        "GDP": "2",
        "SH": "1",
        "SF": "3",
        "AVG": "0.235",
        "OBP": "0.314",
        "SLG": "0.453",
        "OPS": "0.767",
        "R/ePA": "0.125",
        "wRC+": "89.3",
        "WAR": "-0.23"
      }
    }
  ],
  [
    "49cf709db4d60c265eaca029eaff23cc9beed2ef",
    {
      "id": "49cf709db4d60c265eaca029eaff23cc9beed2ef",
      "name": "김태윤",
      "team": "SSG",
      "position": "2B",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "b6d23b7e65407535fb1167d4ab0b0ab7bfac20c7",
    {
      "id": "b6d23b7e65407535fb1167d4ab0b0ab7bfac20c7",
      "name": "오태곤",
      "team": "SSG",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "32",
        "Pos.": "1B",
        "oWAR": "1.19",
        "dWAR": "0.09",
        "G": "117",
        "PA": "288",
        "ePA": "281",
        "AB": "247",
        "R": "43",
        "H": "68",
        "2B": "16",
        "3B": "0",
        "HR": "9",
        "TB": "111",
        "RBI": "36",
        "SB": "27",
        "CS": "4",
        "BB": "31",
        "HP": "1",
        "IB": "2",
        "SO": "73",
        "GDP": "4",
        "SH": "6",
        "SF": "3",
        "AVG": "0.275",
        "OBP": "0.355",
        "SLG": "0.449",
        "OPS": "0.804",
        "R/ePA": "0.143",
        "wRC+": "102.4",
        "WAR": "1.29"
      }
    }
  ],
  [
    "be04be204fe1e3420b809f7e8e7f682f21321835",
    {
      "id": "be04be204fe1e3420b809f7e8e7f682f21321835",
      "name": "노경은",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "",
        "Age": "39",
        "Pos.": "P",
        "G": "77",
        "GS": "0",
        "GR": "77",
        "GF": "7",
        "CG": "0",
        "SHO": "0",
        "W": "8",
        "L": "5",
        "S": "0",
        "HD": "38",
        "IP": "83.2",
        "ER": "27",
        "R": "30",
        "rRA": "36.00",
        "TBF": "350",
        "H": "71",
        "2B": "10",
        "3B": "1",
        "HR": "10",
        "BB": "32",
        "HP": "4",
        "IB": "4",
        "SO": "71",
        "ROE": "2",
        "BK": "0",
        "WP": "3",
        "ERA": "2.90",
        "RA9": "3.23",
        "rRA9": "3.87",
        "rRA9pf": "3.75",
        "FIP": "4.69",
        "WHIP": "1.23",
        "WAR": "2.95"
      }
    }
  ],
  [
    "6971111a82b17a3b2ce0851bfca5d076af4c1d90",
    {
      "id": "6971111a82b17a3b2ce0851bfca5d076af4c1d90",
      "name": "김건우",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2021",
        "Team": "SSG",
        "Age": "18",
        "Pos.": "P",
        "G": "6",
        "GS": "4",
        "GR": "2",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "11.0",
        "ER": "6",
        "R": "7",
        "rRA": "4.95",
        "TBF": "54",
        "H": "8",
        "2B": "1",
        "3B": "0",
        "HR": "1",
        "BB": "12",
        "HP": "0",
        "IB": "0",
        "SO": "7",
        "ROE": "1",
        "BK": "0",
        "WP": "1",
        "ERA": "4.91",
        "RA9": "5.73",
        "rRA9": "4.05",
        "rRA9pf": "4.13",
        "FIP": "6.64",
        "WHIP": "1.82",
        "WAR": "0.28"
      }
    }
  ],
  [
    "284f20bdc23b44743e7c919818f92831d4d9b791",
    {
      "id": "284f20bdc23b44743e7c919818f92831d4d9b791",
      "name": "이기순",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "20",
        "Pos.": "P",
        "G": "10",
        "GS": "2",
        "GR": "8",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "19.2",
        "ER": "8",
        "R": "10",
        "rRA": "9.15",
        "TBF": "94",
        "H": "12",
        "2B": "1",
        "3B": "0",
        "HR": "2",
        "BB": "23",
        "HP": "1",
        "IB": "0",
        "SO": "24",
        "ROE": "0",
        "BK": "0",
        "WP": "1",
        "ERA": "3.66",
        "RA9": "4.58",
        "rRA9": "4.19",
        "rRA9pf": "4.12",
        "FIP": "6.14",
        "WHIP": "1.78",
        "WAR": "0.47"
      }
    }
  ],
  [
    "97cdb80adf9b138f66fc15e736113c3641be15b0",
    {
      "id": "97cdb80adf9b138f66fc15e736113c3641be15b0",
      "name": "신범수",
      "team": "SSG",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "",
        "Age": "25",
        "Pos.": "DH",
        "oWAR": "-0.07",
        "dWAR": "0.00",
        "G": "11",
        "PA": "29",
        "ePA": "29",
        "AB": "27",
        "R": "4",
        "H": "6",
        "2B": "3",
        "3B": "0",
        "HR": "0",
        "TB": "9",
        "RBI": "2",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "1",
        "IB": "0",
        "SO": "5",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.222",
        "OBP": "0.276",
        "SLG": "0.333",
        "OPS": "0.609",
        "R/ePA": "0.074",
        "wRC+": "51.8",
        "WAR": "-0.07"
      }
    }
  ],
  [
    "965b5f514c071d5219247ff140f927480d5766ce",
    {
      "id": "965b5f514c071d5219247ff140f927480d5766ce",
      "name": "박민호",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "",
        "Age": "31",
        "Pos.": "P",
        "G": "19",
        "GS": "0",
        "GR": "19",
        "GF": "8",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "22.2",
        "ER": "12",
        "R": "14",
        "rRA": "17.10",
        "TBF": "98",
        "H": "25",
        "2B": "3",
        "3B": "0",
        "HR": "4",
        "BB": "7",
        "HP": "2",
        "IB": "1",
        "SO": "10",
        "ROE": "1",
        "BK": "0",
        "WP": "0",
        "ERA": "4.76",
        "RA9": "5.56",
        "rRA9": "6.79",
        "rRA9pf": "6.27",
        "FIP": "6.29",
        "WHIP": "1.41",
        "WAR": "0.15"
      }
    }
  ],
  [
    "70e6fe29be714213ae9f262d1d27e309791d6a8a",
    {
      "id": "70e6fe29be714213ae9f262d1d27e309791d6a8a",
      "name": "문승원",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "",
        "Age": "34",
        "Pos.": "P",
        "G": "62",
        "GS": "0",
        "GR": "62",
        "GF": "37",
        "CG": "0",
        "SHO": "0",
        "W": "6",
        "L": "1",
        "S": "20",
        "HD": "6",
        "IP": "60.0",
        "ER": "30",
        "R": "33",
        "rRA": "35.75",
        "TBF": "265",
        "H": "67",
        "2B": "7",
        "3B": "0",
        "HR": "10",
        "BB": "24",
        "HP": "1",
        "IB": "3",
        "SO": "53",
        "ROE": "3",
        "BK": "0",
        "WP": "2",
        "ERA": "4.50",
        "RA9": "4.95",
        "rRA9": "5.36",
        "rRA9pf": "5.05",
        "FIP": "5.16",
        "WHIP": "1.52",
        "WAR": "1.06"
      }
    }
  ],
  [
    "1db3551f4faaebf712244b3ade303840ef4289b4",
    {
      "id": "1db3551f4faaebf712244b3ade303840ef4289b4",
      "name": "김택형",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "27",
        "Pos.": "P",
        "G": "6",
        "GS": "0",
        "GR": "6",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "7.0",
        "ER": "7",
        "R": "7",
        "rRA": "6.15",
        "TBF": "37",
        "H": "10",
        "2B": "1",
        "3B": "0",
        "HR": "1",
        "BB": "6",
        "HP": "1",
        "IB": "1",
        "SO": "1",
        "ROE": "0",
        "BK": "1",
        "WP": "1",
        "ERA": "9.00",
        "RA9": "9.00",
        "rRA9": "7.91",
        "rRA9pf": "7.90",
        "FIP": "8.64",
        "WHIP": "2.29",
        "WAR": "-0.05"
      }
    }
  ],
  [
    "ce29edb0a3ecba066479aa06ed958897bb19fc3f",
    {
      "id": "ce29edb0a3ecba066479aa06ed958897bb19fc3f",
      "name": "김지현",
      "team": "SSG",
      "position": "C",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "f364de85d05237b0b4b5fb5f4371db5cf79d1858",
    {
      "id": "f364de85d05237b0b4b5fb5f4371db5cf79d1858",
      "name": "유호식",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "28563b3f5f34e367658c206bc4890e827be0fab0",
    {
      "id": "28563b3f5f34e367658c206bc4890e827be0fab0",
      "name": "이승훈",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "5e8257bd48c526c756c1fbb128e3bad9a73c8517",
    {
      "id": "5e8257bd48c526c756c1fbb128e3bad9a73c8517",
      "name": "오원석",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "22",
        "Pos.": "P",
        "G": "29",
        "GS": "25",
        "GR": "4",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "6",
        "L": "9",
        "S": "0",
        "HD": "1",
        "IP": "121.2",
        "ER": "68",
        "R": "72",
        "rRA": "68.70",
        "TBF": "550",
        "H": "122",
        "2B": "14",
        "3B": "1",
        "HR": "17",
        "BB": "65",
        "HP": "13",
        "IB": "1",
        "SO": "112",
        "ROE": "6",
        "BK": "0",
        "WP": "3",
        "ERA": "5.03",
        "RA9": "5.33",
        "rRA9": "5.08",
        "rRA9pf": "4.96",
        "FIP": "5.52",
        "WHIP": "1.54",
        "WAR": "2.67"
      }
    }
  ],
  [
    "de76daa81cd89e4526af58b6c23e61a3b38990b5",
    {
      "id": "de76daa81cd89e4526af58b6c23e61a3b38990b5",
      "name": "안현서",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "e4dd254b7eff44d704db8efa8fc8c9e18db8a72d",
    {
      "id": "e4dd254b7eff44d704db8efa8fc8c9e18db8a72d",
      "name": "강진성",
      "team": "SSG",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "",
        "Age": "30",
        "Pos.": "DH",
        "oWAR": "-0.26",
        "dWAR": "0.10",
        "G": "16",
        "PA": "31",
        "ePA": "30",
        "AB": "27",
        "R": "4",
        "H": "5",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "5",
        "RBI": "2",
        "SB": "0",
        "CS": "0",
        "BB": "3",
        "HP": "0",
        "IB": "0",
        "SO": "5",
        "GDP": "0",
        "SH": "1",
        "SF": "0",
        "AVG": "0.185",
        "OBP": "0.267",
        "SLG": "0.185",
        "OPS": "0.452",
        "R/ePA": "0.022",
        "wRC+": "16.4",
        "WAR": "-0.16"
      }
    }
  ],
  [
    "fa51d594f997d5be415b4ef7b3faeca31a2cd4c0",
    {
      "id": "fa51d594f997d5be415b4ef7b3faeca31a2cd4c0",
      "name": "박종훈",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "",
        "Age": "32",
        "Pos.": "P",
        "G": "10",
        "GS": "9",
        "GR": "1",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "4",
        "S": "0",
        "HD": "0",
        "IP": "35.0",
        "ER": "27",
        "R": "28",
        "rRA": "26.10",
        "TBF": "164",
        "H": "37",
        "2B": "7",
        "3B": "0",
        "HR": "7",
        "BB": "21",
        "HP": "8",
        "IB": "0",
        "SO": "34",
        "ROE": "0",
        "BK": "0",
        "WP": "3",
        "ERA": "6.94",
        "RA9": "7.20",
        "rRA9": "6.71",
        "rRA9pf": "6.68",
        "FIP": "6.72",
        "WHIP": "1.66",
        "WAR": "0.20"
      }
    }
  ],
  [
    "652147483ee28f358c7cf38d785559ef3859c457",
    {
      "id": "652147483ee28f358c7cf38d785559ef3859c457",
      "name": "정동윤",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "26",
        "Pos.": "P",
        "G": "3",
        "GS": "0",
        "GR": "3",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "1.2",
        "ER": "0",
        "R": "0",
        "rRA": "0.35",
        "TBF": "7",
        "H": "1",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "2",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "0.00",
        "RA9": "0.00",
        "rRA9": "1.89",
        "rRA9pf": "1.76",
        "FIP": "3.06",
        "WHIP": "1.20",
        "WAR": "0.06"
      }
    }
  ],
  [
    "2dcf04062d85303dba629a01f20e6549d40a9319",
    {
      "id": "2dcf04062d85303dba629a01f20e6549d40a9319",
      "name": "최경모",
      "team": "SSG",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "27",
        "Pos.": "2B",
        "oWAR": "-0.17",
        "dWAR": "-0.04",
        "G": "50",
        "PA": "50",
        "ePA": "43",
        "AB": "35",
        "R": "12",
        "H": "6",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "6",
        "RBI": "0",
        "SB": "6",
        "CS": "3",
        "BB": "8",
        "HP": "0",
        "IB": "0",
        "SO": "7",
        "GDP": "2",
        "SH": "7",
        "SF": "0",
        "AVG": "0.171",
        "OBP": "0.326",
        "SLG": "0.171",
        "OPS": "0.497",
        "R/ePA": "0.062",
        "wRC+": "45.2",
        "WAR": "-0.21"
      }
    }
  ],
  [
    "097ca20a0cb4f5ee447280c7fd5ad92c9c782586",
    {
      "id": "097ca20a0cb4f5ee447280c7fd5ad92c9c782586",
      "name": "김성민",
      "team": "SSG",
      "position": "2B",
      "record": {
        "Div.": "베스트",
        "Year": "2020",
        "Team": "SK",
        "Age": "18",
        "Pos.": "2B",
        "oWAR": "0.18",
        "dWAR": "-0.20",
        "G": "9",
        "PA": "16",
        "ePA": "16",
        "AB": "14",
        "R": "4",
        "H": "4",
        "2B": "0",
        "3B": "0",
        "HR": "2",
        "TB": "10",
        "RBI": "4",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "1",
        "IB": "0",
        "SO": "3",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.286",
        "OBP": "0.375",
        "SLG": "0.714",
        "OPS": "1.089",
        "R/ePA": "0.225",
        "wRC+": "160.1",
        "WAR": "-0.01"
      }
    }
  ],
  [
    "cd21278e9a75607d72c9f409254cd7abe1105393",
    {
      "id": "cd21278e9a75607d72c9f409254cd7abe1105393",
      "name": "최지훈",
      "team": "SSG",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "26",
        "Pos.": "CF",
        "oWAR": "2.03",
        "dWAR": "-0.27",
        "G": "125",
        "PA": "543",
        "ePA": "537",
        "AB": "483",
        "R": "89",
        "H": "133",
        "2B": "22",
        "3B": "7",
        "HR": "11",
        "TB": "202",
        "RBI": "49",
        "SB": "32",
        "CS": "5",
        "BB": "50",
        "HP": "2",
        "IB": "0",
        "SO": "71",
        "GDP": "5",
        "SH": "6",
        "SF": "2",
        "AVG": "0.275",
        "OBP": "0.345",
        "SLG": "0.418",
        "OPS": "0.763",
        "R/ePA": "0.128",
        "wRC+": "92.2",
        "WAR": "1.76"
      }
    }
  ],
  [
    "4845f182be6574f5c909b2aa0be1e347112731ab",
    {
      "id": "4845f182be6574f5c909b2aa0be1e347112731ab",
      "name": "엘리아스",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "35",
        "Pos.": "P",
        "G": "22",
        "GS": "21",
        "GR": "1",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "7",
        "L": "7",
        "S": "0",
        "HD": "0",
        "IP": "123.2",
        "ER": "56",
        "R": "59",
        "rRA": "55.90",
        "TBF": "525",
        "H": "126",
        "2B": "14",
        "3B": "0",
        "HR": "13",
        "BB": "30",
        "HP": "6",
        "IB": "0",
        "SO": "104",
        "ROE": "10",
        "BK": "0",
        "WP": "3",
        "ERA": "4.08",
        "RA9": "4.29",
        "rRA9": "4.07",
        "rRA9pf": "3.92",
        "FIP": "4.25",
        "WHIP": "1.26",
        "WAR": "4.09"
      }
    }
  ],
  [
    "d7119f898cf6b54d062e6000d09aa4b7c2a31219",
    {
      "id": "d7119f898cf6b54d062e6000d09aa4b7c2a31219",
      "name": "전의산",
      "team": "SSG",
      "position": "1B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "23",
        "Pos.": "1B",
        "oWAR": "-1.09",
        "dWAR": "-0.26",
        "G": "34",
        "PA": "63",
        "ePA": "63",
        "AB": "57",
        "R": "3",
        "H": "5",
        "2B": "0",
        "3B": "0",
        "HR": "1",
        "TB": "8",
        "RBI": "3",
        "SB": "0",
        "CS": "1",
        "BB": "5",
        "HP": "0",
        "IB": "0",
        "SO": "15",
        "GDP": "3",
        "SH": "0",
        "SF": "1",
        "AVG": "0.088",
        "OBP": "0.159",
        "SLG": "0.140",
        "OPS": "0.299",
        "R/ePA": "-0.051",
        "wRC+": "-37.6",
        "WAR": "-1.35"
      }
    }
  ],
  [
    "90f8307e537660ee11fb3660b0d8e776bf09e671",
    {
      "id": "90f8307e537660ee11fb3660b0d8e776bf09e671",
      "name": "박시후",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "22",
        "Pos.": "P",
        "G": "11",
        "GS": "0",
        "GR": "11",
        "GF": "5",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "14.2",
        "ER": "11",
        "R": "11",
        "rRA": "10.65",
        "TBF": "70",
        "H": "18",
        "2B": "1",
        "3B": "0",
        "HR": "1",
        "BB": "7",
        "HP": "2",
        "IB": "0",
        "SO": "11",
        "ROE": "1",
        "BK": "0",
        "WP": "0",
        "ERA": "6.75",
        "RA9": "6.75",
        "rRA9": "6.54",
        "rRA9pf": "6.34",
        "FIP": "5.04",
        "WHIP": "1.70",
        "WAR": "0.12"
      }
    }
  ],
  [
    "0b6e98e8cd2ed79bc2c124889c26889bffc569e1",
    {
      "id": "0b6e98e8cd2ed79bc2c124889c26889bffc569e1",
      "name": "박정빈",
      "team": "SSG",
      "position": "LF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "cd22eb8bf67f3f66965ec1de4a88b5e159523df1",
    {
      "id": "cd22eb8bf67f3f66965ec1de4a88b5e159523df1",
      "name": "이지영",
      "team": "SSG",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "",
        "Age": "37",
        "Pos.": "C",
        "oWAR": "0.96",
        "dWAR": "0.57",
        "G": "123",
        "PA": "432",
        "ePA": "425",
        "AB": "398",
        "R": "45",
        "H": "111",
        "2B": "11",
        "3B": "1",
        "HR": "5",
        "TB": "139",
        "RBI": "50",
        "SB": "8",
        "CS": "1",
        "BB": "18",
        "HP": "7",
        "IB": "0",
        "SO": "35",
        "GDP": "10",
        "SH": "7",
        "SF": "2",
        "AVG": "0.279",
        "OBP": "0.320",
        "SLG": "0.349",
        "OPS": "0.669",
        "R/ePA": "0.097",
        "wRC+": "70.1",
        "WAR": "1.53"
      }
    }
  ],
  [
    "628a38b01b2e33fc3c1dbf4bb3b0800d1bdbd826",
    {
      "id": "628a38b01b2e33fc3c1dbf4bb3b0800d1bdbd826",
      "name": "채현우",
      "team": "SSG",
      "position": "LF",
      "record": {
        "Div.": "베스트",
        "Year": "2019",
        "Team": "SK",
        "Age": "23",
        "Pos.": "LF",
        "oWAR": "0.08",
        "dWAR": "0.00",
        "G": "9",
        "PA": "1",
        "ePA": "1",
        "AB": "1",
        "R": "2",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "3",
        "CS": "1",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.000",
        "SLG": "0.000",
        "OPS": "0.000",
        "R/ePA": "-0.164",
        "wRC+": "-131.9",
        "WAR": "0.08"
      }
    }
  ],
  [
    "2dfdb0837c32ccc5838096d6fa5b7f1b7ce04bcf",
    {
      "id": "2dfdb0837c32ccc5838096d6fa5b7f1b7ce04bcf",
      "name": "서상준",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2023",
        "Team": "SSG",
        "Age": "22",
        "Pos.": "P",
        "G": "2",
        "GS": "0",
        "GR": "2",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "2.0",
        "ER": "0",
        "R": "3",
        "rRA": "3.00",
        "TBF": "13",
        "H": "1",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "BB": "5",
        "HP": "0",
        "IB": "0",
        "SO": "3",
        "ROE": "1",
        "BK": "0",
        "WP": "1",
        "ERA": "0.00",
        "RA9": "13.50",
        "rRA9": "13.50",
        "rRA9pf": "12.38",
        "FIP": "7.85",
        "WHIP": "3.00",
        "WAR": "-0.06"
      }
    }
  ],
  [
    "b964521783ef26ce5760e5cfc9719ecff2ac5adb",
    {
      "id": "b964521783ef26ce5760e5cfc9719ecff2ac5adb",
      "name": "최수호",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "51ea0d6643d648100f2ba9ab5ff34c80b9d30873",
    {
      "id": "51ea0d6643d648100f2ba9ab5ff34c80b9d30873",
      "name": "류효승",
      "team": "SSG",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "27",
        "Pos.": "DH",
        "oWAR": "-0.05",
        "dWAR": "0.00",
        "G": "1",
        "PA": "2",
        "ePA": "2",
        "AB": "2",
        "R": "0",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "0",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.000",
        "SLG": "0.000",
        "OPS": "0.000",
        "R/ePA": "-0.190",
        "wRC+": "-146.7",
        "WAR": "-0.05"
      }
    }
  ],
  [
    "8ef219e8c0e592163b7b32a303f5efcc1725cfa1",
    {
      "id": "8ef219e8c0e592163b7b32a303f5efcc1725cfa1",
      "name": "김창평",
      "team": "SSG",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "",
        "Age": "23",
        "Pos.": "DH",
        "oWAR": "-0.10",
        "dWAR": "0.04",
        "G": "6",
        "PA": "9",
        "ePA": "9",
        "AB": "7",
        "R": "1",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "1",
        "CS": "0",
        "BB": "2",
        "HP": "0",
        "IB": "0",
        "SO": "4",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.222",
        "SLG": "0.000",
        "OPS": "0.222",
        "R/ePA": "-0.029",
        "wRC+": "-22.1",
        "WAR": "-0.06"
      }
    }
  ],
  [
    "ed9c669463a48b0a693ca5d2eb304015aa67e1d2",
    {
      "id": "ed9c669463a48b0a693ca5d2eb304015aa67e1d2",
      "name": "김정민",
      "team": "SSG",
      "position": "LF",
      "record": {
        "Div.": "베스트",
        "Year": "2023",
        "Team": "SSG",
        "Age": "18",
        "Pos.": "RF",
        "oWAR": "0.03",
        "dWAR": "-0.06",
        "G": "8",
        "PA": "2",
        "ePA": "2",
        "AB": "2",
        "R": "1",
        "H": "1",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "1",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.500",
        "OBP": "0.500",
        "SLG": "0.500",
        "OPS": "1.000",
        "R/ePA": "0.222",
        "wRC+": "180.0",
        "WAR": "-0.03"
      }
    }
  ],
  [
    "2d44b3e4aadb312fb0a00c1f89c6f6866a74e47f",
    {
      "id": "2d44b3e4aadb312fb0a00c1f89c6f6866a74e47f",
      "name": "김건이",
      "team": "SSG",
      "position": "C",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "78531ea4ce1ef309b545a9a82fb109b9c4c09370",
    {
      "id": "78531ea4ce1ef309b545a9a82fb109b9c4c09370",
      "name": "장지훈",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "25",
        "Pos.": "P",
        "G": "18",
        "GS": "0",
        "GR": "18",
        "GF": "7",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "21.1",
        "ER": "16",
        "R": "16",
        "rRA": "18.40",
        "TBF": "101",
        "H": "29",
        "2B": "6",
        "3B": "0",
        "HR": "3",
        "BB": "10",
        "HP": "1",
        "IB": "0",
        "SO": "10",
        "ROE": "1",
        "BK": "0",
        "WP": "0",
        "ERA": "6.75",
        "RA9": "6.75",
        "rRA9": "7.76",
        "rRA9pf": "7.62",
        "FIP": "6.30",
        "WHIP": "1.83",
        "WAR": "-0.09"
      }
    }
  ],
  [
    "18c266ae7709b2850d9ba02b0265410fbb3d331f",
    {
      "id": "18c266ae7709b2850d9ba02b0265410fbb3d331f",
      "name": "최민준",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "24",
        "Pos.": "P",
        "G": "32",
        "GS": "0",
        "GR": "32",
        "GF": "3",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "39.1",
        "ER": "34",
        "R": "36",
        "rRA": "41.20",
        "TBF": "202",
        "H": "61",
        "2B": "9",
        "3B": "1",
        "HR": "9",
        "BB": "24",
        "HP": "3",
        "IB": "1",
        "SO": "40",
        "ROE": "2",
        "BK": "0",
        "WP": "1",
        "ERA": "7.78",
        "RA9": "8.24",
        "rRA9": "9.43",
        "rRA9pf": "9.17",
        "FIP": "6.43",
        "WHIP": "2.16",
        "WAR": "-0.67"
      }
    }
  ],
  [
    "ea5e2f4dde2822782bb653e23aef92a4d697f29d",
    {
      "id": "ea5e2f4dde2822782bb653e23aef92a4d697f29d",
      "name": "류현곤",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "9b88e519ff585487a9a49c20f5b401c17ff281b2",
    {
      "id": "9b88e519ff585487a9a49c20f5b401c17ff281b2",
      "name": "최민창",
      "team": "SSG",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "",
        "Age": "27",
        "Pos.": "RF",
        "oWAR": "-0.06",
        "dWAR": "0.00",
        "G": "7",
        "PA": "3",
        "ePA": "3",
        "AB": "3",
        "R": "2",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.000",
        "SLG": "0.000",
        "OPS": "0.000",
        "R/ePA": "-0.190",
        "wRC+": "-133.8",
        "WAR": "-0.07"
      }
    }
  ],
  [
    "c7c1a09fd1d197e79ae52ec01a8ff8b97f66ef11",
    {
      "id": "c7c1a09fd1d197e79ae52ec01a8ff8b97f66ef11",
      "name": "박기호",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "e168b40f91c8e3c856104b7d0879eaf3a97d2b29",
    {
      "id": "e168b40f91c8e3c856104b7d0879eaf3a97d2b29",
      "name": "이로운",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "19",
        "Pos.": "P",
        "G": "63",
        "GS": "0",
        "GR": "63",
        "GF": "14",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "3",
        "S": "1",
        "HD": "9",
        "IP": "56.0",
        "ER": "37",
        "R": "43",
        "rRA": "40.10",
        "TBF": "277",
        "H": "67",
        "2B": "9",
        "3B": "2",
        "HR": "4",
        "BB": "43",
        "HP": "3",
        "IB": "4",
        "SO": "41",
        "ROE": "3",
        "BK": "0",
        "WP": "3",
        "ERA": "5.95",
        "RA9": "6.91",
        "rRA9": "6.44",
        "rRA9pf": "6.22",
        "FIP": "5.65",
        "WHIP": "1.96",
        "WAR": "0.30"
      }
    }
  ],
  [
    "d3fadc5dc34d4e984b6486e7ce8d77ce40f0c50b",
    {
      "id": "d3fadc5dc34d4e984b6486e7ce8d77ce40f0c50b",
      "name": "박지환",
      "team": "SSG",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "18",
        "Pos.": "2B",
        "oWAR": "0.49",
        "dWAR": "-0.40",
        "G": "76",
        "PA": "249",
        "ePA": "246",
        "AB": "228",
        "R": "33",
        "H": "63",
        "2B": "9",
        "3B": "2",
        "HR": "4",
        "TB": "88",
        "RBI": "21",
        "SB": "8",
        "CS": "3",
        "BB": "12",
        "HP": "3",
        "IB": "0",
        "SO": "61",
        "GDP": "3",
        "SH": "3",
        "SF": "3",
        "AVG": "0.276",
        "OBP": "0.317",
        "SLG": "0.386",
        "OPS": "0.703",
        "R/ePA": "0.106",
        "wRC+": "75.2",
        "WAR": "0.09"
      }
    }
  ],
  [
    "0f967c944dcb4050de7cae78ab03632c2e84946e",
    {
      "id": "0f967c944dcb4050de7cae78ab03632c2e84946e",
      "name": "최현석",
      "team": "SSG",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "20",
        "Pos.": "P",
        "G": "2",
        "GS": "0",
        "GR": "2",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "0.2",
        "ER": "4",
        "R": "4",
        "rRA": "4.00",
        "TBF": "10",
        "H": "5",
        "2B": "0",
        "3B": "0",
        "HR": "1",
        "BB": "3",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "54.00",
        "RA9": "54.00",
        "rRA9": "54.00",
        "rRA9pf": "53.38",
        "FIP": "32.25",
        "WHIP": "12.00",
        "WAR": "-0.16"
      }
    }
  ],
  [
    "e485fa23fce243d4c505908bbc5b6d90dc576386",
    {
      "id": "e485fa23fce243d4c505908bbc5b6d90dc576386",
      "name": "정준재",
      "team": "SSG",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "20",
        "Pos.": "2B",
        "oWAR": "1.52",
        "dWAR": "0.28",
        "G": "88",
        "PA": "240",
        "ePA": "237",
        "AB": "215",
        "R": "40",
        "H": "66",
        "2B": "8",
        "3B": "5",
        "HR": "1",
        "TB": "87",
        "RBI": "23",
        "SB": "16",
        "CS": "5",
        "BB": "19",
        "HP": "3",
        "IB": "0",
        "SO": "49",
        "GDP": "3",
        "SH": "3",
        "SF": "0",
        "AVG": "0.307",
        "OBP": "0.371",
        "SLG": "0.405",
        "OPS": "0.776",
        "R/ePA": "0.143",
        "wRC+": "103.2",
        "WAR": "1.80"
      }
    }
  ],
  [
    "0b4889c44dddf47ecae1ff0cff952718c5558baa",
    {
      "id": "0b4889c44dddf47ecae1ff0cff952718c5558baa",
      "name": "정현승",
      "team": "SSG",
      "position": "RF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "SSG",
        "Age": "22",
        "Pos.": "RF",
        "oWAR": "-0.17",
        "dWAR": "-0.08",
        "G": "23",
        "PA": "31",
        "ePA": "31",
        "AB": "29",
        "R": "1",
        "H": "7",
        "2B": "0",
        "3B": "0",
        "HR": "1",
        "TB": "10",
        "RBI": "4",
        "SB": "1",
        "CS": "0",
        "BB": "2",
        "HP": "0",
        "IB": "0",
        "SO": "6",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.241",
        "OBP": "0.290",
        "SLG": "0.345",
        "OPS": "0.635",
        "R/ePA": "0.076",
        "wRC+": "53.9",
        "WAR": "-0.25"
      }
    }
  ],
  [
    "f1a99cb17a76fae9d8930b1b8984eb10a92ab9bc",
    {
      "id": "f1a99cb17a76fae9d8930b1b8984eb10a92ab9bc",
      "name": "이승민",
      "team": "SSG",
      "position": "RF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "82cc2d1a235b57660eb0514e0bb1a151abe66aba",
    {
      "id": "82cc2d1a235b57660eb0514e0bb1a151abe66aba",
      "name": "박윤성",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "19",
        "Pos.": "P",
        "G": "17",
        "GS": "0",
        "GR": "17",
        "GF": "7",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "1",
        "IP": "18.2",
        "ER": "12",
        "R": "12",
        "rRA": "13.40",
        "TBF": "81",
        "H": "14",
        "2B": "5",
        "3B": "0",
        "HR": "5",
        "BB": "9",
        "HP": "0",
        "IB": "0",
        "SO": "14",
        "ROE": "2",
        "BK": "0",
        "WP": "2",
        "ERA": "5.79",
        "RA9": "5.79",
        "rRA9": "6.46",
        "rRA9pf": "6.62",
        "FIP": "7.01",
        "WHIP": "1.23",
        "WAR": "0.09"
      }
    }
  ],
  [
    "c66c9d57061a3c688054c4505d8654bed4d8df2f",
    {
      "id": "c66c9d57061a3c688054c4505d8654bed4d8df2f",
      "name": "김병휘",
      "team": "키움",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "22",
        "Pos.": "SS",
        "oWAR": "0.03",
        "dWAR": "-0.21",
        "G": "28",
        "PA": "71",
        "ePA": "70",
        "AB": "59",
        "R": "6",
        "H": "10",
        "2B": "3",
        "3B": "0",
        "HR": "1",
        "TB": "16",
        "RBI": "3",
        "SB": "0",
        "CS": "0",
        "BB": "6",
        "HP": "5",
        "IB": "0",
        "SO": "26",
        "GDP": "0",
        "SH": "1",
        "SF": "0",
        "AVG": "0.169",
        "OBP": "0.300",
        "SLG": "0.271",
        "OPS": "0.571",
        "R/ePA": "0.081",
        "wRC+": "61.7",
        "WAR": "-0.19"
      }
    }
  ],
  [
    "08e167821c2cf2680d0304d857341b5a30124c6e",
    {
      "id": "08e167821c2cf2680d0304d857341b5a30124c6e",
      "name": "김태진",
      "team": "키움",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "28",
        "Pos.": "SS",
        "oWAR": "-0.77",
        "dWAR": "0.73",
        "G": "81",
        "PA": "202",
        "ePA": "197",
        "AB": "189",
        "R": "26",
        "H": "42",
        "2B": "6",
        "3B": "3",
        "HR": "0",
        "TB": "54",
        "RBI": "10",
        "SB": "0",
        "CS": "0",
        "BB": "7",
        "HP": "0",
        "IB": "1",
        "SO": "27",
        "GDP": "1",
        "SH": "5",
        "SF": "1",
        "AVG": "0.222",
        "OBP": "0.249",
        "SLG": "0.286",
        "OPS": "0.535",
        "R/ePA": "0.035",
        "wRC+": "27.5",
        "WAR": "-0.04"
      }
    }
  ],
  [
    "46bf24633814a6f030b2aec97f9654ba2ba48e87",
    {
      "id": "46bf24633814a6f030b2aec97f9654ba2ba48e87",
      "name": "이우현",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "e2e1beabe96bb3475fe1b050e378e0807f555cdb",
    {
      "id": "e2e1beabe96bb3475fe1b050e378e0807f555cdb",
      "name": "이주형",
      "team": "키움",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "22",
        "Pos.": "RF",
        "oWAR": "1.72",
        "dWAR": "0.25",
        "G": "115",
        "PA": "537",
        "ePA": "536",
        "AB": "473",
        "R": "82",
        "H": "126",
        "2B": "19",
        "3B": "3",
        "HR": "13",
        "TB": "190",
        "RBI": "60",
        "SB": "6",
        "CS": "0",
        "BB": "49",
        "HP": "14",
        "IB": "1",
        "SO": "119",
        "GDP": "4",
        "SH": "0",
        "SF": "1",
        "AVG": "0.266",
        "OBP": "0.352",
        "SLG": "0.402",
        "OPS": "0.754",
        "R/ePA": "0.133",
        "wRC+": "103.6",
        "WAR": "1.96"
      }
    }
  ],
  [
    "e9aa939e1a083f5535dab2fd48a739084adbfba1",
    {
      "id": "e9aa939e1a083f5535dab2fd48a739084adbfba1",
      "name": "김주훈",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "228d45c7a47e502b76a1a9e27798b0fc71eabb4b",
    {
      "id": "228d45c7a47e502b76a1a9e27798b0fc71eabb4b",
      "name": "박채울",
      "team": "키움",
      "position": "LF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "019f47bdb3e0f7347d0429f0bc7fe113c62d522b",
    {
      "id": "019f47bdb3e0f7347d0429f0bc7fe113c62d522b",
      "name": "김혜성",
      "team": "키움",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "24",
        "Pos.": "2B",
        "oWAR": "4.47",
        "dWAR": "0.69",
        "G": "127",
        "PA": "567",
        "ePA": "565",
        "AB": "509",
        "R": "90",
        "H": "166",
        "2B": "26",
        "3B": "4",
        "HR": "11",
        "TB": "233",
        "RBI": "75",
        "SB": "30",
        "CS": "6",
        "BB": "47",
        "HP": "4",
        "IB": "2",
        "SO": "62",
        "GDP": "6",
        "SH": "0",
        "SF": "7",
        "AVG": "0.326",
        "OBP": "0.383",
        "SLG": "0.458",
        "OPS": "0.841",
        "R/ePA": "0.160",
        "wRC+": "124.1",
        "WAR": "5.16"
      }
    }
  ],
  [
    "84c026834c65819a5d2bcf75578b4a8c9bd39339",
    {
      "id": "84c026834c65819a5d2bcf75578b4a8c9bd39339",
      "name": "박범준",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "19",
        "Pos.": "P",
        "G": "5",
        "GS": "0",
        "GR": "5",
        "GF": "3",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "5.1",
        "ER": "8",
        "R": "8",
        "rRA": "7.30",
        "TBF": "28",
        "H": "7",
        "2B": "2",
        "3B": "0",
        "HR": "1",
        "BB": "4",
        "HP": "1",
        "IB": "0",
        "SO": "8",
        "ROE": "0",
        "BK": "0",
        "WP": "2",
        "ERA": "13.50",
        "RA9": "13.50",
        "rRA9": "12.32",
        "rRA9pf": "11.88",
        "FIP": "5.59",
        "WHIP": "2.06",
        "WAR": "-0.19"
      }
    }
  ],
  [
    "c6b313ea76588300eb920d142350349671d62ad4",
    {
      "id": "c6b313ea76588300eb920d142350349671d62ad4",
      "name": "이재상",
      "team": "키움",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "18",
        "Pos.": "SS",
        "oWAR": "-0.55",
        "dWAR": "-0.49",
        "G": "36",
        "PA": "89",
        "ePA": "88",
        "AB": "84",
        "R": "7",
        "H": "16",
        "2B": "1",
        "3B": "0",
        "HR": "1",
        "TB": "20",
        "RBI": "5",
        "SB": "0",
        "CS": "0",
        "BB": "2",
        "HP": "2",
        "IB": "0",
        "SO": "33",
        "GDP": "0",
        "SH": "1",
        "SF": "0",
        "AVG": "0.190",
        "OBP": "0.227",
        "SLG": "0.238",
        "OPS": "0.465",
        "R/ePA": "0.013",
        "wRC+": "9.9",
        "WAR": "-1.04"
      }
    }
  ],
  [
    "9d9e059a039857ee3ea01f8f7eb6f6c6172158dd",
    {
      "id": "9d9e059a039857ee3ea01f8f7eb6f6c6172158dd",
      "name": "박승호",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "338f5d3156492cf629f74fb22e1db1f26471a632",
    {
      "id": "338f5d3156492cf629f74fb22e1db1f26471a632",
      "name": "김주형",
      "team": "키움",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "27",
        "Pos.": "SS",
        "oWAR": "-0.19",
        "dWAR": "0.07",
        "G": "22",
        "PA": "25",
        "ePA": "24",
        "AB": "20",
        "R": "0",
        "H": "3",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "3",
        "RBI": "2",
        "SB": "0",
        "CS": "1",
        "BB": "1",
        "HP": "3",
        "IB": "0",
        "SO": "9",
        "GDP": "0",
        "SH": "1",
        "SF": "0",
        "AVG": "0.150",
        "OBP": "0.292",
        "SLG": "0.150",
        "OPS": "0.442",
        "R/ePA": "0.050",
        "wRC+": "37.3",
        "WAR": "-0.12"
      }
    }
  ],
  [
    "57ebed685775c378dc8e0a04db3c4a27d10752a2",
    {
      "id": "57ebed685775c378dc8e0a04db3c4a27d10752a2",
      "name": "심휘윤",
      "team": "키움",
      "position": "2B",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "f152c11f05fceffc87463b0d13d2954ed7091587",
    {
      "id": "f152c11f05fceffc87463b0d13d2954ed7091587",
      "name": "김성민",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "넥센",
        "Age": "29",
        "Pos.": "P",
        "G": "46",
        "GS": "0",
        "GR": "46",
        "GF": "4",
        "CG": "0",
        "SHO": "0",
        "W": "3",
        "L": "4",
        "S": "0",
        "HD": "14",
        "IP": "45.2",
        "ER": "22",
        "R": "24",
        "rRA": "25.15",
        "TBF": "188",
        "H": "45",
        "2B": "4",
        "3B": "0",
        "HR": "5",
        "BB": "17",
        "HP": "1",
        "IB": "0",
        "SO": "34",
        "ROE": "0",
        "BK": "1",
        "WP": "3",
        "ERA": "4.34",
        "RA9": "4.73",
        "rRA9": "4.96",
        "rRA9pf": "5.33",
        "FIP": "4.86",
        "WHIP": "1.36",
        "WAR": "0.65"
      }
    }
  ],
  [
    "8b78ed99e60f99ae4d2bdb35e6b6a8c145236dd1",
    {
      "id": "8b78ed99e60f99ae4d2bdb35e6b6a8c145236dd1",
      "name": "신효수",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "72175eb419ca3205f7153b06fabc125003692e05",
    {
      "id": "72175eb419ca3205f7153b06fabc125003692e05",
      "name": "송재선",
      "team": "키움",
      "position": "LF",
      "record": {
        "Div.": "베스트",
        "Year": "2023",
        "Team": "키움",
        "Age": "22",
        "Pos.": "RF",
        "oWAR": "0.00",
        "dWAR": "-0.08",
        "G": "5",
        "PA": "3",
        "ePA": "3",
        "AB": "3",
        "R": "0",
        "H": "1",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "1",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.333",
        "OBP": "0.333",
        "SLG": "0.333",
        "OPS": "0.666",
        "R/ePA": "0.093",
        "wRC+": "82.4",
        "WAR": "-0.09"
      }
    }
  ],
  [
    "306c1c730ea8332b12d1e2d350aee571d70827b0",
    {
      "id": "306c1c730ea8332b12d1e2d350aee571d70827b0",
      "name": "김웅빈",
      "team": "키움",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "27",
        "Pos.": "DH",
        "oWAR": "-0.40",
        "dWAR": "0.00",
        "G": "12",
        "PA": "38",
        "ePA": "37",
        "AB": "32",
        "R": "2",
        "H": "4",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "4",
        "RBI": "2",
        "SB": "0",
        "CS": "0",
        "BB": "4",
        "HP": "1",
        "IB": "0",
        "SO": "15",
        "GDP": "1",
        "SH": "1",
        "SF": "0",
        "AVG": "0.125",
        "OBP": "0.243",
        "SLG": "0.125",
        "OPS": "0.368",
        "R/ePA": "0.002",
        "wRC+": "1.5",
        "WAR": "-0.40"
      }
    }
  ],
  [
    "24c2f93db05c7ba9f289b396930b8049cafa6eb9",
    {
      "id": "24c2f93db05c7ba9f289b396930b8049cafa6eb9",
      "name": "조상우",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "넥센",
        "Age": "29",
        "Pos.": "P",
        "G": "44",
        "GS": "0",
        "GR": "44",
        "GF": "7",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "6",
        "HD": "9",
        "IP": "39.2",
        "ER": "14",
        "R": "17",
        "rRA": "18.85",
        "TBF": "171",
        "H": "40",
        "2B": "6",
        "3B": "0",
        "HR": "2",
        "BB": "20",
        "HP": "0",
        "IB": "1",
        "SO": "36",
        "ROE": "0",
        "BK": "0",
        "WP": "1",
        "ERA": "3.18",
        "RA9": "3.86",
        "rRA9": "4.28",
        "rRA9pf": "4.66",
        "FIP": "4.02",
        "WHIP": "1.51",
        "WAR": "0.87"
      }
    }
  ],
  [
    "eb086f39e9e9e0a4c3cc5f35e03f20b8d9818c1c",
    {
      "id": "eb086f39e9e9e0a4c3cc5f35e03f20b8d9818c1c",
      "name": "김건희",
      "team": "키움",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "19",
        "Pos.": "C",
        "oWAR": "0.37",
        "dWAR": "-0.16",
        "G": "83",
        "PA": "283",
        "ePA": "283",
        "AB": "261",
        "R": "27",
        "H": "67",
        "2B": "15",
        "3B": "0",
        "HR": "9",
        "TB": "109",
        "RBI": "38",
        "SB": "0",
        "CS": "0",
        "BB": "18",
        "HP": "0",
        "IB": "0",
        "SO": "82",
        "GDP": "7",
        "SH": "0",
        "SF": "4",
        "AVG": "0.257",
        "OBP": "0.300",
        "SLG": "0.418",
        "OPS": "0.718",
        "R/ePA": "0.103",
        "wRC+": "79.2",
        "WAR": "0.22"
      }
    }
  ],
  [
    "b2fd7486672bd19f9ba39de83a207ec5bece9400",
    {
      "id": "b2fd7486672bd19f9ba39de83a207ec5bece9400",
      "name": "김수환",
      "team": "키움",
      "position": "1B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "25",
        "Pos.": "DH",
        "oWAR": "-0.08",
        "dWAR": "0.00",
        "G": "2",
        "PA": "3",
        "ePA": "3",
        "AB": "3",
        "R": "0",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.000",
        "SLG": "0.000",
        "OPS": "0.000",
        "R/ePA": "-0.190",
        "wRC+": "-154.5",
        "WAR": "-0.08"
      }
    }
  ],
  [
    "6d191175e7f972f06fbbb26353a15b3cc91cc2ca",
    {
      "id": "6d191175e7f972f06fbbb26353a15b3cc91cc2ca",
      "name": "박수종",
      "team": "키움",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "24",
        "Pos.": "CF",
        "oWAR": "-0.05",
        "dWAR": "0.11",
        "G": "80",
        "PA": "148",
        "ePA": "144",
        "AB": "129",
        "R": "23",
        "H": "33",
        "2B": "5",
        "3B": "1",
        "HR": "0",
        "TB": "40",
        "RBI": "6",
        "SB": "3",
        "CS": "0",
        "BB": "13",
        "HP": "2",
        "IB": "0",
        "SO": "17",
        "GDP": "4",
        "SH": "4",
        "SF": "0",
        "AVG": "0.256",
        "OBP": "0.333",
        "SLG": "0.310",
        "OPS": "0.643",
        "R/ePA": "0.097",
        "wRC+": "73.8",
        "WAR": "0.05"
      }
    }
  ],
  [
    "a5ceddb2d24e3d32697b525028c5fe770b1355b1",
    {
      "id": "a5ceddb2d24e3d32697b525028c5fe770b1355b1",
      "name": "이용규",
      "team": "키움",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "넥센",
        "Age": "38",
        "Pos.": "CF",
        "oWAR": "1.43",
        "dWAR": "-0.01",
        "G": "60",
        "PA": "227",
        "ePA": "224",
        "AB": "183",
        "R": "27",
        "H": "56",
        "2B": "5",
        "3B": "2",
        "HR": "1",
        "TB": "68",
        "RBI": "12",
        "SB": "2",
        "CS": "2",
        "BB": "31",
        "HP": "9",
        "IB": "0",
        "SO": "33",
        "GDP": "4",
        "SH": "3",
        "SF": "1",
        "AVG": "0.306",
        "OBP": "0.429",
        "SLG": "0.372",
        "OPS": "0.801",
        "R/ePA": "0.172",
        "wRC+": "136.7",
        "WAR": "1.42"
      }
    }
  ],
  [
    "56f44b36f9d0231e9b7e852273c20de97729afaf",
    {
      "id": "56f44b36f9d0231e9b7e852273c20de97729afaf",
      "name": "이원석",
      "team": "키움",
      "position": "3B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "넥센",
        "Age": "37",
        "Pos.": "DH",
        "oWAR": "-0.33",
        "dWAR": "-0.20",
        "G": "39",
        "PA": "109",
        "ePA": "109",
        "AB": "91",
        "R": "5",
        "H": "20",
        "2B": "4",
        "3B": "0",
        "HR": "0",
        "TB": "24",
        "RBI": "8",
        "SB": "0",
        "CS": "0",
        "BB": "14",
        "HP": "2",
        "IB": "0",
        "SO": "18",
        "GDP": "2",
        "SH": "0",
        "SF": "2",
        "AVG": "0.220",
        "OBP": "0.330",
        "SLG": "0.264",
        "OPS": "0.594",
        "R/ePA": "0.085",
        "wRC+": "66.4",
        "WAR": "-0.53"
      }
    }
  ],
  [
    "5cd6b9860219a2f5ef5a0b7a17436077a7d4db6e",
    {
      "id": "5cd6b9860219a2f5ef5a0b7a17436077a7d4db6e",
      "name": "김윤하",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "18",
        "Pos.": "P",
        "G": "19",
        "GS": "12",
        "GR": "7",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "6",
        "S": "0",
        "HD": "2",
        "IP": "79.0",
        "ER": "53",
        "R": "57",
        "rRA": "54.95",
        "TBF": "363",
        "H": "93",
        "2B": "22",
        "3B": "2",
        "HR": "6",
        "BB": "35",
        "HP": "4",
        "IB": "0",
        "SO": "43",
        "ROE": "4",
        "BK": "1",
        "WP": "3",
        "ERA": "6.04",
        "RA9": "6.49",
        "rRA9": "6.26",
        "rRA9pf": "6.67",
        "FIP": "5.27",
        "WHIP": "1.62",
        "WAR": "0.54"
      }
    }
  ],
  [
    "68ad85625c469b3d5f15c8bc544c8ee46bbfa297",
    {
      "id": "68ad85625c469b3d5f15c8bc544c8ee46bbfa297",
      "name": "조영건",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "24",
        "Pos.": "P",
        "G": "25",
        "GS": "3",
        "GR": "22",
        "GF": "3",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "1",
        "S": "0",
        "HD": "1",
        "IP": "39.1",
        "ER": "35",
        "R": "39",
        "rRA": "35.55",
        "TBF": "197",
        "H": "51",
        "2B": "10",
        "3B": "1",
        "HR": "7",
        "BB": "24",
        "HP": "1",
        "IB": "0",
        "SO": "31",
        "ROE": "3",
        "BK": "0",
        "WP": "3",
        "ERA": "8.01",
        "RA9": "8.92",
        "rRA9": "8.13",
        "rRA9pf": "8.83",
        "FIP": "6.01",
        "WHIP": "1.91",
        "WAR": "-0.55"
      }
    }
  ],
  [
    "881901a053b22d60cc7a2223ee0ea93724ea5241",
    {
      "id": "881901a053b22d60cc7a2223ee0ea93724ea5241",
      "name": "문성현",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "넥센",
        "Age": "32",
        "Pos.": "P",
        "G": "42",
        "GS": "0",
        "GR": "42",
        "GF": "12",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "2",
        "S": "3",
        "HD": "2",
        "IP": "38.1",
        "ER": "28",
        "R": "28",
        "rRA": "29.55",
        "TBF": "189",
        "H": "54",
        "2B": "6",
        "3B": "2",
        "HR": "3",
        "BB": "25",
        "HP": "2",
        "IB": "0",
        "SO": "31",
        "ROE": "1",
        "BK": "0",
        "WP": "2",
        "ERA": "6.57",
        "RA9": "6.57",
        "rRA9": "6.94",
        "rRA9pf": "7.26",
        "FIP": "5.29",
        "WHIP": "2.06",
        "WAR": "-0.09"
      }
    }
  ],
  [
    "0793214c388896d1b23b7f88a2ac54bfd6c40645",
    {
      "id": "0793214c388896d1b23b7f88a2ac54bfd6c40645",
      "name": "김동헌",
      "team": "키움",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "19",
        "Pos.": "C",
        "oWAR": "0.04",
        "dWAR": "-0.14",
        "G": "2",
        "PA": "7",
        "ePA": "7",
        "AB": "5",
        "R": "0",
        "H": "1",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "1",
        "RBI": "1",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "1",
        "IB": "0",
        "SO": "0",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.200",
        "OBP": "0.429",
        "SLG": "0.200",
        "OPS": "0.629",
        "R/ePA": "0.153",
        "wRC+": "115.9",
        "WAR": "-0.10"
      }
    }
  ],
  [
    "e2e63c774a05937f8a570e7f7224874484d3ceff",
    {
      "id": "e2e63c774a05937f8a570e7f7224874484d3ceff",
      "name": "송성문",
      "team": "키움",
      "position": "3B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "27",
        "Pos.": "3B",
        "oWAR": "5.83",
        "dWAR": "0.29",
        "G": "142",
        "PA": "602",
        "ePA": "601",
        "AB": "527",
        "R": "88",
        "H": "179",
        "2B": "29",
        "3B": "4",
        "HR": "19",
        "TB": "273",
        "RBI": "104",
        "SB": "21",
        "CS": "0",
        "BB": "64",
        "HP": "3",
        "IB": "2",
        "SO": "82",
        "GDP": "5",
        "SH": "0",
        "SF": "8",
        "AVG": "0.340",
        "OBP": "0.409",
        "SLG": "0.518",
        "OPS": "0.927",
        "R/ePA": "0.191",
        "wRC+": "148.9",
        "WAR": "6.13"
      }
    }
  ],
  [
    "64f7fa22a7d8fbdd51e1adbd224168090b4ac4c4",
    {
      "id": "64f7fa22a7d8fbdd51e1adbd224168090b4ac4c4",
      "name": "주성원",
      "team": "키움",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "23",
        "Pos.": "RF",
        "oWAR": "-0.23",
        "dWAR": "0.09",
        "G": "27",
        "PA": "62",
        "ePA": "61",
        "AB": "55",
        "R": "7",
        "H": "11",
        "2B": "1",
        "3B": "0",
        "HR": "1",
        "TB": "15",
        "RBI": "5",
        "SB": "0",
        "CS": "0",
        "BB": "6",
        "HP": "0",
        "IB": "0",
        "SO": "18",
        "GDP": "2",
        "SH": "1",
        "SF": "0",
        "AVG": "0.200",
        "OBP": "0.279",
        "SLG": "0.273",
        "OPS": "0.552",
        "R/ePA": "0.054",
        "wRC+": "42.4",
        "WAR": "-0.13"
      }
    }
  ],
  [
    "22828fbbaa9c50ff9f9896881ae5a274526fe0f6",
    {
      "id": "22828fbbaa9c50ff9f9896881ae5a274526fe0f6",
      "name": "김시앙",
      "team": "키움",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "22",
        "Pos.": "C",
        "oWAR": "-0.09",
        "dWAR": "0.02",
        "G": "6",
        "PA": "7",
        "ePA": "7",
        "AB": "7",
        "R": "1",
        "H": "1",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "1",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.143",
        "OBP": "0.143",
        "SLG": "0.143",
        "OPS": "0.286",
        "R/ePA": "-0.070",
        "wRC+": "-52.9",
        "WAR": "-0.07"
      }
    }
  ],
  [
    "3e50c45bac89d65f0c297da83c36af13bdfeacb9",
    {
      "id": "3e50c45bac89d65f0c297da83c36af13bdfeacb9",
      "name": "도슨",
      "team": "키움",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "28",
        "Pos.": "LF",
        "oWAR": "3.07",
        "dWAR": "0.10",
        "G": "95",
        "PA": "429",
        "ePA": "429",
        "AB": "382",
        "R": "69",
        "H": "126",
        "2B": "31",
        "3B": "2",
        "HR": "11",
        "TB": "194",
        "RBI": "57",
        "SB": "2",
        "CS": "2",
        "BB": "39",
        "HP": "6",
        "IB": "0",
        "SO": "54",
        "GDP": "10",
        "SH": "0",
        "SF": "2",
        "AVG": "0.330",
        "OBP": "0.399",
        "SLG": "0.508",
        "OPS": "0.907",
        "R/ePA": "0.187",
        "wRC+": "145.9",
        "WAR": "3.17"
      }
    }
  ],
  [
    "dcc7bb14804b6ef13e07e1cba5135dfc3c42f275",
    {
      "id": "dcc7bb14804b6ef13e07e1cba5135dfc3c42f275",
      "name": "김재웅",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "25",
        "Pos.": "P",
        "G": "26",
        "GS": "0",
        "GR": "26",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "2",
        "S": "0",
        "HD": "7",
        "IP": "23.2",
        "ER": "9",
        "R": "9",
        "rRA": "11.25",
        "TBF": "107",
        "H": "24",
        "2B": "3",
        "3B": "1",
        "HR": "2",
        "BB": "12",
        "HP": "1",
        "IB": "1",
        "SO": "19",
        "ROE": "3",
        "BK": "0",
        "WP": "1",
        "ERA": "3.42",
        "RA9": "3.42",
        "rRA9": "4.28",
        "rRA9pf": "4.63",
        "FIP": "4.90",
        "WHIP": "1.52",
        "WAR": "0.54"
      }
    }
  ],
  [
    "a4b32d2a75a061899947bf959c95c61f7b26810b",
    {
      "id": "a4b32d2a75a061899947bf959c95c61f7b26810b",
      "name": "임지열",
      "team": "키움",
      "position": "3B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "28",
        "Pos.": "RF",
        "oWAR": "-0.59",
        "dWAR": "-0.28",
        "G": "22",
        "PA": "57",
        "ePA": "57",
        "AB": "49",
        "R": "4",
        "H": "5",
        "2B": "0",
        "3B": "0",
        "HR": "1",
        "TB": "8",
        "RBI": "5",
        "SB": "0",
        "CS": "0",
        "BB": "7",
        "HP": "0",
        "IB": "0",
        "SO": "16",
        "GDP": "2",
        "SH": "0",
        "SF": "1",
        "AVG": "0.102",
        "OBP": "0.211",
        "SLG": "0.163",
        "OPS": "0.374",
        "R/ePA": "-0.011",
        "wRC+": "-8.3",
        "WAR": "-0.87"
      }
    }
  ],
  [
    "8d82ea710fe8d7959e3a9892df6715d1a1b47026",
    {
      "id": "8d82ea710fe8d7959e3a9892df6715d1a1b47026",
      "name": "주승우",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "23",
        "Pos.": "P",
        "G": "55",
        "GS": "0",
        "GR": "55",
        "GF": "35",
        "CG": "0",
        "SHO": "0",
        "W": "4",
        "L": "6",
        "S": "14",
        "HD": "5",
        "IP": "51.2",
        "ER": "25",
        "R": "25",
        "rRA": "26.55",
        "TBF": "225",
        "H": "50",
        "2B": "14",
        "3B": "1",
        "HR": "4",
        "BB": "18",
        "HP": "5",
        "IB": "5",
        "SO": "43",
        "ROE": "2",
        "BK": "0",
        "WP": "1",
        "ERA": "4.35",
        "RA9": "4.35",
        "rRA9": "4.62",
        "rRA9pf": "4.94",
        "FIP": "4.18",
        "WHIP": "1.32",
        "WAR": "0.98"
      }
    }
  ],
  [
    "2f132df4763111e0ad28cbf57581b7d690c7fef9",
    {
      "id": "2f132df4763111e0ad28cbf57581b7d690c7fef9",
      "name": "오석주",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "25",
        "Pos.": "P",
        "G": "17",
        "GS": "0",
        "GR": "17",
        "GF": "7",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "17.0",
        "ER": "21",
        "R": "21",
        "rRA": "20.15",
        "TBF": "92",
        "H": "28",
        "2B": "4",
        "3B": "1",
        "HR": "0",
        "BB": "11",
        "HP": "3",
        "IB": "1",
        "SO": "16",
        "ROE": "1",
        "BK": "0",
        "WP": "2",
        "ERA": "11.12",
        "RA9": "11.12",
        "rRA9": "10.67",
        "rRA9pf": "11.22",
        "FIP": "4.40",
        "WHIP": "2.29",
        "WAR": "-0.50"
      }
    }
  ],
  [
    "8fb56ff8b4b7606399b729cd0faa3f9e86e804bf",
    {
      "id": "8fb56ff8b4b7606399b729cd0faa3f9e86e804bf",
      "name": "김재현",
      "team": "키움",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "넥센",
        "Age": "30",
        "Pos.": "C",
        "oWAR": "-0.18",
        "dWAR": "0.10",
        "G": "110",
        "PA": "326",
        "ePA": "316",
        "AB": "288",
        "R": "27",
        "H": "70",
        "2B": "11",
        "3B": "0",
        "HR": "0",
        "TB": "81",
        "RBI": "26",
        "SB": "1",
        "CS": "1",
        "BB": "14",
        "HP": "10",
        "IB": "0",
        "SO": "63",
        "GDP": "8",
        "SH": "10",
        "SF": "4",
        "AVG": "0.243",
        "OBP": "0.297",
        "SLG": "0.281",
        "OPS": "0.578",
        "R/ePA": "0.067",
        "wRC+": "52.1",
        "WAR": "-0.07"
      }
    }
  ],
  [
    "4df403a77d72bf31b59950395830dc5229551628",
    {
      "id": "4df403a77d72bf31b59950395830dc5229551628",
      "name": "원성준",
      "team": "키움",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "23",
        "Pos.": "DH",
        "oWAR": "-0.16",
        "dWAR": "-0.07",
        "G": "51",
        "PA": "125",
        "ePA": "124",
        "AB": "112",
        "R": "12",
        "H": "28",
        "2B": "5",
        "3B": "0",
        "HR": "2",
        "TB": "39",
        "RBI": "11",
        "SB": "2",
        "CS": "0",
        "BB": "10",
        "HP": "0",
        "IB": "0",
        "SO": "36",
        "GDP": "2",
        "SH": "1",
        "SF": "2",
        "AVG": "0.250",
        "OBP": "0.306",
        "SLG": "0.348",
        "OPS": "0.654",
        "R/ePA": "0.087",
        "wRC+": "67.3",
        "WAR": "-0.22"
      }
    }
  ],
  [
    "62a161847e8969fcad3829a53baa1e048c24d1f2",
    {
      "id": "62a161847e8969fcad3829a53baa1e048c24d1f2",
      "name": "이명기",
      "team": "키움",
      "position": "1B",
      "record": {
        "Div.": "베스트",
        "Year": "2021",
        "Team": "키움",
        "Age": "20",
        "Pos.": "2B",
        "oWAR": "0.00",
        "dWAR": "0.07",
        "G": "3",
        "PA": "8",
        "ePA": "8",
        "AB": "6",
        "R": "0",
        "H": "1",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "1",
        "RBI": "1",
        "SB": "0",
        "CS": "0",
        "BB": "2",
        "HP": "0",
        "IB": "0",
        "SO": "3",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.167",
        "OBP": "0.375",
        "SLG": "0.167",
        "OPS": "0.542",
        "R/ePA": "0.095",
        "wRC+": "73.2",
        "WAR": "0.07"
      }
    }
  ],
  [
    "af2b0486b9002c292384b80d9cdac4e45142440e",
    {
      "id": "af2b0486b9002c292384b80d9cdac4e45142440e",
      "name": "임병욱",
      "team": "키움",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "넥센",
        "Age": "28",
        "Pos.": "CF",
        "oWAR": "-0.13",
        "dWAR": "-0.03",
        "G": "42",
        "PA": "92",
        "ePA": "92",
        "AB": "87",
        "R": "14",
        "H": "21",
        "2B": "3",
        "3B": "0",
        "HR": "3",
        "TB": "33",
        "RBI": "10",
        "SB": "1",
        "CS": "0",
        "BB": "4",
        "HP": "0",
        "IB": "0",
        "SO": "32",
        "GDP": "1",
        "SH": "0",
        "SF": "1",
        "AVG": "0.241",
        "OBP": "0.272",
        "SLG": "0.379",
        "OPS": "0.651",
        "R/ePA": "0.075",
        "wRC+": "57.9",
        "WAR": "-0.16"
      }
    }
  ],
  [
    "ce58586208582befbd733b3ad8c4d285c89bb9c7",
    {
      "id": "ce58586208582befbd733b3ad8c4d285c89bb9c7",
      "name": "이형종",
      "team": "키움",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "34",
        "Pos.": "RF",
        "oWAR": "0.28",
        "dWAR": "0.08",
        "G": "35",
        "PA": "126",
        "ePA": "125",
        "AB": "102",
        "R": "19",
        "H": "22",
        "2B": "3",
        "3B": "0",
        "HR": "4",
        "TB": "37",
        "RBI": "19",
        "SB": "1",
        "CS": "0",
        "BB": "22",
        "HP": "1",
        "IB": "1",
        "SO": "27",
        "GDP": "1",
        "SH": "1",
        "SF": "0",
        "AVG": "0.216",
        "OBP": "0.360",
        "SLG": "0.363",
        "OPS": "0.723",
        "R/ePA": "0.128",
        "wRC+": "100.7",
        "WAR": "0.36"
      }
    }
  ],
  [
    "34076dea0e3d546509f4f15b119f185b0a5c2330",
    {
      "id": "34076dea0e3d546509f4f15b119f185b0a5c2330",
      "name": "신준우",
      "team": "키움",
      "position": "2B",
      "record": {
        "Div.": "베스트",
        "Year": "2022",
        "Team": "키움",
        "Age": "20",
        "Pos.": "SS",
        "oWAR": "0.02",
        "dWAR": "0.24",
        "G": "76",
        "PA": "54",
        "ePA": "52",
        "AB": "43",
        "R": "6",
        "H": "6",
        "2B": "3",
        "3B": "0",
        "HR": "0",
        "TB": "9",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "7",
        "HP": "2",
        "IB": "0",
        "SO": "19",
        "GDP": "0",
        "SH": "2",
        "SF": "0",
        "AVG": "0.140",
        "OBP": "0.288",
        "SLG": "0.209",
        "OPS": "0.497",
        "R/ePA": "0.062",
        "wRC+": "54.2",
        "WAR": "0.26"
      }
    }
  ],
  [
    "a26b0f325cea526c3222d5e2a86b408b8c186d53",
    {
      "id": "a26b0f325cea526c3222d5e2a86b408b8c186d53",
      "name": "이명종",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "21",
        "Pos.": "P",
        "G": "10",
        "GS": "0",
        "GR": "10",
        "GF": "7",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "11.0",
        "ER": "10",
        "R": "10",
        "rRA": "13.30",
        "TBF": "58",
        "H": "20",
        "2B": "2",
        "3B": "0",
        "HR": "4",
        "BB": "5",
        "HP": "0",
        "IB": "1",
        "SO": "4",
        "ROE": "1",
        "BK": "0",
        "WP": "1",
        "ERA": "8.18",
        "RA9": "8.18",
        "rRA9": "10.88",
        "rRA9pf": "11.51",
        "FIP": "8.74",
        "WHIP": "2.27",
        "WAR": "-0.30"
      }
    }
  ],
  [
    "1b1808995d9d51671489a58808b9c310fa917422",
    {
      "id": "1b1808995d9d51671489a58808b9c310fa917422",
      "name": "오상원",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2023",
        "Team": "키움",
        "Age": "18",
        "Pos.": "P",
        "G": "11",
        "GS": "1",
        "GR": "10",
        "GF": "7",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "15.0",
        "ER": "11",
        "R": "11",
        "rRA": "11.50",
        "TBF": "74",
        "H": "18",
        "2B": "5",
        "3B": "1",
        "HR": "0",
        "BB": "9",
        "HP": "3",
        "IB": "0",
        "SO": "7",
        "ROE": "1",
        "BK": "0",
        "WP": "0",
        "ERA": "6.60",
        "RA9": "6.60",
        "rRA9": "6.90",
        "rRA9pf": "7.15",
        "FIP": "5.16",
        "WHIP": "1.80",
        "WAR": "-0.07"
      }
    }
  ],
  [
    "9aefa604ee42bd788ab4c43982a6ce7247611763",
    {
      "id": "9aefa604ee42bd788ab4c43982a6ce7247611763",
      "name": "박승주",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "29",
        "Pos.": "P",
        "G": "15",
        "GS": "0",
        "GR": "15",
        "GF": "6",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "17.1",
        "ER": "13",
        "R": "13",
        "rRA": "11.95",
        "TBF": "74",
        "H": "16",
        "2B": "2",
        "3B": "0",
        "HR": "2",
        "BB": "9",
        "HP": "1",
        "IB": "0",
        "SO": "13",
        "ROE": "0",
        "BK": "0",
        "WP": "3",
        "ERA": "6.75",
        "RA9": "6.75",
        "rRA9": "6.20",
        "rRA9pf": "6.56",
        "FIP": "5.49",
        "WHIP": "1.44",
        "WAR": "0.10"
      }
    }
  ],
  [
    "8ce59ed63bb430aea37a1deecb15a02cdf592df1",
    {
      "id": "8ce59ed63bb430aea37a1deecb15a02cdf592df1",
      "name": "김동규",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "19",
        "Pos.": "P",
        "G": "2",
        "GS": "0",
        "GR": "2",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "0.1",
        "ER": "2",
        "R": "2",
        "rRA": "1.30",
        "TBF": "4",
        "H": "1",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "BB": "2",
        "HP": "0",
        "IB": "0",
        "SO": "0",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "54.00",
        "RA9": "54.00",
        "rRA9": "35.10",
        "rRA9pf": "32.69",
        "FIP": "22.80",
        "WHIP": "9.00",
        "WAR": "-0.07"
      }
    }
  ],
  [
    "d2fa08f7341583cc7304390e026d7f287c31d0d3",
    {
      "id": "d2fa08f7341583cc7304390e026d7f287c31d0d3",
      "name": "고영우",
      "team": "키움",
      "position": "3B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "22",
        "Pos.": "3B",
        "oWAR": "0.01",
        "dWAR": "-0.43",
        "G": "94",
        "PA": "290",
        "ePA": "286",
        "AB": "254",
        "R": "21",
        "H": "65",
        "2B": "12",
        "3B": "2",
        "HR": "0",
        "TB": "81",
        "RBI": "35",
        "SB": "0",
        "CS": "0",
        "BB": "28",
        "HP": "1",
        "IB": "0",
        "SO": "71",
        "GDP": "5",
        "SH": "4",
        "SF": "3",
        "AVG": "0.256",
        "OBP": "0.329",
        "SLG": "0.319",
        "OPS": "0.648",
        "R/ePA": "0.094",
        "wRC+": "73.5",
        "WAR": "-0.42"
      }
    }
  ],
  [
    "aa4df16faf3b9dab960b82ccdf44a1b434e4a43c",
    {
      "id": "aa4df16faf3b9dab960b82ccdf44a1b434e4a43c",
      "name": "원종현",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "넥센",
        "Age": "36",
        "Pos.": "P",
        "G": "4",
        "GS": "0",
        "GR": "4",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "3.2",
        "ER": "2",
        "R": "2",
        "rRA": "2.00",
        "TBF": "18",
        "H": "4",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "BB": "3",
        "HP": "0",
        "IB": "0",
        "SO": "4",
        "ROE": "0",
        "BK": "0",
        "WP": "2",
        "ERA": "4.91",
        "RA9": "4.91",
        "rRA9": "4.91",
        "rRA9pf": "4.45",
        "FIP": "4.01",
        "WHIP": "1.91",
        "WAR": "0.10"
      }
    }
  ],
  [
    "5b2db8d32a9c1ebd022f4faf3027ebc452de9404",
    {
      "id": "5b2db8d32a9c1ebd022f4faf3027ebc452de9404",
      "name": "박찬혁",
      "team": "키움",
      "position": "LF",
      "record": {
        "Div.": "베스트",
        "Year": "2022",
        "Team": "키움",
        "Age": "18",
        "Pos.": "DH",
        "oWAR": "-0.31",
        "dWAR": "-0.41",
        "G": "52",
        "PA": "175",
        "ePA": "175",
        "AB": "161",
        "R": "13",
        "H": "34",
        "2B": "5",
        "3B": "0",
        "HR": "6",
        "TB": "57",
        "RBI": "17",
        "SB": "0",
        "CS": "1",
        "BB": "9",
        "HP": "5",
        "IB": "0",
        "SO": "67",
        "GDP": "1",
        "SH": "0",
        "SF": "0",
        "AVG": "0.211",
        "OBP": "0.274",
        "SLG": "0.354",
        "OPS": "0.628",
        "R/ePA": "0.084",
        "wRC+": "74.2",
        "WAR": "-0.72"
      }
    }
  ],
  [
    "f0ce529a3bc1765465d6712ad0dd197d731908be",
    {
      "id": "f0ce529a3bc1765465d6712ad0dd197d731908be",
      "name": "김선기",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "32",
        "Pos.": "P",
        "G": "42",
        "GS": "6",
        "GR": "36",
        "GF": "4",
        "CG": "0",
        "SHO": "0",
        "W": "6",
        "L": "4",
        "S": "0",
        "HD": "0",
        "IP": "76.1",
        "ER": "47",
        "R": "51",
        "rRA": "53.60",
        "TBF": "357",
        "H": "91",
        "2B": "18",
        "3B": "0",
        "HR": "13",
        "BB": "30",
        "HP": "6",
        "IB": "1",
        "SO": "43",
        "ROE": "6",
        "BK": "0",
        "WP": "6",
        "ERA": "5.54",
        "RA9": "6.01",
        "rRA9": "6.32",
        "rRA9pf": "6.50",
        "FIP": "6.28",
        "WHIP": "1.59",
        "WAR": "0.31"
      }
    }
  ],
  [
    "0daee08711371ad429b1389f51fb99d1a6c7018e",
    {
      "id": "0daee08711371ad429b1389f51fb99d1a6c7018e",
      "name": "하영민",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "28",
        "Pos.": "P",
        "G": "28",
        "GS": "28",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "9",
        "L": "8",
        "S": "0",
        "HD": "0",
        "IP": "150.1",
        "ER": "73",
        "R": "82",
        "rRA": "79.25",
        "TBF": "671",
        "H": "168",
        "2B": "33",
        "3B": "6",
        "HR": "8",
        "BB": "58",
        "HP": "5",
        "IB": "0",
        "SO": "101",
        "ROE": "9",
        "BK": "0",
        "WP": "7",
        "ERA": "4.37",
        "RA9": "4.91",
        "rRA9": "4.74",
        "rRA9pf": "5.02",
        "FIP": "4.45",
        "WHIP": "1.50",
        "WAR": "3.25"
      }
    }
  ],
  [
    "bd7a5debeb36ed7474f3d120ee77612c89fd0317",
    {
      "id": "bd7a5debeb36ed7474f3d120ee77612c89fd0317",
      "name": "최주환",
      "team": "키움",
      "position": "3B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "넥센",
        "Age": "35",
        "Pos.": "1B",
        "oWAR": "-0.32",
        "dWAR": "0.30",
        "G": "130",
        "PA": "544",
        "ePA": "544",
        "AB": "482",
        "R": "49",
        "H": "124",
        "2B": "23",
        "3B": "1",
        "HR": "13",
        "TB": "188",
        "RBI": "84",
        "SB": "0",
        "CS": "1",
        "BB": "47",
        "HP": "6",
        "IB": "2",
        "SO": "92",
        "GDP": "12",
        "SH": "0",
        "SF": "9",
        "AVG": "0.257",
        "OBP": "0.325",
        "SLG": "0.390",
        "OPS": "0.715",
        "R/ePA": "0.111",
        "wRC+": "86.1",
        "WAR": "-0.03"
      }
    }
  ],
  [
    "659b319db3e970bc708394731ec49c48d9c2cd97",
    {
      "id": "659b319db3e970bc708394731ec49c48d9c2cd97",
      "name": "헤이수스",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "27",
        "Pos.": "P",
        "G": "30",
        "GS": "30",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "13",
        "L": "11",
        "S": "0",
        "HD": "0",
        "IP": "171.1",
        "ER": "70",
        "R": "78",
        "rRA": "75.75",
        "TBF": "728",
        "H": "171",
        "2B": "31",
        "3B": "2",
        "HR": "22",
        "BB": "44",
        "HP": "14",
        "IB": "1",
        "SO": "178",
        "ROE": "8",
        "BK": "1",
        "WP": "6",
        "ERA": "3.68",
        "RA9": "4.10",
        "rRA9": "3.98",
        "rRA9pf": "4.24",
        "FIP": "4.11",
        "WHIP": "1.25",
        "WAR": "5.09"
      }
    }
  ],
  [
    "0613a132627257b7ca0332a769da559bdd8aa2d2",
    {
      "id": "0613a132627257b7ca0332a769da559bdd8aa2d2",
      "name": "양지율",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "25",
        "Pos.": "P",
        "G": "21",
        "GS": "0",
        "GR": "21",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "2",
        "S": "0",
        "HD": "3",
        "IP": "18.2",
        "ER": "13",
        "R": "13",
        "rRA": "11.30",
        "TBF": "83",
        "H": "16",
        "2B": "4",
        "3B": "0",
        "HR": "1",
        "BB": "13",
        "HP": "2",
        "IB": "0",
        "SO": "7",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "6.27",
        "RA9": "6.27",
        "rRA9": "5.45",
        "rRA9pf": "5.92",
        "FIP": "6.39",
        "WHIP": "1.55",
        "WAR": "0.16"
      }
    }
  ],
  [
    "5e55440e23ce738fc8c4627197a0c8bb638751bb",
    {
      "id": "5e55440e23ce738fc8c4627197a0c8bb638751bb",
      "name": "변상권",
      "team": "키움",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "26",
        "Pos.": "LF",
        "oWAR": "-0.58",
        "dWAR": "0.38",
        "G": "77",
        "PA": "232",
        "ePA": "232",
        "AB": "219",
        "R": "16",
        "H": "55",
        "2B": "3",
        "3B": "1",
        "HR": "5",
        "TB": "75",
        "RBI": "21",
        "SB": "0",
        "CS": "0",
        "BB": "11",
        "HP": "1",
        "IB": "0",
        "SO": "51",
        "GDP": "4",
        "SH": "0",
        "SF": "1",
        "AVG": "0.251",
        "OBP": "0.289",
        "SLG": "0.342",
        "OPS": "0.631",
        "R/ePA": "0.075",
        "wRC+": "57.6",
        "WAR": "-0.20"
      }
    }
  ],
  [
    "d23a0ff5a634d48b1e52620bbfe71c90c06b487d",
    {
      "id": "d23a0ff5a634d48b1e52620bbfe71c90c06b487d",
      "name": "박주홍",
      "team": "키움",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "22",
        "Pos.": "LF",
        "oWAR": "-0.44",
        "dWAR": "0.17",
        "G": "25",
        "PA": "59",
        "ePA": "59",
        "AB": "49",
        "R": "5",
        "H": "5",
        "2B": "2",
        "3B": "0",
        "HR": "0",
        "TB": "7",
        "RBI": "1",
        "SB": "2",
        "CS": "0",
        "BB": "10",
        "HP": "0",
        "IB": "0",
        "SO": "17",
        "GDP": "1",
        "SH": "0",
        "SF": "0",
        "AVG": "0.102",
        "OBP": "0.254",
        "SLG": "0.143",
        "OPS": "0.397",
        "R/ePA": "0.016",
        "wRC+": "12.6",
        "WAR": "-0.27"
      }
    }
  ],
  [
    "7784b9aa51ed237bda4dfdee3a448169003736d3",
    {
      "id": "7784b9aa51ed237bda4dfdee3a448169003736d3",
      "name": "정찬헌",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "33",
        "Pos.": "P",
        "G": "4",
        "GS": "4",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "2",
        "S": "0",
        "HD": "0",
        "IP": "16.0",
        "ER": "14",
        "R": "14",
        "rRA": "13.30",
        "TBF": "74",
        "H": "26",
        "2B": "5",
        "3B": "0",
        "HR": "3",
        "BB": "3",
        "HP": "1",
        "IB": "0",
        "SO": "5",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "7.88",
        "RA9": "7.88",
        "rRA9": "7.48",
        "rRA9pf": "7.26",
        "FIP": "6.42",
        "WHIP": "1.81",
        "WAR": "0.03"
      }
    }
  ],
  [
    "93ce319f3fa52b48d58a5a866fd9bb2743a7c6db",
    {
      "id": "93ce319f3fa52b48d58a5a866fd9bb2743a7c6db",
      "name": "노운현",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2022",
        "Team": "키움",
        "Age": "18",
        "Pos.": "P",
        "G": "5",
        "GS": "0",
        "GR": "5",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "4.0",
        "ER": "5",
        "R": "7",
        "rRA": "6.65",
        "TBF": "28",
        "H": "10",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "BB": "3",
        "HP": "1",
        "IB": "0",
        "SO": "3",
        "ROE": "2",
        "BK": "0",
        "WP": "1",
        "ERA": "11.25",
        "RA9": "15.75",
        "rRA9": "14.96",
        "rRA9pf": "16.22",
        "FIP": "5.01",
        "WHIP": "3.25",
        "WAR": "-0.30"
      }
    }
  ],
  [
    "7dbcfe40cfff1148c101f1cbce1db3f5a06b1f3c",
    {
      "id": "7dbcfe40cfff1148c101f1cbce1db3f5a06b1f3c",
      "name": "김동혁",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "22",
        "Pos.": "P",
        "G": "17",
        "GS": "0",
        "GR": "17",
        "GF": "8",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "2",
        "S": "0",
        "HD": "0",
        "IP": "22.1",
        "ER": "17",
        "R": "21",
        "rRA": "23.75",
        "TBF": "102",
        "H": "27",
        "2B": "5",
        "3B": "1",
        "HR": "7",
        "BB": "10",
        "HP": "2",
        "IB": "0",
        "SO": "9",
        "ROE": "1",
        "BK": "0",
        "WP": "0",
        "ERA": "6.85",
        "RA9": "8.46",
        "rRA9": "9.57",
        "rRA9pf": "9.86",
        "FIP": "8.59",
        "WHIP": "1.66",
        "WAR": "-0.72"
      }
    }
  ],
  [
    "1e8bcf0fe8a1e264ee29a5fae982b85643108d4a",
    {
      "id": "1e8bcf0fe8a1e264ee29a5fae982b85643108d4a",
      "name": "장재영",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2023",
        "Team": "키움",
        "Age": "20",
        "Pos.": "P",
        "G": "23",
        "GS": "17",
        "GR": "6",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "5",
        "S": "0",
        "HD": "0",
        "IP": "71.2",
        "ER": "44",
        "R": "46",
        "rRA": "43.25",
        "TBF": "339",
        "H": "63",
        "2B": "15",
        "3B": "0",
        "HR": "4",
        "BB": "66",
        "HP": "8",
        "IB": "0",
        "SO": "67",
        "ROE": "6",
        "BK": "2",
        "WP": "8",
        "ERA": "5.53",
        "RA9": "5.78",
        "rRA9": "5.43",
        "rRA9pf": "5.63",
        "FIP": "5.38",
        "WHIP": "1.80",
        "WAR": "0.57"
      }
    }
  ],
  [
    "94c1e9a1e0a6cbcfa6d24a783a6d23de25011cb3",
    {
      "id": "94c1e9a1e0a6cbcfa6d24a783a6d23de25011cb3",
      "name": "전준표",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "18",
        "Pos.": "P",
        "G": "18",
        "GS": "5",
        "GR": "13",
        "GF": "4",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "4",
        "S": "0",
        "HD": "0",
        "IP": "27.2",
        "ER": "21",
        "R": "28",
        "rRA": "27.00",
        "TBF": "144",
        "H": "35",
        "2B": "4",
        "3B": "0",
        "HR": "4",
        "BB": "20",
        "HP": "5",
        "IB": "0",
        "SO": "18",
        "ROE": "4",
        "BK": "0",
        "WP": "4",
        "ERA": "6.83",
        "RA9": "9.11",
        "rRA9": "8.78",
        "rRA9pf": "8.93",
        "FIP": "7.09",
        "WHIP": "1.99",
        "WAR": "-0.35"
      }
    }
  ],
  [
    "70138870adad7024a6a3ff53935297cf80f69139",
    {
      "id": "70138870adad7024a6a3ff53935297cf80f69139",
      "name": "손현기",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "18",
        "Pos.": "P",
        "G": "6",
        "GS": "2",
        "GR": "4",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "14.1",
        "ER": "12",
        "R": "14",
        "rRA": "13.30",
        "TBF": "69",
        "H": "17",
        "2B": "3",
        "3B": "1",
        "HR": "2",
        "BB": "8",
        "HP": "3",
        "IB": "0",
        "SO": "9",
        "ROE": "0",
        "BK": "0",
        "WP": "3",
        "ERA": "7.53",
        "RA9": "8.79",
        "rRA9": "8.35",
        "rRA9pf": "8.66",
        "FIP": "6.67",
        "WHIP": "1.74",
        "WAR": "-0.11"
      }
    }
  ],
  [
    "8776c4b7ca1005ba6493f460361afa5f4dace88b",
    {
      "id": "8776c4b7ca1005ba6493f460361afa5f4dace88b",
      "name": "박성빈",
      "team": "키움",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "19",
        "Pos.": "C",
        "oWAR": "0.05",
        "dWAR": "-0.07",
        "G": "12",
        "PA": "11",
        "ePA": "11",
        "AB": "9",
        "R": "1",
        "H": "2",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "TB": "3",
        "RBI": "2",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "1",
        "IB": "0",
        "SO": "2",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.222",
        "OBP": "0.364",
        "SLG": "0.333",
        "OPS": "0.697",
        "R/ePA": "0.137",
        "wRC+": "110.5",
        "WAR": "-0.02"
      }
    }
  ],
  [
    "84cbb622dc743d1dcc044c180ca6f12cd9e8b052",
    {
      "id": "84cbb622dc743d1dcc044c180ca6f12cd9e8b052",
      "name": "김동욱",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "26",
        "Pos.": "P",
        "G": "37",
        "GS": "0",
        "GR": "37",
        "GF": "11",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "1",
        "S": "0",
        "HD": "3",
        "IP": "36.0",
        "ER": "20",
        "R": "20",
        "rRA": "23.45",
        "TBF": "146",
        "H": "33",
        "2B": "9",
        "3B": "1",
        "HR": "4",
        "BB": "6",
        "HP": "2",
        "IB": "1",
        "SO": "18",
        "ROE": "1",
        "BK": "0",
        "WP": "1",
        "ERA": "5.00",
        "RA9": "5.00",
        "rRA9": "5.86",
        "rRA9pf": "6.22",
        "FIP": "4.96",
        "WHIP": "1.08",
        "WAR": "0.22"
      }
    }
  ],
  [
    "348366839181e956a4820fbb062ad91ddabb82ba",
    {
      "id": "348366839181e956a4820fbb062ad91ddabb82ba",
      "name": "이종민",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "22",
        "Pos.": "P",
        "G": "12",
        "GS": "9",
        "GR": "3",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "8",
        "S": "0",
        "HD": "0",
        "IP": "43.2",
        "ER": "37",
        "R": "39",
        "rRA": "36.10",
        "TBF": "212",
        "H": "56",
        "2B": "10",
        "3B": "2",
        "HR": "9",
        "BB": "22",
        "HP": "5",
        "IB": "2",
        "SO": "21",
        "ROE": "2",
        "BK": "1",
        "WP": "0",
        "ERA": "7.63",
        "RA9": "8.04",
        "rRA9": "7.44",
        "rRA9pf": "7.72",
        "FIP": "7.22",
        "WHIP": "1.79",
        "WAR": "-0.12"
      }
    }
  ],
  [
    "0fd8c5f7ce9de0f06fb35f9e82434a7f5fc07e4e",
    {
      "id": "0fd8c5f7ce9de0f06fb35f9e82434a7f5fc07e4e",
      "name": "김인범",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "23",
        "Pos.": "P",
        "G": "19",
        "GS": "14",
        "GR": "5",
        "GF": "4",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "7",
        "S": "0",
        "HD": "0",
        "IP": "63.1",
        "ER": "38",
        "R": "40",
        "rRA": "39.30",
        "TBF": "297",
        "H": "75",
        "2B": "8",
        "3B": "1",
        "HR": "9",
        "BB": "31",
        "HP": "8",
        "IB": "0",
        "SO": "27",
        "ROE": "2",
        "BK": "1",
        "WP": "3",
        "ERA": "5.40",
        "RA9": "5.68",
        "rRA9": "5.58",
        "rRA9pf": "5.77",
        "FIP": "6.73",
        "WHIP": "1.67",
        "WAR": "0.79"
      }
    }
  ],
  [
    "694abc7ce4b745461a9972d4fba05b488c33ace0",
    {
      "id": "694abc7ce4b745461a9972d4fba05b488c33ace0",
      "name": "김연주",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "19",
        "Pos.": "P",
        "G": "34",
        "GS": "0",
        "GR": "34",
        "GF": "11",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "1",
        "S": "0",
        "HD": "1",
        "IP": "31.1",
        "ER": "23",
        "R": "24",
        "rRA": "24.50",
        "TBF": "148",
        "H": "34",
        "2B": "10",
        "3B": "0",
        "HR": "5",
        "BB": "17",
        "HP": "5",
        "IB": "1",
        "SO": "18",
        "ROE": "0",
        "BK": "0",
        "WP": "2",
        "ERA": "6.61",
        "RA9": "6.89",
        "rRA9": "7.04",
        "rRA9pf": "7.43",
        "FIP": "6.74",
        "WHIP": "1.63",
        "WAR": "-0.11"
      }
    }
  ],
  [
    "3a9b4a0919bb5ad986cb8099a5c8e83e9cc56ed7",
    {
      "id": "3a9b4a0919bb5ad986cb8099a5c8e83e9cc56ed7",
      "name": "후라도",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "27",
        "Pos.": "P",
        "G": "30",
        "GS": "30",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "10",
        "L": "8",
        "S": "0",
        "HD": "0",
        "IP": "190.1",
        "ER": "71",
        "R": "78",
        "rRA": "76.95",
        "TBF": "779",
        "H": "185",
        "2B": "27",
        "3B": "4",
        "HR": "19",
        "BB": "32",
        "HP": "9",
        "IB": "0",
        "SO": "169",
        "ROE": "12",
        "BK": "1",
        "WP": "3",
        "ERA": "3.36",
        "RA9": "3.69",
        "rRA9": "3.64",
        "rRA9pf": "3.78",
        "FIP": "3.84",
        "WHIP": "1.14",
        "WAR": "6.61"
      }
    }
  ],
  [
    "fb197afa428486115e3db7a06767c4dde3111df4",
    {
      "id": "fb197afa428486115e3db7a06767c4dde3111df4",
      "name": "주승빈",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "fdc0f681042387cfd6deb3f0ca926dbf73571521",
    {
      "id": "fdc0f681042387cfd6deb3f0ca926dbf73571521",
      "name": "이승원",
      "team": "키움",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "19",
        "Pos.": "SS",
        "oWAR": "-0.53",
        "dWAR": "-0.20",
        "G": "20",
        "PA": "41",
        "ePA": "39",
        "AB": "37",
        "R": "3",
        "H": "4",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "TB": "5",
        "RBI": "1",
        "SB": "0",
        "CS": "0",
        "BB": "2",
        "HP": "0",
        "IB": "0",
        "SO": "13",
        "GDP": "2",
        "SH": "2",
        "SF": "0",
        "AVG": "0.108",
        "OBP": "0.154",
        "SLG": "0.135",
        "OPS": "0.289",
        "R/ePA": "-0.058",
        "wRC+": "-43.9",
        "WAR": "-0.74"
      }
    }
  ],
  [
    "fc2ab724b49aeea677c377d27193e4747b16e125",
    {
      "id": "fc2ab724b49aeea677c377d27193e4747b16e125",
      "name": "김준형",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2022",
        "Team": "키움",
        "Age": "19",
        "Pos.": "P",
        "G": "12",
        "GS": "0",
        "GR": "12",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "13.1",
        "ER": "3",
        "R": "3",
        "rRA": "4.05",
        "TBF": "61",
        "H": "9",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "BB": "13",
        "HP": "2",
        "IB": "0",
        "SO": "6",
        "ROE": "0",
        "BK": "0",
        "WP": "3",
        "ERA": "2.03",
        "RA9": "2.03",
        "rRA9": "2.73",
        "rRA9pf": "2.88",
        "FIP": "6.15",
        "WHIP": "1.65",
        "WAR": "0.40"
      }
    }
  ],
  [
    "9463e5c03436e82361981610f16ffcbac47075b1",
    {
      "id": "9463e5c03436e82361981610f16ffcbac47075b1",
      "name": "송지후",
      "team": "키움",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "18",
        "Pos.": "2B",
        "oWAR": "-0.03",
        "dWAR": "-0.01",
        "G": "2",
        "PA": "1",
        "ePA": "1",
        "AB": "1",
        "R": "1",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "0",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.000",
        "SLG": "0.000",
        "OPS": "0.000",
        "R/ePA": "-0.190",
        "wRC+": "-151.2",
        "WAR": "-0.05"
      }
    }
  ],
  [
    "a2a7c3ef931039c6c5f952ef1d360c0728b52b72",
    {
      "id": "a2a7c3ef931039c6c5f952ef1d360c0728b52b72",
      "name": "윤석원",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "20",
        "Pos.": "P",
        "G": "11",
        "GS": "1",
        "GR": "10",
        "GF": "3",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "17.1",
        "ER": "22",
        "R": "24",
        "rRA": "24.20",
        "TBF": "88",
        "H": "31",
        "2B": "11",
        "3B": "0",
        "HR": "3",
        "BB": "7",
        "HP": "2",
        "IB": "0",
        "SO": "1",
        "ROE": "2",
        "BK": "1",
        "WP": "3",
        "ERA": "11.42",
        "RA9": "12.46",
        "rRA9": "12.57",
        "rRA9pf": "12.91",
        "FIP": "7.72",
        "WHIP": "2.19",
        "WAR": "-0.66"
      }
    }
  ],
  [
    "b6184fad4a15ff1e02f693867d223967e14cb4a7",
    {
      "id": "b6184fad4a15ff1e02f693867d223967e14cb4a7",
      "name": "박준형",
      "team": "키움",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "24",
        "Pos.": "C",
        "oWAR": "-0.12",
        "dWAR": "-0.11",
        "G": "4",
        "PA": "5",
        "ePA": "5",
        "AB": "5",
        "R": "0",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "3",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.000",
        "SLG": "0.000",
        "OPS": "0.000",
        "R/ePA": "-0.190",
        "wRC+": "-154.2",
        "WAR": "-0.22"
      }
    }
  ],
  [
    "43f4dd072e743f90f1c497ba1687b23d4d8b3f12",
    {
      "id": "43f4dd072e743f90f1c497ba1687b23d4d8b3f12",
      "name": "김지성",
      "team": "키움",
      "position": "C",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "1a81d40253f938caa7eb20a680d9359e6b445542",
    {
      "id": "1a81d40253f938caa7eb20a680d9359e6b445542",
      "name": "서유신",
      "team": "키움",
      "position": "2B",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "491f5ac7464146090082d4c7369995062a452b35",
    {
      "id": "491f5ac7464146090082d4c7369995062a452b35",
      "name": "윤정현",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "키움",
        "Age": "30",
        "Pos.": "P",
        "G": "2",
        "GS": "0",
        "GR": "2",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "3.0",
        "ER": "3",
        "R": "3",
        "rRA": "3.00",
        "TBF": "15",
        "H": "4",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "BB": "2",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "9.00",
        "RA9": "9.00",
        "rRA9": "9.00",
        "rRA9pf": "9.41",
        "FIP": "5.43",
        "WHIP": "2.00",
        "WAR": "-0.02"
      }
    }
  ],
  [
    "e48c292713883652937346f64f89fa9291d6b9b3",
    {
      "id": "e48c292713883652937346f64f89fa9291d6b9b3",
      "name": "조성훈",
      "team": "키움",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2023",
        "Team": "SSG",
        "Age": "23",
        "Pos.": "P",
        "G": "2",
        "GS": "2",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "7.0",
        "ER": "5",
        "R": "5",
        "rRA": "5.00",
        "TBF": "34",
        "H": "10",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "BB": "4",
        "HP": "2",
        "IB": "0",
        "SO": "4",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "6.43",
        "RA9": "6.43",
        "rRA9": "6.43",
        "rRA9pf": "6.12",
        "FIP": "5.08",
        "WHIP": "2.00",
        "WAR": "0.02"
      }
    }
  ],
  [
    "6e3946fe0855550feee26517fadf6ef5859ad97a",
    {
      "id": "6e3946fe0855550feee26517fadf6ef5859ad97a",
      "name": "우승원",
      "team": "키움",
      "position": "2B",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "d5290337d81aa768a195da3701f788836ec67f96",
    {
      "id": "d5290337d81aa768a195da3701f788836ec67f96",
      "name": "이호열",
      "team": "키움",
      "position": "2B",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "28af3b7f12429abdd39584886169f199515da39b",
    {
      "id": "28af3b7f12429abdd39584886169f199515da39b",
      "name": "변헌성",
      "team": "키움",
      "position": "C",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "b92d5b31c2210c30a019b72fc2d43629ccbfcdb9",
    {
      "id": "b92d5b31c2210c30a019b72fc2d43629ccbfcdb9",
      "name": "안겸",
      "team": "키움",
      "position": "C",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "cd394b5afa48467febf70ce3656245d5e6063013",
    {
      "id": "cd394b5afa48467febf70ce3656245d5e6063013",
      "name": "문상인",
      "team": "NC",
      "position": "C",
      "record": {
        "Div.": "베스트",
        "Year": "2023",
        "Team": "KT",
        "Age": "24",
        "Pos.": "C",
        "oWAR": "0.05",
        "dWAR": "-0.02",
        "G": "1",
        "PA": "1",
        "ePA": "1",
        "AB": "0",
        "R": "0",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "0",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "",
        "OBP": "1.000",
        "SLG": "",
        "OPS": "",
        "R/ePA": "0.498",
        "wRC+": "387.9",
        "WAR": "0.02"
      }
    }
  ],
  [
    "fa081c0d1372686a430f268f3b9c8db2d0accbd3",
    {
      "id": "fa081c0d1372686a430f268f3b9c8db2d0accbd3",
      "name": "안중열",
      "team": "NC",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "28",
        "Pos.": "C",
        "oWAR": "0.29",
        "dWAR": "-0.06",
        "G": "10",
        "PA": "25",
        "ePA": "25",
        "AB": "21",
        "R": "5",
        "H": "6",
        "2B": "1",
        "3B": "0",
        "HR": "2",
        "TB": "13",
        "RBI": "4",
        "SB": "0",
        "CS": "0",
        "BB": "3",
        "HP": "0",
        "IB": "0",
        "SO": "7",
        "GDP": "0",
        "SH": "0",
        "SF": "1",
        "AVG": "0.286",
        "OBP": "0.360",
        "SLG": "0.619",
        "OPS": "0.979",
        "R/ePA": "0.187",
        "wRC+": "138.4",
        "WAR": "0.23"
      }
    }
  ],
  [
    "2f3186755c874689e3639369dec4e40b4a615736",
    {
      "id": "2f3186755c874689e3639369dec4e40b4a615736",
      "name": "박민우",
      "team": "NC",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "30",
        "Pos.": "2B",
        "oWAR": "4.11",
        "dWAR": "0.58",
        "G": "121",
        "PA": "528",
        "ePA": "520",
        "AB": "457",
        "R": "75",
        "H": "150",
        "2B": "26",
        "3B": "2",
        "HR": "8",
        "TB": "204",
        "RBI": "50",
        "SB": "32",
        "CS": "7",
        "BB": "54",
        "HP": "9",
        "IB": "4",
        "SO": "79",
        "GDP": "5",
        "SH": "4",
        "SF": "4",
        "AVG": "0.328",
        "OBP": "0.406",
        "SLG": "0.446",
        "OPS": "0.852",
        "R/ePA": "0.172",
        "wRC+": "129.6",
        "WAR": "4.70"
      }
    }
  ],
  [
    "17a92f4d54ee7b2b59d0eeef6e928096a850ecb4",
    {
      "id": "17a92f4d54ee7b2b59d0eeef6e928096a850ecb4",
      "name": "구창모",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2020",
        "Team": "NC",
        "Age": "22",
        "Pos.": "P",
        "G": "15",
        "GS": "14",
        "GR": "1",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "9",
        "L": "0",
        "S": "0",
        "HD": "1",
        "IP": "93.1",
        "ER": "18",
        "R": "20",
        "rRA": "20.00",
        "TBF": "348",
        "H": "58",
        "2B": "12",
        "3B": "0",
        "HR": "7",
        "BB": "18",
        "HP": "0",
        "IB": "0",
        "SO": "102",
        "ROE": "6",
        "BK": "0",
        "WP": "2",
        "ERA": "1.74",
        "RA9": "1.93",
        "rRA9": "1.93",
        "rRA9pf": "1.85",
        "FIP": "2.75",
        "WHIP": "0.81",
        "WAR": "5.39"
      }
    }
  ],
  [
    "cfb099b326a66f7372bf94ced3d78e07a1dd2346",
    {
      "id": "cfb099b326a66f7372bf94ced3d78e07a1dd2346",
      "name": "김수윤",
      "team": "NC",
      "position": "1B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "25",
        "Pos.": "1B",
        "oWAR": "-0.07",
        "dWAR": "-0.08",
        "G": "9",
        "PA": "10",
        "ePA": "10",
        "AB": "9",
        "R": "0",
        "H": "2",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "2",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "4",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.222",
        "OBP": "0.300",
        "SLG": "0.222",
        "OPS": "0.522",
        "R/ePA": "0.050",
        "wRC+": "37.3",
        "WAR": "-0.16"
      }
    }
  ],
  [
    "2b6e5c91cb1f43d8addd2063795c91134f0342fd",
    {
      "id": "2b6e5c91cb1f43d8addd2063795c91134f0342fd",
      "name": "김택우",
      "team": "NC",
      "position": "IF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "240483ebf6c94c8cc8a15a2ae8b3797091de3292",
    {
      "id": "240483ebf6c94c8cc8a15a2ae8b3797091de3292",
      "name": "서호철",
      "team": "NC",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "27",
        "Pos.": "3B",
        "oWAR": "1.28",
        "dWAR": "0.11",
        "G": "141",
        "PA": "567",
        "ePA": "561",
        "AB": "512",
        "R": "68",
        "H": "146",
        "2B": "19",
        "3B": "3",
        "HR": "10",
        "TB": "201",
        "RBI": "61",
        "SB": "1",
        "CS": "3",
        "BB": "30",
        "HP": "16",
        "IB": "0",
        "SO": "101",
        "GDP": "15",
        "SH": "6",
        "SF": "3",
        "AVG": "0.285",
        "OBP": "0.342",
        "SLG": "0.393",
        "OPS": "0.735",
        "R/ePA": "0.123",
        "wRC+": "93.3",
        "WAR": "1.39"
      }
    }
  ],
  [
    "b04e216f2d269c9f4ca4612e796ebe03796ec403",
    {
      "id": "b04e216f2d269c9f4ca4612e796ebe03796ec403",
      "name": "박주찬",
      "team": "NC",
      "position": "C",
      "record": {
        "Div.": "베스트",
        "Year": "2023",
        "Team": "NC",
        "Age": "26",
        "Pos.": "3B",
        "oWAR": "-0.19",
        "dWAR": "-0.12",
        "G": "5",
        "PA": "14",
        "ePA": "14",
        "AB": "14",
        "R": "1",
        "H": "2",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "2",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "2",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.143",
        "OBP": "0.143",
        "SLG": "0.143",
        "OPS": "0.286",
        "R/ePA": "-0.056",
        "wRC+": "-49.0",
        "WAR": "-0.31"
      }
    }
  ],
  [
    "7d9186d1f93c2bc8590be3441a6ab65432d6b5c7",
    {
      "id": "7d9186d1f93c2bc8590be3441a6ab65432d6b5c7",
      "name": "김주원",
      "team": "NC",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "21",
        "Pos.": "SS",
        "oWAR": "3.07",
        "dWAR": "0.68",
        "G": "134",
        "PA": "475",
        "ePA": "466",
        "AB": "385",
        "R": "61",
        "H": "97",
        "2B": "18",
        "3B": "2",
        "HR": "9",
        "TB": "146",
        "RBI": "49",
        "SB": "16",
        "CS": "6",
        "BB": "51",
        "HP": "25",
        "IB": "1",
        "SO": "111",
        "GDP": "5",
        "SH": "9",
        "SF": "5",
        "AVG": "0.252",
        "OBP": "0.371",
        "SLG": "0.379",
        "OPS": "0.750",
        "R/ePA": "0.143",
        "wRC+": "108.4",
        "WAR": "3.75"
      }
    }
  ],
  [
    "5ccecb988afe0178391634b202f0a7d37b1f882e",
    {
      "id": "5ccecb988afe0178391634b202f0a7d37b1f882e",
      "name": "송승환",
      "team": "NC",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "23",
        "Pos.": "RF",
        "oWAR": "-0.05",
        "dWAR": "0.14",
        "G": "3",
        "PA": "5",
        "ePA": "5",
        "AB": "5",
        "R": "1",
        "H": "1",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "1",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.200",
        "OBP": "0.200",
        "SLG": "0.200",
        "OPS": "0.400",
        "R/ePA": "-0.022",
        "wRC+": "-16.6",
        "WAR": "0.09"
      }
    }
  ],
  [
    "569454c4272d1a791d22ceaf11ebb2c11e48eb9d",
    {
      "id": "569454c4272d1a791d22ceaf11ebb2c11e48eb9d",
      "name": "박영빈",
      "team": "NC",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "26",
        "Pos.": "LF",
        "oWAR": "0.13",
        "dWAR": "-0.23",
        "G": "19",
        "PA": "7",
        "ePA": "7",
        "AB": "6",
        "R": "3",
        "H": "3",
        "2B": "2",
        "3B": "0",
        "HR": "0",
        "TB": "5",
        "RBI": "1",
        "SB": "2",
        "CS": "1",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "3",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.500",
        "OBP": "0.571",
        "SLG": "0.833",
        "OPS": "1.404",
        "R/ePA": "0.374",
        "wRC+": "278.2",
        "WAR": "-0.09"
      }
    }
  ],
  [
    "5501fe0d56e4c7c36c3cf4019120ae225b702b14",
    {
      "id": "5501fe0d56e4c7c36c3cf4019120ae225b702b14",
      "name": "박세혁",
      "team": "NC",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "33",
        "Pos.": "C",
        "oWAR": "0.69",
        "dWAR": "-0.21",
        "G": "82",
        "PA": "171",
        "ePA": "166",
        "AB": "148",
        "R": "21",
        "H": "39",
        "2B": "9",
        "3B": "0",
        "HR": "1",
        "TB": "51",
        "RBI": "10",
        "SB": "4",
        "CS": "0",
        "BB": "14",
        "HP": "3",
        "IB": "0",
        "SO": "43",
        "GDP": "3",
        "SH": "5",
        "SF": "1",
        "AVG": "0.264",
        "OBP": "0.337",
        "SLG": "0.345",
        "OPS": "0.682",
        "R/ePA": "0.108",
        "wRC+": "82.4",
        "WAR": "0.48"
      }
    }
  ],
  [
    "e397cf379ffb9782bce58d2ed5903ba4e55ddc1d",
    {
      "id": "e397cf379ffb9782bce58d2ed5903ba4e55ddc1d",
      "name": "송명기",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "23",
        "Pos.": "P",
        "G": "33",
        "GS": "0",
        "GR": "33",
        "GF": "5",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "1",
        "S": "0",
        "HD": "4",
        "IP": "38.1",
        "ER": "25",
        "R": "27",
        "rRA": "28.70",
        "TBF": "173",
        "H": "40",
        "2B": "7",
        "3B": "1",
        "HR": "4",
        "BB": "17",
        "HP": "3",
        "IB": "1",
        "SO": "31",
        "ROE": "2",
        "BK": "0",
        "WP": "7",
        "ERA": "5.87",
        "RA9": "6.34",
        "rRA9": "6.74",
        "rRA9pf": "6.82",
        "FIP": "4.96",
        "WHIP": "1.49",
        "WAR": "0.04"
      }
    }
  ],
  [
    "ad446f3071af7716278035ebb9f57932876066da",
    {
      "id": "ad446f3071af7716278035ebb9f57932876066da",
      "name": "임형원",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2020",
        "Team": "NC",
        "Age": "18",
        "Pos.": "P",
        "G": "1",
        "GS": "0",
        "GR": "1",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "0.0",
        "ER": "1",
        "R": "1",
        "rRA": "0.30",
        "TBF": "1",
        "H": "1",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "0",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "99.99",
        "RA9": "99.99",
        "rRA9": "13.33",
        "rRA9pf": "12.72",
        "FIP": "4.00",
        "WHIP": "99.99",
        "WAR": "-0.01"
      }
    }
  ],
  [
    "38bbe053cd5e3f394c60a771a218fb6f043fa464",
    {
      "id": "38bbe053cd5e3f394c60a771a218fb6f043fa464",
      "name": "임정호",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "33",
        "Pos.": "P",
        "G": "65",
        "GS": "0",
        "GR": "65",
        "GF": "10",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "6",
        "S": "2",
        "HD": "9",
        "IP": "55.0",
        "ER": "27",
        "R": "32",
        "rRA": "31.65",
        "TBF": "243",
        "H": "54",
        "2B": "8",
        "3B": "1",
        "HR": "3",
        "BB": "25",
        "HP": "3",
        "IB": "3",
        "SO": "46",
        "ROE": "3",
        "BK": "0",
        "WP": "0",
        "ERA": "4.42",
        "RA9": "5.24",
        "rRA9": "5.18",
        "rRA9pf": "5.28",
        "FIP": "4.22",
        "WHIP": "1.44",
        "WAR": "0.85"
      }
    }
  ],
  [
    "850ee8e28107e050a7022c5eacbf3e6d55faea79",
    {
      "id": "850ee8e28107e050a7022c5eacbf3e6d55faea79",
      "name": "최정원",
      "team": "NC",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "23",
        "Pos.": "CF",
        "oWAR": "0.55",
        "dWAR": "0.06",
        "G": "65",
        "PA": "155",
        "ePA": "154",
        "AB": "131",
        "R": "33",
        "H": "37",
        "2B": "5",
        "3B": "0",
        "HR": "1",
        "TB": "45",
        "RBI": "14",
        "SB": "7",
        "CS": "3",
        "BB": "17",
        "HP": "4",
        "IB": "0",
        "SO": "34",
        "GDP": "1",
        "SH": "1",
        "SF": "2",
        "AVG": "0.282",
        "OBP": "0.377",
        "SLG": "0.344",
        "OPS": "0.721",
        "R/ePA": "0.131",
        "wRC+": "99.0",
        "WAR": "0.62"
      }
    }
  ],
  [
    "40aa191c49c21670888cf68c96ac17dec24cebc3",
    {
      "id": "40aa191c49c21670888cf68c96ac17dec24cebc3",
      "name": "김태현",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "25",
        "Pos.": "P",
        "G": "11",
        "GS": "0",
        "GR": "11",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "10.0",
        "ER": "8",
        "R": "10",
        "rRA": "11.20",
        "TBF": "50",
        "H": "14",
        "2B": "4",
        "3B": "0",
        "HR": "3",
        "BB": "7",
        "HP": "0",
        "IB": "0",
        "SO": "3",
        "ROE": "2",
        "BK": "0",
        "WP": "1",
        "ERA": "7.20",
        "RA9": "9.00",
        "rRA9": "10.08",
        "rRA9pf": "10.43",
        "FIP": "9.19",
        "WHIP": "2.10",
        "WAR": "-0.20"
      }
    }
  ],
  [
    "f916a3f0e6d2c72f0de043ae1bc89595b2a90e97",
    {
      "id": "f916a3f0e6d2c72f0de043ae1bc89595b2a90e97",
      "name": "도태훈",
      "team": "NC",
      "position": "3B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "30",
        "Pos.": "1B",
        "oWAR": "-0.24",
        "dWAR": "0.30",
        "G": "100",
        "PA": "181",
        "ePA": "177",
        "AB": "145",
        "R": "20",
        "H": "31",
        "2B": "4",
        "3B": "0",
        "HR": "0",
        "TB": "35",
        "RBI": "15",
        "SB": "1",
        "CS": "0",
        "BB": "24",
        "HP": "6",
        "IB": "0",
        "SO": "29",
        "GDP": "5",
        "SH": "4",
        "SF": "2",
        "AVG": "0.214",
        "OBP": "0.345",
        "SLG": "0.241",
        "OPS": "0.586",
        "R/ePA": "0.092",
        "wRC+": "69.3",
        "WAR": "0.06"
      }
    }
  ],
  [
    "0a07dbccc8cc0957c62de172b2fcf0e590ddf927",
    {
      "id": "0a07dbccc8cc0957c62de172b2fcf0e590ddf927",
      "name": "김영규",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "23",
        "Pos.": "P",
        "G": "42",
        "GS": "0",
        "GR": "42",
        "GF": "4",
        "CG": "0",
        "SHO": "0",
        "W": "4",
        "L": "2",
        "S": "1",
        "HD": "14",
        "IP": "45.2",
        "ER": "16",
        "R": "19",
        "rRA": "17.20",
        "TBF": "183",
        "H": "40",
        "2B": "4",
        "3B": "0",
        "HR": "3",
        "BB": "14",
        "HP": "1",
        "IB": "0",
        "SO": "48",
        "ROE": "2",
        "BK": "0",
        "WP": "1",
        "ERA": "3.15",
        "RA9": "3.74",
        "rRA9": "3.39",
        "rRA9pf": "3.53",
        "FIP": "3.37",
        "WHIP": "1.18",
        "WAR": "1.74"
      }
    }
  ],
  [
    "4814238afa0fa625b921c67ecbcfdaf02f327242",
    {
      "id": "4814238afa0fa625b921c67ecbcfdaf02f327242",
      "name": "심창민",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2016",
        "Team": "삼성",
        "Age": "22",
        "Pos.": "P",
        "G": "62",
        "GS": "0",
        "GR": "62",
        "GF": "42",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "6",
        "S": "25",
        "HD": "4",
        "IP": "72.2",
        "ER": "24",
        "R": "29",
        "rRA": "35.00",
        "TBF": "303",
        "H": "59",
        "2B": "11",
        "3B": "0",
        "HR": "6",
        "BB": "26",
        "HP": "4",
        "IB": "3",
        "SO": "76",
        "ROE": "3",
        "BK": "1",
        "WP": "1",
        "ERA": "2.97",
        "RA9": "3.59",
        "rRA9": "4.33",
        "rRA9pf": "4.11",
        "FIP": "3.80",
        "WHIP": "1.17",
        "WAR": "2.46"
      }
    }
  ],
  [
    "69e4f878bd93c4be378d7a6fd6d2fa088320f021",
    {
      "id": "69e4f878bd93c4be378d7a6fd6d2fa088320f021",
      "name": "박석민",
      "team": "NC",
      "position": "3B",
      "record": {
        "Div.": "베스트",
        "Year": "2015",
        "Team": "삼성",
        "Age": "29",
        "Pos.": "3B",
        "oWAR": "5.84",
        "dWAR": "0.64",
        "G": "135",
        "PA": "559",
        "ePA": "558",
        "AB": "448",
        "R": "90",
        "H": "144",
        "2B": "25",
        "3B": "0",
        "HR": "26",
        "TB": "247",
        "RBI": "116",
        "SB": "3",
        "CS": "0",
        "BB": "83",
        "HP": "19",
        "IB": "0",
        "SO": "97",
        "GDP": "13",
        "SH": "1",
        "SF": "8",
        "AVG": "0.321",
        "OBP": "0.441",
        "SLG": "0.551",
        "OPS": "0.992",
        "R/ePA": "0.209",
        "wRC+": "159.0",
        "WAR": "6.48"
      }
    }
  ],
  [
    "96d4acedbea505a43adab59650762c51d2a5d695",
    {
      "id": "96d4acedbea505a43adab59650762c51d2a5d695",
      "name": "채원후",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "28",
        "Pos.": "P",
        "G": "3",
        "GS": "0",
        "GR": "3",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "3.0",
        "ER": "4",
        "R": "4",
        "rRA": "4.00",
        "TBF": "18",
        "H": "6",
        "2B": "2",
        "3B": "0",
        "HR": "0",
        "BB": "3",
        "HP": "0",
        "IB": "0",
        "SO": "2",
        "ROE": "0",
        "BK": "0",
        "WP": "2",
        "ERA": "12.00",
        "RA9": "12.00",
        "rRA9": "12.00",
        "rRA9pf": "11.86",
        "FIP": "5.63",
        "WHIP": "3.00",
        "WAR": "-0.06"
      }
    }
  ],
  [
    "c9bda9da52e190e40939c44f391a4058f4cbac9f",
    {
      "id": "c9bda9da52e190e40939c44f391a4058f4cbac9f",
      "name": "요키시",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "34",
        "Pos.": "P",
        "G": "8",
        "GS": "8",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "3",
        "L": "4",
        "S": "0",
        "HD": "0",
        "IP": "39.1",
        "ER": "25",
        "R": "29",
        "rRA": "28.65",
        "TBF": "189",
        "H": "51",
        "2B": "11",
        "3B": "2",
        "HR": "5",
        "BB": "20",
        "HP": "2",
        "IB": "0",
        "SO": "32",
        "ROE": "3",
        "BK": "0",
        "WP": "2",
        "ERA": "5.72",
        "RA9": "6.64",
        "rRA9": "6.56",
        "rRA9pf": "6.86",
        "FIP": "5.41",
        "WHIP": "1.81",
        "WAR": "0.22"
      }
    }
  ],
  [
    "73503a56b14a857abdb1922a94e689a93154bf5d",
    {
      "id": "73503a56b14a857abdb1922a94e689a93154bf5d",
      "name": "카스타노",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "29",
        "Pos.": "P",
        "G": "19",
        "GS": "19",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "8",
        "L": "6",
        "S": "0",
        "HD": "0",
        "IP": "111.2",
        "ER": "54",
        "R": "65",
        "rRA": "63.45",
        "TBF": "476",
        "H": "117",
        "2B": "23",
        "3B": "4",
        "HR": "11",
        "BB": "25",
        "HP": "7",
        "IB": "0",
        "SO": "89",
        "ROE": "7",
        "BK": "1",
        "WP": "0",
        "ERA": "4.35",
        "RA9": "5.24",
        "rRA9": "5.11",
        "rRA9pf": "5.20",
        "FIP": "4.27",
        "WHIP": "1.27",
        "WAR": "2.26"
      }
    }
  ],
  [
    "bde381dc40df84fcde6da564cbb1dbb7fff361ef",
    {
      "id": "bde381dc40df84fcde6da564cbb1dbb7fff361ef",
      "name": "김시훈",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "24",
        "Pos.": "P",
        "G": "39",
        "GS": "18",
        "GR": "21",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "3",
        "L": "4",
        "S": "0",
        "HD": "5",
        "IP": "107.1",
        "ER": "54",
        "R": "60",
        "rRA": "57.45",
        "TBF": "477",
        "H": "110",
        "2B": "25",
        "3B": "1",
        "HR": "7",
        "BB": "59",
        "HP": "3",
        "IB": "0",
        "SO": "74",
        "ROE": "7",
        "BK": "1",
        "WP": "1",
        "ERA": "4.53",
        "RA9": "5.03",
        "rRA9": "4.82",
        "rRA9pf": "4.98",
        "FIP": "5.04",
        "WHIP": "1.57",
        "WAR": "2.31"
      }
    }
  ],
  [
    "8d8f72694c5390fc066b0f4addcbc456c8a8a179",
    {
      "id": "8d8f72694c5390fc066b0f4addcbc456c8a8a179",
      "name": "이용찬",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "34",
        "Pos.": "P",
        "G": "57",
        "GS": "0",
        "GR": "57",
        "GF": "36",
        "CG": "0",
        "SHO": "0",
        "W": "3",
        "L": "9",
        "S": "16",
        "HD": "2",
        "IP": "54.1",
        "ER": "37",
        "R": "48",
        "rRA": "46.50",
        "TBF": "262",
        "H": "82",
        "2B": "20",
        "3B": "3",
        "HR": "6",
        "BB": "21",
        "HP": "2",
        "IB": "1",
        "SO": "49",
        "ROE": "5",
        "BK": "0",
        "WP": "6",
        "ERA": "6.13",
        "RA9": "7.95",
        "rRA9": "7.70",
        "rRA9pf": "8.00",
        "FIP": "4.57",
        "WHIP": "1.90",
        "WAR": "-1.14"
      }
    }
  ],
  [
    "710086726ae0779da51ade1bcc6a5521d4a5d202",
    {
      "id": "710086726ae0779da51ade1bcc6a5521d4a5d202",
      "name": "천재환",
      "team": "NC",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "29",
        "Pos.": "RF",
        "oWAR": "0.56",
        "dWAR": "-0.20",
        "G": "89",
        "PA": "236",
        "ePA": "233",
        "AB": "215",
        "R": "31",
        "H": "61",
        "2B": "9",
        "3B": "1",
        "HR": "5",
        "TB": "87",
        "RBI": "33",
        "SB": "8",
        "CS": "2",
        "BB": "13",
        "HP": "4",
        "IB": "0",
        "SO": "52",
        "GDP": "7",
        "SH": "3",
        "SF": "1",
        "AVG": "0.284",
        "OBP": "0.335",
        "SLG": "0.405",
        "OPS": "0.740",
        "R/ePA": "0.121",
        "wRC+": "91.5",
        "WAR": "0.37"
      }
    }
  ],
  [
    "5cc232b56cad6865a2fe5730748b35a68e3695f9",
    {
      "id": "5cc232b56cad6865a2fe5730748b35a68e3695f9",
      "name": "데이비슨",
      "team": "NC",
      "position": "1B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "32",
        "Pos.": "1B",
        "oWAR": "4.26",
        "dWAR": "-0.28",
        "G": "131",
        "PA": "567",
        "ePA": "565",
        "AB": "504",
        "R": "90",
        "H": "154",
        "2B": "25",
        "3B": "1",
        "HR": "46",
        "TB": "319",
        "RBI": "119",
        "SB": "0",
        "CS": "1",
        "BB": "39",
        "HP": "17",
        "IB": "5",
        "SO": "142",
        "GDP": "12",
        "SH": "0",
        "SF": "7",
        "AVG": "0.306",
        "OBP": "0.370",
        "SLG": "0.633",
        "OPS": "1.003",
        "R/ePA": "0.203",
        "wRC+": "153.9",
        "WAR": "3.98"
      }
    }
  ],
  [
    "982472a951d0e77844b3427b26913c4b920471c7",
    {
      "id": "982472a951d0e77844b3427b26913c4b920471c7",
      "name": "김형준",
      "team": "NC",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "24",
        "Pos.": "C",
        "oWAR": "0.31",
        "dWAR": "0.11",
        "G": "119",
        "PA": "414",
        "ePA": "407",
        "AB": "354",
        "R": "39",
        "H": "69",
        "2B": "12",
        "3B": "0",
        "HR": "17",
        "TB": "132",
        "RBI": "50",
        "SB": "0",
        "CS": "0",
        "BB": "45",
        "HP": "2",
        "IB": "0",
        "SO": "144",
        "GDP": "6",
        "SH": "7",
        "SF": "6",
        "AVG": "0.195",
        "OBP": "0.285",
        "SLG": "0.373",
        "OPS": "0.658",
        "R/ePA": "0.086",
        "wRC+": "64.9",
        "WAR": "0.42"
      }
    }
  ],
  [
    "22eb61ca4314e6206139151406e7f92e4b24741e",
    {
      "id": "22eb61ca4314e6206139151406e7f92e4b24741e",
      "name": "최성영",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "26",
        "Pos.": "P",
        "G": "24",
        "GS": "4",
        "GR": "20",
        "GF": "7",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "0",
        "S": "1",
        "HD": "1",
        "IP": "46.2",
        "ER": "30",
        "R": "37",
        "rRA": "37.50",
        "TBF": "218",
        "H": "65",
        "2B": "10",
        "3B": "0",
        "HR": "7",
        "BB": "17",
        "HP": "0",
        "IB": "0",
        "SO": "29",
        "ROE": "3",
        "BK": "0",
        "WP": "0",
        "ERA": "5.79",
        "RA9": "7.14",
        "rRA9": "7.23",
        "rRA9pf": "7.25",
        "FIP": "5.30",
        "WHIP": "1.76",
        "WAR": "-0.05"
      }
    }
  ],
  [
    "b9656fb133c3a121799872f8408e21a10196a816",
    {
      "id": "b9656fb133c3a121799872f8408e21a10196a816",
      "name": "이준호",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "23",
        "Pos.": "P",
        "G": "38",
        "GS": "2",
        "GR": "36",
        "GF": "12",
        "CG": "0",
        "SHO": "0",
        "W": "3",
        "L": "5",
        "S": "0",
        "HD": "2",
        "IP": "49.1",
        "ER": "41",
        "R": "41",
        "rRA": "46.40",
        "TBF": "243",
        "H": "71",
        "2B": "11",
        "3B": "0",
        "HR": "7",
        "BB": "26",
        "HP": "3",
        "IB": "0",
        "SO": "42",
        "ROE": "1",
        "BK": "0",
        "WP": "0",
        "ERA": "7.48",
        "RA9": "7.48",
        "rRA9": "8.46",
        "rRA9pf": "8.64",
        "FIP": "5.58",
        "WHIP": "1.97",
        "WAR": "-0.62"
      }
    }
  ],
  [
    "81ee21ae9ec6fe17101878c29d0c7ee8e61d1582",
    {
      "id": "81ee21ae9ec6fe17101878c29d0c7ee8e61d1582",
      "name": "임상현",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "18",
        "Pos.": "P",
        "G": "12",
        "GS": "7",
        "GR": "5",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "5",
        "S": "0",
        "HD": "0",
        "IP": "40.1",
        "ER": "29",
        "R": "35",
        "rRA": "34.50",
        "TBF": "181",
        "H": "46",
        "2B": "13",
        "3B": "0",
        "HR": "5",
        "BB": "23",
        "HP": "2",
        "IB": "0",
        "SO": "21",
        "ROE": "1",
        "BK": "0",
        "WP": "1",
        "ERA": "6.47",
        "RA9": "7.81",
        "rRA9": "7.70",
        "rRA9pf": "7.64",
        "FIP": "6.29",
        "WHIP": "1.71",
        "WAR": "-0.03"
      }
    }
  ],
  [
    "bf7485aa34542ca627837413e09b44fe96213e4a",
    {
      "id": "bf7485aa34542ca627837413e09b44fe96213e4a",
      "name": "최우재",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "502e4b57544dd8e6ff529b30c9cc29bf97a0e4e3",
    {
      "id": "502e4b57544dd8e6ff529b30c9cc29bf97a0e4e3",
      "name": "하트",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "31",
        "Pos.": "P",
        "G": "26",
        "GS": "26",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "13",
        "L": "3",
        "S": "0",
        "HD": "0",
        "IP": "157.0",
        "ER": "47",
        "R": "51",
        "rRA": "51.00",
        "TBF": "631",
        "H": "124",
        "2B": "16",
        "3B": "1",
        "HR": "11",
        "BB": "38",
        "HP": "11",
        "IB": "0",
        "SO": "182",
        "ROE": "7",
        "BK": "0",
        "WP": "2",
        "ERA": "2.69",
        "RA9": "2.92",
        "rRA9": "2.92",
        "rRA9pf": "3.00",
        "FIP": "3.10",
        "WHIP": "1.03",
        "WAR": "6.93"
      }
    }
  ],
  [
    "d53eccff7a58fdd1623180926a7b1624b0bb8058",
    {
      "id": "d53eccff7a58fdd1623180926a7b1624b0bb8058",
      "name": "손아섭",
      "team": "NC",
      "position": "RF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "35",
        "Pos.": "DH",
        "oWAR": "-0.23",
        "dWAR": "0.31",
        "G": "84",
        "PA": "355",
        "ePA": "353",
        "AB": "333",
        "R": "45",
        "H": "95",
        "2B": "16",
        "3B": "0",
        "HR": "7",
        "TB": "132",
        "RBI": "50",
        "SB": "6",
        "CS": "2",
        "BB": "16",
        "HP": "0",
        "IB": "0",
        "SO": "65",
        "GDP": "1",
        "SH": "2",
        "SF": "4",
        "AVG": "0.285",
        "OBP": "0.314",
        "SLG": "0.396",
        "OPS": "0.710",
        "R/ePA": "0.103",
        "wRC+": "78.3",
        "WAR": "0.08"
      }
    }
  ],
  [
    "00b09a2469996fbd2e52b0b2f8aa8b5b9c67dce6",
    {
      "id": "00b09a2469996fbd2e52b0b2f8aa8b5b9c67dce6",
      "name": "김재열",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "27",
        "Pos.": "P",
        "G": "69",
        "GS": "0",
        "GR": "69",
        "GF": "16",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "5",
        "S": "2",
        "HD": "12",
        "IP": "68.2",
        "ER": "19",
        "R": "20",
        "rRA": "25.45",
        "TBF": "284",
        "H": "58",
        "2B": "15",
        "3B": "1",
        "HR": "4",
        "BB": "29",
        "HP": "0",
        "IB": "3",
        "SO": "67",
        "ROE": "2",
        "BK": "1",
        "WP": "1",
        "ERA": "2.49",
        "RA9": "2.62",
        "rRA9": "3.34",
        "rRA9pf": "3.46",
        "FIP": "3.72",
        "WHIP": "1.27",
        "WAR": "2.42"
      }
    }
  ],
  [
    "2c7ce1dc30beeaa0a82bf47ac288fa1e17460f5f",
    {
      "id": "2c7ce1dc30beeaa0a82bf47ac288fa1e17460f5f",
      "name": "한석현",
      "team": "NC",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "29",
        "Pos.": "RF",
        "oWAR": "-0.22",
        "dWAR": "-0.30",
        "G": "40",
        "PA": "76",
        "ePA": "76",
        "AB": "73",
        "R": "10",
        "H": "18",
        "2B": "4",
        "3B": "1",
        "HR": "0",
        "TB": "24",
        "RBI": "3",
        "SB": "2",
        "CS": "1",
        "BB": "2",
        "HP": "1",
        "IB": "0",
        "SO": "22",
        "GDP": "2",
        "SH": "0",
        "SF": "0",
        "AVG": "0.247",
        "OBP": "0.276",
        "SLG": "0.329",
        "OPS": "0.605",
        "R/ePA": "0.067",
        "wRC+": "50.3",
        "WAR": "-0.52"
      }
    }
  ],
  [
    "a8e7fb7366bbf848054adc520ddb915476fd5e0f",
    {
      "id": "a8e7fb7366bbf848054adc520ddb915476fd5e0f",
      "name": "오영수",
      "team": "NC",
      "position": "3B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "23",
        "Pos.": "1B",
        "oWAR": "-0.04",
        "dWAR": "0.12",
        "G": "20",
        "PA": "34",
        "ePA": "34",
        "AB": "30",
        "R": "4",
        "H": "6",
        "2B": "0",
        "3B": "0",
        "HR": "3",
        "TB": "15",
        "RBI": "5",
        "SB": "0",
        "CS": "0",
        "BB": "2",
        "HP": "1",
        "IB": "0",
        "SO": "6",
        "GDP": "0",
        "SH": "0",
        "SF": "1",
        "AVG": "0.200",
        "OBP": "0.265",
        "SLG": "0.500",
        "OPS": "0.765",
        "R/ePA": "0.108",
        "wRC+": "80.8",
        "WAR": "0.08"
      }
    }
  ],
  [
    "4ffe51f73651907be840b56dd6849fd3da57c1ff",
    {
      "id": "4ffe51f73651907be840b56dd6849fd3da57c1ff",
      "name": "한재환",
      "team": "NC",
      "position": "3B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "22",
        "Pos.": "DH",
        "oWAR": "-0.05",
        "dWAR": "-0.03",
        "G": "7",
        "PA": "9",
        "ePA": "9",
        "AB": "9",
        "R": "1",
        "H": "2",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "TB": "3",
        "RBI": "1",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "3",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.222",
        "OBP": "0.222",
        "SLG": "0.333",
        "OPS": "0.555",
        "R/ePA": "0.036",
        "wRC+": "26.9",
        "WAR": "-0.08"
      }
    }
  ],
  [
    "46bda453262614712b37c0a2c15ac84c11e4184b",
    {
      "id": "46bda453262614712b37c0a2c15ac84c11e4184b",
      "name": "권희동",
      "team": "NC",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "33",
        "Pos.": "LF",
        "oWAR": "3.48",
        "dWAR": "0.29",
        "G": "123",
        "PA": "511",
        "ePA": "509",
        "AB": "416",
        "R": "66",
        "H": "125",
        "2B": "22",
        "3B": "1",
        "HR": "13",
        "TB": "188",
        "RBI": "77",
        "SB": "4",
        "CS": "0",
        "BB": "77",
        "HP": "10",
        "IB": "1",
        "SO": "63",
        "GDP": "10",
        "SH": "2",
        "SF": "6",
        "AVG": "0.300",
        "OBP": "0.417",
        "SLG": "0.452",
        "OPS": "0.869",
        "R/ePA": "0.182",
        "wRC+": "137.9",
        "WAR": "3.77"
      }
    }
  ],
  [
    "78dfb4091b8d2568638c34e5a6b0b60549a11f74",
    {
      "id": "78dfb4091b8d2568638c34e5a6b0b60549a11f74",
      "name": "박건우",
      "team": "NC",
      "position": "RF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "33",
        "Pos.": "RF",
        "oWAR": "2.96",
        "dWAR": "-0.32",
        "G": "89",
        "PA": "362",
        "ePA": "360",
        "AB": "323",
        "R": "58",
        "H": "111",
        "2B": "23",
        "3B": "1",
        "HR": "13",
        "TB": "175",
        "RBI": "53",
        "SB": "4",
        "CS": "0",
        "BB": "33",
        "HP": "4",
        "IB": "3",
        "SO": "54",
        "GDP": "7",
        "SH": "0",
        "SF": "2",
        "AVG": "0.344",
        "OBP": "0.409",
        "SLG": "0.542",
        "OPS": "0.951",
        "R/ePA": "0.198",
        "wRC+": "150.1",
        "WAR": "2.64"
      }
    }
  ],
  [
    "7b02aa9a948d838b612259c93cea790fcf3cfa7b",
    {
      "id": "7b02aa9a948d838b612259c93cea790fcf3cfa7b",
      "name": "오장한",
      "team": "NC",
      "position": "RF",
      "record": {
        "Div.": "베스트",
        "Year": "2023",
        "Team": "NC",
        "Age": "20",
        "Pos.": "RF",
        "oWAR": "-0.02",
        "dWAR": "0.01",
        "G": "3",
        "PA": "8",
        "ePA": "8",
        "AB": "7",
        "R": "2",
        "H": "1",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "1",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "5",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.143",
        "OBP": "0.250",
        "SLG": "0.143",
        "OPS": "0.393",
        "R/ePA": "0.014",
        "wRC+": "11.4",
        "WAR": "-0.01"
      }
    }
  ],
  [
    "b6a82b33d7d85fbf4df4e4b49c04c0754ee46e22",
    {
      "id": "b6a82b33d7d85fbf4df4e4b49c04c0754ee46e22",
      "name": "김성욱",
      "team": "NC",
      "position": "RF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "30",
        "Pos.": "CF",
        "oWAR": "-0.10",
        "dWAR": "-0.38",
        "G": "129",
        "PA": "412",
        "ePA": "409",
        "AB": "358",
        "R": "55",
        "H": "73",
        "2B": "8",
        "3B": "2",
        "HR": "17",
        "TB": "136",
        "RBI": "60",
        "SB": "10",
        "CS": "4",
        "BB": "31",
        "HP": "15",
        "IB": "0",
        "SO": "79",
        "GDP": "7",
        "SH": "3",
        "SF": "5",
        "AVG": "0.204",
        "OBP": "0.291",
        "SLG": "0.380",
        "OPS": "0.671",
        "R/ePA": "0.095",
        "wRC+": "71.4",
        "WAR": "-0.49"
      }
    }
  ],
  [
    "0a3df7a060bc9d964970035511da496b721b79a9",
    {
      "id": "0a3df7a060bc9d964970035511da496b721b79a9",
      "name": "박시원",
      "team": "NC",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "22",
        "Pos.": "CF",
        "oWAR": "-0.26",
        "dWAR": "-0.20",
        "G": "55",
        "PA": "158",
        "ePA": "156",
        "AB": "141",
        "R": "17",
        "H": "33",
        "2B": "5",
        "3B": "1",
        "HR": "2",
        "TB": "46",
        "RBI": "17",
        "SB": "3",
        "CS": "0",
        "BB": "14",
        "HP": "0",
        "IB": "0",
        "SO": "46",
        "GDP": "2",
        "SH": "2",
        "SF": "1",
        "AVG": "0.234",
        "OBP": "0.301",
        "SLG": "0.326",
        "OPS": "0.627",
        "R/ePA": "0.080",
        "wRC+": "60.9",
        "WAR": "-0.46"
      }
    }
  ],
  [
    "a0a17fa64f7c7dfb3831c075f389eb1985302e4a",
    {
      "id": "a0a17fa64f7c7dfb3831c075f389eb1985302e4a",
      "name": "서준교",
      "team": "NC",
      "position": "SS",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "19f21df797367caaf87ce4547ae5178cb4458eba",
    {
      "id": "19f21df797367caaf87ce4547ae5178cb4458eba",
      "name": "류진욱",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "27",
        "Pos.": "P",
        "G": "50",
        "GS": "0",
        "GR": "50",
        "GF": "5",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "1",
        "S": "0",
        "HD": "10",
        "IP": "42.1",
        "ER": "27",
        "R": "30",
        "rRA": "31.90",
        "TBF": "194",
        "H": "52",
        "2B": "8",
        "3B": "0",
        "HR": "4",
        "BB": "19",
        "HP": "2",
        "IB": "0",
        "SO": "37",
        "ROE": "1",
        "BK": "0",
        "WP": "3",
        "ERA": "5.74",
        "RA9": "6.38",
        "rRA9": "6.78",
        "rRA9pf": "6.70",
        "FIP": "4.68",
        "WHIP": "1.68",
        "WAR": "-0.03"
      }
    }
  ],
  [
    "9ea48aba49cc131da0b0daa3cf17690c7485f793",
    {
      "id": "9ea48aba49cc131da0b0daa3cf17690c7485f793",
      "name": "신용석",
      "team": "NC",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "20",
        "Pos.": "C",
        "oWAR": "-0.07",
        "dWAR": "0.00",
        "G": "2",
        "PA": "2",
        "ePA": "2",
        "AB": "2",
        "R": "0",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "0",
        "GDP": "1",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.000",
        "SLG": "0.000",
        "OPS": "0.000",
        "R/ePA": "-0.190",
        "wRC+": "-136.9",
        "WAR": "-0.06"
      }
    }
  ],
  [
    "6f1ecdc5442d9188e5c05f15b21725f2fc117670",
    {
      "id": "6f1ecdc5442d9188e5c05f15b21725f2fc117670",
      "name": "신영우",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "19",
        "Pos.": "P",
        "G": "4",
        "GS": "2",
        "GR": "2",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "9.1",
        "ER": "11",
        "R": "11",
        "rRA": "10.15",
        "TBF": "52",
        "H": "9",
        "2B": "3",
        "3B": "0",
        "HR": "0",
        "BB": "15",
        "HP": "2",
        "IB": "0",
        "SO": "6",
        "ROE": "0",
        "BK": "0",
        "WP": "1",
        "ERA": "10.61",
        "RA9": "10.61",
        "rRA9": "9.79",
        "rRA9pf": "9.54",
        "FIP": "8.24",
        "WHIP": "2.57",
        "WAR": "-0.13"
      }
    }
  ],
  [
    "97bd7bb1ef515082838d05fe92e3d9ef0eeeca1d",
    {
      "id": "97bd7bb1ef515082838d05fe92e3d9ef0eeeca1d",
      "name": "박성재",
      "team": "NC",
      "position": "C",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "cea50c7a0004fcb627e9bd29e7488a6585924539",
    {
      "id": "cea50c7a0004fcb627e9bd29e7488a6585924539",
      "name": "김휘집",
      "team": "NC",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "21",
        "Pos.": "SS",
        "oWAR": "1.52",
        "dWAR": "0.47",
        "G": "89",
        "PA": "366",
        "ePA": "364",
        "AB": "314",
        "R": "54",
        "H": "86",
        "2B": "15",
        "3B": "0",
        "HR": "11",
        "TB": "134",
        "RBI": "48",
        "SB": "4",
        "CS": "3",
        "BB": "37",
        "HP": "7",
        "IB": "0",
        "SO": "70",
        "GDP": "7",
        "SH": "2",
        "SF": "6",
        "AVG": "0.274",
        "OBP": "0.357",
        "SLG": "0.427",
        "OPS": "0.784",
        "R/ePA": "0.141",
        "wRC+": "106.3",
        "WAR": "1.99"
      }
    }
  ],
  [
    "8ffefc33689e7332f7edef7462c91645fcec9c92",
    {
      "id": "8ffefc33689e7332f7edef7462c91645fcec9c92",
      "name": "이우석",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2021",
        "Team": "NC",
        "Age": "24",
        "Pos.": "P",
        "G": "10",
        "GS": "0",
        "GR": "10",
        "GF": "3",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "9.2",
        "ER": "6",
        "R": "6",
        "rRA": "6.00",
        "TBF": "49",
        "H": "13",
        "2B": "4",
        "3B": "0",
        "HR": "1",
        "BB": "7",
        "HP": "3",
        "IB": "0",
        "SO": "8",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "5.59",
        "RA9": "5.59",
        "rRA9": "5.59",
        "rRA9pf": "5.29",
        "FIP": "6.14",
        "WHIP": "2.07",
        "WAR": "0.10"
      }
    }
  ],
  [
    "2c8283f1fa04a493a5b390826fa7099aa041bf8d",
    {
      "id": "2c8283f1fa04a493a5b390826fa7099aa041bf8d",
      "name": "조현진",
      "team": "NC",
      "position": "2B",
      "record": {
        "Div.": "베스트",
        "Year": "2023",
        "Team": "NC",
        "Age": "20",
        "Pos.": "SS",
        "oWAR": "-0.06",
        "dWAR": "-0.05",
        "G": "10",
        "PA": "5",
        "ePA": "5",
        "AB": "5",
        "R": "0",
        "H": "1",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "1",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "1",
        "SH": "0",
        "SF": "0",
        "AVG": "0.200",
        "OBP": "0.200",
        "SLG": "0.200",
        "OPS": "0.400",
        "R/ePA": "-0.011",
        "wRC+": "-9.4",
        "WAR": "-0.11"
      }
    }
  ],
  [
    "294737a0a30187114ae229f1cae6605cec5eec19",
    {
      "id": "294737a0a30187114ae229f1cae6605cec5eec19",
      "name": "전루건",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "23",
        "Pos.": "P",
        "G": "6",
        "GS": "0",
        "GR": "6",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "5.2",
        "ER": "14",
        "R": "14",
        "rRA": "14.15",
        "TBF": "44",
        "H": "15",
        "2B": "3",
        "3B": "0",
        "HR": "0",
        "BB": "9",
        "HP": "4",
        "IB": "0",
        "SO": "0",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "22.24",
        "RA9": "22.24",
        "rRA9": "22.47",
        "rRA9pf": "21.89",
        "FIP": "11.31",
        "WHIP": "4.24",
        "WAR": "-0.45"
      }
    }
  ],
  [
    "961bbf66c9994b516a69e2f14989b671be7503af",
    {
      "id": "961bbf66c9994b516a69e2f14989b671be7503af",
      "name": "이용준",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "21",
        "Pos.": "P",
        "G": "9",
        "GS": "9",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "3",
        "S": "0",
        "HD": "0",
        "IP": "33.2",
        "ER": "25",
        "R": "28",
        "rRA": "25.40",
        "TBF": "157",
        "H": "42",
        "2B": "6",
        "3B": "2",
        "HR": "5",
        "BB": "14",
        "HP": "1",
        "IB": "0",
        "SO": "26",
        "ROE": "3",
        "BK": "0",
        "WP": "1",
        "ERA": "6.68",
        "RA9": "7.49",
        "rRA9": "6.79",
        "rRA9pf": "7.10",
        "FIP": "5.42",
        "WHIP": "1.66",
        "WAR": "0.12"
      }
    }
  ],
  [
    "d57136339ed95d87e10b61a0d9a5e0317f10dfaa",
    {
      "id": "d57136339ed95d87e10b61a0d9a5e0317f10dfaa",
      "name": "최보성",
      "team": "NC",
      "position": "3B",
      "record": {
        "Div.": "베스트",
        "Year": "2023",
        "Team": "NC",
        "Age": "24",
        "Pos.": "3B",
        "oWAR": "0.01",
        "dWAR": "-0.12",
        "G": "12",
        "PA": "21",
        "ePA": "21",
        "AB": "19",
        "R": "2",
        "H": "5",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "5",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "2",
        "HP": "0",
        "IB": "0",
        "SO": "2",
        "GDP": "1",
        "SH": "0",
        "SF": "0",
        "AVG": "0.263",
        "OBP": "0.333",
        "SLG": "0.263",
        "OPS": "0.596",
        "R/ePA": "0.082",
        "wRC+": "68.3",
        "WAR": "-0.12"
      }
    }
  ],
  [
    "5040dff9c5fe277f0f01cda50f3067e50cef71f2",
    {
      "id": "5040dff9c5fe277f0f01cda50f3067e50cef71f2",
      "name": "오태양",
      "team": "NC",
      "position": "SS",
      "record": {
        "Div.": "베스트",
        "Year": "2022",
        "Team": "NC",
        "Age": "19",
        "Pos.": "CF",
        "oWAR": "-0.06",
        "dWAR": "0.10",
        "G": "15",
        "PA": "4",
        "ePA": "4",
        "AB": "3",
        "R": "5",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "2",
        "CS": "2",
        "BB": "0",
        "HP": "1",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.250",
        "SLG": "0.000",
        "OPS": "0.250",
        "R/ePA": "0.026",
        "wRC+": "20.7",
        "WAR": "0.05"
      }
    }
  ],
  [
    "ca927cf076ac58af059ee89b17045864ebcc7cdf",
    {
      "id": "ca927cf076ac58af059ee89b17045864ebcc7cdf",
      "name": "소이현",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "24",
        "Pos.": "P",
        "G": "4",
        "GS": "0",
        "GR": "4",
        "GF": "3",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "3.1",
        "ER": "5",
        "R": "7",
        "rRA": "5.95",
        "TBF": "19",
        "H": "6",
        "2B": "3",
        "3B": "0",
        "HR": "0",
        "BB": "1",
        "HP": "1",
        "IB": "0",
        "SO": "1",
        "ROE": "1",
        "BK": "0",
        "WP": "0",
        "ERA": "13.50",
        "RA9": "18.90",
        "rRA9": "16.07",
        "rRA9pf": "16.65",
        "FIP": "5.31",
        "WHIP": "2.10",
        "WAR": "-0.15"
      }
    }
  ],
  [
    "3b01606f105bae66111e556cb8142df2c48e20be",
    {
      "id": "3b01606f105bae66111e556cb8142df2c48e20be",
      "name": "이재학",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "33",
        "Pos.": "P",
        "G": "21",
        "GS": "21",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "3",
        "L": "12",
        "S": "0",
        "HD": "0",
        "IP": "104.1",
        "ER": "64",
        "R": "68",
        "rRA": "66.25",
        "TBF": "462",
        "H": "108",
        "2B": "27",
        "3B": "3",
        "HR": "17",
        "BB": "42",
        "HP": "4",
        "IB": "0",
        "SO": "97",
        "ROE": "3",
        "BK": "0",
        "WP": "2",
        "ERA": "5.52",
        "RA9": "5.87",
        "rRA9": "5.71",
        "rRA9pf": "5.86",
        "FIP": "5.18",
        "WHIP": "1.44",
        "WAR": "1.45"
      }
    }
  ],
  [
    "500e9aef64dec036d1d551fecb01d06bde74cdd8",
    {
      "id": "500e9aef64dec036d1d551fecb01d06bde74cdd8",
      "name": "윤형준",
      "team": "NC",
      "position": "1B",
      "record": {
        "Div.": "베스트",
        "Year": "2023",
        "Team": "NC",
        "Age": "28",
        "Pos.": "1B",
        "oWAR": "-0.22",
        "dWAR": "0.29",
        "G": "82",
        "PA": "236",
        "ePA": "234",
        "AB": "218",
        "R": "17",
        "H": "55",
        "2B": "12",
        "3B": "1",
        "HR": "5",
        "TB": "84",
        "RBI": "27",
        "SB": "1",
        "CS": "1",
        "BB": "10",
        "HP": "3",
        "IB": "0",
        "SO": "58",
        "GDP": "6",
        "SH": "2",
        "SF": "3",
        "AVG": "0.252",
        "OBP": "0.291",
        "SLG": "0.385",
        "OPS": "0.676",
        "R/ePA": "0.096",
        "wRC+": "81.1",
        "WAR": "0.07"
      }
    }
  ],
  [
    "24b461d5c26c426b80f8d1273e35180b250679f3",
    {
      "id": "24b461d5c26c426b80f8d1273e35180b250679f3",
      "name": "신민혁",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "24",
        "Pos.": "P",
        "G": "25",
        "GS": "24",
        "GR": "1",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "8",
        "L": "9",
        "S": "0",
        "HD": "0",
        "IP": "121.0",
        "ER": "58",
        "R": "67",
        "rRA": "61.30",
        "TBF": "524",
        "H": "140",
        "2B": "24",
        "3B": "3",
        "HR": "20",
        "BB": "16",
        "HP": "7",
        "IB": "0",
        "SO": "74",
        "ROE": "8",
        "BK": "1",
        "WP": "2",
        "ERA": "4.31",
        "RA9": "4.98",
        "rRA9": "4.56",
        "rRA9pf": "4.67",
        "FIP": "5.23",
        "WHIP": "1.29",
        "WAR": "3.14"
      }
    }
  ],
  [
    "7a4ba10d4123fa1ee08cbba4cc9c87348bb59e18",
    {
      "id": "7a4ba10d4123fa1ee08cbba4cc9c87348bb59e18",
      "name": "김진호",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "25",
        "Pos.": "P",
        "G": "2",
        "GS": "0",
        "GR": "2",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "2.1",
        "ER": "2",
        "R": "2",
        "rRA": "2.00",
        "TBF": "12",
        "H": "3",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "BB": "2",
        "HP": "0",
        "IB": "0",
        "SO": "3",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "7.71",
        "RA9": "7.71",
        "rRA9": "7.71",
        "rRA9pf": "8.07",
        "FIP": "3.64",
        "WHIP": "2.14",
        "WAR": "0.00"
      }
    }
  ],
  [
    "20563429049a23760357f365b21459b385fa5903",
    {
      "id": "20563429049a23760357f365b21459b385fa5903",
      "name": "한재승",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "22",
        "Pos.": "P",
        "G": "51",
        "GS": "0",
        "GR": "51",
        "GF": "12",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "2",
        "S": "0",
        "HD": "6",
        "IP": "45.1",
        "ER": "20",
        "R": "21",
        "rRA": "24.20",
        "TBF": "201",
        "H": "37",
        "2B": "7",
        "3B": "1",
        "HR": "2",
        "BB": "27",
        "HP": "3",
        "IB": "0",
        "SO": "44",
        "ROE": "2",
        "BK": "0",
        "WP": "6",
        "ERA": "3.97",
        "RA9": "4.17",
        "rRA9": "4.80",
        "rRA9pf": "4.84",
        "FIP": "4.35",
        "WHIP": "1.41",
        "WAR": "0.88"
      }
    }
  ],
  [
    "8bed64d100faad4348891b51da340573e86ec1f0",
    {
      "id": "8bed64d100faad4348891b51da340573e86ec1f0",
      "name": "전사민",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "24",
        "Pos.": "P",
        "G": "17",
        "GS": "0",
        "GR": "17",
        "GF": "9",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "23.2",
        "ER": "25",
        "R": "25",
        "rRA": "26.05",
        "TBF": "128",
        "H": "39",
        "2B": "6",
        "3B": "1",
        "HR": "3",
        "BB": "17",
        "HP": "3",
        "IB": "0",
        "SO": "17",
        "ROE": "0",
        "BK": "2",
        "WP": "4",
        "ERA": "9.51",
        "RA9": "9.51",
        "rRA9": "9.91",
        "rRA9pf": "10.02",
        "FIP": "6.53",
        "WHIP": "2.37",
        "WAR": "-0.45"
      }
    }
  ],
  [
    "6b213c33f8aa8c0b322f3a0a6ca18fbb9e49ebaf",
    {
      "id": "6b213c33f8aa8c0b322f3a0a6ca18fbb9e49ebaf",
      "name": "서의태",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "26",
        "Pos.": "P",
        "G": "11",
        "GS": "0",
        "GR": "11",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "7.2",
        "ER": "8",
        "R": "8",
        "rRA": "7.30",
        "TBF": "34",
        "H": "5",
        "2B": "1",
        "3B": "0",
        "HR": "1",
        "BB": "8",
        "HP": "0",
        "IB": "0",
        "SO": "7",
        "ROE": "0",
        "BK": "0",
        "WP": "1",
        "ERA": "9.39",
        "RA9": "9.39",
        "rRA9": "8.57",
        "rRA9pf": "8.78",
        "FIP": "6.70",
        "WHIP": "1.70",
        "WAR": "-0.08"
      }
    }
  ],
  [
    "6beeb755a908a3a56b07f876a533aa4e96392fa1",
    {
      "id": "6beeb755a908a3a56b07f876a533aa4e96392fa1",
      "name": "김휘건",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "6fb7794481516672bc38ffbe774a0369246df502",
    {
      "id": "6fb7794481516672bc38ffbe774a0369246df502",
      "name": "박한결",
      "team": "NC",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "19",
        "Pos.": "LF",
        "oWAR": "-0.07",
        "dWAR": "-0.21",
        "G": "28",
        "PA": "71",
        "ePA": "71",
        "AB": "65",
        "R": "8",
        "H": "12",
        "2B": "1",
        "3B": "0",
        "HR": "7",
        "TB": "34",
        "RBI": "14",
        "SB": "0",
        "CS": "0",
        "BB": "6",
        "HP": "0",
        "IB": "0",
        "SO": "29",
        "GDP": "1",
        "SH": "0",
        "SF": "0",
        "AVG": "0.185",
        "OBP": "0.254",
        "SLG": "0.523",
        "OPS": "0.777",
        "R/ePA": "0.110",
        "wRC+": "84.6",
        "WAR": "-0.28"
      }
    }
  ],
  [
    "abf49979beba0dafc4d44f8310cba83f1c60ac0f",
    {
      "id": "abf49979beba0dafc4d44f8310cba83f1c60ac0f",
      "name": "배재환",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "28",
        "Pos.": "P",
        "G": "20",
        "GS": "0",
        "GR": "20",
        "GF": "5",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "19.2",
        "ER": "16",
        "R": "17",
        "rRA": "16.50",
        "TBF": "91",
        "H": "20",
        "2B": "2",
        "3B": "0",
        "HR": "5",
        "BB": "15",
        "HP": "0",
        "IB": "1",
        "SO": "13",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "7.32",
        "RA9": "7.78",
        "rRA9": "7.55",
        "rRA9pf": "7.56",
        "FIP": "7.94",
        "WHIP": "1.78",
        "WAR": "-0.07"
      }
    }
  ],
  [
    "61ed109fabf1b1620623e497d79fe1d18bb6ae89",
    {
      "id": "61ed109fabf1b1620623e497d79fe1d18bb6ae89",
      "name": "김민규",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "22",
        "Pos.": "P",
        "G": "7",
        "GS": "0",
        "GR": "7",
        "GF": "3",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "6.1",
        "ER": "4",
        "R": "4",
        "rRA": "6.05",
        "TBF": "30",
        "H": "8",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "BB": "3",
        "HP": "0",
        "IB": "0",
        "SO": "2",
        "ROE": "0",
        "BK": "0",
        "WP": "1",
        "ERA": "5.68",
        "RA9": "5.68",
        "rRA9": "8.60",
        "rRA9pf": "8.90",
        "FIP": "4.88",
        "WHIP": "1.74",
        "WAR": "-0.07"
      }
    }
  ],
  [
    "aa4e304cc62b1409c6e7aa7292691ad6eb3d054e",
    {
      "id": "aa4e304cc62b1409c6e7aa7292691ad6eb3d054e",
      "name": "최우석",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "18",
        "Pos.": "P",
        "G": "9",
        "GS": "0",
        "GR": "9",
        "GF": "3",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "8.2",
        "ER": "3",
        "R": "3",
        "rRA": "4.20",
        "TBF": "37",
        "H": "7",
        "2B": "2",
        "3B": "0",
        "HR": "2",
        "BB": "6",
        "HP": "0",
        "IB": "0",
        "SO": "8",
        "ROE": "0",
        "BK": "0",
        "WP": "1",
        "ERA": "3.12",
        "RA9": "3.12",
        "rRA9": "4.36",
        "rRA9pf": "4.59",
        "FIP": "6.78",
        "WHIP": "1.50",
        "WAR": "0.16"
      }
    }
  ],
  [
    "f58091fe16f32192ccb210e3e4f48f57e065e8a1",
    {
      "id": "f58091fe16f32192ccb210e3e4f48f57e065e8a1",
      "name": "조민석",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2023",
        "Team": "NC",
        "Age": "24",
        "Pos.": "P",
        "G": "35",
        "GS": "0",
        "GR": "35",
        "GF": "8",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "0",
        "S": "0",
        "HD": "4",
        "IP": "31.0",
        "ER": "14",
        "R": "15",
        "rRA": "17.05",
        "TBF": "137",
        "H": "33",
        "2B": "9",
        "3B": "0",
        "HR": "2",
        "BB": "14",
        "HP": "3",
        "IB": "0",
        "SO": "28",
        "ROE": "2",
        "BK": "0",
        "WP": "1",
        "ERA": "4.06",
        "RA9": "4.35",
        "rRA9": "4.95",
        "rRA9pf": "4.89",
        "FIP": "4.06",
        "WHIP": "1.52",
        "WAR": "0.35"
      }
    }
  ],
  [
    "26e2d837aa0b892cef72f5d5430373b5d7fa412a",
    {
      "id": "26e2d837aa0b892cef72f5d5430373b5d7fa412a",
      "name": "이현우",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "f95ba2b7479d2973489cf9fa3f374d64bd2f5f29",
    {
      "id": "f95ba2b7479d2973489cf9fa3f374d64bd2f5f29",
      "name": "김범준",
      "team": "NC",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "23",
        "Pos.": "LF",
        "oWAR": "-0.15",
        "dWAR": "0.00",
        "G": "5",
        "PA": "5",
        "ePA": "5",
        "AB": "5",
        "R": "0",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "2",
        "GDP": "1",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.000",
        "SLG": "0.000",
        "OPS": "0.000",
        "R/ePA": "-0.190",
        "wRC+": "-142.8",
        "WAR": "-0.15"
      }
    }
  ],
  [
    "a17679e5c96c6782f2cfbea47c630f9faa2a9398",
    {
      "id": "a17679e5c96c6782f2cfbea47c630f9faa2a9398",
      "name": "박주현",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "24",
        "Pos.": "P",
        "G": "3",
        "GS": "0",
        "GR": "3",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "3.0",
        "ER": "2",
        "R": "2",
        "rRA": "2.00",
        "TBF": "13",
        "H": "3",
        "2B": "0",
        "3B": "0",
        "HR": "1",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "4",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "6.00",
        "RA9": "6.00",
        "rRA9": "6.00",
        "rRA9pf": "5.44",
        "FIP": "5.87",
        "WHIP": "1.33",
        "WAR": "0.04"
      }
    }
  ],
  [
    "b04ebb8a632da12d79f712d889c48e2d557b0db4",
    {
      "id": "b04ebb8a632da12d79f712d889c48e2d557b0db4",
      "name": "김한별",
      "team": "NC",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "22",
        "Pos.": "SS",
        "oWAR": "0.24",
        "dWAR": "-0.31",
        "G": "40",
        "PA": "50",
        "ePA": "50",
        "AB": "44",
        "R": "8",
        "H": "14",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "TB": "15",
        "RBI": "3",
        "SB": "0",
        "CS": "0",
        "BB": "2",
        "HP": "4",
        "IB": "0",
        "SO": "9",
        "GDP": "2",
        "SH": "0",
        "SF": "0",
        "AVG": "0.318",
        "OBP": "0.400",
        "SLG": "0.341",
        "OPS": "0.741",
        "R/ePA": "0.148",
        "wRC+": "109.8",
        "WAR": "-0.07"
      }
    }
  ],
  [
    "242ffca60a42e39f34d5d965bca1151ee3fc0f19",
    {
      "id": "242ffca60a42e39f34d5d965bca1151ee3fc0f19",
      "name": "목지훈",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "19",
        "Pos.": "P",
        "G": "3",
        "GS": "3",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "9.0",
        "ER": "12",
        "R": "12",
        "rRA": "11.15",
        "TBF": "52",
        "H": "15",
        "2B": "6",
        "3B": "0",
        "HR": "1",
        "BB": "10",
        "HP": "2",
        "IB": "0",
        "SO": "3",
        "ROE": "1",
        "BK": "0",
        "WP": "1",
        "ERA": "12.00",
        "RA9": "12.00",
        "rRA9": "11.15",
        "rRA9pf": "11.79",
        "FIP": "8.82",
        "WHIP": "2.78",
        "WAR": "-0.26"
      }
    }
  ],
  [
    "e3701a606ed1c7bd2df738aab21674da3bc560ba",
    {
      "id": "e3701a606ed1c7bd2df738aab21674da3bc560ba",
      "name": "김세훈",
      "team": "NC",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "18",
        "Pos.": "SS",
        "oWAR": "-0.06",
        "dWAR": "-0.05",
        "G": "7",
        "PA": "5",
        "ePA": "5",
        "AB": "4",
        "R": "0",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "2",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.200",
        "SLG": "0.000",
        "OPS": "0.200",
        "R/ePA": "-0.045",
        "wRC+": "-35.0",
        "WAR": "-0.10"
      }
    }
  ],
  [
    "607e7da49bb6f91f873354c6d5280522f2ddc55f",
    {
      "id": "607e7da49bb6f91f873354c6d5280522f2ddc55f",
      "name": "안인산",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2020",
        "Team": "NC",
        "Age": "18",
        "Pos.": "P",
        "G": "1",
        "GS": "0",
        "GR": "1",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "1.0",
        "ER": "0",
        "R": "0",
        "rRA": "0.00",
        "TBF": "3",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "0",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "0.00",
        "RA9": "0.00",
        "rRA9": "0.00",
        "rRA9pf": "0.00",
        "FIP": "4.00",
        "WHIP": "0.00",
        "WAR": "0.06"
      }
    }
  ],
  [
    "e254ddfd5ef1e6204466d403e1d5a602352e7963",
    {
      "id": "e254ddfd5ef1e6204466d403e1d5a602352e7963",
      "name": "손주환",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "NC",
        "Age": "21",
        "Pos.": "P",
        "G": "4",
        "GS": "1",
        "GR": "3",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "3.2",
        "ER": "4",
        "R": "4",
        "rRA": "2.80",
        "TBF": "16",
        "H": "2",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "BB": "2",
        "HP": "1",
        "IB": "0",
        "SO": "4",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "9.82",
        "RA9": "9.82",
        "rRA9": "6.87",
        "rRA9pf": "6.90",
        "FIP": "4.01",
        "WHIP": "1.09",
        "WAR": "-0.03"
      }
    }
  ],
  [
    "05cda511a2cbabd67af774c40a941b6abe3a5a27",
    {
      "id": "05cda511a2cbabd67af774c40a941b6abe3a5a27",
      "name": "노재원",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "5c763658aeefddd5a58a64020e9bbc2091857a11",
    {
      "id": "5c763658aeefddd5a58a64020e9bbc2091857a11",
      "name": "김민균",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "bf5d9a80d9ec6c981a32f4354c83fcb47e833aa2",
    {
      "id": "bf5d9a80d9ec6c981a32f4354c83fcb47e833aa2",
      "name": "홍유원",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "a199e943742642405032a7b1a56ed2bcf0aa9f2e",
    {
      "id": "a199e943742642405032a7b1a56ed2bcf0aa9f2e",
      "name": "원종해",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "5219679806205d7fe77d988ceca08d332d5c0fc1",
    {
      "id": "5219679806205d7fe77d988ceca08d332d5c0fc1",
      "name": "조현민",
      "team": "NC",
      "position": "2B",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "3325636b501802e1d02af89e0f25a3947340129a",
    {
      "id": "3325636b501802e1d02af89e0f25a3947340129a",
      "name": "고승완",
      "team": "NC",
      "position": "LF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "907bc1eba9d312c502fec6b78f14b5c0102533f8",
    {
      "id": "907bc1eba9d312c502fec6b78f14b5c0102533f8",
      "name": "김재민",
      "team": "NC",
      "position": "C",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "aa057dc6a9d8d34976f1e04a02ae2109b1bda43a",
    {
      "id": "aa057dc6a9d8d34976f1e04a02ae2109b1bda43a",
      "name": "김준원",
      "team": "NC",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "5dd92e2f45fe80925636e584ce30a3d777fd92f8",
    {
      "id": "5dd92e2f45fe80925636e584ce30a3d777fd92f8",
      "name": "박인우",
      "team": "NC",
      "position": "2B",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "c151832ca8599c0a41ba3686d449b480701b3d85",
    {
      "id": "c151832ca8599c0a41ba3686d449b480701b3d85",
      "name": "김태호",
      "team": "NC",
      "position": "C",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "1b9cb02eb08f37944c939ec40e5af8fbdb742ba6",
    {
      "id": "1b9cb02eb08f37944c939ec40e5af8fbdb742ba6",
      "name": "김건형",
      "team": "KT",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "27",
        "Pos.": "RF",
        "oWAR": "-0.35",
        "dWAR": "-0.03",
        "G": "13",
        "PA": "25",
        "ePA": "25",
        "AB": "24",
        "R": "2",
        "H": "3",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "TB": "4",
        "RBI": "1",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "8",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.125",
        "OBP": "0.160",
        "SLG": "0.167",
        "OPS": "0.327",
        "R/ePA": "-0.046",
        "wRC+": "-34.1",
        "WAR": "-0.38"
      }
    }
  ],
  [
    "5103abca70baffffcf359b71cca66e8215a59e18",
    {
      "id": "5103abca70baffffcf359b71cca66e8215a59e18",
      "name": "고영표",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "32",
        "Pos.": "P",
        "G": "18",
        "GS": "17",
        "GR": "1",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "6",
        "L": "8",
        "S": "0",
        "HD": "0",
        "IP": "100.0",
        "ER": "55",
        "R": "61",
        "rRA": "59.80",
        "TBF": "456",
        "H": "141",
        "2B": "27",
        "3B": "4",
        "HR": "6",
        "BB": "14",
        "HP": "11",
        "IB": "0",
        "SO": "79",
        "ROE": "4",
        "BK": "1",
        "WP": "2",
        "ERA": "4.95",
        "RA9": "5.49",
        "rRA9": "5.38",
        "rRA9pf": "5.31",
        "FIP": "3.71",
        "WHIP": "1.55",
        "WAR": "1.86"
      }
    }
  ],
  [
    "cba120f49a0575d70bf215bcc1de1618a5037298",
    {
      "id": "cba120f49a0575d70bf215bcc1de1618a5037298",
      "name": "심우준",
      "team": "KT",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "28",
        "Pos.": "SS",
        "oWAR": "0.65",
        "dWAR": "-0.01",
        "G": "53",
        "PA": "192",
        "ePA": "187",
        "AB": "169",
        "R": "22",
        "H": "45",
        "2B": "4",
        "3B": "0",
        "HR": "3",
        "TB": "58",
        "RBI": "28",
        "SB": "7",
        "CS": "1",
        "BB": "17",
        "HP": "1",
        "IB": "0",
        "SO": "29",
        "GDP": "3",
        "SH": "5",
        "SF": "0",
        "AVG": "0.266",
        "OBP": "0.337",
        "SLG": "0.343",
        "OPS": "0.680",
        "R/ePA": "0.105",
        "wRC+": "75.5",
        "WAR": "0.64"
      }
    }
  ],
  [
    "7a9af6ff3af438d5fff9c1e0153f28922b8b6826",
    {
      "id": "7a9af6ff3af438d5fff9c1e0153f28922b8b6826",
      "name": "로하스",
      "team": "KT",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "33",
        "Pos.": "RF",
        "oWAR": "6.34",
        "dWAR": "0.16",
        "G": "144",
        "PA": "670",
        "ePA": "664",
        "AB": "572",
        "R": "108",
        "H": "188",
        "2B": "39",
        "3B": "1",
        "HR": "32",
        "TB": "325",
        "RBI": "112",
        "SB": "2",
        "CS": "1",
        "BB": "88",
        "HP": "6",
        "IB": "9",
        "SO": "115",
        "GDP": "10",
        "SH": "0",
        "SF": "4",
        "AVG": "0.329",
        "OBP": "0.421",
        "SLG": "0.568",
        "OPS": "0.989",
        "R/ePA": "0.211",
        "wRC+": "153.3",
        "WAR": "6.50"
      }
    }
  ],
  [
    "e6b5c30cff53c70e0b439860073d68a9ab741e48",
    {
      "id": "e6b5c30cff53c70e0b439860073d68a9ab741e48",
      "name": "오윤석",
      "team": "KT",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "31",
        "Pos.": "2B",
        "oWAR": "1.36",
        "dWAR": "0.16",
        "G": "73",
        "PA": "201",
        "ePA": "198",
        "AB": "174",
        "R": "33",
        "H": "51",
        "2B": "12",
        "3B": "2",
        "HR": "6",
        "TB": "85",
        "RBI": "27",
        "SB": "0",
        "CS": "1",
        "BB": "18",
        "HP": "4",
        "IB": "0",
        "SO": "41",
        "GDP": "7",
        "SH": "3",
        "SF": "2",
        "AVG": "0.293",
        "OBP": "0.369",
        "SLG": "0.489",
        "OPS": "0.858",
        "R/ePA": "0.166",
        "wRC+": "120.7",
        "WAR": "1.52"
      }
    }
  ],
  [
    "600089e4101c9415793a1bc51a81595df8c94b9d",
    {
      "id": "600089e4101c9415793a1bc51a81595df8c94b9d",
      "name": "강민성",
      "team": "KT",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "24",
        "Pos.": "1B",
        "oWAR": "-0.03",
        "dWAR": "0.04",
        "G": "1",
        "PA": "1",
        "ePA": "1",
        "AB": "1",
        "R": "0",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "0",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.000",
        "SLG": "0.000",
        "OPS": "0.000",
        "R/ePA": "-0.190",
        "wRC+": "-127.9",
        "WAR": "0.01"
      }
    }
  ],
  [
    "25d1ca94283f2b5525b41a3a9f3a980f4c747701",
    {
      "id": "25d1ca94283f2b5525b41a3a9f3a980f4c747701",
      "name": "박경수",
      "team": "KT",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "39",
        "Pos.": "2B",
        "oWAR": "0.11",
        "dWAR": "-0.05",
        "G": "5",
        "PA": "3",
        "ePA": "3",
        "AB": "3",
        "R": "2",
        "H": "2",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "TB": "3",
        "RBI": "1",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.667",
        "OBP": "0.667",
        "SLG": "1.000",
        "OPS": "1.667",
        "R/ePA": "0.487",
        "wRC+": "354.6",
        "WAR": "0.05"
      }
    }
  ],
  [
    "d1fee11a0582a71ff5db430f0b38f0455a65d966",
    {
      "id": "d1fee11a0582a71ff5db430f0b38f0455a65d966",
      "name": "김상수",
      "team": "KT",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "",
        "Age": "33",
        "Pos.": "SS",
        "oWAR": "1.77",
        "dWAR": "0.13",
        "G": "113",
        "PA": "420",
        "ePA": "413",
        "AB": "369",
        "R": "60",
        "H": "102",
        "2B": "18",
        "3B": "4",
        "HR": "4",
        "TB": "140",
        "RBI": "45",
        "SB": "3",
        "CS": "2",
        "BB": "37",
        "HP": "6",
        "IB": "0",
        "SO": "59",
        "GDP": "12",
        "SH": "7",
        "SF": "1",
        "AVG": "0.276",
        "OBP": "0.351",
        "SLG": "0.379",
        "OPS": "0.730",
        "R/ePA": "0.126",
        "wRC+": "91.5",
        "WAR": "1.89"
      }
    }
  ],
  [
    "3f1526e906585762e7644b47e578583840d58f44",
    {
      "id": "3f1526e906585762e7644b47e578583840d58f44",
      "name": "안치영",
      "team": "KT",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "25",
        "Pos.": "CF",
        "oWAR": "-0.26",
        "dWAR": "0.05",
        "G": "31",
        "PA": "48",
        "ePA": "46",
        "AB": "44",
        "R": "12",
        "H": "11",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "11",
        "RBI": "3",
        "SB": "0",
        "CS": "1",
        "BB": "2",
        "HP": "0",
        "IB": "0",
        "SO": "12",
        "GDP": "0",
        "SH": "2",
        "SF": "0",
        "AVG": "0.250",
        "OBP": "0.283",
        "SLG": "0.250",
        "OPS": "0.533",
        "R/ePA": "0.042",
        "wRC+": "30.8",
        "WAR": "-0.22"
      }
    }
  ],
  [
    "16a19270975f99d93d0e51c267fa73e4133e0673",
    {
      "id": "16a19270975f99d93d0e51c267fa73e4133e0673",
      "name": "김철호",
      "team": "KT",
      "position": "2B",
      "record": {
        "Div.": "베스트",
        "Year": "2018",
        "Team": "NC",
        "Age": "19",
        "Pos.": "2B",
        "oWAR": "-0.03",
        "dWAR": "0.00",
        "G": "1",
        "PA": "1",
        "ePA": "1",
        "AB": "1",
        "R": "0",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.000",
        "SLG": "0.000",
        "OPS": "0.000",
        "R/ePA": "-0.189",
        "wRC+": "-153.1",
        "WAR": "-0.03"
      }
    }
  ],
  [
    "ec3dab218fe391760f25ed5068d189ccbd065793",
    {
      "id": "ec3dab218fe391760f25ed5068d189ccbd065793",
      "name": "황재균",
      "team": "KT",
      "position": "3B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "36",
        "Pos.": "3B",
        "oWAR": "0.02",
        "dWAR": "-0.32",
        "G": "137",
        "PA": "536",
        "ePA": "531",
        "AB": "493",
        "R": "60",
        "H": "128",
        "2B": "22",
        "3B": "0",
        "HR": "13",
        "TB": "189",
        "RBI": "58",
        "SB": "4",
        "CS": "6",
        "BB": "34",
        "HP": "2",
        "IB": "0",
        "SO": "95",
        "GDP": "16",
        "SH": "5",
        "SF": "2",
        "AVG": "0.260",
        "OBP": "0.309",
        "SLG": "0.383",
        "OPS": "0.692",
        "R/ePA": "0.099",
        "wRC+": "72.2",
        "WAR": "-0.30"
      }
    }
  ],
  [
    "b734ee42fa294b89b2f2074bf8314f4d1be7dc1e",
    {
      "id": "b734ee42fa294b89b2f2074bf8314f4d1be7dc1e",
      "name": "김민",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "24",
        "Pos.": "P",
        "G": "71",
        "GS": "1",
        "GR": "70",
        "GF": "5",
        "CG": "0",
        "SHO": "0",
        "W": "8",
        "L": "4",
        "S": "0",
        "HD": "21",
        "IP": "77.1",
        "ER": "37",
        "R": "38",
        "rRA": "36.45",
        "TBF": "336",
        "H": "84",
        "2B": "13",
        "3B": "0",
        "HR": "5",
        "BB": "27",
        "HP": "2",
        "IB": "3",
        "SO": "77",
        "ROE": "3",
        "BK": "0",
        "WP": "5",
        "ERA": "4.31",
        "RA9": "4.42",
        "rRA9": "4.24",
        "rRA9pf": "4.20",
        "FIP": "3.60",
        "WHIP": "1.44",
        "WAR": "2.10"
      }
    }
  ],
  [
    "9e23dc6850d03f1252286e39451b63c5941a822b",
    {
      "id": "9e23dc6850d03f1252286e39451b63c5941a822b",
      "name": "송민섭",
      "team": "KT",
      "position": "RF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "32",
        "Pos.": "RF",
        "oWAR": "0.08",
        "dWAR": "-0.26",
        "G": "36",
        "PA": "20",
        "ePA": "19",
        "AB": "14",
        "R": "8",
        "H": "3",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "3",
        "RBI": "1",
        "SB": "0",
        "CS": "1",
        "BB": "3",
        "HP": "2",
        "IB": "0",
        "SO": "6",
        "GDP": "0",
        "SH": "1",
        "SF": "0",
        "AVG": "0.214",
        "OBP": "0.421",
        "SLG": "0.214",
        "OPS": "0.635",
        "R/ePA": "0.145",
        "wRC+": "105.2",
        "WAR": "-0.18"
      }
    }
  ],
  [
    "06ebc528497de0fe60aa4b7a011b0452db6cc9c3",
    {
      "id": "06ebc528497de0fe60aa4b7a011b0452db6cc9c3",
      "name": "문용익",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "28",
        "Pos.": "P",
        "G": "12",
        "GS": "0",
        "GR": "12",
        "GF": "4",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "17.0",
        "ER": "23",
        "R": "27",
        "rRA": "28.55",
        "TBF": "99",
        "H": "23",
        "2B": "4",
        "3B": "0",
        "HR": "2",
        "BB": "22",
        "HP": "2",
        "IB": "0",
        "SO": "17",
        "ROE": "3",
        "BK": "0",
        "WP": "2",
        "ERA": "12.18",
        "RA9": "14.29",
        "rRA9": "15.11",
        "rRA9pf": "15.15",
        "FIP": "7.48",
        "WHIP": "2.65",
        "WAR": "-0.79"
      }
    }
  ],
  [
    "d382aab1cc42900820399b56c98ef75d6042ccdc",
    {
      "id": "d382aab1cc42900820399b56c98ef75d6042ccdc",
      "name": "천성호",
      "team": "KT",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "26",
        "Pos.": "2B",
        "oWAR": "0.63",
        "dWAR": "-0.33",
        "G": "75",
        "PA": "255",
        "ePA": "254",
        "AB": "234",
        "R": "41",
        "H": "69",
        "2B": "8",
        "3B": "3",
        "HR": "1",
        "TB": "86",
        "RBI": "17",
        "SB": "7",
        "CS": "0",
        "BB": "18",
        "HP": "1",
        "IB": "0",
        "SO": "43",
        "GDP": "5",
        "SH": "1",
        "SF": "1",
        "AVG": "0.295",
        "OBP": "0.346",
        "SLG": "0.368",
        "OPS": "0.714",
        "R/ePA": "0.116",
        "wRC+": "84.6",
        "WAR": "0.30"
      }
    }
  ],
  [
    "f4c99339efc7085a60f722c6829c97af6cffd6fb",
    {
      "id": "f4c99339efc7085a60f722c6829c97af6cffd6fb",
      "name": "장준원",
      "team": "KT",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "28",
        "Pos.": "SS",
        "oWAR": "-0.13",
        "dWAR": "0.09",
        "G": "17",
        "PA": "18",
        "ePA": "18",
        "AB": "14",
        "R": "1",
        "H": "1",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "1",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "4",
        "HP": "0",
        "IB": "0",
        "SO": "5",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.071",
        "OBP": "0.278",
        "SLG": "0.071",
        "OPS": "0.349",
        "R/ePA": "0.018",
        "wRC+": "13.5",
        "WAR": "-0.04"
      }
    }
  ],
  [
    "d7f3d267a62d89ab2fe47a7ae76faa875e6d0939",
    {
      "id": "d7f3d267a62d89ab2fe47a7ae76faa875e6d0939",
      "name": "이채호",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "25",
        "Pos.": "P",
        "G": "9",
        "GS": "0",
        "GR": "9",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "6.2",
        "ER": "7",
        "R": "7",
        "rRA": "7.00",
        "TBF": "37",
        "H": "13",
        "2B": "0",
        "3B": "1",
        "HR": "2",
        "BB": "3",
        "HP": "1",
        "IB": "0",
        "SO": "5",
        "ROE": "0",
        "BK": "0",
        "WP": "1",
        "ERA": "9.45",
        "RA9": "9.45",
        "rRA9": "9.45",
        "rRA9pf": "9.51",
        "FIP": "7.75",
        "WHIP": "2.40",
        "WAR": "-0.13"
      }
    }
  ],
  [
    "3e0f71511115186d325c7297ed5618b9800f27ed",
    {
      "id": "3e0f71511115186d325c7297ed5618b9800f27ed",
      "name": "엄상백",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "27",
        "Pos.": "P",
        "G": "29",
        "GS": "29",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "13",
        "L": "10",
        "S": "0",
        "HD": "0",
        "IP": "156.2",
        "ER": "85",
        "R": "88",
        "rRA": "85.40",
        "TBF": "675",
        "H": "164",
        "2B": "26",
        "3B": "3",
        "HR": "26",
        "BB": "42",
        "HP": "7",
        "IB": "0",
        "SO": "159",
        "ROE": "3",
        "BK": "0",
        "WP": "2",
        "ERA": "4.88",
        "RA9": "5.06",
        "rRA9": "4.91",
        "rRA9pf": "4.74",
        "FIP": "4.61",
        "WHIP": "1.31",
        "WAR": "3.93"
      }
    }
  ],
  [
    "5469dd612c16a63e5467037dcba03dc75cae3861",
    {
      "id": "5469dd612c16a63e5467037dcba03dc75cae3861",
      "name": "배제성",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2021",
        "Team": "KT",
        "Age": "24",
        "Pos.": "P",
        "G": "26",
        "GS": "26",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "9",
        "L": "10",
        "S": "0",
        "HD": "0",
        "IP": "141.2",
        "ER": "58",
        "R": "67",
        "rRA": "64.10",
        "TBF": "621",
        "H": "126",
        "2B": "27",
        "3B": "1",
        "HR": "6",
        "BB": "82",
        "HP": "1",
        "IB": "1",
        "SO": "136",
        "ROE": "12",
        "BK": "0",
        "WP": "9",
        "ERA": "3.68",
        "RA9": "4.26",
        "rRA9": "4.07",
        "rRA9pf": "4.02",
        "FIP": "3.69",
        "WHIP": "1.47",
        "WAR": "3.91"
      }
    }
  ],
  [
    "5a692fa5dbf933fe6aa422bb31c779cc4bad4e61",
    {
      "id": "5a692fa5dbf933fe6aa422bb31c779cc4bad4e61",
      "name": "김태오",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2018",
        "Team": "KT",
        "Age": "20",
        "Pos.": "P",
        "G": "8",
        "GS": "4",
        "GR": "4",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "2",
        "S": "0",
        "HD": "0",
        "IP": "21.0",
        "ER": "14",
        "R": "16",
        "rRA": "16.00",
        "TBF": "102",
        "H": "32",
        "2B": "7",
        "3B": "1",
        "HR": "4",
        "BB": "9",
        "HP": "2",
        "IB": "0",
        "SO": "14",
        "ROE": "1",
        "BK": "0",
        "WP": "0",
        "ERA": "6.00",
        "RA9": "6.86",
        "rRA9": "6.86",
        "rRA9pf": "7.04",
        "FIP": "6.57",
        "WHIP": "1.95",
        "WAR": "0.06"
      }
    }
  ],
  [
    "07ee8c1f90a6516955a3b566e885c7fa3896252e",
    {
      "id": "07ee8c1f90a6516955a3b566e885c7fa3896252e",
      "name": "우규민",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "38",
        "Pos.": "P",
        "G": "45",
        "GS": "0",
        "GR": "45",
        "GF": "15",
        "CG": "0",
        "SHO": "0",
        "W": "4",
        "L": "1",
        "S": "1",
        "HD": "4",
        "IP": "43.1",
        "ER": "12",
        "R": "14",
        "rRA": "15.90",
        "TBF": "181",
        "H": "47",
        "2B": "7",
        "3B": "2",
        "HR": "1",
        "BB": "2",
        "HP": "2",
        "IB": "0",
        "SO": "39",
        "ROE": "3",
        "BK": "0",
        "WP": "0",
        "ERA": "2.49",
        "RA9": "2.91",
        "rRA9": "3.30",
        "rRA9pf": "3.15",
        "FIP": "2.51",
        "WHIP": "1.13",
        "WAR": "1.48"
      }
    }
  ],
  [
    "d726154cfb6801c5151187ebf9f2ed29977db25a",
    {
      "id": "d726154cfb6801c5151187ebf9f2ed29977db25a",
      "name": "장성우",
      "team": "KT",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "33",
        "Pos.": "C",
        "oWAR": "3.13",
        "dWAR": "0.10",
        "G": "131",
        "PA": "489",
        "ePA": "486",
        "AB": "418",
        "R": "53",
        "H": "112",
        "2B": "19",
        "3B": "0",
        "HR": "19",
        "TB": "188",
        "RBI": "81",
        "SB": "5",
        "CS": "0",
        "BB": "60",
        "HP": "1",
        "IB": "1",
        "SO": "86",
        "GDP": "8",
        "SH": "2",
        "SF": "8",
        "AVG": "0.268",
        "OBP": "0.355",
        "SLG": "0.450",
        "OPS": "0.805",
        "R/ePA": "0.144",
        "wRC+": "104.2",
        "WAR": "3.23"
      }
    }
  ],
  [
    "98d1de83f732a582f9fc6fc63cccfbd749e9012e",
    {
      "id": "98d1de83f732a582f9fc6fc63cccfbd749e9012e",
      "name": "조용호",
      "team": "KT",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "34",
        "Pos.": "RF",
        "oWAR": "-0.53",
        "dWAR": "0.01",
        "G": "60",
        "PA": "165",
        "ePA": "162",
        "AB": "149",
        "R": "17",
        "H": "37",
        "2B": "5",
        "3B": "0",
        "HR": "0",
        "TB": "42",
        "RBI": "13",
        "SB": "5",
        "CS": "1",
        "BB": "13",
        "HP": "0",
        "IB": "0",
        "SO": "25",
        "GDP": "5",
        "SH": "3",
        "SF": "0",
        "AVG": "0.248",
        "OBP": "0.309",
        "SLG": "0.282",
        "OPS": "0.591",
        "R/ePA": "0.071",
        "wRC+": "51.9",
        "WAR": "-0.52"
      }
    }
  ],
  [
    "2d97574f50212349cb86f1a4b65f49b0ae9641bc",
    {
      "id": "2d97574f50212349cb86f1a4b65f49b0ae9641bc",
      "name": "문상철",
      "team": "KT",
      "position": "1B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "32",
        "Pos.": "1B",
        "oWAR": "0.96",
        "dWAR": "0.68",
        "G": "125",
        "PA": "403",
        "ePA": "401",
        "AB": "347",
        "R": "50",
        "H": "89",
        "2B": "11",
        "3B": "0",
        "HR": "17",
        "TB": "151",
        "RBI": "58",
        "SB": "6",
        "CS": "1",
        "BB": "45",
        "HP": "7",
        "IB": "2",
        "SO": "86",
        "GDP": "8",
        "SH": "1",
        "SF": "3",
        "AVG": "0.256",
        "OBP": "0.351",
        "SLG": "0.435",
        "OPS": "0.786",
        "R/ePA": "0.139",
        "wRC+": "101.7",
        "WAR": "1.64"
      }
    }
  ],
  [
    "207fd1b64ac110a020e38b60408c9df9d736a4b2",
    {
      "id": "207fd1b64ac110a020e38b60408c9df9d736a4b2",
      "name": "박민석",
      "team": "KT",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "23",
        "Pos.": "2B",
        "oWAR": "-0.14",
        "dWAR": "-0.18",
        "G": "20",
        "PA": "14",
        "ePA": "14",
        "AB": "14",
        "R": "6",
        "H": "3",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "3",
        "RBI": "0",
        "SB": "0",
        "CS": "1",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "7",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.214",
        "OBP": "0.214",
        "SLG": "0.214",
        "OPS": "0.428",
        "R/ePA": "-0.010",
        "wRC+": "-7.4",
        "WAR": "-0.31"
      }
    }
  ],
  [
    "18fef19b4820f7af08b3094cc767dd2e26504d91",
    {
      "id": "18fef19b4820f7af08b3094cc767dd2e26504d91",
      "name": "김민수",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "31",
        "Pos.": "P",
        "G": "75",
        "GS": "0",
        "GR": "75",
        "GF": "6",
        "CG": "0",
        "SHO": "0",
        "W": "5",
        "L": "3",
        "S": "0",
        "HD": "12",
        "IP": "81.1",
        "ER": "47",
        "R": "56",
        "rRA": "54.45",
        "TBF": "358",
        "H": "84",
        "2B": "13",
        "3B": "5",
        "HR": "8",
        "BB": "33",
        "HP": "1",
        "IB": "2",
        "SO": "75",
        "ROE": "8",
        "BK": "0",
        "WP": "3",
        "ERA": "5.20",
        "RA9": "6.20",
        "rRA9": "6.03",
        "rRA9pf": "5.92",
        "FIP": "4.28",
        "WHIP": "1.44",
        "WAR": "0.70"
      }
    }
  ],
  [
    "5d07c84b3948c3e64c98ff526e4faede91ca8992",
    {
      "id": "5d07c84b3948c3e64c98ff526e4faede91ca8992",
      "name": "배정대",
      "team": "KT",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "28",
        "Pos.": "CF",
        "oWAR": "1.41",
        "dWAR": "-0.52",
        "G": "113",
        "PA": "473",
        "ePA": "464",
        "AB": "404",
        "R": "49",
        "H": "111",
        "2B": "25",
        "3B": "1",
        "HR": "7",
        "TB": "159",
        "RBI": "59",
        "SB": "9",
        "CS": "7",
        "BB": "50",
        "HP": "4",
        "IB": "1",
        "SO": "114",
        "GDP": "5",
        "SH": "8",
        "SF": "7",
        "AVG": "0.275",
        "OBP": "0.355",
        "SLG": "0.394",
        "OPS": "0.749",
        "R/ePA": "0.129",
        "wRC+": "94.1",
        "WAR": "0.88"
      }
    }
  ],
  [
    "a569ba17e78826524f647ecdc66d1b8aa895f714",
    {
      "id": "a569ba17e78826524f647ecdc66d1b8aa895f714",
      "name": "하준호",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "34",
        "Pos.": "P",
        "G": "2",
        "GS": "0",
        "GR": "2",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "2.0",
        "ER": "2",
        "R": "2",
        "rRA": "2.00",
        "TBF": "11",
        "H": "5",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "0",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "9.00",
        "RA9": "9.00",
        "rRA9": "9.00",
        "rRA9pf": "8.38",
        "FIP": "5.75",
        "WHIP": "3.00",
        "WAR": "0.00"
      }
    }
  ],
  [
    "458d5a62791464b8431dfafdb23ce98a5d3fa124",
    {
      "id": "458d5a62791464b8431dfafdb23ce98a5d3fa124",
      "name": "전용주",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "23",
        "Pos.": "P",
        "G": "4",
        "GS": "0",
        "GR": "4",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "1.2",
        "ER": "2",
        "R": "3",
        "rRA": "3.15",
        "TBF": "11",
        "H": "2",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "BB": "3",
        "HP": "1",
        "IB": "0",
        "SO": "4",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "10.80",
        "RA9": "16.20",
        "rRA9": "17.01",
        "rRA9pf": "17.02",
        "FIP": "5.64",
        "WHIP": "3.00",
        "WAR": "-0.22"
      }
    }
  ],
  [
    "a31efdd2c54ff0a83092cb99e826cd5688baa321",
    {
      "id": "a31efdd2c54ff0a83092cb99e826cd5688baa321",
      "name": "소형준",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "22",
        "Pos.": "P",
        "G": "6",
        "GS": "0",
        "GR": "6",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "8.1",
        "ER": "3",
        "R": "3",
        "rRA": "2.50",
        "TBF": "30",
        "H": "7",
        "2B": "2",
        "3B": "0",
        "HR": "1",
        "BB": "1",
        "HP": "1",
        "IB": "0",
        "SO": "3",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "3.24",
        "RA9": "3.24",
        "rRA9": "2.70",
        "rRA9pf": "2.59",
        "FIP": "5.47",
        "WHIP": "0.96",
        "WAR": "0.33"
      }
    }
  ],
  [
    "9aa6ce5bb9d6ff6d21abd17905bf94b967c6875e",
    {
      "id": "9aa6ce5bb9d6ff6d21abd17905bf94b967c6875e",
      "name": "홍현빈",
      "team": "KT",
      "position": "RF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "26",
        "Pos.": "RF",
        "oWAR": "0.04",
        "dWAR": "-0.22",
        "G": "28",
        "PA": "22",
        "ePA": "21",
        "AB": "18",
        "R": "5",
        "H": "4",
        "2B": "0",
        "3B": "1",
        "HR": "0",
        "TB": "6",
        "RBI": "2",
        "SB": "0",
        "CS": "0",
        "BB": "3",
        "HP": "0",
        "IB": "0",
        "SO": "7",
        "GDP": "0",
        "SH": "1",
        "SF": "0",
        "AVG": "0.222",
        "OBP": "0.333",
        "SLG": "0.333",
        "OPS": "0.666",
        "R/ePA": "0.108",
        "wRC+": "78.0",
        "WAR": "-0.18"
      }
    }
  ],
  [
    "e14b68c7febc8e3da4dc96a2a390974f891fe442",
    {
      "id": "e14b68c7febc8e3da4dc96a2a390974f891fe442",
      "name": "쿠에바스",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "33",
        "Pos.": "P",
        "G": "31",
        "GS": "31",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "7",
        "L": "12",
        "S": "0",
        "HD": "0",
        "IP": "173.1",
        "ER": "79",
        "R": "86",
        "rRA": "86.00",
        "TBF": "737",
        "H": "158",
        "2B": "36",
        "3B": "3",
        "HR": "17",
        "BB": "59",
        "HP": "7",
        "IB": "4",
        "SO": "154",
        "ROE": "11",
        "BK": "1",
        "WP": "11",
        "ERA": "4.10",
        "RA9": "4.47",
        "rRA9": "4.47",
        "rRA9pf": "4.34",
        "FIP": "4.25",
        "WHIP": "1.25",
        "WAR": "5.06"
      }
    }
  ],
  [
    "d3be9d8d7cea544d480d404f5d1f6498c98cb06e",
    {
      "id": "d3be9d8d7cea544d480d404f5d1f6498c98cb06e",
      "name": "박세진",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "26",
        "Pos.": "P",
        "G": "6",
        "GS": "0",
        "GR": "6",
        "GF": "3",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "5.1",
        "ER": "2",
        "R": "4",
        "rRA": "6.20",
        "TBF": "25",
        "H": "7",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "BB": "4",
        "HP": "0",
        "IB": "0",
        "SO": "2",
        "ROE": "1",
        "BK": "0",
        "WP": "0",
        "ERA": "3.38",
        "RA9": "6.75",
        "rRA9": "10.46",
        "rRA9pf": "10.37",
        "FIP": "5.59",
        "WHIP": "2.06",
        "WAR": "-0.09"
      }
    }
  ],
  [
    "f06ade8c695e4c0bbeef554ec29f6c3cb9d74dfb",
    {
      "id": "f06ade8c695e4c0bbeef554ec29f6c3cb9d74dfb",
      "name": "이호연",
      "team": "KT",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "28",
        "Pos.": "3B",
        "oWAR": "-0.55",
        "dWAR": "-0.06",
        "G": "27",
        "PA": "43",
        "ePA": "42",
        "AB": "41",
        "R": "3",
        "H": "6",
        "2B": "1",
        "3B": "0",
        "HR": "0",
        "TB": "7",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "12",
        "GDP": "2",
        "SH": "1",
        "SF": "0",
        "AVG": "0.146",
        "OBP": "0.167",
        "SLG": "0.171",
        "OPS": "0.338",
        "R/ePA": "-0.044",
        "wRC+": "-32.6",
        "WAR": "-0.61"
      }
    }
  ],
  [
    "5b991614f9fd4857baace74d4d1a5a7d57f837ed",
    {
      "id": "5b991614f9fd4857baace74d4d1a5a7d57f837ed",
      "name": "윤준혁",
      "team": "KT",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "22",
        "Pos.": "3B",
        "oWAR": "-0.15",
        "dWAR": "-0.08",
        "G": "13",
        "PA": "18",
        "ePA": "18",
        "AB": "17",
        "R": "3",
        "H": "3",
        "2B": "2",
        "3B": "0",
        "HR": "0",
        "TB": "5",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "6",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.176",
        "OBP": "0.222",
        "SLG": "0.294",
        "OPS": "0.516",
        "R/ePA": "0.030",
        "wRC+": "21.9",
        "WAR": "-0.23"
      }
    }
  ],
  [
    "8a2098157b199430b357c1a2d23d203859400a2e",
    {
      "id": "8a2098157b199430b357c1a2d23d203859400a2e",
      "name": "오재일",
      "team": "KT",
      "position": "1B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "37",
        "Pos.": "1B",
        "oWAR": "-0.01",
        "dWAR": "-0.43",
        "G": "83",
        "PA": "272",
        "ePA": "272",
        "AB": "232",
        "R": "27",
        "H": "57",
        "2B": "13",
        "3B": "0",
        "HR": "8",
        "TB": "94",
        "RBI": "37",
        "SB": "0",
        "CS": "0",
        "BB": "30",
        "HP": "2",
        "IB": "0",
        "SO": "70",
        "GDP": "3",
        "SH": "0",
        "SF": "8",
        "AVG": "0.246",
        "OBP": "0.327",
        "SLG": "0.405",
        "OPS": "0.732",
        "R/ePA": "0.116",
        "wRC+": "84.3",
        "WAR": "-0.45"
      }
    }
  ],
  [
    "0d042f0f423738e2d2d2f09f2f63b2cf7d24aac2",
    {
      "id": "0d042f0f423738e2d2d2f09f2f63b2cf7d24aac2",
      "name": "이상동",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "28",
        "Pos.": "P",
        "G": "29",
        "GS": "0",
        "GR": "29",
        "GF": "8",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "3",
        "IP": "32.0",
        "ER": "19",
        "R": "20",
        "rRA": "24.45",
        "TBF": "152",
        "H": "42",
        "2B": "9",
        "3B": "0",
        "HR": "3",
        "BB": "14",
        "HP": "3",
        "IB": "1",
        "SO": "30",
        "ROE": "0",
        "BK": "1",
        "WP": "4",
        "ERA": "5.34",
        "RA9": "5.63",
        "rRA9": "6.88",
        "rRA9pf": "6.95",
        "FIP": "4.62",
        "WHIP": "1.75",
        "WAR": "0.01"
      }
    }
  ],
  [
    "f5646551e9dd51ec0548cd624c57ce6c6aed38bf",
    {
      "id": "f5646551e9dd51ec0548cd624c57ce6c6aed38bf",
      "name": "주권",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "28",
        "Pos.": "P",
        "G": "48",
        "GS": "1",
        "GR": "47",
        "GF": "13",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "58.0",
        "ER": "43",
        "R": "48",
        "rRA": "49.90",
        "TBF": "254",
        "H": "78",
        "2B": "14",
        "3B": "2",
        "HR": "7",
        "BB": "11",
        "HP": "3",
        "IB": "0",
        "SO": "31",
        "ROE": "2",
        "BK": "0",
        "WP": "0",
        "ERA": "6.67",
        "RA9": "7.45",
        "rRA9": "7.74",
        "rRA9pf": "7.60",
        "FIP": "5.05",
        "WHIP": "1.53",
        "WAR": "-0.18"
      }
    }
  ],
  [
    "e60dbbcf2e78cce4dea2730c483299f69f95c3b6",
    {
      "id": "e60dbbcf2e78cce4dea2730c483299f69f95c3b6",
      "name": "양승혁",
      "team": "KT",
      "position": "2B",
      "record": {
        "Div.": "베스트",
        "Year": "2022",
        "Team": "KT",
        "Age": "22",
        "Pos.": "2B",
        "oWAR": "-0.14",
        "dWAR": "-0.08",
        "G": "11",
        "PA": "5",
        "ePA": "5",
        "AB": "5",
        "R": "0",
        "H": "0",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "0",
        "RBI": "0",
        "SB": "1",
        "CS": "1",
        "BB": "0",
        "HP": "0",
        "IB": "0",
        "SO": "1",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.000",
        "OBP": "0.000",
        "SLG": "0.000",
        "OPS": "0.000",
        "R/ePA": "-0.160",
        "wRC+": "-129.3",
        "WAR": "-0.22"
      }
    }
  ],
  [
    "aeb16616fa085899e7c2cb54ef733740e70f4447",
    {
      "id": "aeb16616fa085899e7c2cb54ef733740e70f4447",
      "name": "손동현",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "22",
        "Pos.": "P",
        "G": "42",
        "GS": "0",
        "GR": "42",
        "GF": "10",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "2",
        "S": "1",
        "HD": "4",
        "IP": "47.1",
        "ER": "28",
        "R": "31",
        "rRA": "33.05",
        "TBF": "214",
        "H": "50",
        "2B": "6",
        "3B": "1",
        "HR": "5",
        "BB": "20",
        "HP": "1",
        "IB": "2",
        "SO": "27",
        "ROE": "3",
        "BK": "0",
        "WP": "3",
        "ERA": "5.32",
        "RA9": "5.89",
        "rRA9": "6.28",
        "rRA9pf": "6.24",
        "FIP": "5.34",
        "WHIP": "1.48",
        "WAR": "0.28"
      }
    }
  ],
  [
    "031f0c6438c8ec32251a6809529629b738761ba0",
    {
      "id": "031f0c6438c8ec32251a6809529629b738761ba0",
      "name": "조대현",
      "team": "KT",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "24",
        "Pos.": "C",
        "oWAR": "0.17",
        "dWAR": "0.29",
        "G": "26",
        "PA": "45",
        "ePA": "40",
        "AB": "36",
        "R": "2",
        "H": "11",
        "2B": "2",
        "3B": "0",
        "HR": "0",
        "TB": "13",
        "RBI": "3",
        "SB": "0",
        "CS": "0",
        "BB": "3",
        "HP": "0",
        "IB": "0",
        "SO": "12",
        "GDP": "1",
        "SH": "5",
        "SF": "1",
        "AVG": "0.306",
        "OBP": "0.350",
        "SLG": "0.361",
        "OPS": "0.711",
        "R/ePA": "0.113",
        "wRC+": "81.4",
        "WAR": "0.46"
      }
    }
  ],
  [
    "52f563018e89e85371cd8cdee108a880478f4b58",
    {
      "id": "52f563018e89e85371cd8cdee108a880478f4b58",
      "name": "벤자민",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "30",
        "Pos.": "P",
        "G": "28",
        "GS": "28",
        "GR": "0",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "11",
        "L": "8",
        "S": "0",
        "HD": "0",
        "IP": "149.2",
        "ER": "77",
        "R": "90",
        "rRA": "86.65",
        "TBF": "639",
        "H": "141",
        "2B": "25",
        "3B": "4",
        "HR": "28",
        "BB": "48",
        "HP": "4",
        "IB": "2",
        "SO": "156",
        "ROE": "12",
        "BK": "0",
        "WP": "4",
        "ERA": "4.63",
        "RA9": "5.41",
        "rRA9": "5.21",
        "rRA9pf": "5.11",
        "FIP": "4.88",
        "WHIP": "1.26",
        "WAR": "3.19"
      }
    }
  ],
  [
    "062cb4bddebf8a8bed2b5b089aca4c245c4a57d4",
    {
      "id": "062cb4bddebf8a8bed2b5b089aca4c245c4a57d4",
      "name": "김준태",
      "team": "KT",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "29",
        "Pos.": "C",
        "oWAR": "0.15",
        "dWAR": "0.00",
        "G": "23",
        "PA": "30",
        "ePA": "30",
        "AB": "25",
        "R": "6",
        "H": "6",
        "2B": "2",
        "3B": "0",
        "HR": "0",
        "TB": "8",
        "RBI": "3",
        "SB": "0",
        "CS": "0",
        "BB": "5",
        "HP": "0",
        "IB": "0",
        "SO": "6",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.240",
        "OBP": "0.367",
        "SLG": "0.320",
        "OPS": "0.687",
        "R/ePA": "0.122",
        "wRC+": "91.3",
        "WAR": "0.15"
      }
    }
  ],
  [
    "6d33323c4248ba67eca5cbcd3af0938df754d7f9",
    {
      "id": "6d33323c4248ba67eca5cbcd3af0938df754d7f9",
      "name": "이태규",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "7b3bef47ac5e551922d65f8e792366f4151d9602",
    {
      "id": "7b3bef47ac5e551922d65f8e792366f4151d9602",
      "name": "박시영",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "34",
        "Pos.": "P",
        "G": "26",
        "GS": "0",
        "GR": "26",
        "GF": "5",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "1",
        "HD": "4",
        "IP": "25.1",
        "ER": "13",
        "R": "13",
        "rRA": "15.40",
        "TBF": "110",
        "H": "27",
        "2B": "8",
        "3B": "1",
        "HR": "4",
        "BB": "7",
        "HP": "1",
        "IB": "1",
        "SO": "23",
        "ROE": "0",
        "BK": "0",
        "WP": "3",
        "ERA": "4.62",
        "RA9": "4.62",
        "rRA9": "5.47",
        "rRA9pf": "5.36",
        "FIP": "4.67",
        "WHIP": "1.34",
        "WAR": "0.36"
      }
    }
  ],
  [
    "2a3b506ceda2d5c1437d47052a28e115e47fb2e1",
    {
      "id": "2a3b506ceda2d5c1437d47052a28e115e47fb2e1",
      "name": "신범준",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "57efa9a7e509193e599a44c5add41730f1fc638d",
    {
      "id": "57efa9a7e509193e599a44c5add41730f1fc638d",
      "name": "김영현",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "21",
        "Pos.": "P",
        "G": "8",
        "GS": "0",
        "GR": "8",
        "GF": "4",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "7.1",
        "ER": "4",
        "R": "6",
        "rRA": "6.35",
        "TBF": "39",
        "H": "9",
        "2B": "2",
        "3B": "0",
        "HR": "1",
        "BB": "7",
        "HP": "1",
        "IB": "0",
        "SO": "8",
        "ROE": "1",
        "BK": "0",
        "WP": "1",
        "ERA": "4.91",
        "RA9": "7.36",
        "rRA9": "7.79",
        "rRA9pf": "7.47",
        "FIP": "6.48",
        "WHIP": "2.18",
        "WAR": "0.02"
      }
    }
  ],
  [
    "7a29377a6928bed52771a15b7d4411d717f6c692",
    {
      "id": "7a29377a6928bed52771a15b7d4411d717f6c692",
      "name": "김건웅",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "405324a042b80bb93dfbc122ef2af97634720b47",
    {
      "id": "405324a042b80bb93dfbc122ef2af97634720b47",
      "name": "강백호",
      "team": "KT",
      "position": "1B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "24",
        "Pos.": "DH",
        "oWAR": "2.46",
        "dWAR": "-0.34",
        "G": "144",
        "PA": "614",
        "ePA": "612",
        "AB": "550",
        "R": "92",
        "H": "159",
        "2B": "27",
        "3B": "0",
        "HR": "26",
        "TB": "264",
        "RBI": "96",
        "SB": "6",
        "CS": "2",
        "BB": "59",
        "HP": "3",
        "IB": "4",
        "SO": "127",
        "GDP": "6",
        "SH": "0",
        "SF": "2",
        "AVG": "0.289",
        "OBP": "0.360",
        "SLG": "0.480",
        "OPS": "0.840",
        "R/ePA": "0.154",
        "wRC+": "112.3",
        "WAR": "2.12"
      }
    }
  ],
  [
    "5302c8cd11e97819d29a61a22ef957c3462f5923",
    {
      "id": "5302c8cd11e97819d29a61a22ef957c3462f5923",
      "name": "이정현",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2022",
        "Team": "KT",
        "Age": "24",
        "Pos.": "P",
        "G": "3",
        "GS": "0",
        "GR": "3",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "3.2",
        "ER": "1",
        "R": "1",
        "rRA": "1.00",
        "TBF": "18",
        "H": "3",
        "2B": "0",
        "3B": "0",
        "HR": "1",
        "BB": "4",
        "HP": "1",
        "IB": "0",
        "SO": "1",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "2.45",
        "RA9": "2.45",
        "rRA9": "2.45",
        "rRA9pf": "2.26",
        "FIP": "10.58",
        "WHIP": "1.91",
        "WAR": "0.11"
      }
    }
  ],
  [
    "9915e75fda09677709d5f5ebe21742e18affbd0c",
    {
      "id": "9915e75fda09677709d5f5ebe21742e18affbd0c",
      "name": "권동진",
      "team": "KT",
      "position": "2B",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "25",
        "Pos.": "2B",
        "oWAR": "-0.03",
        "dWAR": "-0.07",
        "G": "9",
        "PA": "10",
        "ePA": "10",
        "AB": "9",
        "R": "3",
        "H": "2",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "2",
        "RBI": "0",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "2",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.222",
        "OBP": "0.300",
        "SLG": "0.222",
        "OPS": "0.522",
        "R/ePA": "0.050",
        "wRC+": "37.9",
        "WAR": "-0.09"
      }
    }
  ],
  [
    "fd7703f65675fa3a04bebee4e993567fd686e861",
    {
      "id": "fd7703f65675fa3a04bebee4e993567fd686e861",
      "name": "김민혁",
      "team": "KT",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "28",
        "Pos.": "LF",
        "oWAR": "2.08",
        "dWAR": "0.12",
        "G": "115",
        "PA": "393",
        "ePA": "389",
        "AB": "351",
        "R": "47",
        "H": "124",
        "2B": "14",
        "3B": "3",
        "HR": "1",
        "TB": "147",
        "RBI": "34",
        "SB": "4",
        "CS": "2",
        "BB": "35",
        "HP": "1",
        "IB": "1",
        "SO": "45",
        "GDP": "9",
        "SH": "3",
        "SF": "3",
        "AVG": "0.353",
        "OBP": "0.410",
        "SLG": "0.419",
        "OPS": "0.829",
        "R/ePA": "0.164",
        "wRC+": "118.0",
        "WAR": "2.20"
      }
    }
  ],
  [
    "06429e8f9d50ba0feb3582da3e6b8438bd3361d2",
    {
      "id": "06429e8f9d50ba0feb3582da3e6b8438bd3361d2",
      "name": "조이현",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "28",
        "Pos.": "P",
        "G": "16",
        "GS": "9",
        "GR": "7",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "2",
        "S": "0",
        "HD": "0",
        "IP": "40.0",
        "ER": "28",
        "R": "29",
        "rRA": "27.40",
        "TBF": "184",
        "H": "53",
        "2B": "11",
        "3B": "1",
        "HR": "9",
        "BB": "10",
        "HP": "1",
        "IB": "1",
        "SO": "19",
        "ROE": "1",
        "BK": "0",
        "WP": "1",
        "ERA": "6.30",
        "RA9": "6.53",
        "rRA9": "6.17",
        "rRA9pf": "5.93",
        "FIP": "6.46",
        "WHIP": "1.58",
        "WAR": "0.55"
      }
    }
  ],
  [
    "e9ade71f1036a8bd4b96f6494957c5fc5952986e",
    {
      "id": "e9ade71f1036a8bd4b96f6494957c5fc5952986e",
      "name": "강현우",
      "team": "KT",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "22",
        "Pos.": "C",
        "oWAR": "0.46",
        "dWAR": "-0.12",
        "G": "18",
        "PA": "36",
        "ePA": "34",
        "AB": "26",
        "R": "4",
        "H": "8",
        "2B": "1",
        "3B": "0",
        "HR": "1",
        "TB": "12",
        "RBI": "8",
        "SB": "0",
        "CS": "0",
        "BB": "8",
        "HP": "0",
        "IB": "0",
        "SO": "10",
        "GDP": "0",
        "SH": "2",
        "SF": "0",
        "AVG": "0.308",
        "OBP": "0.471",
        "SLG": "0.462",
        "OPS": "0.933",
        "R/ePA": "0.216",
        "wRC+": "161.1",
        "WAR": "0.34"
      }
    }
  ],
  [
    "f4bc10d0408a62f425e46ec0002593f2fabd1663",
    {
      "id": "f4bc10d0408a62f425e46ec0002593f2fabd1663",
      "name": "신본기",
      "team": "KT",
      "position": "SS",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "",
        "Age": "34",
        "Pos.": "SS",
        "oWAR": "0.84",
        "dWAR": "-0.02",
        "G": "84",
        "PA": "152",
        "ePA": "143",
        "AB": "122",
        "R": "18",
        "H": "34",
        "2B": "3",
        "3B": "0",
        "HR": "3",
        "TB": "46",
        "RBI": "19",
        "SB": "2",
        "CS": "0",
        "BB": "16",
        "HP": "3",
        "IB": "0",
        "SO": "28",
        "GDP": "3",
        "SH": "9",
        "SF": "2",
        "AVG": "0.279",
        "OBP": "0.371",
        "SLG": "0.377",
        "OPS": "0.748",
        "R/ePA": "0.135",
        "wRC+": "97.6",
        "WAR": "0.82"
      }
    }
  ],
  [
    "71e9ac142eab200e3e3870b320bb9a2cefde6920",
    {
      "id": "71e9ac142eab200e3e3870b320bb9a2cefde6920",
      "name": "김병준",
      "team": "KT",
      "position": "CF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "20",
        "Pos.": "CF",
        "oWAR": "0.15",
        "dWAR": "0.01",
        "G": "35",
        "PA": "16",
        "ePA": "16",
        "AB": "14",
        "R": "9",
        "H": "5",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "5",
        "RBI": "1",
        "SB": "0",
        "CS": "0",
        "BB": "2",
        "HP": "0",
        "IB": "0",
        "SO": "2",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.357",
        "OBP": "0.438",
        "SLG": "0.357",
        "OPS": "0.795",
        "R/ePA": "0.162",
        "wRC+": "121.4",
        "WAR": "0.16"
      }
    }
  ],
  [
    "0a2f99b674c3ee12af059cff9687195eebe6d477",
    {
      "id": "0a2f99b674c3ee12af059cff9687195eebe6d477",
      "name": "정준영",
      "team": "KT",
      "position": "LF",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "19",
        "Pos.": "RF",
        "oWAR": "-0.17",
        "dWAR": "0.34",
        "G": "43",
        "PA": "74",
        "ePA": "71",
        "AB": "62",
        "R": "9",
        "H": "14",
        "2B": "2",
        "3B": "0",
        "HR": "0",
        "TB": "16",
        "RBI": "3",
        "SB": "0",
        "CS": "0",
        "BB": "8",
        "HP": "1",
        "IB": "0",
        "SO": "16",
        "GDP": "1",
        "SH": "3",
        "SF": "0",
        "AVG": "0.226",
        "OBP": "0.324",
        "SLG": "0.258",
        "OPS": "0.582",
        "R/ePA": "0.079",
        "wRC+": "57.8",
        "WAR": "0.17"
      }
    }
  ],
  [
    "3391bd438ff2bbfb712b6190e1a46a2877ed4adb",
    {
      "id": "3391bd438ff2bbfb712b6190e1a46a2877ed4adb",
      "name": "한차현",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "25",
        "Pos.": "P",
        "G": "10",
        "GS": "5",
        "GR": "5",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "4",
        "S": "0",
        "HD": "0",
        "IP": "19.0",
        "ER": "21",
        "R": "22",
        "rRA": "19.70",
        "TBF": "103",
        "H": "29",
        "2B": "4",
        "3B": "1",
        "HR": "4",
        "BB": "18",
        "HP": "0",
        "IB": "0",
        "SO": "17",
        "ROE": "1",
        "BK": "0",
        "WP": "2",
        "ERA": "9.95",
        "RA9": "10.42",
        "rRA9": "9.33",
        "rRA9pf": "9.40",
        "FIP": "7.40",
        "WHIP": "2.47",
        "WAR": "-0.24"
      }
    }
  ],
  [
    "5664780dabf60980df84efe96e8dcac925690194",
    {
      "id": "5664780dabf60980df84efe96e8dcac925690194",
      "name": "박영현",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "20",
        "Pos.": "P",
        "G": "66",
        "GS": "0",
        "GR": "66",
        "GF": "52",
        "CG": "0",
        "SHO": "0",
        "W": "10",
        "L": "2",
        "S": "25",
        "HD": "0",
        "IP": "76.2",
        "ER": "30",
        "R": "31",
        "rRA": "32.85",
        "TBF": "310",
        "H": "63",
        "2B": "7",
        "3B": "1",
        "HR": "12",
        "BB": "22",
        "HP": "0",
        "IB": "2",
        "SO": "87",
        "ROE": "3",
        "BK": "0",
        "WP": "1",
        "ERA": "3.52",
        "RA9": "3.64",
        "rRA9": "3.86",
        "rRA9pf": "3.82",
        "FIP": "4.07",
        "WHIP": "1.11",
        "WAR": "2.72"
      }
    }
  ],
  [
    "41dd34e6024150541901198c4ed35f3095e5c46a",
    {
      "id": "41dd34e6024150541901198c4ed35f3095e5c46a",
      "name": "이선우",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "23",
        "Pos.": "P",
        "G": "6",
        "GS": "1",
        "GR": "5",
        "GF": "3",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "10.0",
        "ER": "6",
        "R": "6",
        "rRA": "5.35",
        "TBF": "45",
        "H": "10",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "BB": "4",
        "HP": "2",
        "IB": "0",
        "SO": "4",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "5.40",
        "RA9": "5.40",
        "rRA9": "4.82",
        "rRA9pf": "5.05",
        "FIP": "5.06",
        "WHIP": "1.40",
        "WAR": "0.14"
      }
    }
  ],
  [
    "09aed0c0e73d24372d5a67acefb41be8808ebbaa",
    {
      "id": "09aed0c0e73d24372d5a67acefb41be8808ebbaa",
      "name": "안현민",
      "team": "KT",
      "position": "C",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "20",
        "Pos.": "LF",
        "oWAR": "0.01",
        "dWAR": "-0.08",
        "G": "16",
        "PA": "29",
        "ePA": "29",
        "AB": "25",
        "R": "5",
        "H": "5",
        "2B": "0",
        "3B": "1",
        "HR": "1",
        "TB": "10",
        "RBI": "2",
        "SB": "0",
        "CS": "0",
        "BB": "2",
        "HP": "1",
        "IB": "0",
        "SO": "10",
        "GDP": "0",
        "SH": "0",
        "SF": "1",
        "AVG": "0.200",
        "OBP": "0.276",
        "SLG": "0.400",
        "OPS": "0.676",
        "R/ePA": "0.091",
        "wRC+": "67.3",
        "WAR": "-0.07"
      }
    }
  ],
  [
    "5426eb72dfed2fbb351ae5be091b68c6b6208913",
    {
      "id": "5426eb72dfed2fbb351ae5be091b68c6b6208913",
      "name": "원상현",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "19",
        "Pos.": "P",
        "G": "22",
        "GS": "10",
        "GR": "12",
        "GF": "3",
        "CG": "0",
        "SHO": "0",
        "W": "2",
        "L": "5",
        "S": "0",
        "HD": "1",
        "IP": "65.1",
        "ER": "51",
        "R": "54",
        "rRA": "52.80",
        "TBF": "324",
        "H": "105",
        "2B": "18",
        "3B": "2",
        "HR": "6",
        "BB": "31",
        "HP": "2",
        "IB": "0",
        "SO": "59",
        "ROE": "3",
        "BK": "1",
        "WP": "7",
        "ERA": "7.03",
        "RA9": "7.44",
        "rRA9": "7.27",
        "rRA9pf": "7.30",
        "FIP": "4.60",
        "WHIP": "2.08",
        "WAR": "0.04"
      }
    }
  ],
  [
    "2df7710124706378f6b5f49ca12c55cdcc2e6339",
    {
      "id": "2df7710124706378f6b5f49ca12c55cdcc2e6339",
      "name": "육청명",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "18",
        "Pos.": "P",
        "G": "13",
        "GS": "10",
        "GR": "3",
        "GF": "1",
        "CG": "0",
        "SHO": "0",
        "W": "1",
        "L": "5",
        "S": "0",
        "HD": "0",
        "IP": "44.2",
        "ER": "27",
        "R": "31",
        "rRA": "28.95",
        "TBF": "208",
        "H": "52",
        "2B": "13",
        "3B": "1",
        "HR": "6",
        "BB": "20",
        "HP": "6",
        "IB": "2",
        "SO": "20",
        "ROE": "6",
        "BK": "0",
        "WP": "3",
        "ERA": "5.44",
        "RA9": "6.25",
        "rRA9": "5.83",
        "rRA9pf": "5.85",
        "FIP": "6.41",
        "WHIP": "1.61",
        "WAR": "0.61"
      }
    }
  ],
  [
    "8d32507e95f13697b097cc5d760f8da63a534dc5",
    {
      "id": "8d32507e95f13697b097cc5d760f8da63a534dc5",
      "name": "윤강찬",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "c642d8fcaab53868383f6a8a8800fab74594a2ff",
    {
      "id": "c642d8fcaab53868383f6a8a8800fab74594a2ff",
      "name": "이종혁",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2018",
        "Team": "KT",
        "Age": "20",
        "Pos.": "P",
        "G": "47",
        "GS": "0",
        "GR": "47",
        "GF": "8",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "5",
        "S": "1",
        "HD": "4",
        "IP": "48.1",
        "ER": "25",
        "R": "28",
        "rRA": "33.35",
        "TBF": "215",
        "H": "61",
        "2B": "8",
        "3B": "0",
        "HR": "4",
        "BB": "17",
        "HP": "2",
        "IB": "3",
        "SO": "31",
        "ROE": "3",
        "BK": "0",
        "WP": "2",
        "ERA": "4.66",
        "RA9": "5.21",
        "rRA9": "6.21",
        "rRA9pf": "6.32",
        "FIP": "4.75",
        "WHIP": "1.61",
        "WAR": "0.25"
      }
    }
  ],
  [
    "5712f71263d343d6ff791a4dbe84db5df569521b",
    {
      "id": "5712f71263d343d6ff791a4dbe84db5df569521b",
      "name": "박시윤",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "49acf15421c87243efb6db43d5163e321008cf8d",
    {
      "id": "49acf15421c87243efb6db43d5163e321008cf8d",
      "name": "최성민",
      "team": "KT",
      "position": "LF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "a816f5e0ccbf9cd1ce91c072aa71ff13b4047375",
    {
      "id": "a816f5e0ccbf9cd1ce91c072aa71ff13b4047375",
      "name": "김성균",
      "team": "KT",
      "position": "SS",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "99dd9bab9a2dea2984b5163eb06df5421b358c3c",
    {
      "id": "99dd9bab9a2dea2984b5163eb06df5421b358c3c",
      "name": "김민성",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "979a75be59606deec37f4420610c05ba7d525e8b",
    {
      "id": "979a75be59606deec37f4420610c05ba7d525e8b",
      "name": "성재헌",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "26",
        "Pos.": "P",
        "G": "30",
        "GS": "1",
        "GR": "29",
        "GF": "3",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "1",
        "IP": "32.0",
        "ER": "26",
        "R": "29",
        "rRA": "27.90",
        "TBF": "159",
        "H": "46",
        "2B": "13",
        "3B": "2",
        "HR": "1",
        "BB": "16",
        "HP": "5",
        "IB": "2",
        "SO": "20",
        "ROE": "3",
        "BK": "1",
        "WP": "2",
        "ERA": "7.31",
        "RA9": "8.16",
        "rRA9": "7.85",
        "rRA9pf": "7.73",
        "FIP": "4.85",
        "WHIP": "1.94",
        "WAR": "-0.09"
      }
    }
  ],
  [
    "3ed4b7a6a6dfd95fc2044faeb42ba6fbbce31aa3",
    {
      "id": "3ed4b7a6a6dfd95fc2044faeb42ba6fbbce31aa3",
      "name": "한민우",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "237f2c6d28dedd2c2e09eb92f2b0a16f2d33271a",
    {
      "id": "237f2c6d28dedd2c2e09eb92f2b0a16f2d33271a",
      "name": "김민석",
      "team": "KT",
      "position": "C",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "ae18f70027c1cc9a3a29cb54d074fdbda6d5111f",
    {
      "id": "ae18f70027c1cc9a3a29cb54d074fdbda6d5111f",
      "name": "강건",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "올시즌",
        "Year": "2024",
        "Team": "KT",
        "Age": "19",
        "Pos.": "P",
        "G": "4",
        "GS": "1",
        "GR": "3",
        "GF": "2",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "4.0",
        "ER": "5",
        "R": "5",
        "rRA": "5.85",
        "TBF": "24",
        "H": "8",
        "2B": "0",
        "3B": "1",
        "HR": "1",
        "BB": "4",
        "HP": "1",
        "IB": "0",
        "SO": "3",
        "ROE": "0",
        "BK": "0",
        "WP": "0",
        "ERA": "11.25",
        "RA9": "11.25",
        "rRA9": "13.16",
        "rRA9pf": "13.69",
        "FIP": "9.17",
        "WHIP": "3.00",
        "WAR": "-0.18"
      }
    }
  ],
  [
    "c48e6eb2cffc304d98dc83ac80d9c41eca9f51e8",
    {
      "id": "c48e6eb2cffc304d98dc83ac80d9c41eca9f51e8",
      "name": "최정태",
      "team": "KT",
      "position": "OF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "4b5473a460d3301f0d2e21d7650cbc5b876d4475",
    {
      "id": "4b5473a460d3301f0d2e21d7650cbc5b876d4475",
      "name": "이준명",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "747bcf43bea8862c2a720977b7e8f4e6b4e2df32",
    {
      "id": "747bcf43bea8862c2a720977b7e8f4e6b4e2df32",
      "name": "조용근",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "6de221091fb7f0927f336b366329581716821298",
    {
      "id": "6de221091fb7f0927f336b366329581716821298",
      "name": "황의준",
      "team": "KT",
      "position": "LF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "00756a159d3cdc0a0fec2228aa8bf83531212a15",
    {
      "id": "00756a159d3cdc0a0fec2228aa8bf83531212a15",
      "name": "정진호",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "f780b5d3d4c734dacaf42ca4595b0e00e22d93df",
    {
      "id": "f780b5d3d4c734dacaf42ca4595b0e00e22d93df",
      "name": "김규대",
      "team": "KT",
      "position": "LF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "583b430e9cd5dc8e017a6bc9efc6bdb49544db47",
    {
      "id": "583b430e9cd5dc8e017a6bc9efc6bdb49544db47",
      "name": "최윤서",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "2d0675f6630a70e35106946ccfd957334716a491",
    {
      "id": "2d0675f6630a70e35106946ccfd957334716a491",
      "name": "신호준",
      "team": "KT",
      "position": "RF",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "2c141081937b55981b1d18d5016d596240b56f83",
    {
      "id": "2c141081937b55981b1d18d5016d596240b56f83",
      "name": "박정현",
      "team": "KT",
      "position": "2B",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "341244206b657637c0957aaca882e8fa36697502",
    {
      "id": "341244206b657637c0957aaca882e8fa36697502",
      "name": "박태완",
      "team": "KT",
      "position": "SS",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "4acfad700ccf4a314d5c518fc3e9b748e3913814",
    {
      "id": "4acfad700ccf4a314d5c518fc3e9b748e3913814",
      "name": "이근혁",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "12acb64b71b8a60105cc756440c56b4df08082b8",
    {
      "id": "12acb64b71b8a60105cc756440c56b4df08082b8",
      "name": "이승현",
      "team": "KT",
      "position": "C",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "5b7ab716ec8b5f02fc4b1751e5fd87cbd9793338",
    {
      "id": "5b7ab716ec8b5f02fc4b1751e5fd87cbd9793338",
      "name": "이승언",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "3e990af365a71348fc5d42794889480e6afde330",
    {
      "id": "3e990af365a71348fc5d42794889480e6afde330",
      "name": "김지민",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "2f02cf13a7a33fe4c70cbf8d08b3b3e9a48c6f76",
    {
      "id": "2f02cf13a7a33fe4c70cbf8d08b3b3e9a48c6f76",
      "name": "이현민",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "조회된 데이터가 없습니다."
      }
    }
  ],
  [
    "d4d5dd79a9dbc113fefa946733a79d7d184c2654",
    {
      "id": "d4d5dd79a9dbc113fefa946733a79d7d184c2654",
      "name": "최용준",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2021",
        "Team": "KIA",
        "Age": "19",
        "Pos.": "P",
        "G": "3",
        "GS": "2",
        "GR": "1",
        "GF": "0",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "1",
        "S": "0",
        "HD": "0",
        "IP": "6.1",
        "ER": "10",
        "R": "10",
        "rRA": "8.45",
        "TBF": "39",
        "H": "11",
        "2B": "4",
        "3B": "1",
        "HR": "3",
        "BB": "7",
        "HP": "1",
        "IB": "0",
        "SO": "6",
        "ROE": "1",
        "BK": "0",
        "WP": "0",
        "ERA": "14.21",
        "RA9": "14.21",
        "rRA9": "12.01",
        "rRA9pf": "11.89",
        "FIP": "10.95",
        "WHIP": "2.84",
        "WAR": "-0.21"
      }
    }
  ],
  [
    "82849cdcfbd1ecdeebadfb1b4b3149aeeb0a2019",
    {
      "id": "82849cdcfbd1ecdeebadfb1b4b3149aeeb0a2019",
      "name": "김정운",
      "team": "KT",
      "position": "P",
      "record": {
        "Div.": "베스트",
        "Year": "2023",
        "Team": "KT",
        "Age": "18",
        "Pos.": "P",
        "G": "5",
        "GS": "0",
        "GR": "5",
        "GF": "4",
        "CG": "0",
        "SHO": "0",
        "W": "0",
        "L": "0",
        "S": "0",
        "HD": "0",
        "IP": "7.0",
        "ER": "3",
        "R": "4",
        "rRA": "5.20",
        "TBF": "35",
        "H": "6",
        "2B": "2",
        "3B": "0",
        "HR": "0",
        "BB": "6",
        "HP": "2",
        "IB": "0",
        "SO": "3",
        "ROE": "1",
        "BK": "0",
        "WP": "0",
        "ERA": "3.86",
        "RA9": "5.14",
        "rRA9": "6.69",
        "rRA9pf": "6.13",
        "FIP": "6.32",
        "WHIP": "1.71",
        "WAR": "0.03"
      }
    }
  ],
  [
    "059fb395ba80cc9286b73cb0c65dd0566e704e78",
    {
      "id": "059fb395ba80cc9286b73cb0c65dd0566e704e78",
      "name": "류현인",
      "team": "KT",
      "position": "SS",
      "record": {
        "Div.": "베스트",
        "Year": "2023",
        "Team": "KT",
        "Age": "22",
        "Pos.": "3B",
        "oWAR": "-0.27",
        "dWAR": "-0.19",
        "G": "17",
        "PA": "24",
        "ePA": "24",
        "AB": "23",
        "R": "6",
        "H": "3",
        "2B": "0",
        "3B": "0",
        "HR": "0",
        "TB": "3",
        "RBI": "3",
        "SB": "0",
        "CS": "0",
        "BB": "1",
        "HP": "0",
        "IB": "0",
        "SO": "4",
        "GDP": "0",
        "SH": "0",
        "SF": "0",
        "AVG": "0.130",
        "OBP": "0.167",
        "SLG": "0.130",
        "OPS": "0.297",
        "R/ePA": "-0.042",
        "wRC+": "-34.0",
        "WAR": "-0.46"
      }
    }
  ]
];

const playerInfo = new Map();
for (const entry of playerInfoData) {
  playerInfo.set(entry[0], entry[1]);
}
console.log(`Loaded: playerInfo - ${playerInfo.size}`);

export default playerInfo;